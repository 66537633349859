import { ProOrLiteKey, TYPE_LITE } from 'constants/pipe-drive';
import { bostaHttp } from 'http.js';
/** 
 *  pipeDrive integration you should first create person and when you create person
    create deals save in you localStorage deals id and person id to use it for update
    use constant Keys for update user(person) or to update company information (deals)
    https://developers.pipedrive.com/docs/api/v1/
    
    More Details:
      steps for pipedrive:
        create persosn 
        create deals with stage_ids no need for pipeline id 
        each step we update person with new data and update deals with specific stage ids
          pipeline for lite : 12 
            stage id step 1 104 step 2 106 step3 107 step4 108 step 5 109
          pipeline for pro:13
            stage id step 1 110 step 2 111 step3 112 step4 113 step 5 114
        in company step we will create orginzation then update person with data that we need
        plus we will add orginzation id to link person to org_id then update deals
      what happen in login :
        we need to search in persons with email and searh in deals with person id 
        and title(firstname and lastname or comapny name ) 
*/
export const checkTokenPipeDrive = (method, data) => {
  return method(data);
};

export const createPerson = (data) => {
  const Title = data.name;
  try {
    bostaHttp.post('/crm/persons', data).then((response) => {
      if (response) {
        const { id } = response;
        localStorage.setItem('personId', JSON.stringify(id));
        createDeals({
          title: Title,
          person_id: id,
          stage_id: data?.[ProOrLiteKey] === TYPE_LITE ? 104 : 110
        });
      }
    });
    return true;
  } catch (e) {
    return e;
  }
};

export const updatePerson = (id, data) => {
  try {
    bostaHttp.put(`/crm/persons/${id}`, data);
  } catch (e) {
    return e;
  }
};

export const searchPerson = (email) => {
  try {
    bostaHttp.get(`/crm/persons/search?email=${email}`).then((response) => {
      if (response) {
        const item = response?.items[0]?.item;
        const personId = item?.id;
        const orgId = item?.organization?.id;
        localStorage.setItem('personId', JSON.stringify(personId));
        let searchTermsDeals;
        if (orgId != null) {
          localStorage.setItem('orgId', JSON.stringify(orgId));
          searchTermsDeals = item?.organization?.name;
        } else {
          searchTermsDeals = item?.name;
        }
        if (personId != null) {
          searchDeals({ terms: searchTermsDeals, personId: personId });
        }
      }
    });
  } catch (e) {
    return e;
  }
};

export const searchPersonId = (email) => {
  try {
    let personId;
    bostaHttp.get(`/crm/persons/search?email=${email}`).then((response) => {
      const item = response?.items[0]?.item;
      personId = item?.id;
    });
    return personId;
  } catch (e) {
    return e;
  }
};

export const createDeals = (data) => {
  try {
    bostaHttp.post('/crm/deals', data).then((response) => {
      const { id } = response;
      localStorage.setItem('dealId', JSON.stringify(id));
    });
  } catch (e) {
    return e;
  }
};

export const updateDeals = (id, data) => {
  try {
    bostaHttp.put(`/crm/deals/${id}`, data);
  } catch (e) {
    return e;
  }
};

export const searchDeals = (data) => {
  try {
    const { terms, personId } = data;
    bostaHttp
      .get(`/crm/deals/search?terms=${terms}&personId=${personId}`)
      .then((response) => {
        const item = response?.items[0]?.item;
        const dealsId = item?.id;
        if (dealsId != null) {
          localStorage.setItem('dealId', JSON.stringify(dealsId));
        }
      });
  } catch (e) {
    return e;
  }
};

export const createOrganiztion = (data) => {
  try {
    let orgId;
    bostaHttp.post('/crm/organizations', data).then((response) => {
      orgId = response.id;
      localStorage.setItem('orgId', JSON.stringify(orgId));
      const personId = localStorage.getItem('personId');
      updatePerson(personId, { org_id: orgId });
    });
    return orgId;
  } catch (e) {
    return e;
  }
};

export const updateOrganiztion = (id, data) => {
  try {
    bostaHttp.put(`/crm/organizations/${id}`, data);
  } catch (e) {
    return e;
  }
};
