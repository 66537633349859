import {
  COUNTRIES,
  KUWAIT_COUNTRY_DATA,
  OMAN_COUNTRY_DATA,
  QATAR_COUNTRY_DATA
} from 'constants/country-data';
import {
  SmartUploadInternationalOrderTemplateAR,
  SmartUploadInternationalOrderTemplateAR_EG,
  SmartUploadInternationalOrderTemplateEN,
  SmartUploadInternationalOrderTemplateEN_EG
} from 'constants/mass-upload';

export const RETURN_TO_FULFILLMENT = {
  EG: false,
  SA: false,
  AE: true
};

export const RETURN_TO_ORIGIN = {
  EG: false,
  SA: false,
  AE: true
};

export const SMART_UPLOAD_INTERNATIONAL_TEMPLATE = {
  EG: {
    EN: SmartUploadInternationalOrderTemplateEN_EG,
    AR: SmartUploadInternationalOrderTemplateAR_EG
  },
  AE: {
    EN: SmartUploadInternationalOrderTemplateEN,
    AR: SmartUploadInternationalOrderTemplateAR
  },
  DEFAULT: {
    EN: SmartUploadInternationalOrderTemplateEN_EG,
    AR: SmartUploadInternationalOrderTemplateAR_EG
  }
};

export const INTERNATIONAL_SHIPPING_DESTINATION_COUNTRIES = {
  EG: [COUNTRIES[1]],
  SA: [],
  AE: [COUNTRIES[1], QATAR_COUNTRY_DATA, OMAN_COUNTRY_DATA, KUWAIT_COUNTRY_DATA]
};

export const SHOW_INTERNATIONAL_WALLET_EXCHANGE_RATE = {
  AE: true
};
