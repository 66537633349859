import React from 'react';
// import { connect } from 'react-redux';
import { createIntl, createIntlCache, RawIntlProvider } from 'react-intl';
import querySearch from 'stringquery';

import messages from 'messages';
import {
  flattenMessages,
  getLocaleFromLocalStorage,
  setLocaleInLocalStorage,
  changeDocumentDirection
} from 'utils/intl-wrapper';
import { LOCALE } from 'constants/intl-wrapper';
// import { setLocale } from 'actions/app';

const initialLocale = 'en';
const LANGUGAES = [LOCALE.AR, LOCALE.EN];
const query = querySearch(window.location.search);
const pathLang = query.lang;
const isPathHasLang = LANGUGAES.includes(pathLang);
const locale = isPathHasLang ? pathLang : getLocaleFromLocalStorage();
export const cache = createIntlCache();
/** You can use this variable in other files even after reassigning it. */
export let intl = createIntl(
  { locale: initialLocale, messages: flattenMessages(messages[locale]) },
  cache
);
export let customizedIntl = (localeVal) =>
  createIntl(
    { locale: localeVal, messages: flattenMessages(messages[localeVal]) },
    cache
  );

export let fmt = intl.formatMessage;
export let customizedFmt = (value) => {
  return customizedIntl(value).formatMessage;
};

class IntlWrapper extends React.Component {
  componentDidMount() {
    if (isPathHasLang) {
      setLocaleInLocalStorage(pathLang);
      document.documentElement.lang = pathLang;
      changeDocumentDirection(pathLang);
    } else {
      const locale = getLocaleFromLocalStorage();
      document.documentElement.lang = locale;
      changeDocumentDirection(locale);
    }
    // if (!locale) {
    //   setLocaleInLocalStorage('en');
    // } else {
    // const { setLocalization } = this.props;
    // setLocalization(locale);
    // document.documentElement.lang = locale;
    // changeDocumentDirection(locale);
    // }
  }

  render() {
    // const { children, locale } = this.props;
    const { children } = this.props;
    const locale = isPathHasLang ? pathLang : getLocaleFromLocalStorage();

    intl = createIntl(
      {
        locale: locale === LOCALE.AR_SA ? LOCALE.AR : locale,
        messages: flattenMessages(messages[locale])
      },
      cache
    );
    fmt = intl.formatMessage;
    // const x = locale;
    // const m1 = messages;
    // const m = messages[locale];
    // document.documentElement.lang = locale;

    // changeDocumentDirection(locale);

    return <RawIntlProvider value={intl}>{children}</RawIntlProvider>;
  }
}

// const mapDispatchToProps = (dispatch) => ({
//   setLocalization: (locale) => dispatch(setLocale(locale))
// });

// const mapStateToProps = ({ app: { locale } }) => {
//   return { locale };
// };

export default IntlWrapper;
// export default connect(mapStateToProps, mapDispatchToProps)(IntlWrapper);
