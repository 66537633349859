import { fmt } from 'components/IntlWrapper/IntlWrapper';

export const PASSWORD_VALIDATION_RULES = [
  {
    label: fmt({
      id: 'login.set_new_passowrd.contains__number'
    }),
    value: 'containsNumber'
  },
  {
    label: fmt({
      id: 'login.set_new_passowrd.at_least_eight_chars'
    }),
    value: 'atLeastEightChars'
  },
  {
    label: fmt({
      id: 'login.set_new_passowrd.at_least_one_uppercase'
    }),
    value: 'atLeastOneUpperCase'
  },
  {
    label: fmt({
      id: 'login.set_new_passowrd.at_least_special_char'
    }),
    value: 'atLeastOneSpecialChar'
  }
];
