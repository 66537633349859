import { DAYS_OF_WEEK } from 'constants/helpers';

export const WITHOUT_COUNTRY_CODE = 'WITHOUT_COUNTRY_CODE';
export const DEFAULT = 'DEFAULT';

export const AWB_LANGUAGE = {
  EG: 'ar',
  SA: 'en',
  DEFAULT: 'ar'
};

export const PHONE_NUMBER_REGEX = {
  EG: {
    WITHOUT_COUNTRY_CODE: /^1[0-2|5]{1}[0-9]{8}$/,
    DEFAULT: /^(\+201|01)[0-2|5]{1}[0-9]{8}$/
  },
  SA: {
    WITHOUT_COUNTRY_CODE: /^(05|5)([013-9][0-9]{7})$/,
    DEFAULT: /^(\+9665|05|5)([013-9][0-9]{7})$/
  },
  AE: {
    WITHOUT_COUNTRY_CODE: /^(50|52|54|55|56|58)\d{7}$/,
    DEFAULT: /^(?:\+971|00971|0)?(?:50|52|54|55|56|58)\d{7}$/
  },
  QA: {
    WITHOUT_COUNTRY_CODE: /^[3-7][0-9]{7}$/,
    DEFAULT: /^(\+974|974)?[3-7][0-9]{7}$/
  },
  OM: {
    WITHOUT_COUNTRY_CODE: /^(9[1-9][0-9]{6})$/,
    DEFAULT: /^(\+968|968)?9[1-9][0-9]{6}$/
  },
  KW: {
    WITHOUT_COUNTRY_CODE: /^[569|41][0-9]{7}$/,
    DEFAULT: /^(\+965|965)?[569|41][0-9]{7}$/
  }
};

export const ORDERS_SEARCH_PHONE_NUMBER_REGEX = {
  EG: /^(\+2)?(?=\d{10,11}$)0[128]\d{9}$/,
  SA: /^\+(?:[\d -]*)|(\+9665|05|5)([013-9][0-9]{7})$/,
  DEFAULT: /^(\+2)?(?=\d{10,11}$)0[128]\d{9}$/
};

export const ENGLISH_LETTERS_RULE_APPLICABLE = {
  EG: false,
  SA: true,
  DEFAULT: false
};

export const PICKUP_SCHEDULE_TIME_FOR_SAME_DAY = {
  DEFAULT: 4
};

export const IS_SHOP_AVAILABLE = {
  EG: true,
  SA: false
};

export const COUNTRY_PHONE_PLACEHOLDER = {
  EG: 'products.sllr_store.whatsapp_placeholder',
  SA: 'products.sllr_store.whatsapp_placeholder_sa',
  AE: 'products.sllr_store.whatsapp_placeholder_ae'
};

export const CITY_AREA_PLACEHOLDER_ID = {
  EG: 'br_area.city_area_search_placeholder',
  SA: 'br_area.city_area_search_placeholder_sa',
  AE: 'br_area.city_area_search_placeholder_ae',
  QA: 'br_area.city_area_search_placeholder_qa',
  OM: 'br_area.city_area_search_placeholder_om',
  KW: 'br_area.city_area_search_placeholder_kw'
};

export const NATIONAL_ID_COUNT = {
  EG: 14,
  SA: 10
};

export const INTERNATIONAL_SHIPPING = {
  EG: true,
  SA: false,
  AE: true
};

export const DOMESTIC_SHIPPING = {
  EG: true,
  SA: true,
  AE: false
};

export const ADD_HYPHENS_TO_IDENTITY_NUMBER = {
  EG: false,
  SA: false,
  AE: true
};

export const WEEKEND_DAYS = {
  EG: [DAYS_OF_WEEK.FRIDAY],
  SA: [DAYS_OF_WEEK.FRIDAY],
  AE: []
};

export const PACK_PRICE_AVAILABILITY = {
  EG: true,
  SA: false,
  AE: false
};

export const BETA_FUNNEL_AVAILABILITY = {
  EG: true,
  SA: false,
  AE: false
};
