import { useCallback, useRef, useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import dayjs from 'dayjs';
import {
  Radio,
  Dropdown,
  Checkbox,
  Menu,
  Input,
  Select,
  DatePicker
} from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import classnames from 'classnames';

import { LOCALE } from 'constants/intl-wrapper';
import { DATE_FORMAT } from 'constants/date-picker';
import { cleanEmptyString } from 'utils/helpers';

import BRDatePicker from 'components/BRDatePicker/BRDatePicker';
import BRButton from 'components/BRButton/BRButton';

import { ReactComponent as Right } from 'assets/bosta-icons/Right.svg';
import { ReactComponent as Left } from 'assets/bosta-icons/Left.svg';
import { ReactComponent as DropdownIcon } from 'assets/bosta-icons/Dropdown.svg';
import { ReactComponent as InfoCircle } from 'assets/bosta-icons/info.svg';

import './TableFilters.less';

const TableFilters = ({
  tableFiltersItems = [],
  intl,
  updateFilterLabels,
  mobileScreenSizes: { isSmallMobileScreen, isTinylMobileScreen },
  filterData = {},
  setFilterData,
  appliedFilterLabels = {},
  setAppliedFilterLabels,
  isLoading = false
}) => {
  const [scrollStart, setScrollStart] = useState(true); // For detecting start scroll postion
  const [scrollEnd, setScrollEnd] = useState(false); // For detecting end of scrolling
  const [appliedFilter, setAppliedFilter] = useState(filterData);
  const [mainFiltersIndexes, setMainFiltersIndexes] = useState([]);
  const [filterLabels, setFilterLabels] = useState({});
  const [searchValue, setSearchValue] = useState('');
  const [customizedList, setCustomizedList] = useState('');
  const [dateRangeValue, setDateRangeValue] = useState({});
  const [filterCustomizedListOptions, setFilterCustomizedListOptions] =
    useState([]);
  const [isVisible, setIsVisible] = useState(null);

  const scrollingRef = useRef('');
  const buttonRef = useRef('');
  const slide = (type, value) => {
    if (value) {
      scrollingRef.current.scrollLeft += value;
    } else {
      const element = document.getElementsByClassName('br-table-filters')[0];
      const shift = element?.getBoundingClientRect()?.width;
      const buttonWidth = 110;
      if (type === 'right') {
        scrollingRef.current.scrollLeft += shift - buttonWidth;
      } else if (type === 'left') {
        scrollingRef.current.scrollLeft -= shift;
      }
    }
    //For checking if the scroll is at the start
    if (
      Math.floor(scrollingRef.current.scrollWidth) ===
      scrollingRef.current.scrollWidth - scrollingRef.current.scrollLeft
    ) {
      setScrollStart(true);
    } else {
      setScrollStart(false);
    }
    //For checking if the scroll has ended
    if (
      Math.floor(
        scrollingRef.current.scrollWidth - scrollingRef.current.scrollLeft
      ) <= scrollingRef.current.offsetWidth
    ) {
      setScrollEnd(true);
    } else {
      setScrollEnd(false);
    }
  };

  const checkIfButtonIsFaded = async (index) => {
    const element = document.getElementById(`radio-button${index}`);
    const scroll = document.getElementsByClassName('br-table-filters')[0];
    const scrollWidth = scroll?.getBoundingClientRect()?.x;
    const scrollPosition = scroll?.getBoundingClientRect()?.width;
    const elementPosition = element?.getBoundingClientRect().x;
    const elementWidth = element?.getBoundingClientRect().width;
    if (elementPosition > scrollWidth + scrollPosition - elementWidth) {
      handleRightScrolling(isSmallMobileScreen ? +elementWidth : +80);
    } else if (elementPosition < scrollWidth) {
      handleLeftScrolling(isSmallMobileScreen ? -100 : -80);
    }
  };

  useEffect(() => {
    setFilterLabels(appliedFilterLabels);
  }, [appliedFilterLabels]);
  useEffect(() => {
    const checkCustomizedList = tableFiltersItems?.filter(
      (item) => item?.fetchingAPI
    );
    if (checkCustomizedList?.length) {
      checkCustomizedList.map(({ fetchingAPI, key }) => {
        getCustomizedData(fetchingAPI, key);
      });
    }
  }, []);

  const handleApplyFilter = ({
    key,
    title,
    dateTitle,
    updatedLabels,
    selectKey,
    dateKey
  }) => {
    const labels = updatedLabels ? updatedLabels : filterLabels;
    const newFilterLabels = Object.assign({}, labels, {
      [title]: Array.isArray(labels[title])
        ? [...labels[title]]
        : [{ label: labels[title], key: key || selectKey }]
    });
    if (filterLabels[title] && dateTitle) {
      const lastIndex =
        newFilterLabels[title][newFilterLabels[title]?.length - 1];
      const lastIndexValue = lastIndex?.value;
      const lastIndexLabel = lastIndex?.label;
      const startDate = dateRangeValue?.length ? dateRangeValue[0] : null;
      const endDate = dateRangeValue?.length ? dateRangeValue[1] : null;
      newFilterLabels[title].filter((item) => delete item.dateLabel);
      newFilterLabels[title].splice(-1, 1, {
        label: lastIndexLabel,
        dateLabel: `(${startDate?.format('D MMM YYYY')}-${endDate?.format(
          'D MMM YYYY'
        )})`,
        key: selectKey || '',
        dateKey: dateKey || '',
        value: lastIndexValue
      });
    }
    if (dateKey) {
      setAppliedFilter({ ...appliedFilter, [dateKey]: filterData[dateKey] });
    }
    if (selectKey) {
      setAppliedFilter({
        ...appliedFilter,
        [selectKey]: filterData[selectKey]
      });
    } else {
      setAppliedFilter({ ...appliedFilter, [key]: filterData[key] });
    }
    setAppliedFilterLabels({
      ...appliedFilterLabels,
      [title]: newFilterLabels[title]
    });
    updateFilterLabels({
      ...appliedFilterLabels,
      [title]: newFilterLabels[title]
    });
  };

  const handleDateFilters = async ({
    pickedUpAtStart,
    pickedUpAtEnd,
    key,
    label
  }) => {
    const newLables = {
      ...filterLabels,
      [label]: `${dayjs(pickedUpAtStart).format('D MMM YYYY')}-${dayjs(
        pickedUpAtEnd
      ).format('D MMM YYYY')}`
    };
    if (!filterLabels[label]) {
      await setFilterLabels(newLables);
    }
    await setFilterData({
      ...filterData,
      [`${key}Start`]: pickedUpAtStart,
      [`${key}End`]: pickedUpAtEnd
    });
    handleApplyFilter({
      key,
      title: label,
      updatedLabels: newLables
    });
  };

  const onChangeFilterValue = ({
    title,
    label,
    checked,
    value,
    key,
    index,
    menuValue = []
  }) => {
    const mappedMenu = menuValue?.map(({ value }) => value);
    const newFilterObj = checked
      ? filterData[key]
        ? [...filterData[key], value]
        : [value]
      : filterData[key]?.filter((item) => !value.includes(item));
    const newFilterLabels = checked
      ? filterLabels[title]
        ? [...filterLabels[title], { label, value, key }]
        : [{ label, value, key }]
      : filterLabels[title]?.filter((item) => item.label !== label);
    const containsAll = mappedMenu.flat().every((element) => {
      return newFilterObj.flat().includes(element);
    });
    if (containsAll && checked) {
      setMainFiltersIndexes(
        getUniqueListBy([...mainFiltersIndexes, { key, index }], 'key')
      );
    } else {
      setMainFiltersIndexes(
        mainFiltersIndexes.filter(
          ({ index: filterIndex }) => filterIndex !== index
        )
      );
    }
    let newValue = filterData;
    if (newFilterObj.length) {
      newValue = {
        ...newValue,
        [key]: newFilterObj.flat()
      };
    } else {
      delete newValue[key];
    }
    setFilterData(newValue);
    setFilterLabels({
      ...filterLabels,
      [title]: getUniqueListBy(newFilterLabels?.flat(), 'label')
    });
  };

  function getUniqueListBy(arr, key) {
    return [...new Map(arr?.map((item) => [item[key], item])).values()];
  }

  const onChangeMainFilterValue = (
    checked,
    recievedValue,
    key,
    index,
    title
  ) => {
    const mappedLabels = [];
    const mappedValue = recievedValue.map((item) => {
      mappedLabels.push({ label: item.label, key, value: item.value });
      return item.value;
    });

    const newFilter = filterData[key];
    let filterObj = {};
    let newFilterLabels = {};
    if (checked) {
      setMainFiltersIndexes([...mainFiltersIndexes, { key, index }]);
      if (filterData[key]) {
        const values = mappedValue.filter((item) => !newFilter.includes(item));
        filterObj = {
          [key]: [...filterData[key], ...values.flat()]
        };
        newFilterLabels = {
          [title]: getUniqueListBy(
            [...filterLabels[title], ...mappedLabels],
            'label'
          )
        };
      } else {
        filterObj = { [key]: mappedValue.flat() };
        newFilterLabels = { [title]: mappedLabels };
      }
    } else {
      setMainFiltersIndexes(
        mainFiltersIndexes.filter(
          (item) => item.index !== index && item.key === key
        )
      );
      let checkedObj = filterData[key]?.filter(
        (item) => !mappedValue.flat().includes(item)
      );
      newFilterLabels = {
        [title]: filterLabels[title].filter(function (objFromA) {
          return !mappedLabels?.find(function (objFromB) {
            return objFromA.label === objFromB.label;
          });
        })
      };
      filterObj = { [key]: checkedObj };
    }
    setFilterData({
      ...filterData,
      ...filterObj
    });
    setFilterLabels({ ...filterLabels, ...newFilterLabels });
  };

  const handleClearFilter = async ({ key, title, dateKey, selectKey }) => {
    setIsVisible(null);
    const newFilterObj = { ...filterData };
    const newAppliedFilterObj = { ...appliedFilter };
    const newFilterLables = { ...filterLabels };
    const newAppliedFilterLables = { ...appliedFilterLabels };
    delete newFilterObj[key || selectKey];
    delete newFilterLables[title];
    delete newAppliedFilterLables[title];
    setMainFiltersIndexes(
      mainFiltersIndexes?.filter((item) => ![key, selectKey].includes(item.key))
    );
    if (dateKey) {
      delete newFilterObj[`${dateKey}Start`];
      delete newFilterLables[`${dateKey}End`];
      setDateRangeValue({});
    }
    await setFilterData({ ...newFilterObj });
    setFilterLabels(newFilterLables);
    setAppliedFilterLabels(newAppliedFilterLables);
    if (Object.keys(appliedFilter).includes(key || selectKey)) {
      delete newAppliedFilterObj[key || selectKey];
      updateFilterLabels(newAppliedFilterLables);
      setAppliedFilter(newAppliedFilterObj);
    }
  };

  const handleCancelFilter = () => {
    setIsVisible(null);
    setFilterData(appliedFilter);
  };

  const resetValues = () => {
    setIsVisible(null);
    setSearchValue(null);
    setFilterCustomizedListOptions(customizedList);
  };

  const onChangeRadio = ({ value, key, title, menu }) => {
    setFilterData({
      ...filterData,
      [key]: value
    });
    const checkedItem = menu?.find((item) => item.value === value);
    setFilterLabels({ ...filterLabels, [title]: checkedItem?.label });
  };

  const getCustomizedData = useCallback(
    async (fetchingAPI, key) => {
      try {
        const { list } = await fetchingAPI();
        setCustomizedList([...customizedList, { key, list }]);
        setFilterCustomizedListOptions([
          ...filterCustomizedListOptions,
          { key, list }
        ]);
        return null;
      } catch (error) {
        // notify({ msg: error.message, ...error });
      }
    },
    [customizedList]
  );

  const isSearchMatched = ({
    record,
    value,
    keyToBeSelectedFromListInEn,
    keyToBeSelectedFromListInAr
  }) =>
    intl.locale === LOCALE.AR && keyToBeSelectedFromListInAr
      ? record[keyToBeSelectedFromListInAr]
          ?.toLowerCase()
          .includes(value.toLowerCase())
      : record[keyToBeSelectedFromListInEn]
          ?.toLowerCase()
          .includes(value.toLowerCase());

  const onChangeInputSearch = async ({
    value,
    key,
    keyToBeSelectedFromListInEn,
    keyToBeSelectedFromListInAr
  }) => {
    const { list } = customizedList?.find((list) => list.key === key);
    await setSearchValue(value);
    setFilterCustomizedListOptions([
      {
        key,
        list: cleanEmptyString(
          list.map((record) =>
            isSearchMatched({
              record,
              value,
              keyToBeSelectedFromListInEn,
              keyToBeSelectedFromListInAr
            })
              ? record
              : null
          )
        )
      }
    ]);
  };

  const handleCustomizedOverlay = useCallback(
    ({
      key,
      label,
      placeholder,
      className,
      keyToBeSelectedFromListInEn,
      keyToBeSelectedFromListInAr
    }) => {
      const list = filterCustomizedListOptions?.find(
        (listObj) => listObj.key === key
      )?.list;
      return (
        <Menu
          onClick={(e) => e.domEvent.stopPropagation()}
          key={key}
          className={className}
        >
          <div className="br-table-filter__menu-header">
            <BRButton
              type="basic"
              className="button-sm"
              label={intl.formatMessage({
                id: `common.${appliedFilter[key]?.length ? 'clear' : 'cancel'}`
              })}
              onClick={() => {
                appliedFilter[key]?.length
                  ? handleClearFilter({ key, title: label })
                  : handleCancelFilter();
              }}
            />
            <span className="br-table-filter__menu-header__title body-medium">
              {label}
            </span>
            <BRButton
              label={intl.formatMessage({ id: 'common.apply' })}
              type="primary"
              className="button-sm"
              disabled={!Object.keys(filterData).includes(key)}
              onClick={() => {
                resetValues();
                handleApplyFilter({ key, title: label });
              }}
            />
          </div>
          <div className="br-table-filter__menu-content__input-text">
            <Input
              placeholder={placeholder}
              ref={(inputRef) => inputRef && inputRef.focus()}
              onChange={({ target: { value } }) =>
                onChangeInputSearch({
                  value,
                  key,
                  keyToBeSelectedFromListInEn,
                  keyToBeSelectedFromListInAr
                })
              }
              allowClear
            />
          </div>
          <div className="br-table-filter__menu-content">
            {list?.map((item) => (
              <Menu.Item
                key={item._id}
                onClick={(e) => e.domEvent.stopPropagation()}
              >
                <div>
                  <Checkbox
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    checked={filterData[key]?.indexOf(item._id) >= 0}
                    onChange={({ target: { checked } }) =>
                      onChangeFilterValue({
                        title: label,
                        label:
                          item[
                            intl.locale === LOCALE.AR &&
                            keyToBeSelectedFromListInAr
                              ? keyToBeSelectedFromListInAr
                              : keyToBeSelectedFromListInEn
                          ],
                        checked,
                        value: item._id,
                        key
                      })
                    }
                  >
                    <Highlighter
                      highlightClassName="br-area__highlighted-text"
                      searchWords={[searchValue]}
                      autoEscape
                      textToHighlight={
                        intl.locale === LOCALE.AR &&
                        keyToBeSelectedFromListInAr &&
                        item[keyToBeSelectedFromListInAr]
                          ? item[keyToBeSelectedFromListInAr]
                          : item[keyToBeSelectedFromListInEn]
                      }
                    />
                  </Checkbox>
                </div>
              </Menu.Item>
            ))}
          </div>
        </Menu>
      );
    },
    [filterCustomizedListOptions, customizedList, appliedFilter, filterData]
  );

  const isMainValueChecked = (index, key) =>
    mainFiltersIndexes.some(
      (item) => (item?.selectKey || item?.key) === key && item.index === index
    );

  const isMainValueIndeterminate = (index, key, value) =>
    filterData[key]?.some((item) =>
      value
        ?.map((item) => item.value)
        .flat()
        .includes(item)
    ) &&
    !mainFiltersIndexes.filter(
      (item) => (item?.selectKey || item?.key) === key && item.index === index
    ).length;

  const handleOverlayMenu = ({
    menu,
    label: title,
    key,
    className,
    menuType,
    placeholder,
    note,
    noteSubtitle,
    selectPlaceholder,
    selectTitle,
    dateTitle,
    selectKey,
    dateKey,
    extraTitle,
    chooseFirstAsDefault
  }) => {
    return (
      <Menu className={className} key={key}>
        <div className="br-table-filter__menu-header">
          <BRButton
            type="basic"
            className="button-sm"
            label={intl.formatMessage({
              id: `common.${
                filterData && Object.keys(filterData).includes(key || selectKey)
                  ? 'clear'
                  : 'cancel'
              }`
            })}
            onClick={() =>
              Object.keys(filterData).includes(key || selectKey)
                ? handleClearFilter({ key, title, dateKey, selectKey })
                : handleCancelFilter()
            }
          />

          <span className="br-table-filter__menu-header__title body-medium">
            {extraTitle || title}
          </span>
          <BRButton
            label={intl.formatMessage({ id: 'common.apply' })}
            type="primary"
            className="button-sm"
            disabled={
              menuType === 'dropdown_with_date' && filterData
                ? !Object.keys(filterData).includes(selectKey) ||
                  !Object.keys(filterData).includes(`${dateKey}Start`)
                : !Object.keys(filterData).includes(key)
            }
            onClick={() => {
              setIsVisible(null);
              handleApplyFilter({ title, key, dateTitle, selectKey, dateKey });
            }}
          />
        </div>
        <div className="br-table-filter__menu-content">
          {menuType === 'dropdown_with_date' ? (
            <>
              {note && (
                <div className="br-table-filter__menu-content__input-text--note caption">
                  <Icon
                    className="br-table-filter__menu-content__input-text--note__icon"
                    component={InfoCircle}
                  />
                  <span>
                    {note}
                    {noteSubtitle && (
                      <div className="br-table-filter__menu-content__input-text--note__subtitle caption">
                        {noteSubtitle}
                      </div>
                    )}
                  </span>
                </div>
              )}
              <span className="br-table-filter__menu-content__title">
                {selectTitle}
              </span>
              <Select
                placeholder={selectPlaceholder}
                getPopupContainer={(trigger) =>
                  trigger.parentElement.parentElement.parentElement
                }
                value={filterLabels[title]
                  ?.map((item) => item.label)
                  .join(', ')}
              >
                {menu.map(({ label, value, className }, index) => (
                  <Menu className={className} key={className}>
                    <Menu.ItemGroup
                      title={
                        <div key={label}>
                          <Checkbox
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                            checked={isMainValueChecked(index, selectKey)}
                            indeterminate={isMainValueIndeterminate(
                              index,
                              selectKey,
                              value
                            )}
                            onChange={({ target: { checked } }) => {
                              onChangeMainFilterValue(
                                checked,
                                value,
                                selectKey,
                                index,
                                title
                              );
                            }}
                            key={label}
                          >
                            <span
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            >
                              {label}
                            </span>
                          </Checkbox>
                        </div>
                      }
                    >
                      <>
                        <div className="br-table-filter__menu-border"></div>
                        <div>
                          {value.map((childItem) => (
                            <Menu.Item
                              key={childItem.value}
                              onClick={(e) => e.domEvent.stopPropagation()}
                            >
                              <div>
                                <Checkbox
                                  key={key}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                  checked={childItem.value?.every((v) =>
                                    filterData[selectKey]?.includes(v)
                                  )}
                                  onChange={({ target: { checked } }) =>
                                    onChangeFilterValue({
                                      title,
                                      label: childItem.label,
                                      checked,
                                      value: childItem.value,
                                      key: selectKey,
                                      index,
                                      menuValue: value
                                    })
                                  }
                                >
                                  <span
                                    onClick={(e) => {
                                      e.stopPropagation();
                                    }}
                                  >
                                    {childItem.label}
                                  </span>
                                </Checkbox>
                              </div>
                            </Menu.Item>
                          ))}
                        </div>
                      </>
                    </Menu.ItemGroup>
                  </Menu>
                ))}
              </Select>
              <span className="br-table-filter__menu-content__title">
                {dateTitle}
              </span>
              <DatePicker.RangePicker
                onChange={(dateObjects) => {
                  if (!dateObjects) {
                    return setDateRangeValue({});
                  }
                  onDateChanged(dateObjects, dateKey, title);
                }}
                value={dateRangeValue}
              />
            </>
          ) : menuType === 'radio' ? (
            <Radio.Group
              onChange={({ target: { value } }) =>
                onChangeRadio({ value, key, title, menu })
              }
              className="br-table-filter__menu-content__radio-menu"
              value={
                chooseFirstAsDefault ? filterData[key] || null : filterData[key]
              }
            >
              {menu.map(({ value, label }) => (
                <Radio value={value} key={value}>
                  {label}
                </Radio>
              ))}
            </Radio.Group>
          ) : menuType === 'text' ? (
            <div className="br-table-filter__menu-content__input-text">
              {note && (
                <div className="br-table-filter__menu-content__input-text--note caption">
                  <Icon
                    className="br-table-filter__menu-content__input-text--note__icon"
                    component={InfoCircle}
                  />
                  {note}
                </div>
              )}
              <Input
                placeholder={placeholder}
                ref={(inputRef) => inputRef && inputRef.focus()}
                value={filterData && filterData[key]}
                onChange={({ target: { value } }) => {
                  setFilterData({ ...filterData, [key]: value });
                  setFilterLabels({ ...filterLabels, [title]: value });
                }}
                onKeyDown={({ keyCode }) =>
                  keyCode === 13 ? handleApplyFilter({ title, key }) : ''
                }
              />
            </div>
          ) : (
            menu?.map(({ label, value, className }, index) => {
              return Array.isArray(value) ? (
                <Menu.ItemGroup
                  key={className}
                  className={className}
                  title={
                    <div>
                      <Checkbox
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        checked={isMainValueChecked(index, key)}
                        indeterminate={isMainValueIndeterminate(
                          index,
                          key,
                          value
                        )}
                        onChange={({ target: { checked } }) => {
                          onChangeMainFilterValue(
                            checked,
                            value,
                            key,
                            index,
                            title
                          );
                        }}
                      >
                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          {label}
                        </span>
                      </Checkbox>
                    </div>
                  }
                >
                  <>
                    <div className="br-table-filter__menu-border"></div>
                    <div>
                      {value.map((childItem) => (
                        <Menu.Item
                          key={childItem.value}
                          onClick={(e) => e.domEvent.stopPropagation()}
                        >
                          <div>
                            <Checkbox
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                              checked={childItem.value?.every((v) =>
                                filterData[key]?.includes(v)
                              )}
                              onChange={({ target: { checked } }) =>
                                onChangeFilterValue({
                                  title,
                                  label: childItem.label,
                                  checked,
                                  value: childItem.value,
                                  key,
                                  index,
                                  menuValue: value
                                })
                              }
                            >
                              <span
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                              >
                                {childItem.label}
                              </span>
                            </Checkbox>
                          </div>
                        </Menu.Item>
                      ))}
                    </div>
                  </>
                </Menu.ItemGroup>
              ) : (
                <Menu.Item
                  key={value}
                  onClick={(e) => e.domEvent.stopPropagation()}
                >
                  <div>
                    <Checkbox
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      checked={filterData[key]?.indexOf(value) >= 0}
                      onChange={({ target: { checked } }) =>
                        onChangeFilterValue({
                          title,
                          label,
                          checked,
                          value,
                          key
                        })
                      }
                    >
                      <span
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        {label}
                      </span>
                    </Checkbox>
                  </div>
                </Menu.Item>
              );
            })
          )}
        </div>
      </Menu>
    );
  };

  const onDateChanged = async (dateObjects, dateKey, label) => {
    const formattedDateValues = dateObjects.map((value) =>
      dayjs(value).format(DATE_FORMAT)
    );
    const dates = {
      [`${dateKey}Start`]: formattedDateValues[0],
      [`${dateKey}End`]: formattedDateValues[1]
    };
    setDateRangeValue(dateObjects);
    setFilterData({ ...filterData, ...dates });
  };

  const handleLeftScrolling = useCallback((value) => {
    slide('left', value);
  }, []);

  const handleRightScrolling = useCallback((value) => {
    slide('right', value);
  }, []);

  return (
    <>
      <div
        className={classnames(
          'br-table-filters',
          {
            'br-table-filters__table-without-search':
              tableFiltersItems?.length <= 6
          },
          {
            'br-table-filters__left-fade-in':
              !isTinylMobileScreen &&
              !scrollStart &&
              tableFiltersItems?.length > 6
          },
          {
            'br-table-filters__right-fade-in':
              !isTinylMobileScreen &&
              !scrollEnd &&
              tableFiltersItems?.length > 6
          }
        )}
      >
        <Radio.Group ref={scrollingRef} disabled={isLoading}>
          {tableFiltersItems.map(
            (
              {
                label,
                menu,
                key,
                className,
                menuType,
                placeholder,
                note,
                noteSubtitle,
                fetchingAPI,
                selectPlaceholder,
                selectTitle,
                dateTitle,
                selectKey,
                dateKey,
                keyToBeSelectedFromListInEn,
                keyToBeSelectedFromListInAr,
                isUserAuthorized = true,
                extraTitle,
                id,
                chooseFirstAsDefault = false
              },
              index
            ) =>
              isUserAuthorized &&
              (menuType === 'date' ? (
                <div
                  ref={buttonRef}
                  id={`radio-button${index}`}
                  onClick={() => checkIfButtonIsFaded(index)}
                >
                  <Radio.Button
                    key={label}
                    value={label}
                    id={`radio-button${index}`}
                    className={classnames('br-date-picker-radio-button', {
                      'br-confirmed-at-drop-down-open': isVisible === index
                    })}
                  >
                    <BRDatePicker
                      isActive={true}
                      withOutRanges
                      header={label}
                      label={
                        <>
                          {label} <DropdownIcon />
                        </>
                      }
                      fixedLabel={true}
                      getFilterData={(dates) =>
                        handleDateFilters({ ...dates, key, label })
                      }
                      checkVisbilty={(visible) => {
                        setIsVisible(visible ? index : null);
                      }}
                      dropdownClassName="br-filter-date__dropdown"
                    />
                  </Radio.Button>
                </div>
              ) : (
                <Dropdown
                  key={label}
                  overlayClassName={classnames('br-filter-dropdown', {
                    'br-filter-dropdown__with-note': note || fetchingAPI
                  })}
                  destroyPopupOnHide
                  visible={isVisible === index}
                  onVisibleChange={(visible) => {
                    if (filterData[`${dateKey}Start`]) {
                      setDateRangeValue([
                        dayjs(filterData[`${dateKey}Start`]),
                        dayjs(filterData[`${dateKey}End`])
                      ]);
                    }
                    setIsVisible(visible ? index : null);
                  }}
                  overlay={() =>
                    fetchingAPI
                      ? handleCustomizedOverlay({
                          label,
                          key,
                          placeholder,
                          className,
                          fetchingAPI,
                          note,
                          noteSubtitle,
                          keyToBeSelectedFromListInEn,
                          keyToBeSelectedFromListInAr
                        })
                      : handleOverlayMenu({
                          menu,
                          label,
                          key,
                          className,
                          menuType,
                          placeholder,
                          note,
                          noteSubtitle,
                          selectPlaceholder,
                          selectTitle,
                          dateTitle,
                          selectKey,
                          dateKey,
                          extraTitle,
                          chooseFirstAsDefault
                        })
                  }
                  trigger={['click']}
                  placement={
                    isSmallMobileScreen && placeholder ? 'top' : 'bottom'
                  }
                  getPopupContainer={(trigger) =>
                    trigger.parentElement.parentElement.parentElement
                      .parentElement.parentElement.parentElement
                  }
                >
                  <div
                    ref={buttonRef}
                    id={`radio-button${index}${id || ''}`}
                    onClick={() => checkIfButtonIsFaded(index)}
                  >
                    <Radio.Button key={label} value={label}>
                      {label}
                      <DropdownIcon />
                    </Radio.Button>
                  </div>
                </Dropdown>
              ))
          )}
        </Radio.Group>
      </div>
      {!isSmallMobileScreen && tableFiltersItems?.length > 6 && (
        <>
          <Icon
            component={intl.locale === LOCALE.EN ? Left : Right}
            className={classnames(
              'ant-icon-sm',
              'br-table-filters__left-arrow',
              {
                'br-table-filters__not-active-arrow': scrollStart
              }
            )}
            onClick={() =>
              intl.locale === LOCALE.EN
                ? handleLeftScrolling()
                : handleRightScrolling()
            }
          />
          <Icon
            className={classnames(
              'ant-icon-sm',
              'br-table-filters__right-arrow',
              {
                'br-table-filters__not-active-arrow': scrollEnd
              }
            )}
            component={intl.locale === LOCALE.EN ? Right : Left}
            onClick={() =>
              intl.locale === LOCALE.EN
                ? handleRightScrolling()
                : handleLeftScrolling()
            }
          />
        </>
      )}
    </>
  );
};

export default injectIntl(TableFilters);
