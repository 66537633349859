import { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Dropdown, Progress } from 'antd';
import classnames from 'classnames';
import { useLocation } from 'react-router-dom';

import { isUserFullyActivated } from 'utils/features-access';
import { isUserAuth } from 'utils/helpers';
import { getActiveStep } from 'utils/home-page-activation';
import { ContextWrapper } from 'contexts/wrapper.context';
import {
  ACTIVATION_STEPS_COUNT,
  SETUP_GUIDE_AUTO_SHOW_PATHNAME,
  SETUP_GUIDE_EXCLUDED_PATHNAME
} from 'constants/home-page-activation';

import ActivationSteps from 'components/HomePageActivation/components/HomePageActivationSteps/components/ActivationSteps/ActivationSteps';

import { ReactComponent as FlagIcon } from 'assets/bosta-icons/flag.svg';
import { ReactComponent as UpArrow } from 'assets/bosta-icons/Chevron-up.svg';
import { ReactComponent as DownArrow } from 'assets/bosta-icons/Chevron-down.svg';

import './SetupGuideWrapper.less';

const SetupGuideWrapper = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isButtonVisible, setIsButtonVisible] = useState(false);

  const { businessInfo, isFetchingData } = useContext(ContextWrapper);

  const intl = useIntl();
  const location = useLocation();

  const currentStep = getActiveStep(businessInfo) + 1;

  useEffect(() => {
    const shouldRenderSetupGuideButton = determineButtonVisibility();
    setIsButtonVisible(shouldRenderSetupGuideButton);

    if (shouldRenderSetupGuideButton) {
      checkPathnameAndUpdateVisibility();
    }
  }, [location, isFetchingData]);

  const determineButtonVisibility = () => {
    return !isUserFullyActivated && !isFetchingData && isUserAuth();
  };

  const checkPathnameAndUpdateVisibility = () => {
    if (isExcludedPath()) {
      setIsButtonVisible(false);
    }

    if (isAutoShowPath()) {
      setIsVisible(true);
    }
  };

  const isExcludedPath = () => {
    return SETUP_GUIDE_EXCLUDED_PATHNAME.some((path) =>
      location.pathname.includes(path)
    );
  };

  const isAutoShowPath = () => {
    return SETUP_GUIDE_AUTO_SHOW_PATHNAME.some((path) =>
      location.pathname.includes(path)
    );
  };

  const handleOnClick = (value) => {
    setIsVisible(!isVisible);
  };

  const handleOnAction = () => {
    setIsVisible(false);
  };

  const renderDropdownOverlay = () => (
    <div className="br-setup-guide__dropdown-overlay">
      <ActivationSteps isSetupGuide onAction={handleOnAction} />
    </div>
  );

  const calculateProgressPercentage = () => {
    return (currentStep / ACTIVATION_STEPS_COUNT) * 100;
  };

  const renderSetupGuide = () => {
    if (!isButtonVisible) {
      return null;
    }

    return (
      <Dropdown
        trigger={['click']}
        visible={isVisible}
        onClick={handleOnClick}
        placement="topLeft"
        overlay={renderDropdownOverlay}
      >
        <div
          className={classnames('br-setup-guide__container', {
            'br-setup-guide__hidden': !isVisible
          })}
        >
          <Progress
            type="circle"
            width={26}
            percent={calculateProgressPercentage()}
            format={() => <FlagIcon />}
          />
          {intl.formatMessage(
            { id: 'setup_guide.title' },
            {
              currentStep: currentStep,
              stepsCount: ACTIVATION_STEPS_COUNT
            }
          )}
          {isVisible ? <UpArrow /> : <DownArrow />}
        </div>
      </Dropdown>
    );
  };

  return (
    <>
      {children}
      {renderSetupGuide()}
    </>
  );
};

export default SetupGuideWrapper;
