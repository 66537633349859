import Icon from '@ant-design/icons';

import { ReactComponent as InfoCircleIcon } from 'assets/bosta-icons/Info-Circle.svg';

import './BRInfoBanner.less';

const BRInfoBanner = ({ content, className = '' }) => {
  return (
    <div className={`br-info-banner ${className}`}>
      <div>
        <Icon component={InfoCircleIcon} />
      </div>
      <div>{content}</div>
    </div>
  );
};

export default BRInfoBanner;
