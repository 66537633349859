import React from 'react';
import { Tooltip, Drawer } from 'antd';
import { injectIntl } from 'react-intl';

import { getUserCurrency } from 'constants/countries/countries-mapping';
import { COUNTRIES } from 'constants/country-data';
import { formatNumbersBasedOnLanguage } from 'utils/intl-wrapper';

import { ReactComponent as TooltipIcon } from 'assets/bosta-icons/Info-Circle.svg';

const PricingFlagsMobile = ({ intl, setIsDrawerOpen, isDrawerOpen, FLAGS }) => {
  return (
    <Drawer
      title="Price Breakdown"
      placement="bottom"
      height="70vh"
      closable={false}
      onClose={() => setIsDrawerOpen(false)}
      visible={isDrawerOpen}
      className="br-pricing-flags-section__drawer"
    >
      <>
        <div className="br-pricing-flags-section__header-container-drawer">
          <span className="subheading">
            {intl.formatMessage({
              id: 'pricing_plans_calculator.modal.pricing_flags.header'
            })}
          </span>
          <span className="display-md">
            {
              getUserCurrency(
                formatNumbersBasedOnLanguage(
                  FLAGS().reduce((current, next) => {
                    if (next.isVisible() && next.value) {
                      return current + next.value;
                    }
                    return current;
                  }, 0) || 0
                )
              ).localized
            }
          </span>
          <span className="caption">
            {intl.formatMessage({
              id: 'pricing_plans_calculator.modal.pricing_flags.sub_title'
            })}
          </span>
        </div>
        <div className="br-pricing-flags-flags-container-drawer">
          <div className="br-pricing-flags-flags-list">
            {FLAGS().map(
              (flag, id) =>
                flag.isVisible() && (
                  <div key={id}>
                    <div>
                      <span className="body">{flag.label}</span>
                      <Tooltip title={flag.tooltip}>
                        <TooltipIcon />
                      </Tooltip>
                    </div>
                    <span className="body">
                      {flag.value
                        ? getUserCurrency(
                            formatNumbersBasedOnLanguage(flag.value)
                          ).localized
                        : '-'}
                    </span>
                  </div>
                )
            )}
          </div>
        </div>
        <div className="br-pricing-flags-section__info-text-container-modal">
          <span className="caption">
            {intl.formatMessage({
              id: 'pricing_plans_calculator.modal.pricing_flags.footer'
            })}
          </span>
        </div>
      </>
    </Drawer>
  );
};

export default injectIntl(PricingFlagsMobile);
