export const MAP_MARKER_STATUS = {
  INITIAL: 'initial',
  LOADING: 'loading',
  SUCCESS: 'success',
  FAILED: 'failed',
  UNDETERMINED: 'undetermined'
};

export const ERROR_STATUS = {
  UNDETERMINED_LOCATION: 400
};
