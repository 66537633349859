import React from 'react';
import { Modal } from 'antd';
import { injectIntl } from 'react-intl';
import Icon from '@ant-design/icons';

import { ReactComponent as CloseIcon } from 'assets/bosta-icons/Close.svg';

import './VideoModal.less';

const VideoModal = ({
  intl,
  close,
  src,
  previewVideoTitle = '',
  showCloseIcon,
  ...props
}) => {
  return (
    <>
      {showCloseIcon && (
        <Icon
          className="br-video-modal__close ant-icon-lg"
          component={CloseIcon}
          onClick={close}
        />
      )}
      <Modal
        {...props}
        footer={null}
        title={null}
        width={null}
        onCancel={close}
        wrapClassName="br-video-modal"
      >
        <iframe
          className="br-video-modal__iframe"
          src={src}
          title={previewVideoTitle}
          frameborder="0"
          allow="accelerometer;fullscreen; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        />
      </Modal>
    </>
  );
};

export default injectIntl(VideoModal);
