import { useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import { Modal, Form, Input, Select } from 'antd';

import { ContextWrapper } from 'contexts/wrapper.context';
import { SUGGESTION_BOX_IMPROVEMENT_OPTIONS } from 'constants/common';
import { submitSuggestion } from 'services/suggestions';

import BRButton from 'components/BRButton/BRButton';
import { notify } from 'components/Notify/Notify';

import './SuggestionsModal.less';

const SuggestionsModal = ({ close, ...props }) => {
  const [isLoading, setIsLoading] = useState(false);

  const { businessInfo } = useContext(ContextWrapper);

  const intl = useIntl();

  const userInfo = JSON.parse(localStorage.getItem('userInfo'))?.user;

  const onFinish = async (values) => {
    setIsLoading(true);

    try {
      const payload = {
        ...values,
        category: values.category.join(', ')
      };

      await submitSuggestion(payload);
      notify({
        msg: intl.formatMessage({
          id: 'suggestion_box.submitted_successfully'
        }),
        type: 'success'
      });
      close();
    } catch (error) {
      notify({ msg: error.message, error });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      wrapClassName="br-suggestion-box__modal"
      onCancel={close}
      title={
        <>
          <div className="display-xs">
            {intl.formatMessage({ id: 'suggestion_box.title' })}
          </div>
          <div className="body br-suggestion-box-modal__subtitle">
            {intl.formatMessage({ id: 'suggestion_box.subtitle' })}
          </div>
        </>
      }
      footer={
        <>
          <BRButton
            label={intl.formatMessage({ id: 'common.cancel' })}
            onClick={close}
          />
          <BRButton
            type="primary"
            htmlType="submit"
            form="suggestionBoxForm"
            loading={isLoading}
            label={intl.formatMessage({ id: 'common.submit' })}
          />
        </>
      }
      {...props}
    >
      <Form
        name="suggestionBoxForm"
        onFinish={onFinish}
        initialValues={{
          businessId: businessInfo._id,
          email: userInfo?.emails[0]?.address,
          name: `${userInfo?.profile?.firstName} ${userInfo?.profile?.lastName}`
        }}
      >
        <Form.Item
          name="businessId"
          label={intl.formatMessage({
            id: 'suggestion_box.form_labels.business_id'
          })}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          name="name"
          rules={[{ required: true }]}
          label={intl.formatMessage({
            id: 'suggestion_box.form_labels.name'
          })}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="email"
          rules={[{ required: true }]}
          label={intl.formatMessage({
            id: 'suggestion_box.form_labels.email_address'
          })}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="suggestion"
          rules={[{ required: true }]}
          label={intl.formatMessage({
            id: 'suggestion_box.form_labels.your_suggestion'
          })}
        >
          <Input.TextArea
            autoSize={{ minRows: 3, maxRows: 3 }}
            maxLength={500}
          />
        </Form.Item>
        <Form.Item
          name="category"
          rules={[
            {
              required: true,
              message: intl.formatMessage({
                id: 'suggestion_box.required_field'
              })
            }
          ]}
          label={intl.formatMessage({
            id: 'suggestion_box.form_labels.suggestion_improvement'
          })}
        >
          <Select
            options={SUGGESTION_BOX_IMPROVEMENT_OPTIONS}
            mode="multiple"
            showArrow
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default SuggestionsModal;
