import { ACTIVATION_STEPS_MAPPING } from 'constants/home-page-activation';

export const getActiveStep = (businessInfo = {}) => {
  const {
    CREATE_YOUR_FIRST_ORDER,
    PACK_AND_PREPARE,
    SCHEDULE_PICKUP,
    RECEIVE_REVENUES
  } = ACTIVATION_STEPS_MAPPING;
  const { isFirstOrderCreated, isFirstPickupCreated, featuresConfigurations } =
    businessInfo;

  if (!isFirstOrderCreated) {
    return CREATE_YOUR_FIRST_ORDER;
  } else if (!featuresConfigurations?.isFirstFlyerOrderRequested) {
    return PACK_AND_PREPARE;
  } else if (!isFirstPickupCreated) {
    return SCHEDULE_PICKUP;
  } else {
    return RECEIVE_REVENUES;
  }
};
