import React from 'react';
import { injectIntl } from 'react-intl';

import { requiredRule } from 'utils/forms';
import { NUMBER_TEXT, TEXT } from 'constants/Forms';

import BRFormInputs from 'components/BRFormInputs/BRFormInputs';

import './BRAddressDetails.less';

const BRAddressDetails = ({ intl, formRef, initialValues }) => {
  return (
    <div className="br-address-details">
      <span className="br-address-details__form-title body-medium">
        {intl.formatMessage({
          id: 'international_shipping_in.order_info.address_details'
        })}
      </span>
      <div className="br-form-row">
        <BRFormInputs
          type={TEXT}
          formRef={formRef}
          maxCount={250}
          name="firstLine"
          rules={[
            {
              max: 250,
              message: intl.formatMessage(
                {
                  id: 'form.field_max_length'
                },
                {
                  label: intl.formatMessage({
                    id: 'orders.shipment_details.shipment_details_summary.table_cells.street_name'
                  }),
                  count: 250
                }
              )
            },
            requiredRule(
              intl.formatMessage(
                {
                  id: 'form.required_custom'
                },
                {
                  label: intl.formatMessage({
                    id: 'orders.shipment_details.shipment_details_summary.table_cells.street_name'
                  })
                }
              )
            )
          ]}
          placeholder={intl.formatMessage({
            id: 'location_form_labels.street'
          })}
          initialValue={initialValues?.address?.firstLine}
        />
      </div>
      <div className="br-form-row">
        <BRFormInputs
          type={NUMBER_TEXT}
          formRef={formRef}
          maxCount={25}
          name="buildingNumber"
          rules={[
            {
              max: 25,
              message: intl.formatMessage(
                {
                  id: 'form.address_fields_max_length'
                },
                {
                  count: 25
                }
              )
            }
          ]}
          placeholder={intl.formatMessage({
            id: 'location_form_labels.building'
          })}
          initialValue={initialValues?.address?.buildingNumber}
        />
        <BRFormInputs
          type={NUMBER_TEXT}
          formRef={formRef}
          maxCount={25}
          name="floor"
          rules={[
            {
              max: 25,
              message: intl.formatMessage(
                {
                  id: 'form.address_fields_max_length'
                },
                {
                  count: 25
                }
              )
            }
          ]}
          placeholder={intl.formatMessage({
            id: 'location_form_labels.floor'
          })}
          initialValue={initialValues?.address?.floor}
        />
        <BRFormInputs
          type={NUMBER_TEXT}
          formRef={formRef}
          maxCount={25}
          name="apartment"
          rules={[
            {
              max: 25,
              message: intl.formatMessage(
                {
                  id: 'form.address_fields_max_length'
                },
                {
                  count: 25
                }
              )
            }
          ]}
          placeholder={intl.formatMessage({
            id: 'location_form_labels.apartment'
          })}
          initialValue={initialValues?.address?.apartment}
        />
      </div>
      <div className="br-form-row">
        <BRFormInputs
          type={TEXT}
          formRef={formRef}
          maxCount={50}
          name="secondLine"
          rules={[
            {
              max: 50,
              message: intl.formatMessage(
                {
                  id: 'form.field_max_length'
                },
                {
                  label: intl.formatMessage({
                    id: 'location_form_labels.landmarks'
                  }),
                  count: 50
                }
              )
            }
          ]}
          placeholder={intl.formatMessage({
            id: 'location_form_labels.landmarks'
          })}
          initialValue={initialValues?.address?.secondLine}
        />
      </div>
    </div>
  );
};

export default injectIntl(BRAddressDetails);
