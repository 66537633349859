import { fmt, intl } from 'components/IntlWrapper/IntlWrapper';
import { COUNTRIES, URL_FLAGS } from 'constants/country-data';
import { LOCALE } from './intl-wrapper';
import { COUNTRY_FULLNAME } from './countries/constants/countries';

export const EGYPT_PICKUP_LOCATION_OPTIONS = {
  1: {
    zone: fmt({
      id: `settings.pricing_plan.dropoff_sectors.1`
    }),
    value: fmt({
      id: `settings.pricing_plan.dropoff_sector_areas.1`
    }),
    key: 1,
    label: fmt({
      id: `settings.pricing_plan.dropoff_sector_areas.1`
    })
  },
  2: {
    zone: fmt({
      id: `settings.pricing_plan.dropoff_sectors.2`
    }),
    value: fmt({
      id: `settings.pricing_plan.dropoff_sector_areas.2`
    }),
    key: 2,
    label: fmt({
      id: `settings.pricing_plan.dropoff_sector_areas.2`
    })
  },
  3: {
    zone: fmt({
      id: `settings.pricing_plan.dropoff_sectors.3`
    }),
    value: fmt({
      id: `settings.pricing_plan.dropoff_sector_areas.3`
    }),
    key: 3,
    label: `${fmt({
      id: `settings.pricing_plan.dropoff_sectors.3`
    })}, ${fmt({
      id: `settings.pricing_plan.dropoff_sector_areas.3`
    })}`
  },
  4: {
    zone: fmt({
      id: `settings.pricing_plan.dropoff_sectors.4`
    }),
    value: fmt({
      id: `settings.pricing_plan.dropoff_sector_areas.4`
    }),
    key: 4,
    label: `${fmt({
      id: `settings.pricing_plan.dropoff_sectors.4`
    })}, ${fmt({
      id: `settings.pricing_plan.dropoff_sector_areas.4`
    })}`
  },
  5: {
    zone: fmt({
      id: `settings.pricing_plan.dropoff_sectors.5`
    }),
    value: fmt({
      id: `settings.pricing_plan.dropoff_sector_areas.5`
    }),
    key: 5,
    label: `${fmt({
      id: `settings.pricing_plan.dropoff_sectors.5`
    })}, ${fmt({
      id: `settings.pricing_plan.dropoff_sector_areas.5`
    })}`
  },
  6: {
    zone: fmt({
      id: `settings.pricing_plan.dropoff_sectors.6`
    }),
    value: fmt({
      id: `settings.pricing_plan.dropoff_sector_areas.6`
    }),
    key: 6,
    label: `${fmt({
      id: `settings.pricing_plan.dropoff_sectors.6`
    })}, ${fmt({
      id: `settings.pricing_plan.dropoff_sector_areas.6`
    })}`
  },
  7: {
    zone: fmt({
      id: `settings.pricing_plan.dropoff_sectors.7`
    }),
    value: fmt({
      id: `settings.pricing_plan.dropoff_sector_areas.7`
    }),
    key: 7,
    label: `${fmt({
      id: `settings.pricing_plan.dropoff_sectors.7`
    })}, ${fmt({
      id: `settings.pricing_plan.dropoff_sector_areas.7`
    })}`
  }
};

export const SAUDI_PICKUP_LOCATION_OPTIONS = {
  100: {
    zone: fmt({
      id: `settings.pricing_plan.saudi_dropoff_sectors.100`
    }),
    value: ' ',
    key: 100,
    label: fmt({
      id: `settings.pricing_plan.saudi_dropoff_sectors.100`
    })
  },
  101: {
    zone: fmt({
      id: `settings.pricing_plan.saudi_dropoff_sectors.101`
    }),
    value: ' ',
    key: 101,
    label: fmt({
      id: `settings.pricing_plan.saudi_dropoff_sectors.101`
    })
  },
  102: {
    zone: fmt({
      id: `settings.pricing_plan.saudi_dropoff_sectors.102`
    }),
    value: ' ',
    key: 102,
    label: fmt({
      id: `settings.pricing_plan.saudi_dropoff_sectors.102`
    })
  },
  103: {
    zone: fmt({
      id: `settings.pricing_plan.saudi_dropoff_sectors.103`
    }),
    value: ' ',
    key: 103,
    label: fmt({
      id: `settings.pricing_plan.saudi_dropoff_sectors.103`
    })
  },
  104: {
    zone: fmt({
      id: `settings.pricing_plan.saudi_dropoff_sectors.104`
    }),
    value: ' ',
    key: 104,
    label: fmt({
      id: `settings.pricing_plan.saudi_dropoff_sectors.104`
    })
  }
};

export const PICKUP_LOCATION_OPTIONS =
  JSON.parse(localStorage.getItem('userInfo'))?.user?.country?.code ===
  COUNTRIES[1].codeName
    ? SAUDI_PICKUP_LOCATION_OPTIONS
    : EGYPT_PICKUP_LOCATION_OPTIONS;

export const BULKY = 'Bulky';
export const LIGHT_BULKY = 'Light Bulky';
export const HEAVY_BULKY = 'Heavy Bulky';
export const XXL = 'XXL';

export const PACKAGE_SIZES = {
  NORMAL: 'Normal',
  LARGE: 'Large',
  X_LARGE: 'X-Large',
  WHITE_BAG: 'White Bag'
};

export const EXTRA_WEIGHT = 'EXTRA_WEIGHT';

export const DROPOFF_COUNTRIES = [COUNTRY_FULLNAME.SA.code];

export const renderShippingLocationOption = (code) => {
  return {
    value: code,
    label: (
      <div className="br-pricing__inter-location-option">
        <img
          className="br-international-pricing__country-flag"
          src={URL_FLAGS + code.toLowerCase() + '.svg'}
          alt="flag"
        />
        <p className="body br-pricing__location-option-country-name">
          {intl.locale === LOCALE.EN
            ? COUNTRY_FULLNAME[code].en
            : COUNTRY_FULLNAME[code].ar}
        </p>
      </div>
    ),
    code: 'UAE'
  };
};

export const PAYMENT_FREQUENCY_PRICING_COLUMNS = [
  {
    title: intl.formatMessage({
      id: 'settings.pricing_plan.package_sizes'
    }),
    dataIndex: 'name',
    key: 'name',
    className: 'br-signup-pricing__city-table-header'
  },
  {
    title: (
      <div className="br-pricing-plan__table-header-title">
        {intl.formatMessage({
          id: 'settings.pricing_plan.table_columns.deliver'
        })}
      </div>
    ),
    dataIndex: 'deliver',
    key: 'deliver'
  },
  {
    title: (
      <div className="br-pricing-plan__table-header-title">
        {intl.formatMessage({
          id: 'settings.pricing_plan.table_columns.exchange'
        })}
      </div>
    ),
    dataIndex: 'exchange',
    key: 'exchange'
  },
  {
    title: (
      <div className="br-pricing-plan__table-header-title">
        {intl.formatMessage({
          id: 'settings.pricing_plan.table_columns.return'
        })}
      </div>
    ),
    dataIndex: 'return',
    key: 'return'
  },
  {
    title: (
      <div className="br-pricing-plan__table-header-title">
        {intl.formatMessage({
          id: 'settings.pricing_plan.table_columns.cash_collection'
        })}
      </div>
    ),
    dataIndex: 'cash_collection',
    key: 'cash_collection'
  },
  {
    title: (
      <div className="br-pricing-plan__table-header-title">
        {intl.formatMessage({
          id: 'settings.pricing_plan.table_columns.rto'
        })}
      </div>
    ),
    dataIndex: 'rto',
    key: 'rto'
  }
];
