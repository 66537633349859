import { login, recoverAccount, sendEmailForgetPassword } from 'services/auth';
import {
  PRODUCTION_URL,
  BETA_URL_REDIRECT,
  ERROR_CODES
} from 'constants/login';
import { sendSegment } from 'utils/segment';

import { notify } from 'components/Notify/Notify';

export const userLogin = async ({
  email,
  password,
  setIsLoading,
  history,
  intl,
  restoreAccount = false
}) => {
  const { location } = history;
  try {
    setIsLoading(true);
    const payload = {
      email: email.toLowerCase().trim(),
      password: password
    };
    const serviceToCall = restoreAccount ? recoverAccount : login;
    const userInfo = await serviceToCall(payload);
    sendSegment('E_BUSINESS_SIGNIN', {
      email: userInfo.user.emails[0].address,
      roles: userInfo.user.roles[0],
      'Business Name':
        userInfo.user.businessAdminInfo !== undefined
          ? userInfo.user.businessAdminInfo.businessName
          : null,
      phone: userInfo.user.profile.phone || '',
      Name: `${userInfo.user.profile.firstName} ${
        userInfo.user.profile.lastName ? userInfo.user.profile.lastName : ''
      }`,
      businessId:
        userInfo.user.businessAdminInfo?.businessId !== undefined
          ? {
              id: userInfo.user.businessAdminInfo?.businessId,
              name: userInfo.user.businessAdminInfo?.businessName
            }
          : null,
      'Business Type': userInfo.user.monthlyShipmentVolume || ''
    });
    if (
      window.location.hostname === PRODUCTION_URL &&
      userInfo.user.businessAdminInfo &&
      userInfo.user.businessAdminInfo?.isBetaUser
    ) {
      window.open(
        `${BETA_URL_REDIRECT}${userInfo.token.replace('Bearer ', '')}`,
        '_parent'
      );
    }
    const { user = {} } = userInfo;
    if (userInfo) {
      localStorage.setItem('userInfo', JSON.stringify(userInfo));
      localStorage.setItem('newToken', userInfo.token);
      localStorage.setItem(
        'isRegisteredHekouky',
        !!userInfo?.user?.businessAdminInfo?.hekoukyProfileInfo?.isRegistered
      );

      window?.analytics?.identify(user.emails[0].address, {
        'User Email': user.emails[0].address,
        Permission: user?.group?.name,
        'Business Name':
          user.businessAdminInfo !== undefined
            ? user?.businessAdminInfo?.businessName
            : null,
        Phone: user.profile.phone || '',
        'Business Type': user.monthlyShipmentVolume || '',
        'Dashboard Language': intl.locale
      });

      sendSegment('Step1_Successful-sign-in');
    }
    if (location.search === '?payment') {
      history.push('/settings/payment-info');
    } else if (location.state && location.state.pathName) {
      const {
        state: { pathName, pathSearch = '', pathState = undefined }
      } = location;

      window.history.replaceState(
        pathState,
        null,
        `${pathName}${pathSearch ? `?${pathSearch}` : ''}`
      );

      window.location.reload();
    } else {
      window.location.reload();
    }
  } catch (error) {
    const { errorCode, data } = error;
    setIsLoading(false);
    if (
      [ERROR_CODES.USER_NOT_FOUND, ERROR_CODES.NOT_VALID].includes(errorCode)
    ) {
      notify({
        msg: intl.formatMessage({ id: 'login.not_valid_input' }),
        error
      });
    } else if (errorCode === ERROR_CODES.DELETED_ACCOUNT) {
      history.push({
        pathName: '/signin',
        state: {
          firstName: data.profile.firstName,
          lastName: data.profile.lastName,
          userType: data.regSrc,
          email,
          password,
          restoreAccount: true
        }
      });
    } else if (errorCode === ERROR_CODES.PASSWORD_EXPIRED) {
      await sendEmailForgetPassword(email);
      notify({
        msg: intl.formatMessage({
          id: 'login.set_new_passowrd.expired_error_message'
        })
      });
      history.push({
        pathname: '/confirm-reset-password',
        state: {
          email,
          isPasswordExpired: true
        }
      });
    } else {
      notify({ msg: error.message, error });
    }
  }
};

export const getSignInPath = () => '/signin';
