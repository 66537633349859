import { COUNTRIES } from 'constants/country-data';

export const DEFAULT_COUNTRY = COUNTRIES[0];

export const COUNTRY_FULLNAME = {
  EG: { ar: 'مصر', en: 'Egypt', code: 'EG' },
  SA: { ar: 'السعودية', en: 'Saudi Arabia', code: 'SA' },
  AE: {
    ar: "الإمارات العربية المتحدة'",
    en: 'United Arab Emarates',
    code: 'AE'
  }
};
