import { userInfo } from 'utils/helpers';

export const isUserHasFulfillmentAccess =
  userInfo?.businessAdminInfo?.fulfillmentInfo?.isActive ||
  userInfo?.businessAdminInfo?.isBostaFulfillment;

export const showBox = !!JSON.parse(localStorage.getItem('showBox'));

export const showDraftOrders =
  userInfo?.businessAdminInfo?.isMarketplaceEnabled;

export const isFirstOrderCreated = userInfo?.isFirstOrderCreated;

export const isFirstPickupCreated = userInfo?.isFirstPickupCreated;

export const isUserFullyActivated = userInfo?.isUserFullyActivated;

export const isAnalyticsBlocked = userInfo?.isAnalyticsBlocked;
