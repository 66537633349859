import React, { useEffect, useCallback } from 'react';
import { Button, Modal, Divider } from 'antd';
import { injectIntl } from 'react-intl';
import Icon, { CloseOutlined } from '@ant-design/icons';
import { withRouter } from 'react-router';

import { AllRoleAndPermissions } from 'constants/teams-and-members';
import { sendSegment } from 'utils/segment';

import BRContentHeader from 'components/BRContentHeader/BRContentHeader';

import { ReactComponent as TeamsMemeberOnBoarding } from 'assets/imgRevamp/new-team-members-onboarding.svg';
import { ReactComponent as Plus } from 'assets/imgRevamp/Plus.svg';

import './ViewPermissionsModal.less';

const ViewPermissionsModal = (props) => {
  const { close, intl } = props;
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));

  const handleClose = useCallback(() => {
    localStorage.setItem('viewPermissionsMember', true);
    sendSegment('E_PERMISSIONS_MODAL_WINDOW_GET_STARTED');
    close();
  }, [close]);

  useEffect(() => {
    sendSegment('E_PERMISSIONS_MODAL_WINDOW_VIEWED');
  }, []);

  const accessModulesUi = (data) => {
    return (
      <>
        {data.map((item) => (
          <div className="br-teams-view-permissions__modal__content__list-permission_item">
            <div className="br-teams-view-permissions__modal__content__list-permission_item__icon">
              <Icon component={Plus} />
            </div>
            <div>
              {intl.formatMessage({
                id: `settings.teams_members.view_permission_modal.${item}`
              })}
            </div>
          </div>
        ))}
      </>
    );
  };

  return (
    <Modal
      className="br-teams-view-permissions"
      {...props}
      title={null}
      footer={null}
      width={null}
      onCancel={handleClose}
      closeIcon={<CloseOutlined />}
    >
      <div className="br-teams-view-permissions__modal">
        <div className="br-teams-view-permissions__modal__content">
          <div>
            <Icon component={TeamsMemeberOnBoarding} />
          </div>
          <div className="br-teams-view-permissions__modal__content_title">
            <BRContentHeader
              title={intl.formatMessage({
                id: 'welcome_dialog.title'
              })}
              subtitle={intl.formatMessage(
                {
                  id: 'settings.teams_members.view_permission_modal.subtitle'
                },
                {
                  bsuinessName: (
                    <span>
                      {userInfo?.user?.businessAdminInfo?.businessName}
                    </span>
                  )
                }
              )}
              isInternalComponent
            />
          </div>
          <Divider />
          <div className="br-teams-view-permissions__modal__content__list-permission">
            <p className="br-teams-view-permissions__modal__content__list-permission__heading">
              {intl.formatMessage({
                id: 'settings.teams_members.view_permission_modal.access_title'
              })}
            </p>
            {accessModulesUi(AllRoleAndPermissions[userInfo.user?.group?.name])}
          </div>

          <>
            <div class="br-teams-view-permissions__modal__content__footer">
              <Button
                type="primary"
                className="br-teams-view-permissions__modal__content__next-button"
                onClick={handleClose}
              >
                {intl.formatMessage({
                  id: 'settings.teams_members.view_permission_modal.button_text'
                })}
              </Button>
            </div>
          </>
        </div>
      </div>
    </Modal>
  );
};
export default injectIntl(withRouter(ViewPermissionsModal));
