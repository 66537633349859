import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import Icon from '@ant-design/icons';
import { injectIntl } from 'react-intl';

import { LITE } from 'constants/signup';
import { sendSegment } from 'utils/segment';

import { isDashboardArabic } from 'utils/intl-wrapper';
import { SAUDI_TERMS_AND_CONDITIONS_MAPPER } from 'constants/countries/constants/signup';

import SaudiTerms from '../SaudiTerms/SaudiTerms';

import BRContentHeader from 'components/BRContentHeader/BRContentHeader';
import BRButton from 'components/BRButton/BRButton';

import { ReactComponent as ArrowDown } from 'assets/bosta-icons/Arrow-down.svg';

import './TermsModal.less';

const TermsModal = ({ intl, onOk, email, close, country, ...props }) => {
  const modalBodyClassName = 'br-signup__terms-modal';
  const [isTermsRead, setIsTermsRead] = useState(false);

  useEffect(() => {
    sendSegment('E_SIGNUP_TC_OPENED', {
      email
    });
    setTimeout(() => {
      document
        .getElementsByName(modalBodyClassName)[0]
        .addEventListener('scroll', checkScrollHeight, false);
    });
    return () => {
      document
        .getElementsByName(modalBodyClassName)[0]
        ?.removeEventListener('scroll', checkScrollHeight, false);
    };
  }, []);

  const checkScrollHeight = () => {
    const agreementTextElement =
      document.getElementsByName(modalBodyClassName)[0];
    if (
      agreementTextElement &&
      agreementTextElement.scrollTop + agreementTextElement.offsetHeight >=
        agreementTextElement.scrollHeight
    ) {
      setIsTermsRead(true);
    }
  };

  const handleAcceptTerms = () => {
    sendSegment('E_SIGNUP_TC_ACCEPTED', {
      email
    });
    onOk();
    close();
  };

  const handleCloseTerms = () => {
    sendSegment('E_SIGNUP_TC_CLOSED', {
      email
    });
    close();
  };

  return (
    <Modal
      {...props}
      wrapClassName="br-terms-modal"
      footer={
        <>
          <BRButton
            type="basic"
            onClick={handleCloseTerms}
            label={intl.formatMessage({
              id: 'sign_up.terms_modal.actions.reject_btn'
            })}
          />
          <BRButton
            type="primary"
            disabled={!isTermsRead}
            onClick={handleAcceptTerms}
            label={intl.formatMessage({
              id: 'sign_up.terms_modal.actions.accept_btn'
            })}
          />
        </>
      }
      okButtonProps={{ disabled: !isTermsRead }}
    >
      <BRContentHeader
        title={intl.formatMessage({
          id: 'sign_up.terms_modal.title'
        })}
        subtitle={intl.formatMessage({
          id: 'sign_up.terms_modal.sub_title'
        })}
        className={'br-terms-modal-header'}
        isInternalComponent
      />
      <BRButton
        hidden={isTermsRead}
        label={intl.formatMessage({
          id: 'sign_up.terms_modal.scroll_down'
        })}
        type="primary"
        prefixIcon={
          <Icon
            component={ArrowDown}
            className="br-sign-up__scroll-down-icon"
          />
        }
      />
      {SAUDI_TERMS_AND_CONDITIONS_MAPPER[country?.code] ? (
        <SaudiTerms isLite={LITE} />
      ) : (
        <div className={`${modalBodyClassName}`} name={modalBodyClassName}>
          {isDashboardArabic() ? (
            <p>
              <span>عقد إتفاقية تقدیم خدمات شحن</span>
              <br />
              عميلنا العزيز،
              <br />
              إن شركة بوسطة تكنولوجي –وهي بكل فخر شركة رائدة شابة مصرية – هي
              شركة لخدمة توصيل شحنات "من الباب للباب" تهدف في جوهرها إلى تمكين
              التجارة الإلكترونية في مصر، وذلك من خلال منصة ونظام معلوماتي
              تكنولوجي متكامل عالي الجودة وفريق عمل محترف ومتخصص. تملك بوسطة
              مخازنها و نظامها المعلوماتي الخاص ومنصتها وتطبيقها، ويعمل فريق
              المهندسين الخاص بها على تطويره وتحسينه بشكل دائم وعملي، بأحد الطرق
              وبوسائل الربط المختلفة، لتقديم واحدة من أكفأ الخدمات المتكاملة في
              هذا المجال.
              <br />
              يسرنا تقديم خطة الأسعار والشروط والأحكام فيما بيننا على النحو
              الآتي:
              <ul>
                <li>
                  طرف اول:
                  <br />
                  شركة بوسطه تكنولوجي ومقرها 2 ع شارع 164- حدائق المعادي-
                  القاهره سجل تجارى رقم 101723 ورقم الهوية 537-954-392 ويشار
                  إليه في هذا العقد "طرف أول" أو "بوسطة".
                  <br />
                </li>
                <li>
                  طرف ثاني:
                  <br />
                  الشركة أو العميل المقدم لفتح حساب هنا على منصة بوسطة: ويشار
                  إليه في هذا العقد ب"الطرف الثاني" أو "الشركة"
                </li>
                <br />
              </ul>
              <span> تمهید</span>
              <br />
              حيث أن الطرف الأول شركة متخصصة في مجال النقل و التخزين لنفسها و
              للغیر وحاصله على التراخيص اللازمة التي تساعدها على العمل بنشاطها و
              هو تزويد عملاءه بخدمات النقل السريع، المتضمنة النقل للمنازل
              واستلام الشحنات من النقاط المتفق عليها وتسليمها إلى المرسل إليه
              المعني كما هو موضح في بوليصة الشحن مع بذل العناية الواجبة الممكنة
              لأداء وتقديم الخدمة المتفق عليها. ویرغب الطرف الثانى في نقل
              المنتجات والشحنات والبضائع الخاصة به وتوصيلها إلى عملائه و تقديم
              خدمة التوقيعات الخطية من عملاء الطرف الثاني بعد الأطلاع علي بطاقة
              تحقيق الشخصية و ذلك عن طریق خدمات الطرف الاول وعلى ذلك فقد تلاقت
              رغبت الطرفان على التنسيق والتعاون فيما بينهم بشأن وضع وتنفيذ
              ترتيبات تشغيلية وجداول زمنية مناسبة تهدف إلى تحقيق السرعة والجودة
              والكفاءة التشغيلية المرجوة.،
              <br />
              وبناء عليه وبعد أن أقر الطرفان بأهليتهما القانونية الكاملة
              للتعاقد، وقد أقر ووافق الطرفان على استعمال منصة التعاقد الإلكتروني
              "الخاصة بالطرف الأول و يعتبر ذلك إقرار بصحته توقيعه و موافقته على
              الشروط والأحكام المنصوص عليها بهذه الاتفاقية" أو منصة بوسطة
              واستعمال الإمضاء الإلكتروني، وقد تفهم الطرفان شكلاً ومضموناً
              المسؤولية القانونية لهذا التعاقد الالكتروني، فقد اتفق الطرفان على
              إبرام هذا العقد وفقا للشروط والأحكام الآتية:
              <br />
              يعتبر التمهيد السابق و الملاحق المرفقة جزء لا يتجزأ من هذا العقد
              ومتمماً له وتفسر إرادة طرفيه على مقتضاه.
              <br />
              <br />
              <span> أ- الشروط والأحكام الخاصة بالأسعار:</span>
              <ol>
                <li>
                  إن لائحة الأسعار بالجنيه المصري، غير شاملة المصاريف والضريبة
                  على القيمة المضافة 14%.
                </li>
                <li>
                  إن لائحة الأسعار هي للشحنة الواحدة لكل ما يمكن شحنه داخل فلاير
                  بوسطة الصغير والعادي. تحسب مصاريف إضافية على أكياس التغليف
                  flyers الكبير والكبير جدا وعلى الأكياس البيضاء بحسب الجدول
                  أدناه.
                </li>
                <li>
                  الأسعار تغطي سعر الشحن وسعر خدمة التحصيل عند التسليم حتي قيمة
                  3000 . أما بالنسبة للشحنات التي تتخطى قيمة تحصيلها ال 3000
                  جنيه، فسيتم محاسبة الطرف الثاني ب 1% على المبلغ الإضافي الذي
                  يتخطى ال 3000 جنيه .
                </li>
                <li>
                  من غير المسموح أن تتخطى قيمة التحصيل للشحنة الواحدة 30.000(
                  فقط ثلاثون الف جنيها مصريا )
                </li>
                <li>
                  بإمكان بوسطة تزويدكم بمواد تغليف إضافية مثل صناديق الكرتون
                  boxes، لفائف البابل، الأختام (سيلز)، وأكثر، وذلك بكلفة إضافية.
                  يرجى التواصل مع مدير حسابكم أو خدمة العملاء لمعرفة أسعار هذه
                  المواد، بحسب أسعار السوق.
                </li>
                <li>
                  الأسعار هي للاوردر الواحد الذي يتسع في فلاير بوسطة واحد بغض
                  النظر عن الوزن على ألا يتخطى ال20 كيلوغرام للشحنة الواحدة. إن
                  بوسطة لن تستلم شحنات خارج الفلاير أو مواد التغليف الخاصة بها.
                  يجب وضع الشحنة التي تتضمن أكثر من فلاير واحد داخل الكيس
                  الأبيض. يحتسب كل كيس أبيض على أنه شحنة منفصلة.
                </li>
                <li>
                  دون الأخلال ببنود هذا العقد ، اتفق كلا الطرفين على أن الأسعار
                  المنصوص عليها مؤهلة لأي تعديلات بالزيادة من قبل الطرف الأول
                  ،وعلى الطرف الأول ابلاغ الطرف الثانى بتغيير الاسعار في خلال 7
                  أيام و يعد استخدام طرف الثاني للخدمة في هذه الفتره موافقه
                  ضمنيه منه علي ذلك.
                </li>
                <li>
                  استثناء من البند السابق في حال ارتفاع اسعار الوقود من الدولة
                  علي طرف الاول تعديل التسعيرة وفقا للزيادة السوقية من اليوم
                  التالى من تغير اسعار الوقود.
                </li>
              </ol>
              ب - معادلة المبالغ المحصلة و مصاريف الشحن
              <br />
              يتم خصم مصاريف النقل للشحنات التي تم تسليمها أو إرجاعها من إجمالي
              التحصيلات، ثم تقوم بوسطة تحويل بنكي أسبوعي إلى حسابكم، بحسب الدورة
              الحسابية الخاصة بشركة بوسطة.
              <br />
              يجب التأكد من ملئ البيانات الخاصة بحساب البنك التابع لكم في مرحلة
              التعاقد قبل البدء بتنفيذ الخدمات وذلك للضرورة. يرجى الضغط على
              الرابط الخاص بملئ بيانات البنك في الملحق التابع لهذا العقد
              <br />
              <br />
              ج- التأمين على شحنات الطرف الثاني
              <br />
              <br />
              1- تلتزم بوسطة بتعويضكم عن أي ضياع بقيمة الشحنة بحد أقصى 10,000
              ج.م للشحنة الواحدة أو تلف بمبلغ 500 ج.م فقط كحد أقصى على الشحنة
              الواحدة بعد موافقة القسم المختص بالتحقيقات بخصوص اتباع شروط
              التغليف.
              <br />
              <br />
              2-شروط التعويض*:
              <br />
              <ul>
                <li>
                  يجب أن يتم الإبلاغ عن حالة التلف أو الضياع في الحال عند إثبات
                  الحالة(مثلا في لحظة استلام المرتجعات). ولن تقوم بوسطة بأي
                  تعويضات في حالة التأخر عن الإبلاغ (مثلا بعد مغادرة المندوب)،
                  وبوسطة غير مسؤولة عن أي ضياع أو تلف في حال عدم أو التأخر في
                  الإبلاغ.
                </li>
                <li>
                  بعد الإبلاغ، لدى بوسطة مدة أقصاها 14 يوما للتحقيق الداخلي.
                </li>
                <li>
                  إن الطرف الثاني مسئول مسئولية كاملة عن أي منتج من اللائحة
                  التالية للمنتجات الممنوعة، أو الشحنات المغلفة بطريقة خاطئة أو
                  مخالفة لطريقة التغليف السليمة تعرضها للتلف (يرجى مراجعة دليلنا
                  الخاص بطرق التغليف السليمة- الرابط في الملحق)، أو أي شحنات
                  تفوق قيمتها الفردية العشرة آلاف جنيه مصري.
                </li>
              </ul>
              يرجى مراجعة المقطع الخاص بطريقة الخطوات والبنود التي يجب اتباعها
              في حال التلف أو الضياع، مراجعة دقيقة (الرابط في الملحق)
              <br />
              <br />
              3-المنتجات الممنوعة منعا باتا:
              <br />
              يُقصد بها البضائع التي صنفتها الكيانات الحكومية والتنظيمية أو أي
              كيان آخر على أنها بضائع محظورة والمُدرجة أيضًا في قائمة الأصناف
              المحظورة الخاصة بشركة بوسطة ؛ وعلي سبيل المثال لا الحصر:
              <br />
              <ul>
                <li>البشر أو الحيوانات</li>
                <li>أي مواد طعام أو شرب معرضة للتلف</li>
                <li>
                  أي منتجات مسروقة، غير قانونية، احتيالية أو تم الحصول أو بيعها
                  بطريقة الاحتيال، أو أي منتج غير مصرح لكم بشحنه أو إرساله.
                </li>
                <li>
                  أي منتجات حساسة أو معرضة للكسر أو\و مغلفة بشكل سئ لا يتماشى
                  بالطرق المنصوح بها في بوسطة
                </li>
                <li>أي مشروبات كحولية \روحية ومنتجات التبغ</li>
                <li>
                  المنتجات التي لم يتم الحصول على التصاريح القانونية اللازمة من
                  الجهات المختصة لبيعها أو توزيعها للمستهلك بحسب القوانين
                  المصرية.
                </li>
                <li>جميع المواد المسيئة للأديان السماوية الثلاثة.</li>
                <li>
                  أدوات أو معدات اتصالات أو إلكترونية غير قانونية بحسب القانون
                  المصري.
                </li>
                <li>
                  الذهب والبلاتين وتكون سواء كانت علي هيئة أصناف مشغولة أو سبائك
                  أو على هيئة عملة، و السبائك و الأصناف غير المشغولة وكذلك
                  الأسلاك والقضبان والأنابيب والصفائح و الأحجار الكريمة الثمينة
                  كالألماس والزمرد والياقوت والزفير واللؤلؤ الطبيعي.
                </li>
                <li>
                  مواد قابلة للإنفجار أو الاشتعال (جميع أنواع المواد المسببة
                  للتآكل المتمثلة في حمض الكبريتيك وحمض الهيدروكلوريك وحمض
                  النتريك والمذيبات العضوية والمبيدات الحشرية وبيروكسيد
                  الهيدروجين، والمواد الكيميائية الخطرة على سبيل المثال لا
                  الحصر)
                </li>
                <li>تغيير عملات</li>
                <li>
                  جميع أنواع المنشورات والدعاية والمطبوعات والألعاب الجنسية التي
                  قد تُعرض الأمن القومي والاستقرار الاجتماعي والسياسي للخطر.
                </li>
                <li>
                  جميع أنواع المخدرات: الأفيون (ولا سيما قشور وزهور وبراعم
                  وأوراق نبات الخشخاش) والمورفين والكوكايين والهيروين
                  والماريجوانا وكلوريد الأمفيتامين والإفيدرين وغيرها من المنتجات
                  الأخرى.
                </li>
                <li>
                  جميع أنواع الأسلحة والذخيرة والمتفجرات مثل: البنادق والرصاص
                  والقذائف والقنابل اليدوية والألغام، والقنابل وأدوات التفجير
                  والمتفجرات والبارود.
                </li>

                <li>
                  أدوات أو معدات خطرة قد تؤذي الغير أو تودي بحايتهم( جميع المواد
                  المتفجرة، مثل: الألعاب النارية والمفرقعات وطوربيد المفرقعات
                  والخرطوشة الورقية الخاصة بمسدسات الصوت، والفتيل جميع أنواع
                  المواد القابلة للاشتعال، وتتضمن السوائل والغازات والمواد
                  الصلبة، ومنها على سبيل المثال: البترول والكيروسين وزيت التنغ
                  والكحول ومواد الطلاء وقود الديزل، والأهباء الجوية (الأيروسول)
                  وولاعات الغاز وأسطوانات الغاز والفسفور والكبريت وأعواد الثقاب،
                  جميع أنواع المنتجات الكيميائية الحيوية والمواد المعدية، مثل:
                  الجمرة الخبيثة والجراثيم الخطيرة والمخلفات الطبية، جميع أنواع
                  السموم، مثل: الثاليوم والسيانيد والزرنيخ. على سبيل المثال ولا
                  الحصر)
                </li>
                <li>
                  الأدوات المالية: كالأموال/ العملة أو الشيك المُحرر لحامله أو
                  شيك المسافر أو الكمبيالة المصرفية أو الأصول السائلة؛ شيكات
                  بنكية، كتب ضمان، أو أي مستندات أصلية أو أوراق لها قيمة مالية
                  أو لا يمكن تعويضها
                </li>
                <li>أي منتجات تفوق قيمتها الثلاثون آلاف جنيه مصري 30000 ج.م</li>
              </ul>
              <span>ثانيا:شروط واحكام اخرى</span>
              <ol>
                <li>
                  تعهد الطرف الثاني في إطار الخدمات المقدمة من الطرف الأول موضوع
                  التعاقد، بعدم القيام بأي أعمال غير قانونية أو غير أخلاقية أو
                  بأي عمليات احتيال أو تحايل أو بأي أعمال ذات أهداف مخالفة
                  للقوانين المصرية ومخالفة للمصلحة العامة. ويصرح الطرف الثاني أن
                  استخدامه للخدمات المقدمة من الطرف الأول في إطار هذا العقد أو
                  الشروط والأحكام هو استخدام في إطار القوانين المصرية، وعلى سبيل
                  المثال لا الحصر قد تكون هذه القوانين تابعة للقوانين المالية،
                  تبييض الأموال، المخالفات التجارية، والعمليات الإرهابية و
                  الأعمال المنافية للآداب، ويتحمل الطرف الثاني مسؤولية محتوى
                  الشحنات التابعة له مسؤولية كاملة وخالصة. ويقر الطرف الثانى
                  بإلتزامه بقوانین جمهوریة مصر العربیة فى الحصول على المنتج
                  المراد شحنه وبصلاحیته للاستخدام وأنه قد إستوفى وحصل على
                  التراخیص والتصاریح والموافقات اللازمة لتصیر صالحة لعملائه
                  بمجرد النقل عن طریق الطرف الاول.
                </li>
                <li>
                  يوافق الطرف الثاني أن لدى الطرف الأول الحق الكامل في إنهاء
                  التعاقد فورا ومن دون أي سابق إنذار في حال تورط الطرف الثاني في
                  أي نشاط غير قانوني أو غير أخلاقي أو استخدام خدمات الطرف الأول
                  لأهداف غير قانونية أو غير أخلاقية، وعلى سبيل المثال لا الحصر:
                  <ul>
                    <li>
                      استخدام الطرف الثاني لخدمات الطرف الأول بهدف غير قانوني أو
                      غير أخلاقي أو احتيال أو أسباب فاحشة أو أي أعمال ممنوعة من
                      قبل القانون المصري أو الجهات القانونية أو الرسمية أو
                      الحكومية في جمهورية مصر العربية.
                    </li>
                    <li>
                      أن يكون المنتج المشحون مخالف لأي من القوانين المصرية أو
                      شروط وأحكام هذا العقد.
                    </li>
                  </ul>
                </li>
                <li>
                  يقر الطرف الثاني أن موظفيه التابعين له والممثلين له يتحملون
                  مسؤولية كيفية استعمال خدمات الطرف الأول، وأن موظفي وممثلي
                  وشركاء الطرف الثاني يأخذون مأخذ الطرف الثاني وتقع عليهم
                  المسؤولية القانونية في إطار شروط وأحكام هذا العقد بخلاف الطرف
                  الأول وموظفيه وممثليه.
                </li>
                <li>
                  يتعهد الطرف الثاني بعدم شحن شيكات على بياض أو بإشارة لحامله مع
                  الطرف الأول.
                </li>
                <li>
                  الطرف الثاني مسئول مسئولية كاملة عن كيفية وطريقة تغليف الشحنات
                  التابعة له، وفي حال عدم اتباع الطرف الثاني إرشادات التغليف
                  السليم، لا يتحمل الطرف الأول أي مسؤولية جزئية أو كلية في حال
                  حدوث تلف لمنتجات الطرف الثاني المشحونة مع الطرف الأول.
                </li>
                <li>
                  يخلي الطرف الثاني مسئولية الطرف الأول عن أي عيوب ناتجة عن جودة
                  أو تصنيع منتجاته المشحونة بواسطة الطرف الأول، كما يقر الطرف
                  الثاني بأنه المسؤول الوحيد عن أي عيوب بالمنتجات المشحونة قد
                  يتراء لجهاز حماية المستهلك بأن المستهلك يستحق تعويضاً أي كان
                  نوعه ويلتزم بسداد كافة التعويضات الناتجة عن ذلك.
                </li>
                <li>
                  يحق للطرف الأول إطلاع جهاز حماية المستهلك على بيانات الطرف
                  الثاني، و يتحمل الطرف الثاني وحده كافة التوابع القانونية
                  الناتجة عن ذلك.
                </li>
                <li>
                  يقر الطرف الثاني بأنه ملتزم بكافة قوانين ولوائح جهاز حماية
                  المستهلك.
                </li>
                <li>
                  مدة هذا العقد سنة من تاريخ أول شحنة قابله للتجديد لمدة أو مدد
                  أخرى مساوية بحسب الاتفاق المسبق ما بين الطرفين.
                </li>
                <li>
                  يمكن إلغاء التعاقد من قبل أي من الطرفين بإعطاء إنذار من خلال
                  البريد الإلكتروني ثلاثون يوما قبل الإلغاء.
                </li>

                <li>
                  يحتفظ الطرف الأول بحق الإفصاح عن معلومات وبيانات الطرف الثاني
                  الشخصية لأي جهة أمنية مصرية تحت طلب مصرح وقانوني من هذه الجهة
                  الأمنية وذلك تحت غطاء القوانين المصرية ، بموجب حكم قضائي
                  نهائي.
                </li>

                <li>
                  يلزم على الطرف الثاني ارسال المستندات الخاصة بالشحنات
                  المستوردة لإظهارها حين الطلب من قبل شركة بوسطة فى حال طلبها من
                  الجهات المختصة.
                </li>
                <li>
                  لا تتحمل شركة بوسطة اي غرامات ضريبية او جمركية على أى شحنة
                  خاصه بالطرف الثاني وفي حال تم فرض أي ضرائب أو أى مصاريف حكومية
                  على شركه بوسطه يحق للشركة الرجوع على الطرف الثاني بهذه
                  المصاريف و يتم تحميلها بالكامل على الطرف الثاني و تقدم شركة
                  بوسطة المستندات الدالة علي ذلك
                </li>
                <li>
                  فى حالة نشوء أي نزاع حول تنفيذ أو تفسير هذا العقد تختص محاكم
                  القاهرة بالنظر و الفصل فيه و تسويته
                </li>
                <li>
                  فموجب هذا التعاقد یقوم الطرف الاول باستلام الشحنات الخاصة
                  بالطرف الثاني، والمغلفة بمعرفة الطرف الثاني بفلايرز العائدة
                  للطرف الأول والمطبوع علیها أسم الطرف الاول ثم يتم إقفالها
                  وختمها بمعرفة الطرف الثاني ویلتزم الطرف الثانى بوضع المنتج
                  الخاص به المراد توصیله لعملاء الطرف الثانى فیها ویقوم بغلق
                  العبوه بمعرفته ووضع علیها اسم العمیل والعنوان المراد التوصیل
                  إلیه وبیانات الشحنه وقیمتها وهومسؤول مسؤولية كاملة عن المحتوى
                  داخل الشحنة جنائیا ومدنیا وسیاسیا ولیس للطرف الاول مسؤولية
                  تجاهها.
                </li>
                <li>
                  اتفق الطرفان أن المنتج الذي بداخل الفلاير أصبح شحنة للتوصیل من
                  قبل الطرف الاول وتابعیه فقط وأنه لیس له ولایه علیها وإنما
                  توصیل وتحصیل فقط.
                </li>
                <li>
                  أقر الطرف الثاني بأنه على علم بالمتطلبات القانونية ذات الصلة
                  التي تنطبق أثناء إجراء عملية الشحن داخل القطاع المحلي التي
                  أوصت به شركة بوسطة.
                </li>
                <li>
                  يتحمل الطرف الثاني المسؤولية المتعلقة بتاريخ ووقت الشحن
                  الصحيحين ورقم الحساب وعناوين الشاحن/ المرسل إليه، فضلًا عن
                  بيانات الاتصال ووصف السلعة مع إقرار القيمة بالبضائع.
                </li>
                <li>
                  يتحمل الطرف الثاني المسؤولية المتمثلة في تعبئة بوليصة الشحن
                  ويُعد بمثابة أحد المستندات الحيوية المتعلقة بنقل الشحنة.
                </li>
                <li>
                  يتحمل الطرف الثاني المسؤولية الكاملة عن جميع الغرامات
                  والعقوبات المُتكبدة من جانب شركة بوسطة نتيجة للبيانات الخاطئة
                  المُقدمة من الشاحن؛ كما يضمن التعاون عند إجراء أي تحقيق قد
                  ينشأ عن البيانات الكاذبة.
                </li>
                <li>
                  يقبل الطرف الثاني ويُقر بأن شركة بوسطة تحتفظ بالحق في فتح وفحص
                  الشحنات في أي وقت أثناء النقل دون الحصول على إشعار مسبق وتبليغ
                  الجهات المختصة بكافة البيانات ومحتوى الشحنة حال احتوائها على
                  ما يخالف الآداب العامة والأمن العام والقانون بأي شكل من
                  الأشكال.
                </li>
                <li>
                  في حالة إجراء عمليات التفتيش، يُمكن للهيئات الحكومية التحقق من
                  الشحنات، بما في ذلك قوات الأمن وقوات الشرطة وشرطة المرور،
                  فضلًا عن قيام الكيانات الحكومية بتنفيذ إجراءات السلامة والأمن.
                </li>
                <li>
                  لا يحق للطرف الثاني حوالة أياً من الحقوق أو الالتزامات الناشئة
                  عن هذا العقد أو التنازل عنها للغير سواء كلياً أو جزئياً، كما
                  لا يحق للطرف الثاني التعاقد مع الغير من الباطن لتنفيذ أي من
                  التزاماته الواردة بهذا العقد سواء كلياً أو جزئياً دون الحصول
                  على موافقة كتابية مسبقة من الطرف الأول في هذا الصدد.
                </li>
                <li>
                  يقر كلٍ من الطرفين بأن جميع حقوق الملكية الفكرية للطرف الآخر
                  ومنها - على سبيل المثال لا الحصر- الشعار أو الاسم التجاري أو
                  العلامة التجارية أو التصميمات أو المواد أو حقوق النشر أو
                  الفكرة التجارية أو الخطة التجارية هي الملكية المنفصلة الوحيدة
                  للطرف الاخر و الذي يظل المالك الحصري لهذه الحقوق، دون ان يشكل
                  هذا العقد تنازلاً عنها او منحها للطرف الآخر.
                </li>
                <li>
                  لتزم كلٍ من الطرفين باتخاذ جميع التدابير اللازمة لحماية
                  والحفاظ على حقوق الملكية الفكرية للطرف الاخر وعدم استخدامها أو
                  استغلالها في أي أنشطة تجارية دون موافقة كتابية مسبقة من الطرف
                  الاخر. لأغراض هذا العقد، تعني "المعلومات السرية" أي معلومات أو
                  بيانات ذات طبيعة سرية وملكية بأي شكل (بما في ذلك جميع
                  المعلومات أو البيانات المكتوبة أو الإلكترونية أو الملموسة أو
                  الشفوية أو البصرية وجميع المعلومات أو البيانات المسجلة كتابةً
                  أو بأي وسيلة أخري ) التي تتعلق بالأعمال التجارية لأي من
                  الطرفين، والتي قد يوفرها اي من الطرفين للطرف الاخر أو لأي من
                  موظفيه، وكلائه ومديريه أو المتعاقدين معه أثناء المفاوضات و
                  المناقشات المرتبطة بإبرام هذا العقد و خلال تنفيذه أو تم الكشف
                  عنها بطريقة أخرى بموجب هذا العقد بأي شكل من الأشكال ، والتي
                  يتعهد كل من الطرفين بأنه لن يقوم في أي وقت خلال مدة هذا العقد
                  او بعد انهاء او انتهاء مدتها بالكشف عنها لأي شخص ، باستثناء ما
                  تم الكشف او الإفصاح عنها لموظفيه أو المتعاقدين معه أو
                  المستشارين أو المدراء أو المسئولين أو الوكلاء التابعين له
                  والذين يحتاجون إلى الإطلاع على هذه المعلومات لتنفيذ الغرض من
                  هذا العقد، او كان الإفصاح عنها ضرورياً فقًا لما يقضيه القانون
                  ، او حكم المحكمة المختصة أو أي هيئة حكومية أو تنظيمية ذات صلة
                  ؛ او بموجب موافقة كتابية مسبقة من الطرف الاخر، ويبقى هذا البند
                  سارياً وملزماً لمدة (3) سنوات بعد انتهاء أو إنهاء هذا العقد
                  بأي شكل من الأشكال.
                </li>
                <li>
                  يلتزم الطرف الثاني بعدم القيام بأي تعدي على العلامات التجارية
                  المملوكة لشركة بوسطة، وعدم القيام بوضع اسم بوسطة علي أي منتجات
                  مصنعة من قبل جهات أخرى.
                </li>
                <li>
                  كما يلتزم باتخاذ جميع التدابير اللازمة لحماية والحفاظ على حقوق
                  الملكية الفكرية للطرف الأول وعدم استخدام العلامة التجارية أو
                  استغلالها في أي أنشطة تجارية دون موافقة كتابية مسبقة من الطرف
                  الأول.
                </li>
                <li>
                  يمثل هذا العقد والملاحق المرفقة به كامل العقد المبرم بين
                  الطرفين فيما يتصل بموضوعه. ويحل هذا العقد محل أية اتفاقيات
                  سابقة عليه (سواء كانت كتابية أو شفهية) أو تعهدات أو إقرارات
                  أخرى فيما بين الطرفين بخصوص موضوعه.
                </li>
                <li>
                  في حالة بطلان أي بند من بنود هذا العقد أو ثبوت مخالفته للقانون
                  فلا يمتد أثر هذا البطلان إلى باقي بنود العقد وتظل سارية وملزمة
                  للطرفين ومرتبة لأثرها القانوني. تكون عناوين البنود الواردة في
                  هذا العقد لغرض الإشارة فحسب، ولا يجوز أن تحد من أو تؤثر على
                  تفسير هذا العقد.
                </li>
                <li>
                  تكون عناوين البنود الواردة في هذا العقد لغرض الإشارة فحسب، ولا
                  يجوز أن تحد من أو تؤثر على تفسير هذا العقد.
                </li>
                <li>
                  أقر الطرفان بصحه العناوین والبرید الالكترونى المبین فى صدر
                  العقد وأن أیه مراسلات تتم بینهم تكون نافذه فى حقهم قانونا سواء
                  باللغة العربیة أو اللغة الانجلیزیة.
                </li>
                <li>
                  يخضع هذا العقد في تنفيذه أو تفسيره لأحكام القانون المصري و فى
                  حالة وجود نزاع بين الطرفين نتج عن أو بمناسبة هذا العقد – بما
                  في ذلك على سبيل المثال لا الحصر تنفيذه أو تفسيره أو إنهاؤه أو
                  فسخه – فإنه يتم حسم هذا النزاع بالطرق الودية بين سلطات الإدارة
                  العليا للطرفين ، فإذا لم يتم حسم هذا النزاع ودياً خلال (30)
                  يوماً، ينعقد الإختصاص القضائي لمحاكم القاهرة وجزئياتها كل فى
                  اختصاصه.
                </li>
                <li>
                  لا يوجد فى هذه الاتفاقية ما ينتج عنه شراكة أو وكالة أو علاقة
                  تبعية بين الطرفين أو أن يكون لأي طرف السلطة أو القوة لخلق أي
                  إلتزامات على عاتق الطرف الآخر بأي طريقة أو سبب أو إبرام أي
                  تعاقدات أو تقديم أية تعهدات بإسم الطرف الآخر.
                </li>
              </ol>
            </p>
          ) : (
            <p>
              <span>
                Terms and Conditions agreement for providing delivery services
              </span>
              <br />
              Our dear customer, <br />
              Bosta Technology - proudly a leading Egyptian company - is a
              "door-to-door" freight delivery service company that aims in
              essence to enable e-commerce in Egypt, through an integrated
              high-quality technological information system “Bosta Platform”,
              along with a professional and specialized technological team.
              Bosta owns its own HUBS and information system, platform and
              application, and its team of technical engineers works to develop
              and improve it permanently and practically, to provide one of the
              most efficient integrated services in this field.
              <br />
              We are pleased to offer the price plan and terms and conditions
              between us as follows: <br />
              <br />
              It is on ___________ __/__/{new Date().getFullYear()} this
              contract concluded between the two of:
              <br />
              <br />
              First Party:
              <br />
              <ul>
                <li>
                  Bosta Technology Company, headquartered at 2 A. Street 164 -
                  Hadayek El Maadi - Cairo, Commercial Registration No. 101723
                  and Tax No. 392-954-537 (Referred hereinafter “First Party” or
                  “Bosta”).
                </li>
                <br />
                Second Party
                <br />
                <li>
                  Company name/headquarters or address:
                  <br />
                  Email:
                  <br />
                  Commercial registration number or national number: issued on
                  <br />
                  (Referred hereinafter “Second Party” or “The Company”).
                  <br />
                </li>
                <br />
              </ul>
              <br />
              <span>Preamble:</span>
              <br />
              Whereas, the First Party is a company specialized in “Door to
              Door” , Last Mile Delivery and storage services for itself and
              others, fully complied to the A.R.E laws and regulations through
              obtaining all the required licenses to operate in A.R.E, to
              provide its customers with express delivery services, including
              Door to door delivery, receiving shipments from the agreed points,
              and delivering them to the concerned consignee as shown in the
              shipping policy with due diligence to perform and deliver the
              agreed service.
              <br />
              Whereas the Second Party wishes to transport its own products,
              shipments and goods to deliver back its own products, shipments
              and goods to its customers ;
              <br />
              Additionally, the Second Party wishes to have written signatures
              collecting services from the Second Party’s customers after
              reviewing the personal identification card through the First
              Party.
              <br />
              Therefore, the parties agree to fully cooperate in an appropriate
              manner to implement the operational procedures and time frames for
              the services that will be provided by the First Party.
              <br />
              Accordingly, after the two parties acknowledged their full legal
              capacity to contract, and the two parties approved and agreed to
              use the electronic contracting platform "of the First Party, and
              this is considered an acknowledgment of his validity of his
              signature and his agreement to the terms and conditions stipulated
              in this agreement" A, and the two parties have understood the form
              and content of the legal responsibility For this electronic
              contract, the two parties have agreed to conclude this contract in
              accordance with the following terms and conditions:
              <br />
              <br />
              <strong>
                {' '}
                The preamble and the attached appendices are considered a
                complementary and an integral part of this contract, therefore
                the parties agreement shall be interpreted accordingly:
              </strong>
              <br />
              <br />
              <span>A- Terms and conditions for prices:</span>
              <br />
              <ol>
                <li>
                  The price list is in Egyptian pounds, excluding expenses and
                  14% value-added tax.
                </li>
                <li>
                  The price list is for one single package of everything that
                  can be shipped within a small or regular Flyer. Additional
                  fees shall apply to large and extra-large flyers packaging
                  bags and white bags according to the table below.
                </li>
                <li>
                  The prices cover the shipping price and the price of the
                  collection service upon delivery, up to a value of 3000 EGP.
                  As for shipments whose collection value exceeds 3000 EGP, the
                  Second Party will be charged 1% on the additional amount that
                  exceeds 3000 EGP.
                </li>
                <li>
                  It is not allowed that the value of collection for one
                  shipment exceed 30,000 thirty thousand Egyptian pounds
                </li>
                <li>
                  Bosta can provide you with additional packaging materials such
                  as cardboard boxes, bubble wraps, seals, and more, at an
                  additional cost. Please contact your account manager or
                  customer service to find out the prices of these materials,
                  according to market prices.
                </li>
                <li>
                  Prices are for a single order that can fit in a flyer with one
                  medium, regardless of weight, provided that it does not exceed
                  20 kilograms per shipment. Bosta will not receive shipments
                  outside of the flyer or its packaging materials. Shipments
                  with more than one flyer must be placed in the white bag. Each
                  white bag counts as a separate shipment.
                </li>
                <li>
                  Without prejudice to the terms of this contract, both parties
                  agree that the stipulated prices are eligible for any upward
                  adjustments by the first party, and the first party must
                  inform the second party of the change in prices within 7 days,
                  and the second party’s use of the service during this period
                  is considered an implicit approval of that.
                </li>
                <li>
                  As an exception to the previous clause, in the event of an
                  increase in fuel prices by the state, the first party must
                  adjust the pricing according to the market increase without
                  prior approval from the second party.
                </li>
              </ol>
              <br />
              <br />
              <span>
                B - Equivalence of collected amounts and shipping costs:
              </span>
              <br />
              <ul>
                <li>
                  Transportation expenses for shipments that have been delivered
                  or returned are deducted from the total collections, and then
                  a weekly bank transfer is made to your account, according to
                  the Bosta company's accounting cycle.
                </li>
                <li>
                  As a Must, Please Fill your Bank Account Data in the
                  contracting stage and before Implementing the agreed services,
                  Please click the following link to proceed.
                </li>
              </ul>
              <br />
              <br />
              <span>C- Insurance for the shipments of the Second Party</span>
              <br />
              1- Bosta obligated to compensate for any loss shipment with a
              maximum of 10.000 EGP ( Ten thousand Egyptian pounds ) per
              shipment or damage shipment with a maximum of 500 (five hundred)
              Egyptian pounds only per shipment after the investigation team
              approval obtained regarding the packing conditions or the value
              declared on the damaged or lost shipment policy, whichever is
              less.
              <br />
              <br />
              <span>2- Terms of compensation:</span>
              <ul>
                <li>
                  The case of damage or loss must be reported immediately when
                  the case is proven (for example, at the moment of receiving
                  the returns); Bosta will not make any compensation in the
                  event of a delay in reporting (for example, after the Delivery
                  Employee leaves), and Bosta is not responsible for any loss or
                  damage in the event of failure or delay in reporting.
                </li>
                <li>
                  After reporting, Bosta has a maximum of 14 days for an
                  internal investigation.
                </li>
                <li>
                  The Second Party is fully responsible for any product from the
                  following list of prohibited products, wrongly packaged
                  shipments or a violation of the proper packaging method that
                  exposes them to damage (please refer to our guide for proper
                  packaging methods - link in the appendix), or any shipments
                  whose individual value exceeds ten thousand pounds Egypt.
                </li>
              </ul>
              <br />
              * Please review the video of the steps and items that must be
              followed in the event of damage or loss, a careful review (link in
              the appendix).
              <br />
              <br />
              <span>3- Products strictly prohibited:</span>
              Means goods that have been designated as prohibited goods by
              governmental, regulatory or any other entity and which are also
              included in Bosta's Prohibited Items List; And for example, but
              not limited to: -
              <ul>
                <li> Humans or animals. </li>
                <li> Any perishable food or drink items.</li>
                <li>
                  Any stolen, illegal, fraudulent or fraudulently obtained or
                  sold products, or any product you are not authorized to ship
                  or send.
                </li>
                <li>
                  Any products that are sensitive, prone to breakage, or/or
                  poorly packaged products that do not comply with the methods
                  recommended in Bosta.
                </li>
                <li>Any alcoholic/spiritual beverages and tobacco products.</li>
                <li>
                  Products that have not obtained the necessary legal permits
                  from the competent authorities to sell or distribute them to
                  the consumer according to Egyptian laws.
                </li>
                <li>
                  All materials offensive to the three monotheistic religions.
                </li>
                <li>
                  Illegal electronic or communication tools or equipment
                  according to Egyptian law.
                </li>
                <li>
                  Gold and platinum, whether in the form of worked items,
                  ingots, or in the form of currency, and ingots and unworked
                  items, as well as wires, rods, tubes, sheets, and precious
                  gems such as diamonds, emeralds, sapphires, sapphires, and
                  natural pearls.
                </li>
                <li>
                  Explosive or flammable materials (all kinds of corrosive
                  substances such as sulfuric acid, hydrochloric acid, nitric
                  acid, organic solvents, pesticides, hydrogen peroxide, and
                  hazardous chemicals to name a few).
                </li>
                <li> Currency Exchange.</li>
                <li>
                  All kinds of publications, propaganda, publications, and
                  sexual games that may endanger national security and social
                  and political stability.
                </li>
                <li>
                  All kinds of drugs: opium (especially the peels, flowers,
                  buds, and leaves of the poppy plant), morphine, cocaine,
                  heroin, marijuana, amphetamine chloride, ephedrine and other
                  products.
                </li>
                <li>
                  All types of weapons, ammunition, and explosives such as:
                  guns, bullets, shells, grenades, mines, bombs, detonators,
                  explosives and gunpowder.
                </li>
                <li>
                  Dangerous tools or equipment that may harm or kill others (all
                  explosive materials, such as: fireworks, firecrackers,
                  explosive torpedoes, stun gun paper cartridges, fuses, all
                  types of flammable materials, including liquids, gases and
                  solids, such as: petroleum, kerosene, oil Tung, alcohol, paint
                  materials, diesel fuel, aerosols, gas lighters, gas cylinders,
                  phosphorus, matches, matches, all kinds of biochemical
                  products and infectious materials, such as: anthrax, dangerous
                  germs, medical waste, all kinds of toxins, such as: thallium,
                  cyanide, and arsenic, for example. example but not limited to)
                </li>
                <li>
                  Financial instruments: such as money/currency, bearer check,
                  bank draft or liquid assets; Bank checks, letters of
                  guarantee, or any original documents or papers that have a
                  financial value or that cannot be replaced.
                </li>
                <li>
                  Any products with value exceeding Thirty thousand Egyptian
                  pounds (30,000 EGP)
                </li>
              </ul>
              <br />
              <br />
              <span>Other Terms and Conditions</span>
              <br />
              <ol>
                <li>
                  the Second Party undertakes, within the framework of the
                  services provided by the First Party, the subject of the
                  contract, not to perform any illegal or immoral acts, or any
                  fraud or counterfeit, or any actions with objectives contrary
                  to Egyptian laws and contrary to the public interest. The
                  Second Party declares that its use of the services provided by
                  the First Party within the framework of this contract, or the
                  terms and conditions is a use within the framework of Egyptian
                  laws. For example, these laws may be related to financial
                  laws, money laundering, commercial violations, terrorist
                  operations and immoral acts. The Second Party bears full and
                  exclusive responsibility for the content of its shipments. The
                  Second Party acknowledges its commitment to the laws of the
                  Arab Republic of Egypt in obtaining the product to be shipped
                  and its validity for use and that it has fulfilled and
                  obtained the necessary licenses, permits and approvals to
                  become valid for its customers once transported through the
                  First Party.
                </li>
                <li>
                  The Second Party agrees that the First Party has the full
                  right to terminate the contract immediately and without any
                  prior notice in the event that the Second Party is involved in
                  any illegal or immoral activity or uses the services of the
                  First Party for illegal or immoral purposes, including but not
                  limited to:
                  <ol>
                    <li>
                      The Second Party's use of the First Party's services for
                      illegal, immoral, fraudulent, obscene reasons, or any
                      actions prohibited by Egyptian law, legal, official, or
                      government agencies in the Arab Republic of Egypt.
                    </li>
                    <li>
                      The shipped product Violated any of the Egyptian laws or
                      the terms and conditions of this contract.
                    </li>
                  </ol>
                </li>
                <li>
                  The Second Party acknowledges that its employees and
                  representatives are responsible for how to use the services of
                  the First Party, and that the employees, representatives and
                  partners of the Second Party take the Second Party's approach
                  and bear the legal responsibility within the framework of the
                  terms and conditions of this contract.
                </li>
                <li>
                  The Second Party undertakes not to ship blank checks or with a
                  reference to bearer with the First Party.
                </li>
                <li>
                  The Second Party is fully responsible for by what method and
                  how to pack its shipments, and in the event that the Second
                  Party does not follow proper packaging instructions, the First
                  Party does not bear any partial or total responsibility in the
                  event of damage to the products of the Second Party shipped
                  with the First Party.
                </li>
                <li>
                  The Second Party disclaims the responsibility of the First
                  Party for any defects resulting from the quality or
                  manufacture of its products shipped by the First Party, and
                  the Second Party acknowledges that it is solely responsible
                  for any defects in the shipped products that may be deemed by
                  the Consumer Protection Agency that the consumer deserves
                  compensation of any kind and is obligated to pay all
                  compensation resulting from that.
                </li>
                <li>
                  The First Party has the right to inform the consumer
                  protection agency of the Second Party's data, and the Second
                  Party alone bears all the legal consequences resulting from
                  that.
                </li>
                <li>
                  The Second Party acknowledges that it is committed to all laws
                  and regulations of the Consumer Protection Agency.
                </li>
                <li>
                  The duration of this contract is one year from the date of the
                  First shipment, renewable for another equal period or periods
                  according to the prior agreement between the two parties.
                </li>
                <li>
                  The contract can be canceled by either Party by giving notice
                  through e-mail thirty days prior to the cancellation. The
                  duration of this contract is one year from the date of the
                  First shipment, renewable for another equal period or periods
                  according to the prior agreement between the two parties. The
                  contract can be canceled by either Party by giving notice
                  through e-mail thirty days prior to the cancellation.
                </li>
                <li>
                  The First Party reserves the right to disclose the personal
                  information and data of the Second Party to any Egyptian
                  Governmental security agency under an authorized and legal
                  request from this Governmental security agency under the cover
                  of Egyptian laws, or according to a judicial decree.
                </li>
                <li>
                  The second party shall provide the imported shipment documents
                  to BOSTA in case of requested by the competent Authorities
                </li>
                <li>
                  Bosta shall not bear any Tax or customs fines imposed on any
                  shipments related to second party and If BOSTA suffers any tax
                  or governmental charges BOSTA shall have recourse the second
                  party for such expenses with prove of payment .
                </li>
                <li>
                  In the event of any dispute arising regarding the
                  implementation or interpretation of this contract, the Cairo
                  courts shall be competent to consider, adjudicate and settle
                  it.
                </li>
                <li>
                  According to this contract, the First Party receives the
                  shipments of the Second Party, which are wrapped by the Second
                  Party with flyers belonging to the First Party, on which the
                  name of the First Party is printed, then they are closed and
                  stamped by the Second Party, and the Second Party is obligated
                  to put his own product to be delivered to the customers of the
                  Second Party, and closes the package with a full knowledge and
                  puts the customer’s name, the address to be delivered to,
                  shipment data and its value, therefore, the Second Party is
                  fully responsible for the content inside the shipment
                  criminally, civilly and politically, and the First Party has
                  no responsibility towards it. Both parties agreed that the
                  product inside the flyer has become a shipment for
                  transportation by the First Party and its subordinates only,
                  and that it has no jurisdiction over it, but rather
                  transportation and collection only.
                </li>
                <li>
                  The Second Party acknowledges that it is aware of the relevant
                  legal requirements that apply while carrying out the shipment
                  process within the local sector recommended by Bosta Company.
                </li>
                <li>
                  The Second Party shall be responsible for the correct shipment
                  date and time, account number, and addresses of the
                  shipper/consignee, as well as contact details, description of
                  the item, and declaration of the value of the goods.
                </li>
                <li>
                  The Second Party has the responsibility of filling in the bill
                  of lading and is one of the vital documents related to the
                  transportation of the shipment.
                </li>
                <li>
                  The Second Party shall be fully responsible for all fines and
                  penalties incurred by Bosta Company as a result of false
                  statements provided by the shipper; It also guarantees
                  cooperation when conducting any investigation that may arise
                  from false statements.
                </li>
                <li>
                  The Second Party accepts and acknowledges that Bosta Company
                  reserves the right to open and examine shipments at any time
                  during transportation without obtaining prior notice and
                  informing the competent authorities of all data and the
                  content of the shipment if it contains anything that violates
                  public morals, public security and the law in any way.
                </li>

                <li>
                  If inspections are conducted, shipments can be checked by
                  government agencies, including security forces, police forces,
                  and traffic police, as well as by government entities
                  implementing safety and security measures.
                </li>
                <li>
                  The Second Party is not entitled to allocate or assign any of
                  the rights or obligations arising from this contract to
                  others, whether in whole or in part, nor is the Second Party
                  entitled to subcontract with third parties to implement any of
                  its obligations contained in this contract, whether in whole
                  or in part, without obtaining prior written approval from the
                  First Party in this regard.
                </li>
                <li>
                  Each Party acknowledges that all intellectual property rights
                  of the other Party including - without limitation - the logo,
                  trade name, trademark, designs, materials, copyright, business
                  idea or business plan are the sole and separate property of
                  the other Party, who remains the exclusive owner These rights,
                  without this contract constituting a waiver or granting them
                  to the other Party.
                </li>
                <li>
                  Each of the parties is obligated to take all necessary
                  measures to protect and preserve the intellectual property
                  rights of the other Party and not to use or exploit them in
                  any commercial activities without the prior written consent of
                  the other Party. For the purposes of this Contract,
                  “Confidential Information” means any information or data of a
                  confidential and proprietary nature in any form (including all
                  written, electronic, tangible, oral, or visual information or
                  data and all information or data recorded in writing or by any
                  other means) that relates to the Business business of either
                  Party, which either Party may provide to the other Party or
                  any of its employees, agents, managers or contractors during
                  negotiations and discussions related to the conclusion of this
                  contract and during its implementation or otherwise disclosed
                  under this contract in any way, which undertakes Each of the
                  parties that he will not, at any time during the term of this
                  contract or after the termination or expiry of its term,
                  disclose it to any person, except for what has been disclosed
                  or disclosed to his employees, contractors, consultants,
                  directors, officials or agents affiliated with him and who
                  need to see such information to carry out the purpose of this
                  contract, or its disclosure is necessary as required by law,
                  or the judgment of a competent court or any relevant
                  governmental or regulatory body; Or with the prior written
                  consent of the other Party, and this clause remains valid and
                  binding for a period of (3) years after the expiry or
                  termination of this contract in any way.
                </li>
                <li>
                  The Second Party is obligated not to infringe on the
                  trademarks owned by Bosta, and not to put Bosta's name on any
                  products manufactured by other parties.
                </li>
                <li>
                  It is also obligated to take all necessary measures to protect
                  and preserve the intellectual property rights of the First
                  Party and not to use or exploit the trademark in any
                  commercial activities without the prior written consent of the
                  First Party.
                </li>
                <li>
                  This contract and the appendices attached to it represent the
                  entire contract concluded between the two parties in
                  connection with its subject matter. This contract supersedes
                  any prior agreements (whether written or oral) or other
                  undertakings or representations between the parties regarding
                  its subject matter.
                </li>
                <li>
                  In the event that any of the clauses of this contract is
                  invalid or proven to be in violation of the law, the effect of
                  this invalidity shall not extend to the rest of the clauses of
                  the contract and it shall remain valid and binding on both
                  parties and shall be in order for its legal effect.
                </li>
                <li>
                  The headings of the clauses in this Contract are for reference
                  only and shall not limit or affect the interpretation of this
                  Contract.
                </li>
                <li>
                  The two parties acknowledged the correctness of the addresses
                  and e-mails indicated at the beginning of the contract, and
                  that any correspondence between them would be legally
                  enforceable against them, whether in Arabic or English.
                </li>
                <li>
                  This contract is subject to the implementation or
                  interpretation of the provisions of Egyptian law, and in the
                  event of a dispute between the two parties resulting from or
                  on the occasion of this contract - including but not limited
                  to its implementation, interpretation, termination or
                  termination - then this dispute shall be resolved amicably
                  between the administration authorities If this dispute is not
                  resolved amicably within (30) days, the jurisdiction of the
                  Cairo courts and its parts shall be held, each in its own
                  jurisdiction.
                </li>
                <li>
                  Nothing in this agreement results in a partnership, agency, or
                  ependency relation dship between the two parties, or for
                  either Party to have the authority or power to create any
                  obligations on the other Party in any way or reason, or to
                  enter into any contracts or make any undertakings in the name
                  of the other Party.
                </li>
              </ol>
            </p>
          )}
        </div>
      )}
    </Modal>
  );
};
export default injectIntl(TermsModal);
