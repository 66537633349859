import { useIntl } from 'react-intl';
import querySearch from 'stringquery';

import { LOCALE } from 'constants/intl-wrapper';
import { handleLanguageChange } from 'utils/helpers';

import BRButton from 'components/BRButton/BRButton';

import { ReactComponent as BostaLogo } from 'assets/imgRevamp/bosta_logo.svg';
import { ReactComponent as BostaLogoArabic } from 'assets/imgRevamp/bosta_arabic.svg';
import { ReactComponent as Language } from 'assets/bosta-icons/Language.svg';
import { ReactComponent as ExternalLink } from 'assets/bosta-icons/external-link.svg';
import { ReactComponent as LocationIcon } from 'assets/bosta-icons/Location.svg';

import './SignUpHeader.less';

const SignUpHeader = () => {
  const intl = useIntl();

  const onChangeLanguage = () => {
    const { lang, ...restParams } = querySearch(window.location.search);
    let modifiedPath;
    if (lang) {
      const modifiedQuerySearch = new URLSearchParams(restParams).toString();
      modifiedPath = `${window.location.pathname.split('/')[1]}${
        modifiedQuerySearch && `?${modifiedQuerySearch}`
      }`;
    }

    handleLanguageChange(
      intl.locale === LOCALE.EN ? LOCALE.AR : LOCALE.EN,
      modifiedPath
    );
  };

  const handleGoToWebsitePage = () => {
    window.open(
      `https://bosta.co/${
        intl.locale === LOCALE.EN ? 'en' : 'ar'
      }/tracking-shipments`,
      '_blank'
    );
  };

  return (
    <div className="br-signup-header__container">
      <div className="br-signup-header__logo">
        {intl.locale === LOCALE.EN ? <BostaLogo /> : <BostaLogoArabic />}
      </div>
      <div className="br-signup-header__actions">
        <BRButton
          type="link-color"
          label={intl.formatMessage({ id: 'new_sign_up.header.track_order' })}
          className="br-signup__track-order button-md"
          suffixIcon={<ExternalLink />}
          prefixIcon={<LocationIcon />}
          onClick={handleGoToWebsitePage}
        />
        <BRButton
          type="link-gray"
          label={intl.locale === LOCALE.EN ? 'عربي' : 'English'}
          className="br-signup__language-btn button-md"
          suffixIcon={<Language />}
          onClick={onChangeLanguage}
        />
      </div>
    </div>
  );
};

export default SignUpHeader;
