import React from 'react';
import { Modal } from 'antd';

import { mediaHook } from 'customHooks';

import PackageSizesIcon from 'assets/bosta-icons/package-sizes.png';
import PackageSizesMobileIcon from 'assets/bosta-icons/package-size-mobile.png';

const PackageGuideModal = ({ close, mobileScreenSizes, ...props }) => {
  const { isLargeMobileScreen } = mobileScreenSizes;
  return (
    <Modal
      className="br-package-guide-modal"
      {...props}
      onCancel={close}
      title={null}
      footer={null}
    >
      {isLargeMobileScreen ? (
        <img src={PackageSizesMobileIcon} alt="" onClick={close} />
      ) : (
        <img src={PackageSizesIcon} alt="" onClick={close} />
      )}
    </Modal>
  );
};

export default mediaHook(PackageGuideModal);
