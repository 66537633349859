import { bostaHttp } from 'http.js';

export const getPickupLocations = (payload) => {
  return bostaHttp.get(`/pickup-locations`, payload);
};

export const fetchPickupLocation = (id) => {
  return bostaHttp.get(`/pickup-locations/${id}`);
};

export const createPickupLocation = (payload) => {
  return bostaHttp.post(`/pickup-locations`, payload);
};

export const editPickupLocation = ({ pickupLocationId, payload }) => {
  return bostaHttp.put(`/pickup-locations/${pickupLocationId}`, payload);
};

export const deletePickupLocation = ({ id }) => {
  return bostaHttp.delete(`/pickup-locations/${id}`);
};

export const setDefaultPickupLocation = (id) => {
  return bostaHttp.put(`/pickup-locations/${id}/default`);
};
