import { useIntl } from 'react-intl';
import Icon from '@ant-design/icons';
import { Carousel } from 'antd';

import { getCurrency } from 'constants/countries/countries-mapping';
import { NEW_SIGNUP_FEATURES, SIGNUP_PAGE_VIDEOS_IDS } from 'constants/signup';

import BRButton from 'components/BRButton/BRButton';

import SignUpBannerImage from 'assets/imgRevamp/sign_up_banner.png';
import { ReactComponent as ArrowDown } from 'assets/bosta-icons/Arrow-down.svg';
import { ReactComponent as SignUpBannerBackground } from 'assets/bosta-icons/SignupBannerBackground.svg';

import './SignUpBanner.less';

const SignUpBanner = ({ signUpPricingSectionRef }) => {
  const intl = useIntl();

  const handleOnShippingPricesClick = () => {
    signUpPricingSectionRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    });
  };

  return (
    <div className="br-signup-banner__container">
      <SignUpBannerBackground />
      <div className="br-signup-banner__title display-xl">
        {intl.formatMessage(
          { id: 'new_sign_up.main.banner.title' },
          {
            span: (children) => <span className="highlighted">{children}</span>
          }
        )}
      </div>
      <div className="br-signup-banner__carousel">
        <Carousel autoplay>
          {SIGNUP_PAGE_VIDEOS_IDS.map((videoId) => (
            <iframe
              key={videoId}
              src={`https://www.youtube.com/embed/${videoId}`}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            />
          ))}
        </Carousel>
      </div>
      <div className="br-signup-banner__pricing-calculator">
        <div>
          <div className="br-signup-banner__pricing-title subheading">
            {intl.formatMessage({
              id: 'new_sign_up.main.banner.shipping_prices_title'
            })}
          </div>
          <div className="br-signup-banner__pricing-subtitle heading">
            {intl.formatMessage(
              {
                id: 'new_sign_up.main.banner.shipping_prices_subtitle'
              },
              {
                startingAmount: getCurrency(60).localized,
                span: (children) => (
                  <span className="highlighted">{children}</span>
                )
              }
            )}
          </div>
        </div>
        <BRButton
          className="button-md"
          label={intl.formatMessage({
            id: 'new_sign_up.main.banner.shipping_prices'
          })}
          onClick={handleOnShippingPricesClick}
          suffixIcon={<ArrowDown />}
        />
      </div>
      <div className="br-signup-banner__features">
        {NEW_SIGNUP_FEATURES.map(({ labelId, icon: FeatureIcon }) => (
          <div className="br-signup-banner__feature-item" key={labelId}>
            <Icon component={FeatureIcon} className="ant-icon-md" />
            <span>
              {intl.formatMessage(
                { id: `new_sign_up.main.banner.features.${labelId}` },
                {
                  span: (children) => (
                    <span className="highlighted body-medium">{children}</span>
                  )
                }
              )}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SignUpBanner;
