import React, { useCallback } from 'react';
import { injectIntl } from 'react-intl';
import { Form, Input, Select } from 'antd';
import { debounce } from 'lodash';
import ReactResizeDetector from 'react-resize-detector';
import classNames from 'classnames';

import { requiredRule, phoneNumberRule } from 'utils/forms';
import { LOCALE } from 'constants/intl-wrapper';

import { COUNTRIES, URL_FLAGS } from 'constants/country-data';
import { PHONE_NUMBER } from 'constants/Forms';

import BRFormInputs from 'components/BRFormInputs/BRFormInputs';

import './BRInternationalPhone.less';

const BRInternationalPhone = ({
  intl,
  formRef,
  country,
  setCountry,
  usedPhoneNumber,
  setUsedPhoneNumber = () => {},
  withSelect = true,
  disabledSelector,
  name = 'phone',
  label,
  countriesList = COUNTRIES,
  ...props
}) => {
  const handleRemoveCountryCode = () => {
    const phoneNumber = formRef.current
      .getFieldValue(name)
      ?.replace(/^[0+]+/, '')
      ?.replace(new RegExp('^' + country.codeNumber.replace('+', '')), '');

    formRef.current.setFieldsValue({
      [name]: phoneNumber
    });
  };

  const debounceHandleChange = useCallback(
    debounce(handleRemoveCountryCode, 500),
    [country]
  );

  return (
    <ReactResizeDetector>
      {({ width, height }) => (
        <Form.Item
          label={
            label ||
            intl.formatMessage({
              id: 'contact_form_labels.phone'
            })
          }
          className={classNames('br-international-phone', {
            'br-international-phone__without-select': !withSelect,
            'br-international-phone__select-disabled': disabledSelector
          })}
        >
          <Input.Group compact>
            {withSelect && (
              <Select
                value={country?.id}
                autoComplete={'' + Math.random()}
                dropdownMatchSelectWidth={width}
                onChange={(value) => {
                  formRef.current.setFieldsValue({ [name]: '' });
                  setCountry(
                    countriesList.find((country) => country.id === value)
                  );
                }}
                disabled={disabledSelector}
              >
                {countriesList.map((country, index) => (
                  <Select.Option value={country?.id} key={index}>
                    <img
                      className="br-international-phone__country-flag"
                      src={URL_FLAGS + country.codeName.toLowerCase() + '.svg'}
                      alt="flag"
                    />
                    <div>
                      <span className="br-international-phone__country-name">
                        {intl.locale === LOCALE.EN
                          ? country.name
                          : country.arabicName}
                      </span>
                      <span className="br-international-phone__country-code-number">
                        {country.codeNumber}
                      </span>
                      <span className="br-international-phone__country-code-name">
                        {country.codeName}
                      </span>
                    </div>
                  </Select.Option>
                ))}
              </Select>
            )}
            <BRFormInputs
              type={PHONE_NUMBER}
              formRef={formRef}
              name={name}
              prefix={
                intl.locale === LOCALE.EN
                  ? country?.codeNumber
                  : country?.codeNumberArabic
              }
              placeholder={country?.placeholder}
              rules={[
                requiredRule(
                  intl.formatMessage({
                    id: 'sign_up.phone_required_message'
                  })
                ),
                phoneNumberRule({
                  message: intl.formatMessage({ id: 'form.phone_not_valid' }),
                  country,
                  withoutCountryCode: true
                })
              ]}
              validateStatus={usedPhoneNumber ? 'error' : undefined}
              help={
                usedPhoneNumber
                  ? intl.formatMessage({
                      id: 'sign_up.phone_already_used'
                    })
                  : undefined
              }
              onChange={() => {
                setUsedPhoneNumber(false);
                debounceHandleChange();
              }}
              hasFeedback
            />
          </Input.Group>
        </Form.Item>
      )}
    </ReactResizeDetector>
  );
};

export default injectIntl(BRInternationalPhone);
