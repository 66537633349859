import Icon from '@ant-design/icons';
import { useIntl } from 'react-intl';

import OrderDestinationSelector from 'components/Orders/components/CreateEditOrder/components/OrderDestination/components/OrderDestinationSelector/OrderDestinationSelector';

import { ReactComponent as InternationalServiceBackground } from 'assets/imgRevamp/international-service-background.svg';
import { ReactComponent as ArrowRight } from 'assets/bosta-icons/Arrow-right.svg';

import './InternationalShippingDestination.less';

const InternationalShippingDestination = () => {
  const intl = useIntl();

  return (
    <div className="br-international-shipping-destination">
      <InternationalServiceBackground />
      <div className="br-international-shipping-destination__selector">
        <OrderDestinationSelector isOrigin />
        <Icon component={ArrowRight} className="ant-icon-lg" />
        <OrderDestinationSelector />
      </div>
      <span className="caption">
        {intl.formatMessage({
          id: 'sign_up.services.international_description'
        })}
      </span>
    </div>
  );
};

export default InternationalShippingDestination;
