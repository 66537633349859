export const ATTEMPT_TYPES = {
  DELIVERY: 'delivery',
  PICKUP: 'pickup',
  RETURN: 'return'
};

export const MAX_NUM_OF_ATTEMPTS = {
  DEFAULT: {
    DELIVERY: 3,
    PICKUP: 3,
    RETURN: 3
  },
  SA: {
    DELIVERY: 4,
    PICKUP: 4,
    RETURN: 4
  }
};
