import { fmt } from 'components/IntlWrapper/IntlWrapper';

export const BANK_DETAILS_PRICE = 'BANK_DETAILS_PRICE';
export const ANNOUNCEMENT_MODAL_TEAM = 'ANNOUNCEMENT_MODAL_TEAM';
export const DEFAULT_ACCESS_CODE = 100;
export const BUSINESS_FULL_ACCESS_CODE = 115;
//10 pending
//20 active
//30 deleted

export const stateColor = {
  10: 'yellow',
  15: 'yellow',
  20: 'green',
  30: 'red'
};

export const DEFAULT_ACCESS = 'BUSINESS_DEFAULT_ACCESS';
export const FINANCE_ACCESS = 'BUSINESS_FINANCE_ACCESS';
export const WALLET_ACCESS = 'BUSINESS_WALLET_ACCESS';
export const TECH_ACCESS = 'BUSINESS_TECH_ACCESS';
export const TEAM_MANAGMENT_ACCESS = 'BUSINESS_TEAM_MANAGMENT_ACCESS';
export const FINANCE_WALLET_ACCESS = 'BUSINESS_FINANCE_WALLET_ACCESS';
export const FINANCE_TECH_ACCESS = 'BUSINESS_FINANCE_TECH_ACCESS';
export const FINANCE_TEAM_MANAGMENT_ACCESS =
  'BUSINESS_FINANCE_TEAM_MANAGMENT_ACCESS';
export const WALLET_TECH_ACCESS = 'BUSINESS_WALLET_TECH_ACCESS';
export const WALLET_TEAM_MANAGMENT_ACCESS =
  'BUSINESS_WALLET_TEAM_MANAGMENT_ACCESS';
export const TECH_TEAM_MANAGMENT_ACCESS = 'BUSINESS_TECH_TEAM_MANAGMENT_ACCESS';
export const FULL_ACCESS = 'BUSINESS_FULL_ACCESS';
export const FINANCE_WALLET_TECH_ACCESS = 'BUSINESS_FINANCE_WALLET_TECH_ACCESS';
export const FINANCE_WALLET_TEAM_MANAGMENT_ACCESS =
  'BUSINESS_FINANCE_WALLET_TEAM_MANAGMENT_ACCESS';
export const FINANCE_TEAM_MANAGMENT_TECH_ACCESS =
  'BUSINESS_FINANCE_TEAM_MANAGMENT_TECH_ACCESS';
export const WALLET_TEAM_MANAGMENT_TECH_ACCESS =
  'BUSINESS_WALLET_TEAM_MANAGMENT_TECH_ACCESS';
export const BUSINESS_SUB_ACCOUNT = 'BUSINESS_SUB_ACCOUNT';

export const EDITOR_DEFAULT_ACCESS = 'BUSINESS_EDITOR_DEFAULT_ACCESS';
export const EDITOR_TECH_ACCESS = 'BUSINESS_EDITOR_TECH_ACCESS';
export const EDITOR_TEAM_MANAGMENT_ACCESS =
  'BUSINESS_EDITOR_TEAM_MANAGMENT_ACCESS';
export const EDITOR_TECH_TEAM_MANAGMENT_ACCESS =
  'BUSINESS_EDITOR_TECH_TEAM_MANAGMENT_ACCESS';
export const EDITOR_WALLET_ACCESS = 'BUSINESS_EDITOR_WALLET_ACCESS';
export const EDITOR_WALLET_TECH_ACCESS = 'BUSINESS_EDITOR_WALLET_TECH_ACCESS';
export const EDITOR_WALLET_TEAM_MANAGMENT_ACCESS =
  'BUSINESS_EDITOR_WALLET_TEAM_MANAGMENT_ACCESS';
export const EDITOR_WALLET_TEAM_MANAGMENT_TECH_ACCESS =
  'BUSINESS_EDITOR_WALLET_TEAM_MANAGMENT_TECH_ACCESS';
export const EDITOR_FINANCE_ACCESS = 'BUSINESS_EDITOR_FINANCE_ACCESS';
export const EDITOR_FINANCE_TECH_ACCESS = 'BUSINESS_EDITOR_FINANCE_TECH_ACCESS';
export const EDITOR_FINANCE_TEAM_MANAGMENT_ACCESS =
  'BUSINESS_EDITOR_FINANCE_TEAM_MANAGMENT_ACCESS';
export const EDITOR_FINANCE_WALLET_ACCESS =
  'BUSINESS_EDITOR_FINANCE_WALLET_ACCESS';
export const EDITOR_FINANCE_WALLET_TECH_ACCESS =
  'BUSINESS_EDITOR_FINANCE_WALLET_TECH_ACCESS';
export const EDITOR_FINANCE_TEAM_MANAGMENT_TECH_ACCESS =
  'BUSINESS_EDITOR_FINANCE_TEAM_MANAGMENT_TECH_ACCESS';
export const EDITOR_FINANCE_WALLET_TEAM_MANAGMENT_ACCESS =
  'BUSINESS_EDITOR_FINANCE_WALLET_TEAM_MANAGMENT_ACCESS';
export const EDITOR_FULL_ACCESS = 'BUSINESS_EDITOR_FULL_ACCESS';

export const ANALYTICS_CODE = 116;

export const AllRoleAndPermissions = {
  [DEFAULT_ACCESS]: ['Operations'],
  [FINANCE_ACCESS]: ['Operations', 'Finance'],
  [WALLET_ACCESS]: ['Operations', 'Wallet'],
  [TECH_ACCESS]: ['Operations', 'Integrations'],
  [TEAM_MANAGMENT_ACCESS]: ['Operations', 'Business'],
  [FINANCE_WALLET_ACCESS]: ['Operations', 'Wallet', 'Finance'],
  [FINANCE_TECH_ACCESS]: ['Operations', 'Finance', 'Integrations'],
  [FINANCE_TEAM_MANAGMENT_ACCESS]: ['Operations', 'Finance', 'Business'],
  [WALLET_TECH_ACCESS]: ['Operations', 'Wallet', 'Integrations'],
  [WALLET_TEAM_MANAGMENT_ACCESS]: ['Operations', 'Wallet', 'Business'],
  [TECH_TEAM_MANAGMENT_ACCESS]: ['Operations', 'Integrations', 'Business'],
  [FINANCE_WALLET_TECH_ACCESS]: [
    'Operations',
    'Wallet',
    'Finance',
    'Integrations'
  ],
  [FINANCE_WALLET_TEAM_MANAGMENT_ACCESS]: [
    'Operations',
    'Wallet',
    'Finance',
    'Business'
  ],
  [FINANCE_TEAM_MANAGMENT_TECH_ACCESS]: [
    'Operations',
    'Integrations',
    'Finance',
    'Business'
  ],
  [WALLET_TEAM_MANAGMENT_TECH_ACCESS]: [
    'Operations',
    'Wallet',
    'Integrations',
    'Business'
  ],
  [FULL_ACCESS]: ['Operations', 'Wallet', 'Finance', 'Integrations', 'Business']
};

//100 defualt access
//108 finance access
//104 wallet access
//101 Integrations access TECH_ACCESS
//102 Business access  TEAM_MANAGMENT_ACCESS
//115 full access

const ROLES_TITLES_SUBTITLE = {
  115: {
    title: fmt({
      id: 'settings.teams_members.permission.115.title'
    })
  },
  104: {
    title: fmt({
      id: 'settings.teams_members.permission.104.title'
    }),
    subtitle: fmt({
      id: 'settings.teams_members.permission.104.sub_title'
    })
  },
  108: {
    title: fmt({
      id: 'settings.teams_members.permission.108.title'
    }),
    subtitle: fmt({
      id: 'settings.teams_members.permission.108.sub_title'
    })
  },
  101: {
    title: fmt({
      id: 'settings.teams_members.permission.101.title'
    }),
    subtitle: fmt({
      id: 'settings.teams_members.permission.101.sub_title'
    })
  },
  102: {
    title: fmt({
      id: 'settings.teams_members.permission.102.title'
    }),
    subtitle: fmt({
      id: 'settings.teams_members.permission.102.sub_title'
    })
  },
  116: {
    title: fmt({
      id: 'settings.teams_members.permission.116.title'
    }),
    subtitle: fmt({
      id: 'settings.teams_members.permission.116.sub_title'
    })
  }
};

export const allRolesAndSubItems = [
  // {
  //   code: 100,
  //   subRoleCodes: [],
  //   subTitleLocaiztion: true,
  //   active: true,
  //   hasIcon: false
  // },
  {
    code: 115,
    active: true,
    title: ROLES_TITLES_SUBTITLE[115].title,
    subTitleLocaiztion: false,
    hasIcon: false,
    subRoleCodes: [
      {
        // wallet
        code: 104,
        active: true,
        title: ROLES_TITLES_SUBTITLE[104].title,
        subtitle: ROLES_TITLES_SUBTITLE[104].subtitle,
        hasIcon: true
      },
      {
        // payment methods
        code: 108,
        active: true,
        title: ROLES_TITLES_SUBTITLE[108].title,
        subtitle: ROLES_TITLES_SUBTITLE[108].subtitle,
        hasIcon: true
      },
      {
        // integrations
        code: 101,
        active: true,
        title: ROLES_TITLES_SUBTITLE[101].title,
        subtitle: ROLES_TITLES_SUBTITLE[101].subtitle,
        hasIcon: true
      },
      {
        // admin
        code: 102,
        active: true,
        title: ROLES_TITLES_SUBTITLE[102].title,
        subtitle: ROLES_TITLES_SUBTITLE[102].subtitle,
        hasIcon: true
      },
      {
        code: ANALYTICS_CODE,
        active: true,
        title: ROLES_TITLES_SUBTITLE[ANALYTICS_CODE].title,
        subtitle: ROLES_TITLES_SUBTITLE[ANALYTICS_CODE].subtitle,
        hasIcon: false
      }
    ]
  }
];

export const editorRolesAndSubItems = [
  {
    code: 215,
    active: true,
    title: ROLES_TITLES_SUBTITLE[115].title,
    subTitleLocaiztion: false,
    hasIcon: false,
    subRoleCodes: [
      {
        // wallet
        code: 204,
        active: true,
        title: ROLES_TITLES_SUBTITLE[104].title,
        subtitle: ROLES_TITLES_SUBTITLE[104].subtitle,
        hasIcon: true
      },
      {
        // payment methods
        code: 208,
        active: true,
        title: ROLES_TITLES_SUBTITLE[108].title,
        subtitle: ROLES_TITLES_SUBTITLE[108].subtitle,
        hasIcon: true
      },
      {
        // integrations
        code: 201,
        active: true,
        title: ROLES_TITLES_SUBTITLE[101].title,
        subtitle: ROLES_TITLES_SUBTITLE[101].subtitle,
        hasIcon: true
      },
      {
        // admin
        code: 202,
        active: true,
        title: ROLES_TITLES_SUBTITLE[102].title,
        subtitle: ROLES_TITLES_SUBTITLE[102].subtitle,
        hasIcon: true
      },
      {
        code: ANALYTICS_CODE,
        active: true,
        title: ROLES_TITLES_SUBTITLE[ANALYTICS_CODE].title,
        subtitle: ROLES_TITLES_SUBTITLE[ANALYTICS_CODE].subtitle,
        hasIcon: false
      }
    ]
  }
];

//100 defualt access
//108 finance access
//104 wallet access
//101 Integrations access TECH_ACCESS
//102 Business access  TEAM_MANAGMENT_ACCESS

export const editRoleForm = {
  [DEFAULT_ACCESS]: { 100: true },
  [FINANCE_ACCESS]: { 100: true, 108: true },
  [WALLET_ACCESS]: { 100: true, 104: true },
  [TECH_ACCESS]: { 100: true, 101: true },
  [TEAM_MANAGMENT_ACCESS]: { 100: true, 102: true },
  [FINANCE_WALLET_ACCESS]: { 100: true, 108: true, 104: true },
  [FINANCE_TECH_ACCESS]: { 100: true, 108: true, 101: true },
  [FINANCE_TEAM_MANAGMENT_ACCESS]: { 100: true, 108: true, 102: true },
  [WALLET_TECH_ACCESS]: { 100: true, 104: true, 101: true },
  [WALLET_TEAM_MANAGMENT_ACCESS]: { 100: true, 104: true, 102: true },
  [TECH_TEAM_MANAGMENT_ACCESS]: { 100: true, 101: true, 102: true },

  [FINANCE_WALLET_TECH_ACCESS]: {
    100: true,
    108: true,
    104: true,
    101: true
  },

  [FINANCE_WALLET_TEAM_MANAGMENT_ACCESS]: {
    100: true,
    108: true,
    104: true,
    102: true
  },

  [FINANCE_TEAM_MANAGMENT_TECH_ACCESS]: {
    100: true,
    108: true,
    101: true,
    102: true
  },
  [WALLET_TEAM_MANAGMENT_TECH_ACCESS]: {
    100: true,
    104: true,
    101: true,
    102: true
  },

  [FULL_ACCESS]: {
    100: true,
    108: true,
    104: true,
    101: true,
    102: true,
    115: true
  },
  [EDITOR_DEFAULT_ACCESS]: { 200: true },
  [EDITOR_FINANCE_ACCESS]: { 200: true, 208: true },
  [EDITOR_WALLET_ACCESS]: { 200: true, 204: true },
  [EDITOR_TECH_ACCESS]: { 200: true, 201: true },
  [EDITOR_TEAM_MANAGMENT_ACCESS]: { 200: true, 202: true },
  [EDITOR_FINANCE_WALLET_ACCESS]: { 200: true, 208: true, 204: true },
  [EDITOR_FINANCE_TECH_ACCESS]: { 200: true, 208: true, 201: true },
  [EDITOR_FINANCE_TEAM_MANAGMENT_ACCESS]: { 200: true, 208: true, 202: true },
  [EDITOR_WALLET_TECH_ACCESS]: { 200: true, 204: true, 201: true },
  [EDITOR_WALLET_TEAM_MANAGMENT_ACCESS]: { 200: true, 204: true, 202: true },
  [EDITOR_TECH_TEAM_MANAGMENT_ACCESS]: { 200: true, 201: true, 202: true },

  [EDITOR_FINANCE_WALLET_TECH_ACCESS]: {
    200: true,
    208: true,
    204: true,
    201: true
  },

  [EDITOR_FINANCE_WALLET_TEAM_MANAGMENT_ACCESS]: {
    200: true,
    208: true,
    204: true,
    202: true
  },

  [EDITOR_FINANCE_TEAM_MANAGMENT_TECH_ACCESS]: {
    200: true,
    208: true,
    201: true,
    202: true
  },
  [EDITOR_WALLET_TEAM_MANAGMENT_TECH_ACCESS]: {
    200: true,
    204: true,
    201: true,
    202: true
  },

  [EDITOR_FULL_ACCESS]: {
    200: true,
    208: true,
    204: true,
    201: true,
    202: true,
    215: true
  }
};

export const bannerModalPermission = {
  [BANK_DETAILS_PRICE]: [
    FINANCE_ACCESS,
    EDITOR_FINANCE_ACCESS,
    FINANCE_WALLET_ACCESS,
    EDITOR_WALLET_ACCESS,
    FINANCE_TECH_ACCESS,
    EDITOR_FINANCE_TECH_ACCESS,
    FULL_ACCESS,
    EDITOR_FULL_ACCESS,
    FINANCE_TEAM_MANAGMENT_ACCESS,
    EDITOR_FINANCE_TEAM_MANAGMENT_ACCESS,
    FINANCE_WALLET_TEAM_MANAGMENT_ACCESS,
    EDITOR_FINANCE_WALLET_TEAM_MANAGMENT_ACCESS,
    FINANCE_WALLET_TECH_ACCESS,
    EDITOR_FINANCE_WALLET_TECH_ACCESS,
    FINANCE_TEAM_MANAGMENT_TECH_ACCESS,
    EDITOR_FINANCE_TEAM_MANAGMENT_TECH_ACCESS
  ],
  [ANNOUNCEMENT_MODAL_TEAM]: [
    FULL_ACCESS,
    EDITOR_FULL_ACCESS,
    TEAM_MANAGMENT_ACCESS,
    EDITOR_TEAM_MANAGMENT_ACCESS,
    FINANCE_TEAM_MANAGMENT_ACCESS,
    EDITOR_FINANCE_TEAM_MANAGMENT_ACCESS,
    TECH_TEAM_MANAGMENT_ACCESS,
    EDITOR_TECH_TEAM_MANAGMENT_ACCESS,
    FINANCE_TEAM_MANAGMENT_TECH_ACCESS,
    EDITOR_FINANCE_TEAM_MANAGMENT_TECH_ACCESS,
    WALLET_TEAM_MANAGMENT_TECH_ACCESS,
    EDITOR_WALLET_TEAM_MANAGMENT_TECH_ACCESS,
    FINANCE_WALLET_TEAM_MANAGMENT_ACCESS,
    EDITOR_FINANCE_WALLET_TEAM_MANAGMENT_ACCESS
  ]
};

export const ERROR_CODE_INVITED_USER = 1044;
export const ERROR_CODE_USER_BELONG_TO_ANOTHER = 1043;
export const REGEX_EMAIL = '[\\?&]email=([^&#]*)';

export const JOB_TITLES = [
  {
    label: fmt({ id: 'settings.teams_members.job_titles.owner' }),
    value: 'OWNER'
  },
  {
    label: fmt({ id: 'settings.teams_members.job_titles.administration' }),
    value: 'ADMINISTRATION'
  },
  {
    label: fmt({ id: 'settings.teams_members.job_titles.sales' }),
    value: 'SALES'
  },
  {
    label: fmt({ id: 'settings.teams_members.job_titles.customer_service' }),
    value: 'CUSTOMER_SERVICE_ACCOUNT_MANAGER'
  },
  {
    label: fmt({
      id: 'settings.teams_members.job_titles.finance_and_accounting'
    }),
    value: 'FINANCE_AND_ACCOUNTING'
  },
  {
    label: fmt({
      id: 'settings.teams_members.job_titles.operations_manager'
    }),
    value: 'OPERATIONS_MANAGER'
  },
  {
    label: fmt({
      id: 'settings.teams_members.job_titles.operations_supervisor'
    }),
    value: 'OPERATIONS_SUPERVISOR'
  },
  {
    label: fmt({
      id: 'settings.teams_members.job_titles.operations_clerk'
    }),
    value: 'OPERATIONS_CLERK'
  },
  {
    label: fmt({
      id: 'settings.teams_members.job_titles.marketing_and_advertising'
    }),
    value: 'MARKETING_AND_ADVERTISING'
  },
  {
    label: fmt({
      id: 'settings.teams_members.job_titles.legal'
    }),
    value: 'LEGAL'
  },
  {
    label: fmt({
      id: 'settings.teams_members.job_titles.product_manager'
    }),
    value: 'PRODUCT_MANAGER'
  },
  {
    label: fmt({
      id: 'settings.teams_members.job_titles.procurement'
    }),
    value: 'PROCURMENT'
  }
];

export const ORDERS_PICKUPS_ACCESS = {
  FULL_ACCESS: 100,
  EDITOR_ACCESS: 200
};

export const ORDERS_PICKUPS_ACCESS_PREFIX = {
  FULL_ACCESS: '1',
  EDITOR_ACCESS: '2'
};

export const ORDERS_PICKUPS_ACCESS_OPTIONS = [
  {
    code: ORDERS_PICKUPS_ACCESS.FULL_ACCESS,
    title: fmt({
      id: 'settings.teams_members.orders_pickups_access.admin_access.title'
    }),
    subtitle: fmt({
      id: 'settings.teams_members.orders_pickups_access.admin_access.subtitle'
    })
  },
  {
    code: ORDERS_PICKUPS_ACCESS.EDITOR_ACCESS,
    title: fmt({
      id: 'settings.teams_members.orders_pickups_access.editor_access.title'
    }),
    subtitle: fmt({
      id: 'settings.teams_members.orders_pickups_access.editor_access.subtitle'
    })
  }
];
