import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { getAllAreas } from 'services/cities';

import BRTag from 'components/BRTag/BRTag';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import { notify } from 'components/Notify/Notify';

import './UncoveredZonesList.less';

const UncoveredZonesList = () => {
  const [uncoveredDistricts, setUncoveredDistricts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const intl = useIntl();

  useEffect(() => {
    getAllDistricts();
  }, []);

  const getUncoveredDistricts = (cities = []) => {
    return cities
      .map(({ districts = [], ...city }) => ({
        ...city,
        districts: districts.filter(
          ({ dropOffAvailability }) => !dropOffAvailability
        )
      }))
      .filter(({ districts }) => districts.length);
  };

  const getAllDistricts = async () => {
    setIsLoading(true);
    try {
      const data = await getAllAreas();
      setUncoveredDistricts(getUncoveredDistricts(data));
    } catch (error) {
      notify({ msg: error.message, error });
    }
    setIsLoading(false);
  };
  return (
    <LoadingWrapper loading={isLoading}>
      <div className="br-uncovered-zones-list__container">
        {uncoveredDistricts.map(({ cityName, districts }) => (
          <div className="br-uncovered-zones-list__item">
            <div className="br-uncovered-zones-list__city subheading">
              {cityName}
            </div>
            {districts.map(({ districtName }) => (
              <div className="br-uncovered-zones-list__district-item">
                <div className="br-uncovered-zone-list__district-name">
                  <span />
                  {districtName}
                </div>
                <BRTag>
                  {intl.formatMessage({ id: 'form.uncovered_zone' })}
                </BRTag>
              </div>
            ))}
          </div>
        ))}
      </div>
    </LoadingWrapper>
  );
};

export default UncoveredZonesList;
