import { fmt } from 'components/IntlWrapper/IntlWrapper';

export const PRODUCT_LINK = 'business.bosta.co/checkout/';

export const CREATION_SRC = 'MARKETPLACE';
export const CREATION_SRC_FLYERS = 'FLYERS_DASHBOARD';

export const DATE_FORMAT = 'ddd, D MMM';

export const PRODUCT_TYPE = 'forward';

export const MARKETPLACE_LINKS_COLUMNS = [
  {
    title: fmt({ id: 'marketplace.links_table.columns.description' }),
    dataIndex: 'title'
  },
  {
    title: fmt({ id: 'orders.order_types.cash_collection' }),
    dataIndex: 'cod',
    width: 140
  },
  {
    title: fmt({ id: 'marketplace.links_table.columns.orders' }),
    dataIndex: 'ordersCount',
    width: 104
  },
  {
    title: fmt({ id: 'marketplace.links_table.columns.link' }),
    dataIndex: 'link',
    width: 112
  },
  {
    title: fmt({
      id: 'international_shipping_in.recorded_orders.table_columns.created_at'
    }),
    dataIndex: 'createdAt',
    width: 162
  },
  { dataIndex: 'actions', width: 60 }
];

export const TABLE_QUICK_FILTERS = [
  {
    label: fmt({ id: 'orders.title' }),
    value: 'orders'
  }
  // {
  //   label: fmt({ id: 'marketplace.generated_links' }),
  //   value: 'links'
  // }
];

export const LOCATION_SERVICES_LINK =
  'https://support.google.com/maps/answer/2839911?hl=en&authuser=0&p=browser_lp&visit_id=637811645520953220-886277724&co=GENIE.Platform%3DDesktop&oco=&rd=1#permission';

export const DRAFT_ORDER = 'draft_order';
