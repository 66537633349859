import React, { Component } from 'react';

import './Page404.less';
class Page404 extends Component {
  componentDidMount() {
    const { location, history } = this.props;
    if (location.pathname === '/shipments') {
      history.push('/orders');
    }
  }

  render() {
    return (
      <div className="app full-height display-flex justify-center ">
        <span className="display-flex align-center justify-center">
          <h1 className="br-page-404__left-text">404</h1>
          <span>
            <h4 className="br-page-404__left-text-warning">
              Oops! You're lost.
            </h4>
            <p className=" br-page-404__right-text">
              The page you are looking for was not found.
            </p>
          </span>
        </span>
      </div>
    );
  }
}

export default Page404;
