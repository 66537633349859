import { useEffect, useState, useRef } from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { Progress, Steps } from 'antd';
import querySearch from 'stringquery';
import classnames from 'classnames';

import { useModal } from 'contexts/modalProvider.context';
import { sendSegment } from 'utils/segment';
import { updateVerifyPhone } from 'services/auth';
import {
  STEPPER,
  SERVICES,
  MOBILE_APP_REG_SRC,
  DASHBOARD_REG_SRC,
  INTERNATIONAL_SERVICES
} from 'constants/signup';
import { isSaudi } from 'constants/helpers';
import { LOCALE } from 'constants/intl-wrapper';
import {
  getCurrentUserCountryData,
  isDomesticShippingAvailable
} from 'constants/countries/countries-mapping';
import { CASHOUT_FREQUENCY } from 'constants/wallet';

import { notify } from 'components/Notify/Notify';
import BRButton from 'components/BRButton/BRButton';
import VerifyPhoneModal from './components/VerifyPhoneModal.js/VerifyPhoneModal';
import RegistrationContainer from 'components/RegistrationContainer/RegistrationContainer';
import PricingContainer from './components/PricingContainer/PricingContainer';
import ServicesContainer from './components/ServicesContainer/ServicesContainer';
import SignupContainer from './components/SignupContainer/SignupContainer';
import InternationalServicesContainer from './components/InternationalServicesContainer/InternationalServicesContainer';

import { ReactComponent as ExternalLink } from 'assets/bosta-icons/Teal-External-link.svg';
import { ReactComponent as ArrowLeft } from 'assets/bosta-icons/Arrow-left.svg';
import { ReactComponent as Check } from 'assets/bosta-icons/Check.svg';
import { ReactComponent as ArrowRight } from 'assets/bosta-icons/Arrow-right.svg';

import './SignUp.less';

const services = !isDomesticShippingAvailable()
  ? INTERNATIONAL_SERVICES[getCurrentUserCountryData().codeName]
  : SERVICES;

const SignUp = ({
  history,
  location: { search },
  intl,
  country,
  setCountry
}) => {
  const [usedPhoneNumber, setUsedPhoneNumber] = useState(false);
  const [plannedShipmentTypes, setPlannedShipmentTypes] = useState(
    services
      .filter(({ defaultChecked }) => defaultChecked)
      .map(({ apiValue }) => apiValue)
  );
  const [stepperCurrentStep, setStepperCurrentStep] = useState(
    STEPPER.SERVICES_STEP
  );
  const [notifyMe, setNotifyMe] = useState([]);
  const [activePlan, setActivePlan] = useState(null);
  const [plannedFrequency, setPlannedFrequency] = useState(
    CASHOUT_FREQUENCY.WEEKLY
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingTip, setIsLoadingTip] = useState('');
  const [cashoutType, setCashoutType] = useState(
    intl.formatMessage({ id: 'pickups.table_columns.repeatation_types.weekly' })
  );

  const progressPercentage = isDomesticShippingAvailable()
    ? ((stepperCurrentStep + 1) / STEPPER.LAST_STEP) * 100
    : 95;

  const formRef = useRef();
  const footerRef = useRef();
  const { openModal } = useModal();

  const userInfo = JSON.parse(localStorage.getItem('userInfo'));

  const { Step } = Steps;
  const { isPhoneVerified, plannedShipmentType } = userInfo?.user || false;

  const loadGoogleRecaptcha = (url) => {
    const head = document.getElementsByTagName('head')[0];
    const script = document.createElement('script');
    script.src = url;
    head.appendChild(script);
  };

  const handleGoToWebsitePage = () => {
    sendSegment('Step1a_Create-Account-Track-link');
    window.open('https://bosta.co/tracking-shipments', '_blank');
  };

  const onChangeServiceCheckbox = (list) => {
    setPlannedShipmentTypes(list);
  };

  const onNotifyChange = ({ value, checked, interestedNotificationMsg }) => {
    setNotifyMe((prevList) => {
      if (checked) {
        notify({
          msg: interestedNotificationMsg,
          type: 'success'
        });
        return [...prevList, value];
      } else {
        return prevList.filter((el) => el !== value);
      }
    });
  };

  const handleStepperBack = () => {
    setStepperCurrentStep(stepperCurrentStep - 1);
  };

  const handleServiceNext = async () => {
    setIsLoading(true);
    const plannedAndNotifiedShipmentTypes = [
      ...new Set([...plannedShipmentTypes, ...notifyMe])
    ];
    const interestedSegmentAttributes = {};
    services.forEach(({ apiValue, interestedSegmentEventAttr }) => {
      if (interestedSegmentEventAttr) {
        interestedSegmentAttributes[interestedSegmentEventAttr] =
          notifyMe.includes(apiValue);
      }
    });

    try {
      const id = userInfo?.user?._id;
      const payload = {
        plannedShipmentType: plannedAndNotifiedShipmentTypes
      };
      await updateVerifyPhone(id, payload);
      userInfo.user = {
        ...userInfo.user,
        plannedShipmentType: plannedAndNotifiedShipmentTypes
      };
      localStorage.setItem('userInfo', JSON.stringify(userInfo));
      sendSegment(
        'Step2b_Choose-Service-Selected',
        interestedSegmentAttributes
      );

      if (isDomesticShippingAvailable()) {
        setStepperCurrentStep(stepperCurrentStep + 1);
      } else {
        handleFinishSignup();
      }
    } catch (error) {
      notify({
        msg: error?.message,
        error
      });
    }
    setIsLoading(false);
  };
  const handleFinishSignup = async () => {
    setIsLoading(true);
    setIsLoadingTip(
      intl.formatMessage({
        id: 'login.signing_in'
      })
    );

    try {
      const id = userInfo.user?._id;
      const payload = {
        plannedPaymentFrequency: plannedFrequency
      };
      await updateVerifyPhone(id, payload);
      userInfo.user = {
        ...userInfo.user,
        plannedPaymentFrequency: plannedFrequency
      };
      localStorage.setItem('userInfo', JSON.stringify(userInfo));
      notify({
        msg: intl.formatMessage({
          id: 'sign_up.account_created'
        }),
        type: 'success'
      });
      sendSegment('Step4_Account-Created (1A)');

      history.push({
        pathname: '/overview',
        state: { activationModal: true }
      });
    } catch (error) {
      notify({
        msg: error.message,
        type: 'error'
      });
      setIsLoadingTip('');
    }

    setIsLoading(false);
  };

  useEffect(() => {
    handleOnViewingPage();

    if (!isDomesticShippingAvailable()) {
      const pricingTierIds = JSON.parse(localStorage.getItem('userInfo'))?.user
        ?.country?.pricingSignUpTiers;

      setActivePlan(pricingTierIds?.basicTierId);
      setPlannedFrequency(CASHOUT_FREQUENCY.WEEKLY);
    }
  }, []);

  const handleOnViewingPage = () => {
    loadGoogleRecaptcha('https://www.google.com/recaptcha/api.js');
    setNotifyMe(plannedShipmentType || []);
    plannedShipmentType && setStepperCurrentStep(STEPPER.PRICING_STEP);

    if (search.length > 0) {
      const query = querySearch(search);
      if (
        query.hasOwnProperty('utm_campaign') &&
        query.hasOwnProperty('utm_source') &&
        query.hasOwnProperty('utm_term')
      ) {
        localStorage.setItem('campaignObject', JSON.stringify(query));
      }
      if (query?.utm_source) {
        localStorage.setItem(
          'regSrc',
          query?.utm_source === MOBILE_APP_REG_SRC
            ? MOBILE_APP_REG_SRC
            : DASHBOARD_REG_SRC
        );
      }
    }

    if (userInfo) {
      const { email, phone } = userInfo?.user?.profile;
      if (!isPhoneVerified) {
        openModal(VerifyPhoneModal, {
          phone: phone,
          email,
          userID: userInfo?.user?._id,
          history: history,
          onOpen: () => {
            sendSegment('Step1b_Showing-OTP');
          }
        });
      }
    } else {
      sendSegment('Step1_Create-Account-Landing');
    }
  };

  const renderRegistrationContent = () => {
    if (!isPhoneVerified) {
      return (
        <SignupContainer
          formRef={formRef}
          usedPhoneNumber={usedPhoneNumber}
          setUsedPhoneNumber={setUsedPhoneNumber}
          country={country}
          setCountry={setCountry}
        />
      );
    }

    if (stepperCurrentStep === STEPPER.SERVICES_STEP) {
      return !isDomesticShippingAvailable() ? (
        <InternationalServicesContainer
          notifyMe={notifyMe}
          onNotifyChange={onNotifyChange}
          plannedShipmentTypes={plannedShipmentTypes}
          onChangeServiceCheckbox={onChangeServiceCheckbox}
          stepperCurrentStep={stepperCurrentStep}
        />
      ) : (
        <ServicesContainer
          notifyMe={notifyMe}
          onNotifyChange={onNotifyChange}
          plannedShipmentTypes={plannedShipmentTypes}
          onChangeServiceCheckbox={onChangeServiceCheckbox}
          stepperCurrentStep={stepperCurrentStep}
        />
      );
    }

    return (
      <PricingContainer
        activePlan={activePlan}
        setActivePlan={setActivePlan}
        plannedFrequency={plannedFrequency}
        setPlannedFrequency={setPlannedFrequency}
        stepperCurrentStep={stepperCurrentStep}
        footerRef={footerRef}
        setCashoutType={setCashoutType}
      />
    );
  };

  const getNextActionButtonLabel = () => {
    if (stepperCurrentStep === STEPPER.SERVICES_STEP) {
      return intl.formatMessage({
        id: `common.${isDomesticShippingAvailable() ? 'next' : 'continue'}`
      });
    }

    return intl.formatMessage(
      {
        id: 'sign_up.pricing.get_cashouts'
      },
      {
        cashout: cashoutType
      }
    );
  };

  return (
    <RegistrationContainer
      isLoading={isLoading}
      tip={isPhoneVerified && isLoadingTip}
      hideTrackOrderInHeader={isPhoneVerified}
      stepper={
        isPhoneVerified && (
          <Steps
            size="small"
            type="navigation"
            className={classnames('br-signup-stepper', {
              'br-signup-stepper__rtl': intl.locale === LOCALE.AR
            })}
            current={stepperCurrentStep}
            responsive={false}
          >
            <Step
              icon={<Check />}
              title={intl.formatMessage({
                id: 'sign_up.stepper.step_1'
              })}
            />
            <Step
              icon={<Check />}
              title={intl.formatMessage({
                id: 'sign_up.stepper.step_2'
              })}
            />

            <Step
              icon={<Check />}
              title={intl.formatMessage({
                id: 'sign_up.stepper.step_3'
              })}
            />
          </Steps>
        )
      }
      content={renderRegistrationContent()}
      footer={
        isPhoneVerified && (
          <div
            className={classnames('br-signup-services__footer', {
              'br-signup-pricing__fixed-footer':
                stepperCurrentStep === STEPPER.PRICING_STEP,
              'br-signup-pricing__fixed-ksa-footer':
                stepperCurrentStep === STEPPER.PRICING_STEP && isSaudi()
            })}
            ref={footerRef}
          >
            <Progress percent={progressPercentage} showInfo={false} />
            <div
              className={classnames('br-signup__footer', {
                'br-signup-services__footer':
                  stepperCurrentStep === STEPPER.SERVICES_STEP
              })}
            >
              {stepperCurrentStep + 1 === STEPPER.LAST_STEP && (
                <BRButton
                  type="link-gray"
                  prefixIcon={<ArrowLeft />}
                  onClick={handleStepperBack}
                  label={intl.formatMessage({
                    id: 'common.back'
                  })}
                />
              )}
              <BRButton
                type="primary"
                onClick={
                  stepperCurrentStep === STEPPER.SERVICES_STEP
                    ? handleServiceNext
                    : handleFinishSignup
                }
                label={getNextActionButtonLabel()}
                suffixIcon={
                  stepperCurrentStep === STEPPER.SERVICES_STEP &&
                  !isDomesticShippingAvailable() && <ArrowRight />
                }
                disabled={
                  stepperCurrentStep === STEPPER.SERVICES_STEP &&
                  !plannedShipmentTypes.length
                }
              />
            </div>
          </div>
        )
      }
      actionLink={
        !isPhoneVerified && (
          <BRButton
            type="link-color"
            label={intl.formatMessage({ id: 'login.track_my_order' })}
            className="button-md br-login__track-order"
            suffixIcon={<ExternalLink />}
            onClick={handleGoToWebsitePage}
          />
        )
      }
      signUpPage
    />
  );
};

export default injectIntl(withRouter(SignUp));
