import {
  UploadFXFOrdersTemplate,
  UploadFXFOrdersTemplateKSA,
  UploadFulfillmentTemplateAR,
  UploadFulfillmentTemplate,
  UploadFulfillmentTemplateKSA_EN,
  UploadFulfillmentTemplateKSA_AR
} from 'constants/mass-upload';

export const UPLOAD_FXF_ORDERS_TEMPLATE = {
  EG: {
    FILE: UploadFXFOrdersTemplate,
    NAME: 'Bosta Fulfillment Upload Template.V4.2.xlsx'
  },
  SA: {
    FILE: UploadFXFOrdersTemplateKSA,
    NAME: 'Bosta Fulfillment Upload Template.xlsx'
  }
};

export const SMART_UPLOAD_FXF_TEMPLATE = {
  DEFAULT: {
    EN: UploadFulfillmentTemplate,
    AR: UploadFulfillmentTemplateAR
  },
  SA: {
    EN: UploadFulfillmentTemplateKSA_EN,
    AR: UploadFulfillmentTemplateKSA_AR
  }
};
