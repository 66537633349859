import React, { Component } from 'react';

import './LoadingPage.less';
class LoadingPage extends Component {
  render() {
    return (
      <div className="justify-content-center full-width display-flex br-loading-page ">
      <div className="clearfix">
        <h1 className="display-3 ">Loading .... </h1>
      </div>
    </div>
    );
  }
}

export default LoadingPage;
