import { bostaHttp } from 'http.js';

export const internationalOrdersSearch = (payload) => {
  return bostaHttp.post('/international-order/search', payload);
};

export const printInternationalOrderAwb = (payload) => {
  return bostaHttp.get('/international-order/awb', payload);
};

export const getInternationalOrder = (orderId) => {
  return bostaHttp.get(`/international-order/${orderId}`);
};

export const updateInternationalOrder = ({ orderId, payload }) => {
  return bostaHttp.put(`/international-order/${orderId}`, payload);
};

export const updateInternationalOrderState = ({ orderId, payload }) => {
  return bostaHttp.put(`/international-order/${orderId}/state`, payload);
};

export const exportInternationalOrders = (payload) => {
  return bostaHttp.post('/international-order/export', payload);
};

export const createMassInternationalOrder = (payload) => {
  return bostaHttp.post('/international-order/bulk', payload);
};

export const printInternationalInvoice = (payload) => {
  return bostaHttp.get('/international-order/bills', payload);
};

export const deleteInternationalOrder = (deliveryId) => {
  return bostaHttp.delete(`/international-order/${deliveryId}`);
};

export const getInternationalOrderProductDetails = (trackingNumber) => {
  return bostaHttp.get(
    `/international-order/${trackingNumber}/productsDetails`
  );
};
