import DOMPurify from 'dompurify';
import dayjs from 'dayjs';
import isBoolean from 'lodash/isBoolean';
import isEmpty from 'lodash/isEmpty';
import diff from 'diffObj';

import Icon from '@ant-design/icons';

import {
  setLocaleInLocalStorage,
  getLocaleFromLocalStorage,
  isDashboardArabic
} from 'utils/intl-wrapper';
import { LOCALE } from 'constants/intl-wrapper';
import { COUNTRIES } from 'constants/country-data';
import {
  END_DATES_KEYS,
  COUNTRIES_TIME_ZONE,
  isSaudi
} from 'constants/helpers';
import { getCurrentUserCountryData } from 'constants/countries/countries-mapping';
import { fmt } from 'components/IntlWrapper/IntlWrapper';

import { ReactComponent as bostaLogo } from 'assets/imgRevamp/bosta_logo.svg';
import { ReactComponent as bostaLogoArabic } from 'assets/imgRevamp/bosta_arabic.svg';

const { sanitize } = DOMPurify;
const javascriptPurify = (dirty) => dirty.replace(/^(javascript\:)/, '');
const { codeNumber, removeZeroWithCodeNumber } = getCurrentUserCountryData();

export const cleanEmptyString = (obj) => {
  if (dayjs.isDayjs(obj)) return obj;
  if (Array.isArray(obj)) {
    return obj
      .map((v) => (v && typeof v === 'object' ? cleanEmptyString(v) : v))
      .filter((v) => !(v === '' || v === undefined || v === null));
  } else {
    return Object.entries(obj)
      .map(([k, v]) => [
        k,
        v && typeof v === 'object' ? cleanEmptyString(v) : v
      ])
      .reduce(
        (a, [k, v]) =>
          v === '' || v === null || v === undefined ? a : ((a[k] = v), a),
        {}
      );
  }
};

export const handleLanguageChange = async (value, redirectTo = false) => {
  await setLocaleInLocalStorage(value);
  redirectTo && window.history.replaceState(null, null, redirectTo);
  window.location.reload();
};

export const secondsToTime = (secs) => {
  const divisor_for_minutes = secs % (60 * 60);
  const minutes = Math.floor(divisor_for_minutes / 60);

  const divisor_for_seconds = divisor_for_minutes % 60;
  const seconds = Math.ceil(divisor_for_seconds);

  if (getLocaleFromLocalStorage() === LOCALE.EN)
    return ` ${minutes < 10 ? `0${minutes}` : minutes} : ${
      seconds < 10 ? `0${seconds}` : seconds
    }`;
  else {
    return ` ${seconds < 10 ? `0${seconds}` : seconds} : ${
      minutes < 10 ? `0${minutes}` : minutes
    }`;
  }
};

export const numberRegex = /^\d+$/;

export const alphanumericRegex = /^[a-zA-Z0-9]+$/;

export const countDown = (values) => {
  const { timer, setTimer, setDisabled } = values;
  return setInterval(() => {
    if (timer > 0) {
      setTimer(timer - 1);
    }
    if (timer === 0) {
      setDisabled(false);
    }
  }, 1000);
};

export const getMinIntoSec = (minutes) => {
  return minutes * 60;
};

export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

/**
 * sanitize text from xss attacks to remove malicious injection of javascript code
 * @param text
 */

export const sanitizeText = (text) =>
  text && !isBoolean(text) ? javascriptPurify(sanitize(text)) : text;

/**
 * sanitize key-value strings maps
 * @param obj
 * @returns {{}}
 */
export const sanitizePlainObject = (obj) =>
  Object.entries(obj)
    .map(([key, value]) => [key, sanitizeText(value)])
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

/**
 * sanitize obj with nested objects and other types
 * @param obj
 * @returns {{}|*}
 */
export const sanitizeAll = (obj) => {
  // test for moment objects, undefined, numbers, or booleans
  if (
    dayjs.isDayjs(obj) ||
    typeof obj === 'undefined' ||
    typeof obj === 'number' ||
    typeof obj === 'boolean'
  ) {
    if (dayjs.isDayjs(obj)) return formatDateUTC(obj);
    else return obj;
  }
  // test for strings
  if (typeof obj !== 'object') {
    return sanitizeText(obj);
  }
  // sanitize object and its nested items after being cleansed from empty and undefined values
  return Object.entries(cleanEmptyString(obj))
    .map(([key, value]) => [
      key,
      typeof value === 'object' && !dayjs.isDayjs(value)
        ? Array.isArray(value)
          ? value.map((item) => sanitizeAll(item))
          : sanitizeAll(value)
        : typeof value === 'number'
        ? value
        : dayjs.isDayjs(value)
        ? formatDateUTC(value, key)
        : sanitizeText(value)
    ])
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
};

export const dates = (value, format, timeZone) => {
  const date = format
    ? dayjs(value)
        .utcOffset(timeZone?.utcOffset || COUNTRIES_TIME_ZONE.egypt)
        .format(format)
    : dayjs(value).utcOffset(timeZone?.utcOffset || COUNTRIES_TIME_ZONE.egypt);
  if (getLocaleFromLocalStorage() === LOCALE.AR) {
    return date?.replace(/\d/g, (d) => '٠١٢٣٤٥٦٧٨٩'[d]);
  } else return date;
};

const formatDateUTC = (date, key) => {
  if (END_DATES_KEYS.includes(key)) {
    return date
      .utc()
      .set('hour', 23)
      .set('minute', 59)
      .set('second', 59)
      .format();
  }
  return date.utc().set('hour', 0).set('minute', 0).set('second', 0).format();
};

export const regexNumber = /^\d+$/;
export const regexNumberWithNegative = /^-?\d*$/;
export const regexFractionsNumber = /^\d+(\.\d*)?$/;

export const regexPhoneNumber = isSaudi()
  ? /^\+?(?:[\d -]*)+$/
  : /^\+?(?:[\d]*)$/;

export const isAuthorized = (userRoles = null, accessRoles) => {
  return userRoles.name === accessRoles;
};

export const toString = (obj) => {
  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === 'object') {
      return toString(obj[key]);
    }

    obj[key] = '' + obj[key];
  });

  return obj;
};

export const arabicCurrency = (country) => {
  return country?.codeName === COUNTRIES[1]?.codeName
    ? 'ريال سعودى'
    : 'جنيه مصرى';
};

export const convertEnglishNumbersToArabic = (number) => {
  const dashboardLanguage = getLocaleFromLocalStorage();
  return dashboardLanguage === LOCALE.AR
    ? number?.toString()?.replace(/\d/g, (d) => '٠١٢٣٤٥٦٧٨٩'[d])
    : number;
};

export const checkDate = (date) => {
  if (dayjs(date).isToday()) {
    return fmt({ id: `date_picker.filter_option.today` });
  } else if (dayjs(date).isYesterday()) {
    return fmt({ id: `date_picker.filter_option.yesterday` });
  } else if (dayjs(date).isTomorrow()) {
    return fmt({ id: `date_picker.filter_option.tomorrow` });
  } else {
    return;
  }
};

export const checkSmartDate = (
  date,
  format = 'ddd, DD MMM',
  extraFormat = false
) => {
  const formattedDate = checkDate(date);
  if (formattedDate) {
    if (extraFormat) {
      return `${formattedDate} ${dates(date, 'DD MMM')}`;
    }
    return formattedDate;
  } else {
    return dates(date, format);
  }
};

export const isDatePassed = (date, interval) => {
  return dayjs(date).isBefore(dayjs(), interval);
};

export const generateUUID = () => {
  // Public Domain/MIT
  let d = new Date().getTime(); //Timestamp
  let d2 =
    (typeof performance !== 'undefined' &&
      performance.now &&
      performance.now() * 1000) ||
    0; //Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = Math.random() * 16; //random number between 0 and 16
    if (d > 0) {
      //Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
};

export const shortenString = (str, maxLength) => {
  if (str.length > maxLength) {
    return str.slice(0, maxLength) + '...';
  } else {
    return str;
  }
};

export const userInfo = JSON.parse(localStorage.getItem('userInfo'))?.user;

export const isUserAuth = () => {
  let userInfo = localStorage.getItem('userInfo');
  if (userInfo) {
    userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const isAuth =
      userInfo?.user?.isPhoneVerified &&
      userInfo?.user?.businessAdminInfo?.businessId;
    return isAuth ? userInfo?.token : false;
  }
  return false;
};

export const isTwoArraysOfStringEqual = (arr1, arr2) => {
  // For sorted and unsorted
  // Ex.1: ['a','b','c'] === ['a','b','c'] returns true
  // Ex.2: ['a','b','c'] === ['b','c','a'] returns true

  if (arr1?.length !== arr2?.length) return false;

  const isInArray1 = arr1.every((item) => arr2.find((item2) => item === item2));
  const isInArray2 = arr2.every((item) => arr1.find((item2) => item === item2));

  return isInArray1 && isInArray2;
};

export const stringToBoolean = (stringValue) => {
  if (!isNaN(stringValue)) {
    return !!stringValue;
  }
  switch (stringValue?.toLowerCase()?.trim()) {
    case 'true':
    case 'yes':
    case '1':
      return true;

    case 'false':
    case 'no':
    case '0':
    case null:
    case undefined:
      return false;

    default:
      return JSON.parse(stringValue);
  }
};

export const sortArrayByDate = (arr) => {
  const sortedArr = arr.sort(
    (a, b) =>
      new Date(a.scheduledDate).getTime() - new Date(b.scheduledDate).getTime()
  );
  return sortedArr;
};

export const getEditChanges = ({ beforeChangeObj, afterChangeObj }) => {
  const diffs = diff(beforeChangeObj, afterChangeObj);
  const unset = {};

  Object.keys(diffs).forEach((key) => {
    const isDeletedValue =
      [undefined, null, ''].includes(diffs[key]) ||
      (typeof diffs[key] === 'object' && isEmpty(diffs[key]));

    if (isDeletedValue) {
      if (afterChangeObj[key] !== beforeChangeObj[key]) {
        unset[key] = 1;
      }
      delete diffs[key];
    }
  });

  const changesPayload = {
    ...diffs,
    ...(!isEmpty(unset) && {
      unset
    })
  };

  return changesPayload;
};

export const hasDigits = (string) => {
  return /\d/.test(string);
};

export const getMobilePhoneNumber = (modifiedPhone) => {
  let phoneWithoutCountryCode = '';
  let phoneWithCountryCode = '';

  if (modifiedPhone) {
    if (modifiedPhone[0] === '+') {
      const regex = new RegExp(`^${codeNumber.replace('+', '\\+')}`);
      if (regex.test(modifiedPhone)) {
        phoneWithCountryCode = modifiedPhone;
        if (removeZeroWithCodeNumber) {
          phoneWithoutCountryCode = `0${modifiedPhone?.substring(
            codeNumber.length
          )}`;
        }
      }
    } else {
      if (removeZeroWithCodeNumber && modifiedPhone[0] === '0') {
        phoneWithCountryCode = `${codeNumber}${modifiedPhone?.substring(1)}`;
      }
      phoneWithoutCountryCode = modifiedPhone;
    }
  }

  return {
    phoneWithCountryCode,
    phoneWithoutCountryCode
  };
};

export const convertArrayToNestedObject = (array, value) => {
  //  This function to deal with handlechangeform function
  // if it takes array, It converts the array to nested object so it can set form fields
  const result = {};
  let nestedObj = result;
  array.forEach((key, index) => {
    nestedObj[key] = {};
    if (index === array.length - 1) {
      nestedObj[key] = value;
    } else {
      nestedObj = nestedObj[key];
    }
  });
  return result;
};

export const getCurrentDashboardLogo = () => {
  return isDashboardArabic() ? bostaLogoArabic : bostaLogo;
};

export const normalizeText = (text) => {
  if (!text) {
    return;
  }

  //normalize Arabic
  text = text.replace(/(آ|إ|أ)/g, 'ا');
  text = text.replace(/(ة)/g, 'ه');
  text = text.replace(/(ئ|ؤ)/g, 'ء');
  text = text.replace(/(ى)/g, 'ي');

  return text;
};

export const isArabic = (text) => /[\u0600-\u06FF]/.test(text);

export const getCityName = ({
  cityName,
  cityOtherName,
  typingLanguage,
  locale
}) => (typingLanguage !== locale ? cityOtherName : cityName);

export const getAreaName = ({ area, typingLanguage, locale, availability }) => {
  const { zoneName, districtName, zoneOtherName, districtOtherName } = area;
  if (
    (area?.[availability] && districtName === 'Others') ||
    districtName !== 'Others'
  ) {
    const adjustedZoneName =
      typingLanguage !== locale ? zoneOtherName : zoneName;
    const adjustedDistrictName =
      typingLanguage !== locale ? districtOtherName : districtName;

    return zoneName === districtName
      ? adjustedZoneName
      : `${adjustedZoneName} - ${adjustedDistrictName}`;
  }
};

export const isRichTextEmpty = (value) => {
  return value === '<p><br></p>';
};

export const renderImage = (image, renderedIcon) =>
  image ? (
    <img alt="store-profile" src={image} />
  ) : (
    <Icon className="store-icon" component={renderedIcon} />
  );

export const checkEmailProvider = (email) => {
  const regex = /@(outlook|live|hotmail)\./i;
  return regex.test(email);
};

export const removeCountryCodeNumber = ({
  phoneNumber,
  country = getCurrentUserCountryData()
}) => {
  if (!phoneNumber) {
    return;
  }

  return phoneNumber.replace(country.codeNumber, '');
};

export const addCountryCodeNumber = ({
  phoneNumber,
  country = getCurrentUserCountryData()
}) => {
  if (!phoneNumber) {
    return;
  }

  const { codeNumber } = country;

  if (phoneNumber.startsWith(codeNumber)) {
    return phoneNumber;
  } else {
    return `${codeNumber}${phoneNumber}`;
  }
};

export const getCurrentUserCountryName = () => {
  const { name, arabicName } = getCurrentUserCountryData() || {};

  return isDashboardArabic() ? arabicName : name;
};

export const ENGLISH_CHARS = /[a-zA-Z0-9]/;

export const isRegisteredViaBetaFunnel = () => {
  return userInfo?.isRegisteredViaBetaFunnel;
};

export const blockStringNumbersChar = (e) => {
  ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();
};
