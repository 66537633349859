import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { isBetaFunnelAvailable } from 'constants/countries/countries-mapping';
import { COUNTRIES } from 'constants/country-data';
import { FEATURE_TOGGLES } from 'constants/feature-toggles';
import { getFeatureToggle } from 'services/signup';

import NewSignUp from 'components/NewSignUp/NewSignUp';
import SignUp from 'components/SignUp/SignUp';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';

import BostaLogoGif from 'assets/bosta-icons/BostaLogoGif.gif';

const SignUpRoute = () => {
  const [country, setCountry] = useState(COUNTRIES[0]);
  const [isLoading, setIsLoading] = useState(false);
  const [showNewSignUp, setShowNewSignup] = useState(false);

  const history = useHistory();

  const isAuth = localStorage.getItem('newToken');
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));

  useEffect(() => {
    if (isAuth) {
      const userCountryId = userInfo?.user?.country?._id;
      const userCountry = COUNTRIES.find(
        (country) => country.id === userCountryId
      );

      setShowNewSignup(false);
      setCountry(userCountry);
    } else {
      getUserCountry();
    }
  }, [isAuth]);

  const checkFeatureToggle = async () => {
    setIsLoading(true);

    try {
      const payload = {
        featureName: FEATURE_TOGGLES.NEW_SIGNUP
      };
      const { shouldUseBetaFeature } = (await getFeatureToggle(payload)) || {};
      setShowNewSignup(shouldUseBetaFeature);
    } catch (error) {
      // notify({ msg: error.message, error });
    }

    setIsLoading(false);
  };

  const getUserCountry = async () => {
    setIsLoading(true);
    let selectedCountry = COUNTRIES[0];

    try {
      const result = await fetch('https://ipinfo.io?token=0dcccef4b7c9f0');
      const { country } = await result.json();
      const isCountryAvailable = COUNTRIES.find(
        ({ codeName }) => codeName === country
      );
      if (!isCountryAvailable && country) {
        history.replace('/unavailable-country');
        return;
      } else {
        selectedCountry =
          country && isCountryAvailable.id ? isCountryAvailable : COUNTRIES[0];

        setCountry(selectedCountry);
      }
    } catch (error) {
      //
    }

    if (isBetaFunnelAvailable(selectedCountry.codeName)) {
      return checkFeatureToggle();
    }
    setIsLoading(false);
  };

  const commonProps = {
    country,
    setCountry
  };

  if (isLoading) {
    return (
      <LoadingWrapper
        className="app-loading-wrapper"
        indicator={<img src={BostaLogoGif} alt="" />}
      />
    );
  }

  return isBetaFunnelAvailable(country.codeName) && showNewSignUp ? (
    <NewSignUp {...commonProps} />
  ) : (
    <SignUp {...commonProps} />
  );
};

export default SignUpRoute;
