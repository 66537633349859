import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { Form, Input } from 'antd';
import classnames from 'classnames';

import { passwordRule } from 'utils/forms';
import {
  AT_LEAST_ONE_DIGIT,
  AT_LEAST_EIGHT_LETTERS,
  AT_LEAST_ONE_UPPERCASE,
  AT_LEAST_ONE_SPECIAL_CHAR
} from 'constants/Forms';
import { PASSWORD_VALIDATION_RULES } from 'constants/auth';

import { ReactComponent as Check } from 'assets/bosta-icons/Circle-Check.svg';
import { ReactComponent as Clear } from 'assets/bosta-icons/Circle-clear.svg';

import './BRPasswordInput.less';

const BRPasswordInput = ({ intl, formRef, label, placeholder, ...props }) => {
  const [focusCount, setFocusCount] = useState(0);
  const [showPasswordValidation, setShowPasswordValidation] = useState({
    showHelp: true,
    atLeastEightChars: false,
    containsNumber: false,
    showBothFields: false,
    showSuccessValidationMessage: false
  });

  const checkPasswordValidation = async ({ target: { value } }) => {
    const atLeastEightChars = AT_LEAST_EIGHT_LETTERS.test(value);
    const containsNumber = AT_LEAST_ONE_DIGIT.test(value);
    const atLeastOneUpperCase = AT_LEAST_ONE_UPPERCASE.test(value);
    const atLeastOneSpecialChar = AT_LEAST_ONE_SPECIAL_CHAR.test(value);

    const isValid =
      containsNumber &&
      atLeastEightChars &&
      atLeastOneUpperCase &&
      atLeastOneSpecialChar;

    setShowPasswordValidation({
      showHelp: false,
      atLeastEightChars,
      containsNumber,
      atLeastOneUpperCase,
      atLeastOneSpecialChar,
      showBothFields: !isValid,
      showSuccessValidationMessage: isValid
    });
    setFocusCount(focusCount + 1);
  };

  const handleShowValidationMessages = () => {
    const passwordValue = formRef.current?.getFieldValue('password');
    setShowPasswordValidation({
      ...showPasswordValidation,
      showBothFields:
        focusCount === 0 ? true : showPasswordValidation.showBothFields,
      showSuccessValidationMessage: !showPasswordValidation.showBothFields
    });
    setFocusCount(focusCount === 0 && !passwordValue ? 0 : focusCount + 1);
  };

  const handleHideValidationMessages = () => {
    setShowPasswordValidation({
      ...showPasswordValidation,
      showSuccessValidationMessage: false
    });
  };

  return (
    <Form.Item
      name="password"
      rules={[{ required: true }, passwordRule()]}
      label={intl.formatMessage({
        id: 'login.set_new_passowrd.new_password'
      })}
      className={classnames('br-password-input')}
      help={
        showPasswordValidation.showBothFields ? (
          <>
            {PASSWORD_VALIDATION_RULES.map(({ label, value }) => (
              <div
                className={classnames(
                  'caption',
                  'br-password-input__validation',
                  {
                    'br-password-input__validation--is-valid':
                      showPasswordValidation[value] && focusCount
                  },
                  {
                    'br-password-input__validation--is-not-valid':
                      !showPasswordValidation[value] && focusCount
                  }
                )}
              >
                {!showPasswordValidation[value] && focusCount !== 0 ? (
                  <Clear />
                ) : (
                  <Check />
                )}
                {label}
              </div>
            ))}
          </>
        ) : showPasswordValidation.showSuccessValidationMessage ? (
          <div className="br-password-input__validation-success caption">
            <Check />
            {intl.formatMessage({
              id: 'login.set_new_passowrd.validation_success'
            })}
          </div>
        ) : showPasswordValidation.showHelp ? (
          intl.formatMessage({
            id: 'sign_up.form_placeholders.password'
          })
        ) : undefined
      }
    >
      <Input.Password
        autoComplete="new-password"
        onChange={checkPasswordValidation}
        onFocus={handleShowValidationMessages}
        onBlur={handleHideValidationMessages}
        placeholder={placeholder}
      />
    </Form.Item>
  );
};

export default injectIntl(BRPasswordInput);
