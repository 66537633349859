import { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { useIntl } from 'react-intl';
import Icon from '@ant-design/icons';

import {
  editPickupLocation,
  fetchPickupLocation
} from 'services/pickup-locations';
import { LOCALE } from 'constants/intl-wrapper';
import { useModal } from 'contexts/modalProvider.context';

import BRCityAreaCollapse from 'components/BRCityAreaCollapse/BRCityAreaCollapse';
import BRButton from 'components/BRButton/BRButton';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import { notify } from 'components/Notify/Notify';

import { ReactComponent as Location } from 'assets/bosta-icons/Location.svg';
import { ReactComponent as AlertIcon } from 'assets/bosta-icons/alert-triangle.svg';

import './IncompleteLocationModal.less';

const IncompleteLocationModal = ({
  close,
  locationId,
  onChangeAddress,
  getPickupLocations,
  title,
  selectedDestinationCountry,
  ...props
}) => {
  const [pickupLocation, setPickupLocation] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const intl = useIntl();
  const { openModal } = useModal();

  useEffect(() => {
    if (locationId) {
      getPickupLocation();
    }
  }, [locationId]);

  const getPickupLocation = async () => {
    setIsLoading(true);

    try {
      const data = await fetchPickupLocation(locationId);
      setPickupLocation(data);
    } catch (error) {
      notify({ msg: error.message, error });
    }

    setIsLoading(false);
  };

  const handleUpdateLocationDistrict = async (districtId) => {
    setIsLoading(true);

    try {
      const pickupLocation = await fetchPickupLocation(locationId);

      delete pickupLocation.address.geoLocation;
      delete pickupLocation.address.userEnteredGeoLocation;

      const payload = {
        locationName: pickupLocation.locationName,
        contacts: pickupLocation.contacts,
        address: {
          ...pickupLocation.address,
          districtId
        }
      };

      payload.contacts.map((contact) => {
        if (contact._id === pickupLocation.contactPerson._id) {
          contact.isDefault = true;
        }
        return contact;
      });

      await editPickupLocation({ pickupLocationId: locationId, payload });
      getPickupLocations({ validateLocationField: true });
      close();
    } catch (error) {
      notify({ msg: error.message, error });
    }

    setIsLoading(false);
  };

  const handleUpdateMissingAreaClick = () => {
    openModal(BRCityAreaCollapse, {
      selectedCountryId: selectedDestinationCountry?.id,
      setCityArea: (value) => handleUpdateLocationDistrict(value?.districtId)
    });
  };

  const handleOnChangeAddress = () => {
    close();
    onChangeAddress();
  };

  return (
    <Modal
      wrapClassName="br-incomplete-location__modal"
      title={null}
      width={null}
      footer={
        <>
          <BRButton
            onClick={handleOnChangeAddress}
            label={intl.formatMessage({
              id: 'incomplete_location_modal.change_address'
            })}
          />
          <BRButton
            type="primary"
            onClick={handleUpdateMissingAreaClick}
            label={intl.formatMessage({
              id: 'incomplete_location_modal.update_address'
            })}
          />
        </>
      }
      {...props}
    >
      <LoadingWrapper loading={isLoading}>
        <div className="br-incomplete-location-modal__container">
          <Icon component={Location} className="ant-icon-lg" />
          <div className="br-incomplete-location-modal__title">
            <div className="display-sm">
              {title ||
                intl.formatMessage({
                  id: 'incomplete_location_modal.return_title'
                })}
            </div>
            <div className="br-incomplete-location__subtitle">
              {intl.formatMessage({ id: 'incomplete_location_modal.subtitle' })}
            </div>
          </div>
          <div className="br-incomplete-location__banner">
            <Icon component={Location} className="ant-icon-md" />
            <div className="br-incomplete-location__banner-details">
              <div>{pickupLocation?.locationName}</div>
              <div className="br-incomplete-location__banner__location">
                <Icon component={AlertIcon} className="ant-icon-sm" />
                {intl.formatMessage(
                  { id: 'incomplete_location_modal.missing_area' },
                  {
                    span: (children) => (
                      <span className="br-inomplete-location__alert-text caption-lg">
                        {children}
                      </span>
                    ),
                    city:
                      intl.locale === LOCALE.EN
                        ? pickupLocation?.address?.city?.name
                        : pickupLocation?.address?.city?.nameAr
                  }
                )}
              </div>
            </div>
          </div>
        </div>
      </LoadingWrapper>
    </Modal>
  );
};

export default IncompleteLocationModal;
