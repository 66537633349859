import Icon from '@ant-design/icons';

import { ANT_STEPS_STATES } from 'constants/onboarding';

import { ReactComponent as CircleCheck } from 'assets/bosta-icons/Circle-Check.svg';

import './ActivationStepProgressDot.less';

const ActivationStepProgressDot = (props, { status, index }) => {
  const customStep = () => {
    if (status === ANT_STEPS_STATES.FINISH) {
      return (
        <Icon
          component={CircleCheck}
          className="br-activation-step-progress-dot__finished ant-icon-md"
        />
      );
    } else if (status === ANT_STEPS_STATES.PROCESS) {
      return (
        <div className="br-activation-step-progress-dot__active caption-medium">
          {index + 1}
        </div>
      );
    } else {
      return (
        <span className="br-activation-step-progress-dot__not-active caption-medium">
          {index + 1}
        </span>
      );
    }
  };

  return customStep();
};

export default ActivationStepProgressDot;
