import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import classnames from 'classnames';

import './BRFormInputCount.less';

const BRFormInputCounter = ({
  maxCount,
  label,
  optional = false,
  intl,
  value,
  onChange,
  children,
  showCounterExceed = false,
  showCounter = false
}) => {
  const [count, setCount] = useState(value ? value.length : 0);
  const [countExceed, setCountExceed] = useState(true);
  const [itemValue, setItemValue] = useState('');

  const onItemChange = (evt) => {
    const {
      target: { value }
    } = evt;

    if (onChange) {
      const validRegex = onChange(evt);
      if (validRegex) {
        setItemValue(value);
        setCount(value.length);
      } else if (validRegex === undefined) {
        setItemValue(value);
        setCount(value.length);
      }
    }
  };

  const childrenWithProps = React.Children.map(children, (child) => {
    // checking isValidElement is the safe way and avoids a typescript error too
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        onChange: onItemChange,
        value: value || itemValue
      });
    }
    return child;
  });

  useEffect(() => {
    if (showCounterExceed) {
      setCountExceed(value?.length > maxCount);
    }
    setCount(value?.length || 0);
  }, [value]);

  return (
    <>
      <div className="br-form-count__label-container">
        <div className="br-form-count__optional-label-container">
          <span className="br-form-count__label body-medium">{label}</span>
          {optional && (
            <span className="br-form-count__optional-label">
              {intl.formatMessage({
                id: 'form.optional_label'
              })}
            </span>
          )}
        </div>
        {(showCounter || countExceed) && (
          <span
            className={classnames('br-form-count__count-label body', {
              'br-form-count__count-label-exceed':
                countExceed && showCounterExceed
            })}
          >
            <div>
              {<span>{count}</span>}/{maxCount}
            </div>
          </span>
        )}
      </div>
      {childrenWithProps}
    </>
  );
};

export default injectIntl(BRFormInputCounter);
