import Icon from '@ant-design/icons';

import {
  constructFulfillmentOrder,
  constructInternationalOrder,
  constructOrder,
  handleLearnHowToFillItClick
} from 'utils/smart-massupload';
import { BUSINESS_TYPES } from 'constants/business';
import {
  getDefaultSmartMassuploadExcelColumns,
  getUserCurrency,
  isMultiPackageAvailable,
  isDomesticShippingAvailable
} from './countries/countries-mapping';
import { isSaudi } from './helpers';
import { createMassOrders } from 'services/shipments';
import { createFulfillmentMassOrders } from 'services/fulfillment';
import { createMassInternationalOrder } from 'services/international-orders';
import { RETURN_TYPES } from './international-orders';

import { fmt, intl } from 'components/IntlWrapper/IntlWrapper';
import BRButton from 'components/BRButton/BRButton';

import { ReactComponent as FulfillmentOrdersImage } from 'assets/imgRevamp/fulfillment-orders.svg';
import { ReactComponent as RegularOrdersImage } from 'assets/imgRevamp/regular-orders.svg';
import { ReactComponent as DeliverIcon } from 'assets/bosta-icons/Deliver.svg';
import { ReactComponent as ExchangeIcon } from 'assets/bosta-icons/Exchange.svg';
import { ReactComponent as CashCollectionIcon } from 'assets/bosta-icons/Cash-Collection.svg';
import { ReactComponent as ReturnIcon } from 'assets/bosta-icons/Return.svg';
import { ReactComponent as SignAndReturnIcon } from 'assets/bosta-icons/Sign-and-Return.svg';
import { ReactComponent as InternationalOrderIcon } from 'assets/bosta-icons/airplane.svg';

export const REGULAR_ORDERS = 'REGULAR_ORDERS';
export const FULFILLMENT_ORDERS = 'FULFILLMENT_ORDERS';
export const INTERNATIONAL_ORDERS = 'INTERNATIONAL_ORDERS';

export const SMART_MASSUPLOAD_UPLOAD_TYPE = (businessInfo) => [
  {
    title: fmt({
      id: 'smart_massupload.smart_massupload_tab.fulfillment_orders'
    }),
    icon: <FulfillmentOrdersImage />,
    value: FULFILLMENT_ORDERS,
    hasAccess:
      businessInfo.fulfillmentInfo?.isActive || businessInfo?.isBostaFulfillment
  },
  {
    title: fmt({ id: 'smart_massupload.smart_massupload_tab.regular_orders' }),
    icon: <RegularOrdersImage />,
    value: REGULAR_ORDERS,
    hasAccess: isDomesticShippingAvailable()
  },
  {
    title: fmt({
      id: 'smart_massupload.smart_massupload_tab.international_orders'
    }),
    icon: (
      <Icon
        component={InternationalOrderIcon}
        className="br-smart-massupload__international-icon ant-icon-xl"
      />
    ),
    value: INTERNATIONAL_ORDERS,
    hasAccess: businessInfo.internationalShippingActivated
  }
];

export const SEND = 'SEND';
export const CASH_COLLECTION = 'CASH_COLLECTION';
export const SIGN_AND_RETURN = 'sign_and_return';
export const RETURN = 'CUSTOMER_RETURN_PICKUP';
export const EXCHANGE = 'EXCHANGE';
export const PRODUCT_QUANTITY = 'PRODUCT_QUANTITY';
export const REGULAR = 'Regular';
export const FULFILLMENT = 'Fulfillment';
export const DELIVER = 'deliver';
export const COD_MAX_LIMIT = 30000;

export const DROP_OFF = 'dropoff';

export const ALLOWED_MULTI_PACKAGES_TYPES = ['deliver', 'exchange', 'return'];

export const SMART_MASSUPLOAD_ORDER_TYPES = (businessType) => [
  {
    value: 'deliver',
    name: fmt({ id: 'orders.order_types.forward' }),
    icon: DeliverIcon,
    access: true
  },
  {
    value: 'exchange',
    name: fmt({ id: 'orders.order_types.exchange' }),
    icon: ExchangeIcon,
    access: true
  },
  {
    value: 'return',
    name: fmt({ id: 'orders.order_types.customer_return_pickup' }),
    icon: ReturnIcon,
    access: true
  },
  {
    value: 'cash_collection',
    name: fmt({ id: 'orders.order_types.cash_collection' }),
    icon: CashCollectionIcon,
    access: true
  },
  {
    value: 'sign_and_return',
    name: fmt({ id: 'orders.order_types.sign_and_return' }),
    icon: SignAndReturnIcon,
    access: businessType === BUSINESS_TYPES.B2B
  }
];

export const ORDER_TYPES_SELECTION = [
  'Deliver',
  'Cash Collection',
  'Return',
  'Exchange'
];
export const YES_NO_SELECTION = ['Yes', 'No'];

export const NO_ORDERS = 'NO_ORDERS';
export const OLD_BOSTA_TEMPLATE = 'OLD_BOSTA_TEMPLATE';
export const EXCEEDS_LIMIT = 'EXCEEDS_LIMIT';

export const EXCEL_VALIDATION_ERRORS = {
  NO_ORDERS: fmt({
    id: 'smart_massupload.smart_massupload_tab.no_orders'
  }),
  OLD_BOSTA_TEMPLATE: fmt({
    id: 'smart_massupload.smart_massupload_tab.old_template_error'
  }),
  EXCEEDS_LIMIT: fmt({
    id: 'smart_massupload.smart_massupload_tab.upload_box.subtitle'
  })
};

export const SMART_MASSUPLOAD_UPLOAD_CHECKS = [
  fmt({
    id: 'smart_massupload.smart_massupload_tab.loading_messages.checking_format'
  }),
  fmt({
    id: 'smart_massupload.smart_massupload_tab.loading_messages.checking_valid'
  }),
  fmt({
    id: 'smart_massupload.smart_massupload_tab.loading_messages.checking_invalid'
  })
];

export const SMART_MASSUPLOAD_STEPS = {
  FILE_UPLOAD: 0,
  ANALYIZE_AND_CHECK: 1,
  SUMMARY_AND_CONFIRMATION: 2,
  SUCCESSFUL_ORDER_CREATION: 3
};

export const INTERNATIONAL_EXCEL_COLUMNS = {
  ...getDefaultSmartMassuploadExcelColumns(),
  WEIGHT: 14,
  INVOICE_VALUE: 15,
  CUSTOM_VAT: 16,
  RETURN_LOCATION: 17
};

export const getDefaultFXFExcelColumns = () => {
  const DEFAULT_FXF_EXCEL_COLUMNS = {
    PRODUCT_SKU: 10,
    PRODUCT_QUANTITY: 11,
    UNIT_PRICE: 12,
    ORDER_REFERENCE: 13,
    ALLOW_OPENEING_PACKAGE: 14
  };

  // Using this function temporarily until we remove Odoo fulfillment
  if (isSaudi()) {
    Object.keys(DEFAULT_FXF_EXCEL_COLUMNS).forEach((key) => {
      DEFAULT_FXF_EXCEL_COLUMNS[key]++;
    });
  }

  return DEFAULT_FXF_EXCEL_COLUMNS;
};

const BOSTA_CUSTOMER_INFOMRATION_COLUMNS = [
  {
    columnName: fmt({
      id: 'smart_massupload.columns_mapping.bosta_fields_title.full_name'
    }),
    defaultName: 'Full Name',
    defaultNameAr: 'اسم العميل',
    name: 'FULL_NAME',
    required: true,
    index: getDefaultSmartMassuploadExcelColumns().FULL_NAME
  },
  {
    columnName: fmt({
      id: 'smart_massupload.columns_mapping.bosta_fields_title.phone'
    }),
    defaultName: 'Phone',
    defaultNameAr: 'تليفون',
    name: 'PHONE',
    required: true,
    index: getDefaultSmartMassuploadExcelColumns().PHONE
  },
  {
    columnName: fmt({
      id: 'location_form_labels.city'
    }),
    defaultName: 'City',
    defaultNameAr: 'المدينة',
    name: 'CITY',
    required: true,
    index: getDefaultSmartMassuploadExcelColumns().CITY
  },
  {
    columnName: fmt({
      id: 'smart_massupload.columns_mapping.bosta_fields_title.street_name'
    }),
    defaultName: 'Street Name',
    defaultNameAr: 'العنوان',
    name: 'STREET_NAME',
    required: true,
    index: getDefaultSmartMassuploadExcelColumns().STREET_NAME
  }
];

// defaultName is used for bosta excel validation
export const BOSTA_DEFAULT_MAPPED_COLUMNS = [
  ...BOSTA_CUSTOMER_INFOMRATION_COLUMNS,
  {
    columnName: fmt({
      id: 'smart_massupload.columns_mapping.bosta_fields_title.cash_amount'
    }),
    defaultName: 'Cash Amount',
    defaultNameAr: 'قيمة التحصيل',
    name: 'CASH_AMOUNT',
    required: true,
    index: getDefaultSmartMassuploadExcelColumns().CASH_AMOUNT
  },
  {
    columnName: fmt({
      id: 'location_form_labels.area'
    }),
    defaultName: 'Area',
    defaultNameAr: 'المنطقة',
    name: 'AREA',
    index: getDefaultSmartMassuploadExcelColumns().AREA
  },
  {
    columnName: fmt({
      id: 'smart_massupload.columns_mapping.bosta_fields_title.landmark'
    }),
    defaultName: 'Landmark',
    defaultNameAr: 'علامة مميزة',
    name: 'SECOND_LINE',
    index: getDefaultSmartMassuploadExcelColumns().SECOND_LINE
  },
  {
    columnName: fmt({
      id: 'smart_massupload.columns_mapping.bosta_fields_title.work_address'
    }),
    defaultName: 'Work Address',
    defaultNameAr: 'عنوان عمل',
    name: 'IS_WORK_ADDRESS',
    helpText: fmt({
      id: 'smart_massupload.columns_mapping.help_text.is_work_address'
    }),
    index: getDefaultSmartMassuploadExcelColumns().IS_WORK_ADDRESS
  },
  {
    columnName: fmt({
      id: 'smart_massupload.columns_mapping.bosta_fields_title.second_phone'
    }),
    defaultName: 'Second Phone',
    defaultNameAr: 'تليفون ثاني',
    name: 'SECOND_PHONE',
    helpText: fmt({
      id: 'smart_massupload.columns_mapping.help_text.second_phone'
    }),
    index: getDefaultSmartMassuploadExcelColumns().SECOND_PHONE
  },
  ...(isMultiPackageAvailable()
    ? [
        {
          columnName: fmt({
            id: 'smart_massupload.columns_mapping.bosta_fields_title.multi_packages'
          }),
          defaultName: 'Multi-Packages',
          defaultNameAr: 'شحنة متعددة العبوات',
          name: 'MULTI_PACKAGES',
          index: getDefaultSmartMassuploadExcelColumns().MULTI_PACKAGES
        }
      ]
    : []),
  {
    columnName: fmt({
      id: 'smart_massupload.columns_mapping.bosta_fields_title.allow_opening_package'
    }),
    defaultName: 'Allow Opening Package',
    defaultNameAr: 'هل تسمح بفتح الشحنة',
    name: 'ALLOW_OPENEING_PACKAGE',
    helpText: fmt({
      id: 'smart_massupload.columns_mapping.help_text.allow_opening_package'
    }),
    index: getDefaultSmartMassuploadExcelColumns().ALLOW_OPENEING_PACKAGE
  },
  {
    columnName: fmt({
      id: 'smart_massupload.columns_mapping.bosta_fields_title.package_type'
    }),
    defaultName: 'Package Type',
    defaultNameAr: 'نوع الشحنة',
    name: 'PACKAGE_TYPE',
    helpText: fmt(
      {
        id: 'smart_massupload.columns_mapping.help_text.package_type'
      },
      {
        clickHere: (
          <BRButton
            type="link-color"
            onClick={handleLearnHowToFillItClick}
            className="br-smart-massupload__package-type-click-here"
            label={fmt({ id: 'common.click_here' })}
          />
        )
      }
    ),
    index: getDefaultSmartMassuploadExcelColumns().PACKAGE_TYPE
  },
  {
    columnName: fmt({
      id: 'orders.shipment_details.shipment_details_summary.table_cells.number_of_items'
    }),
    defaultName: '#Items',
    defaultNameAr: 'عدد القطع',
    name: 'NUMBER_OF_ITEMS',
    index: getDefaultSmartMassuploadExcelColumns().NUMBER_OF_ITEMS
  },
  {
    columnName: fmt({
      id: 'smart_massupload.columns_mapping.bosta_fields_title.package_description'
    }),
    defaultName: 'Package Description',
    defaultNameAr: 'وصف الشحنة',
    name: 'PACKAGE_DESCRIPTION',
    index: getDefaultSmartMassuploadExcelColumns().PACKAGE_DESCRIPTION
  },
  {
    columnName: fmt({
      id: 'smart_massupload.columns_mapping.bosta_fields_title.delivery_notes'
    }),
    defaultName: 'Delivery Notes',
    defaultNameAr: 'ملاحظات',
    name: 'DELIVERY_NOTES',
    index: getDefaultSmartMassuploadExcelColumns().DELIVERY_NOTES
  },
  {
    columnName: fmt({
      id: 'smart_massupload.columns_mapping.bosta_fields_title.order_reference'
    }),
    defaultName: 'Order Reference',
    defaultNameAr: 'مرجع الطلب',
    name: 'ORDER_REFERENCE',
    index: getDefaultSmartMassuploadExcelColumns().ORDER_REFERENCE
  },
  {
    columnName: fmt({
      id: 'smart_massupload.columns_mapping.bosta_fields_title.number_of_return_items'
    }),
    defaultName: 'Return #Items',
    defaultNameAr: 'عدد قطع المرتجع',
    name: 'RETURN_NUMBER_OF_ITEMS',
    index: getDefaultSmartMassuploadExcelColumns().RETURN_NUMBER_OF_ITEMS
  },
  {
    columnName: fmt({
      id: 'smart_massupload.columns_mapping.bosta_fields_title.return_package_description'
    }),
    defaultName: 'Return Package Description',
    defaultNameAr: 'وصف الشحنة المرتجعة',
    name: 'RETURN_PACKAGE_DESCRIPTION',
    index: getDefaultSmartMassuploadExcelColumns().RETURN_PACKAGE_DESCRIPTION
  },
  {
    columnName: fmt({
      id: 'smart_massupload.columns_mapping.bosta_fields_title.goods_value_amount'
    }),
    defaultName: 'Goods Value Amount',
    defaultNameAr: 'قيمة الشحنة',
    name: 'GOODS_VALUE_AMOUNT',
    index: INTERNATIONAL_EXCEL_COLUMNS.GOODS_VALUE_AMOUNT
  }
];

export const BOSTA_DEFAULT_FXF_COLUMNS = [
  ...BOSTA_CUSTOMER_INFOMRATION_COLUMNS,
  {
    columnName: fmt({
      id: 'smart_massupload.columns_mapping.bosta_fields_title.unit_price'
    }),
    defaultName: 'Unit Price',
    defaultNameAr: 'سعر القطعة',
    name: 'UNIT_PRICE',
    index: getDefaultFXFExcelColumns().UNIT_PRICE
  },
  {
    columnName: fmt({
      id: 'location_form_labels.area'
    }),
    defaultName: 'Area',
    defaultNameAr: 'المنطقة',
    name: 'AREA',
    index: getDefaultSmartMassuploadExcelColumns().AREA
  },
  {
    columnName: fmt({
      id: 'smart_massupload.columns_mapping.bosta_fields_title.landmark'
    }),
    defaultName: 'Landmark',
    defaultNameAr: 'علامة مميزة',
    name: 'SECOND_LINE',
    index: getDefaultSmartMassuploadExcelColumns().SECOND_LINE
  },
  {
    columnName: fmt({
      id: 'smart_massupload.columns_mapping.bosta_fields_title.work_address'
    }),
    defaultName: 'Work Address',
    defaultNameAr: 'عنوان عمل',
    name: 'IS_WORK_ADDRESS',
    helpText: fmt({
      id: 'smart_massupload.columns_mapping.help_text.is_work_address'
    }),
    index: getDefaultSmartMassuploadExcelColumns().IS_WORK_ADDRESS
  },
  {
    columnName: fmt({
      id: 'smart_massupload.columns_mapping.bosta_fields_title.second_phone'
    }),
    defaultName: 'Second Phone',
    defaultNameAr: 'تليفون ثاني',
    name: 'SECOND_PHONE',
    helpText: fmt({
      id: 'smart_massupload.columns_mapping.help_text.second_phone'
    }),
    index: getDefaultSmartMassuploadExcelColumns().SECOND_PHONE
  },
  {
    columnName: fmt({
      id: 'fulfillment.inventory.table.bosta_sku'
    }),
    defaultName: 'Product SKU',
    defaultNameAr: 'SKU',
    name: 'PRODUCT_SKU',
    required: true,
    index: getDefaultFXFExcelColumns().PRODUCT_SKU
  },
  {
    columnName: fmt({
      id: 'smart_massupload.columns_mapping.bosta_fields_title.product_quantity'
    }),
    defaultName: 'Product Quantity',
    defaultNameAr: 'كمية المنتج',
    name: 'PRODUCT_QUANTITY',
    required: true,
    index: getDefaultFXFExcelColumns().PRODUCT_QUANTITY
  },
  {
    columnName: fmt({
      id: 'smart_massupload.columns_mapping.bosta_fields_title.allow_opening_package'
    }),
    defaultName: 'Allow Opening Package',
    defaultNameAr: 'هل تسمح بفتح الشحنة',
    name: 'ALLOW_OPENEING_PACKAGE',
    helpText: fmt({
      id: 'smart_massupload.columns_mapping.help_text.allow_opening_package'
    }),
    index: getDefaultFXFExcelColumns().ALLOW_OPENEING_PACKAGE
  },
  {
    columnName: fmt({
      id: 'smart_massupload.columns_mapping.bosta_fields_title.delivery_notes'
    }),
    defaultName: 'Delivery Notes',
    defaultNameAr: 'ملاحظات',
    name: 'DELIVERY_NOTES',
    index: getDefaultSmartMassuploadExcelColumns().DELIVERY_NOTES
  },
  {
    columnName: fmt({
      id: 'smart_massupload.columns_mapping.bosta_fields_title.order_reference'
    }),
    defaultName: 'Order Reference',
    defaultNameAr: 'مرجع الطلب',
    name: 'ORDER_REFERENCE',
    index: getDefaultFXFExcelColumns().ORDER_REFERENCE
  },
  // Using this function temporarily until we remove Odoo fulfillment
  ...(isSaudi()
    ? [
        {
          columnName: fmt({
            id: 'smart_massupload.columns_mapping.bosta_fields_title.cash_amount'
          }),
          defaultName: 'Cash Amount',
          defaultNameAr: 'قيمة التحصيل',
          name: 'CASH_AMOUNT',
          required: true,
          helpText: fmt({
            id: 'smart_massupload.columns_mapping.help_text.fulfillment_cash_amount'
          }),
          index: getDefaultSmartMassuploadExcelColumns().FULFILLMENT_CASH_AMOUNT
        }
      ]
    : [])
];

export const BOSTA_INTERNATIONAL_DEFAULT_COLUMNS = [
  ...BOSTA_CUSTOMER_INFOMRATION_COLUMNS,
  {
    columnName: fmt({
      id: 'smart_massupload.columns_mapping.bosta_fields_title.cash_amount'
    }),
    defaultName: 'Cash Amount',
    defaultNameAr: 'قيمة التحصيل',
    name: 'CASH_AMOUNT',
    index: getDefaultSmartMassuploadExcelColumns().CASH_AMOUNT
  },
  {
    columnName: fmt({
      id: 'location_form_labels.area'
    }),
    defaultName: 'Area',
    defaultNameAr: 'المنطقة',
    name: 'AREA',
    index: getDefaultSmartMassuploadExcelColumns().AREA
  },
  {
    columnName: fmt({
      id: 'smart_massupload.columns_mapping.bosta_fields_title.landmark'
    }),
    defaultName: 'Landmark',
    defaultNameAr: 'علامة مميزة',
    name: 'SECOND_LINE',
    index: getDefaultSmartMassuploadExcelColumns().SECOND_LINE
  },
  {
    columnName: fmt({
      id: 'smart_massupload.columns_mapping.bosta_fields_title.work_address'
    }),
    defaultName: 'Work Address',
    defaultNameAr: 'عنوان عمل',
    name: 'IS_WORK_ADDRESS',
    helpText: fmt({
      id: 'smart_massupload.columns_mapping.help_text.is_work_address'
    }),
    index: getDefaultSmartMassuploadExcelColumns().IS_WORK_ADDRESS
  },
  {
    columnName: fmt({
      id: 'smart_massupload.columns_mapping.bosta_fields_title.second_phone'
    }),
    defaultName: 'Second Phone',
    defaultNameAr: 'تليفون ثاني',
    name: 'SECOND_PHONE',
    helpText: fmt({
      id: 'smart_massupload.columns_mapping.help_text.second_phone'
    }),
    index: getDefaultSmartMassuploadExcelColumns().SECOND_PHONE
  },
  {
    columnName: fmt({
      id: 'orders.shipment_details.shipment_details_summary.table_cells.number_of_items'
    }),
    defaultName: '#Items',
    defaultNameAr: 'عدد القطع',
    name: 'NUMBER_OF_ITEMS',
    index: getDefaultSmartMassuploadExcelColumns().NUMBER_OF_ITEMS
  },
  {
    columnName: fmt({
      id: 'smart_massupload.columns_mapping.bosta_fields_title.package_description'
    }),
    defaultName: 'Package Description',
    defaultNameAr: 'وصف الشحنة',
    name: 'PACKAGE_DESCRIPTION',
    index: getDefaultSmartMassuploadExcelColumns().PACKAGE_DESCRIPTION
  },
  {
    columnName: fmt({
      id: 'smart_massupload.columns_mapping.bosta_fields_title.delivery_notes'
    }),
    defaultName: 'Delivery Notes',
    defaultNameAr: 'ملاحظات',
    name: 'DELIVERY_NOTES',
    index: getDefaultSmartMassuploadExcelColumns().DELIVERY_NOTES
  },
  {
    columnName: fmt({
      id: 'smart_massupload.columns_mapping.bosta_fields_title.order_reference'
    }),
    defaultName: 'Order Reference',
    defaultNameAr: 'مرجع الطلب',
    name: 'ORDER_REFERENCE',
    index: getDefaultSmartMassuploadExcelColumns().ORDER_REFERENCE
  },
  {
    columnName: fmt({
      id: 'smart_massupload.columns_mapping.bosta_fields_title.weight'
    }),
    defaultName: 'Weight',
    defaultNameAr: 'الوزن',
    name: 'WEIGHT',
    required: true,
    index: INTERNATIONAL_EXCEL_COLUMNS.WEIGHT
  },
  {
    columnName: fmt({
      id: 'smart_massupload.columns_mapping.bosta_fields_title.invoice_value'
    }),
    defaultName: 'Invoice Value',
    defaultNameAr: 'قيمة الفاتورة',
    name: 'INVOICE_VALUE',
    required: true,
    index: INTERNATIONAL_EXCEL_COLUMNS.INVOICE_VALUE
  },
  {
    columnName: fmt({
      id: 'smart_massupload.columns_mapping.bosta_fields_title.customs_vat'
    }),
    defaultName: 'Custom VAT',
    defaultNameAr: 'ضريبة القيمة المضافة الجمركية',
    name: 'CUSTOM_VAT',
    index: INTERNATIONAL_EXCEL_COLUMNS.CUSTOM_VAT
  },
  {
    columnName: fmt({
      id: 'smart_massupload.columns_mapping.bosta_fields_title.return_location'
    }),
    defaultName: 'Return Location',
    defaultNameAr: 'موقع العودة',
    name: 'RETURN_LOCATION',
    required: true,
    index: INTERNATIONAL_EXCEL_COLUMNS.RETURN_LOCATION
  }
];

export const FIXED_COLUMNS = ['FULL_NAME', 'PHONE'];

export const COLUMNS_KEYS = {
  FULL_NAME: 'FULL_NAME',
  PHONE: 'PHONE',
  SECOND_PHONE: 'SECOND_PHONE',
  CITY: 'CITY',
  AREA: 'AREA',
  STREET_NAME: 'STREET_NAME',
  BUILDING: 'BUILDING',
  FLOOR: 'FLOOR',
  ARAPRTMENT: 'APARTMENT',
  IS_WORK_ADDRESS: 'IS_WORK_ADDRESS',
  DELIVERY_NOTES: 'DELIVERY_NOTES',
  TYPE: 'TYPE',
  CASH_AMOUNT: 'CASH_AMOUNT',
  NUMBER_OF_ITEMS: 'NUMBER_OF_ITEMS',
  PACKAGE_DESCRIPTION: 'PACKAGE_DESCRIPTION',
  ORDER_REFERENCE: 'ORDER_REFERENCE',
  ALLOW_OPENEING_PACKAGE: 'ALLOW_OPENEING_PACKAGE',
  RETURN_NUMBER_OF_ITEMS: 'RETURN_NUMBER_OF_ITEMS',
  RETURN_PACKAGE_DESCRIPTION: 'RETURN_PACKAGE_DESCRIPTION',
  PACKAGE_TYPE: 'PACKAGE_TYPE',
  SECOND_LINE: 'SECOND_LINE',
  PRODUCT_SKU: 'PRODUCT_SKU',
  PRODUCT_QUANTITY: 'PRODUCT_QUANTITY',
  UNIT_PRICE: 'UNIT_PRICE',
  WEIGHT: 'WEIGHT',
  INVOICE_VALUE: 'INVOICE_VALUE',
  CUSTOM_VAT: 'CUSTOM_VAT',
  RETURN_LOCATION: 'RETURN_LOCATION',
  MULTI_PACKAGES: 'MULTI_PACKAGES',
  GOODS_VALUE_AMOUNT: 'GOODS_VALUE_AMOUNT'
};

export const SMART_UPLOAD_CREATION_SRC = 'NEW_MASS_UPLOAD';

export const EXPORTED_FILE_NAME = {
  DELETE: 'delete-orders',
  SKIPPED: 'error-orders',
  FAILED: 'failed-orders'
};

export const FULFILLMENT_BACKEND_ERRORS = {
  'The COD amount should be less than or equal 30,000 EGP':
    'AMOUNT_EXCEEDS_LIMIT',
  'This BostaSKU doesnt relate to this business': 'INVALID_SKU',
  'This BostaSKU doesnt have more available quantity': 'QUANTITY_EXCEEDED'
};

export const FULFILLMENT_ERRORS_TEXT = {
  INVALID_SKU: fmt({
    id: 'smart_massupload.upload_success.fulfillment_errors.invalid_sku'
  }),
  AMOUNT_EXCEEDS_LIMIT: fmt(
    {
      id: 'smart_massupload.upload_success.fulfillment_errors.amount_exceeded_limit'
    },
    { amount: getUserCurrency(COD_MAX_LIMIT).localized }
  ),
  QUANTITY_EXCEEDED: fmt({
    id: 'smart_massupload.upload_success.fulfillment_errors.quantity_exceeded'
  })
};

export const SMART_MASSUPLOAD_ARTICLE_LINK = `https://bosta.freshdesk.com/a/solutions/articles/36000382542?lang=${intl.locale}`;

export const WATCH_DEMO_YOUTUBE_LINK =
  'https://www.youtube.com/embed/D2fVYc8wh4s';

export const HOW_IT_WORKS_YOUTUBE_LINK =
  'https://www.youtube.com/embed/4tUjG_TLV0g';

export const UPLOAD_TYPE_COLUMNS_MAPPING = {
  REGULAR_ORDERS: BOSTA_DEFAULT_MAPPED_COLUMNS,
  FULFILLMENT_ORDERS: BOSTA_DEFAULT_FXF_COLUMNS,
  INTERNATIONAL_ORDERS: BOSTA_INTERNATIONAL_DEFAULT_COLUMNS
};

export const CONSTRUCT_ORDERS_MAPPING = {
  REGULAR_ORDERS: constructOrder,
  FULFILLMENT_ORDERS: constructFulfillmentOrder,
  INTERNATIONAL_ORDERS: constructInternationalOrder
};

export const MASS_ORDER_CREATION_MAPPING = {
  REGULAR_ORDERS: createMassOrders,
  FULFILLMENT_ORDERS: createFulfillmentMassOrders,
  INTERNATIONAL_ORDERS: createMassInternationalOrder
};

export const SMART_MASSUPLOAD_MAPPING_KEY = {
  REGULAR_ORDERS: 'massUploadMapping',
  FULFILLMENT_ORDERS: 'fulfillmentMassUploadMapping',
  INTERNATIONAL_ORDERS: 'internationalMassUploadMapping'
};

export const INTERNATIONAL_RETURN_LOCATION_MAPPING = (
  defaultInternationalLocationId
) => ({
  'Return to Address': {
    [RETURN_TYPES.RETURN_ADDRESS]: defaultInternationalLocationId
  },
  'Return to Fulfilment': { [RETURN_TYPES.RETURN_TO_FULFILLMENT]: true },
  Terminate: { [RETURN_TYPES.TERMINATE]: true },
  'Return To Origin': { [RETURN_TYPES.RETURN_TO_ORIGIN]: true }
});

export const INTERNATIONAL_RETURN_LOCATIONS_LABELS = {
  'Return to Address': fmt({
    id: 'new_order.order_details.international_return.return_address.label'
  }),
  'Return to Fulfilment': fmt({
    id: 'new_order.order_details.international_return.return_to_fulfillment.label'
  }),
  Terminate: fmt({
    id: 'new_order.order_details.international_return.terminate.label'
  })
};
