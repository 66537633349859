import { useEffect, useState, useContext } from 'react';
import { Modal, Dropdown, Tooltip } from 'antd';
import { injectIntl } from 'react-intl';

import { BUSINESS_TIERS } from 'constants/business';
import { NEXT_DAY_CASHOUT_FREQUENCIES } from 'constants/wallet';
import { EVENTS } from 'constants/common';
import { isInstantCashoutPlanAvailable } from 'constants/countries/countries-mapping';
import { formatNumbersBasedOnLanguage } from 'utils/intl-wrapper';
import { ModalContext } from 'contexts/modalProvider.context';
import BRButton from 'components/BRButton/BRButton';

import ChangeCashoutModal from 'components/CashoutGuideModal/ChangeCashoutModal/ChangeCashoutModal';

import { ReactComponent as CloseIcon } from 'assets/bosta-icons/Close.svg';
import { ReactComponent as MenuIcon } from 'assets/bosta-icons/menu-dropdown-icon.svg';
import { ReactComponent as Arrow } from 'assets/bosta-icons/arrow-guide.svg';
import { ReactComponent as Bolt } from 'assets/bosta-icons/bolt.svg';
import { ReactComponent as TooltipIcon } from 'assets/bosta-icons/Tooltip.svg';

import './CashoutGuideModal.less';

const CashoutGuideModal = ({
  intl,
  close,
  isSignUp = false,
  updateBusinessInfo,
  ...props
}) => {
  const [value, setValue] = useState(
    isInstantCashoutPlanAvailable() ? 'daily' : 'weekly'
  );
  const [visible, setVisible] = useState(false);
  const { businessInfo, openModal } = useContext(ModalContext);

  const {
    user: { isOrganizationalAccount }
  } = JSON.parse(localStorage.getItem('userInfo'));

  const { CLICK } = EVENTS;

  const paymentFrequencyMapper = {
    1: 'weekly',
    2: 'twice_a_week',
    3: 'three_times',
    5: 'daily'
  };

  useEffect(() => {
    if (businessInfo?.paymentInfo?.paymentSchedule.length && !isSignUp) {
      setValue(
        paymentFrequencyMapper[
          businessInfo?.paymentInfo?.paymentSchedule.length || 5
        ]
      );
    }
  }, [businessInfo]);

  const handleChangeCashout = () => {
    close();
    openModal(ChangeCashoutModal, {
      frequency: businessInfo.paymentInfo.paymentFrequency,
      lastChangeDate: businessInfo?.paymentInfo?.paymentFrequencyUpdatedAt,
      updateBusinessInfo
    });
  };

  const handleOnMenuItemClick = (value) => {
    setValue(value);
    setVisible((prev) => !prev);
  };

  const overlayMenu = () => {
    return (
      <div
        onClick={(e) => e.stopPropagation()}
        className="br-cashout-guide__menu-overlay-container"
      >
        {isInstantCashoutPlanAvailable() && (
          <div onClick={() => handleOnMenuItemClick('daily')}>
            <span className="body">
              {intl.formatMessage({
                id: `cash_out_guide.modal.daily`
              })}
            </span>
          </div>
        )}
        <div onClick={() => handleOnMenuItemClick('weekly')}>
          <span className="body">
            {intl.formatMessage({
              id: `cash_out_guide.modal.weekly`
            })}
          </span>
        </div>
      </div>
    );
  };

  const planItems = {
    daily: [
      {
        title: intl.formatMessage({
          id: 'cash_out_guide.modal.daily_sub'
        }),
        daysArray: ['sunday', 'monday', 'tuesday', 'wednesday'],
        showBolt: true,
        deliveryDay: intl.formatMessage({
          id: 'cash_out_guide.modal.next_day_delivery'
        }),
        contentAfterDeliveryDay: null,
        tooltipContent: null
      },
      {
        title: intl.formatMessage({
          id: 'cash_out_guide.modal.daily_except'
        }),
        daysArray: ['thursday', 'saturday'],
        showBolt: true,
        deliveryDay: intl.formatMessage({
          id: 'cash_out_guide.modal.sunday'
        }),
        contentAfterDeliveryDay: intl.formatMessage({
          id: 'cash_out_guide.modal.right_after'
        }),
        tooltipContent: null
      }
    ],
    weekly: [
      {
        title: intl.formatMessage({
          id: 'cash_out_guide.modal.weekly_sub'
        }),
        daysArray: [
          'sunday',
          'monday',
          'tuesday',
          'wednesday',
          'thursday',
          'saturday'
        ],
        showBolt: false,
        deliveryDay: intl.formatMessage({
          id: `cash_out_guide.modal.${
            businessInfo.paymentInfo?.paymentSchedule?.length === 1
              ? businessInfo.paymentInfo?.paymentSchedule[0]?.toLowerCase()
              : 'wednesday'
          }`
        }),
        contentAfterDeliveryDay: intl.formatMessage({
          id: 'cash_out_guide.modal.of_next_week'
        }),
        tooltipContent: null
      }
    ],
    twice_a_week: [
      {
        title: intl.formatMessage({
          id: 'cash_out_guide.modal.rest_sub'
        }),
        daysArray: ['saturday', 'sunday', 'monday'],
        showBolt: true,
        deliveryDay: intl.formatMessage({
          id: 'cash_out_guide.modal.wednesday'
        }),
        contentAfterDeliveryDay: intl.formatMessage({
          id: 'cash_out_guide.modal.of_same_week'
        }),
        tooltipContent: null
      },
      {
        title: intl.formatMessage({
          id: 'cash_out_guide.modal.rest_sub'
        }),
        daysArray: ['tuesday', 'wednesday', 'thursday'],
        showBolt: false,
        deliveryDay: intl.formatMessage({
          id: 'cash_out_guide.modal.monday'
        }),
        contentAfterDeliveryDay: intl.formatMessage({
          id: 'cash_out_guide.modal.of_next_week'
        }),
        tooltipContent: null
      }
    ],
    three_times: [
      {
        title: intl.formatMessage({
          id: 'cash_out_guide.modal.rest_sub'
        }),
        daysArray: ['saturday', 'sunday'],
        showBolt: true,
        deliveryDay: intl.formatMessage({
          id: 'cash_out_guide.modal.tuesday'
        }),
        contentAfterDeliveryDay: intl.formatMessage({
          id: 'cash_out_guide.modal.of_same_week'
        }),
        tooltipContent: null
      },
      {
        title: intl.formatMessage({
          id: 'cash_out_guide.modal.rest_sub'
        }),
        daysArray: ['monday', 'tuesday'],
        showBolt: true,
        deliveryDay: intl.formatMessage({
          id: 'cash_out_guide.modal.thursday'
        }),
        contentAfterDeliveryDay: intl.formatMessage({
          id: 'cash_out_guide.modal.of_same_week'
        }),
        tooltipContent: null
      },
      {
        title: intl.formatMessage({
          id: 'cash_out_guide.modal.rest_sub'
        }),
        daysArray: ['wednesday', 'thursday'],
        showBolt: true,
        deliveryDay: intl.formatMessage({
          id: 'cash_out_guide.modal.sunday'
        }),
        contentAfterDeliveryDay: intl.formatMessage({
          id: 'cash_out_guide.modal.of_next_week'
        }),
        tooltipContent: (
          <span className="caption">
            {intl.formatMessage(
              {
                id: 'cash_out_guide.modal.tooltip'
              },
              {
                info: (
                  <span className="caption-medium">
                    {intl.formatMessage({
                      id: 'cash_out_guide.modal.sunday_next_week'
                    })}
                  </span>
                )
              }
            )}
          </span>
        )
      }
    ]
  };
  return (
    <Modal
      className="br-cashout-guide-modal"
      {...props}
      title={
        <div className="br-cashout-guide-modal__header-container">
          <span>
            {intl.formatMessage({
              id: 'cash_out_guide.modal.header'
            })}
          </span>
          <CloseIcon onClick={close} />
        </div>
      }
      footer={null}
      width={null}
      onClose={close}
      onCancel={close}
    >
      <div className="br-cashout-guide-modal__main-content-container">
        <span className="display-sm">
          {isSignUp
            ? intl.formatMessage(
                {
                  id: 'cash_out_guide.modal.title_new_user'
                },
                {
                  options: (
                    <Dropdown
                      overlay={overlayMenu()}
                      overlayClassName="br-cashout-guide__option-dropdown"
                      visible={visible}
                      trigger={[CLICK]}
                    >
                      <div onClick={() => setVisible((prev) => !prev)}>
                        <span>
                          {intl.formatMessage({
                            id: `cash_out_guide.modal.${value}`
                          })}
                        </span>
                        <MenuIcon />
                      </div>
                    </Dropdown>
                  )
                }
              )
            : intl.formatMessage(
                {
                  id: 'cash_out_guide.modal.title_old_user'
                },
                {
                  options: intl.formatMessage({
                    id: `cash_out_guide.modal.${value}`
                  })
                }
              )}
          {isOrganizationalAccount &&
            businessInfo.businessTier === BUSINESS_TIERS.BRONZE && (
              <BRButton
                label={intl.formatMessage({
                  id: `cash_out_guide.modal.${
                    value === NEXT_DAY_CASHOUT_FREQUENCIES[0]
                      ? 'switch_to_weekly'
                      : 'switch_to_daily'
                  }`
                })}
                type="primary"
                onClick={handleChangeCashout}
              />
            )}
        </span>
      </div>
      <div className="br-cashout-guide-modal__plan-info-container">
        {planItems[value].map((info, id) => (
          <div
            key={id}
            className="br-cashout-guide-modal__plan-info-content"
            id={id}
          >
            {planItems[value].length > 1 && (
              <div className="br-cashout-guide__number-container body">
                {formatNumbersBasedOnLanguage(id + 1)}
              </div>
            )}
            <div className="br-cashout-guide__main-info-container">
              <span className="heading">{info.title}</span>
              <div>
                {info.daysArray.map((day, index) => (
                  <div
                    key={index}
                    className="caption br-cashout-guide-modal__day-container"
                  >
                    {intl.formatMessage({ id: `cash_out_guide.modal.${day}` })}
                  </div>
                ))}
              </div>
              <div>
                <div>
                  <span className="body-medium">
                    {intl.formatMessage({
                      id: 'cash_out_guide.modal.transfered'
                    })}
                  </span>
                  <Arrow />
                </div>
                {info.showBolt && <Bolt />}
                <div>
                  <span className="body-medium">{info.deliveryDay}</span>
                </div>
                <span className="heading">{info.contentAfterDeliveryDay}</span>
                {info.tooltipContent && (
                  <Tooltip
                    title={info.tooltipContent}
                    getPopupContainer={(triggerNode) =>
                      triggerNode.parentElement.parentElement.parentElement
                    }
                  >
                    <TooltipIcon className="br-cashout-guide-tooltip-icon" />
                  </Tooltip>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </Modal>
  );
};

export default injectIntl(CashoutGuideModal);
