import React, { createRef } from 'react';
import { Form, Input, Progress, Checkbox, Select } from 'antd';
import Icon from '@ant-design/icons';
import { injectIntl } from 'react-intl';
import classnames from 'classnames';

import { sendSegment } from 'utils/segment';
import { mediaHook } from 'customHooks';
import { isDashboardArabic } from 'utils/intl-wrapper';
import { isRegisteredViaBetaFunnel } from 'utils/helpers';
import { COUNTRIES } from 'constants/country-data';
import { getInternationalShippingDestinationCountries } from 'constants/countries/countries-mapping';

import GoogleMapCard from 'components/BRGoogleMap/BRGoogleMapCard/GoogleMapCard';
import BRCityArea, { AVAILABILITY } from 'components/BRCityArea/BRCityArea';
import BRAddressDetails from 'components/BRAddressDetails/BRAddressDetails';
import BRButton from 'components/BRButton/BRButton';

import { ReactComponent as ArrowRight } from 'assets/bosta-icons/Arrow-right.svg';

const userInfo = JSON.parse(localStorage?.getItem('userInfo'))?.user;

class LocationForm extends React.Component {
  state = {
    areas: [],
    fields: [],
    userPosition: null,
    userAddress: null,
    isMapUsed: false,
    getResultsFromGoogleMap: false,
    googleMapLimitedDistrict: [],
    limitedAreas: false,
    googleMapInitialValue: null,
    showLocationHelpMsg: true,
    selectedCountryId: null,
    userPositionError: false
  };

  locationFormContainerRef = createRef();

  componentDidMount() {
    const { initialValues, formRef, initialCountryId, activatePage } =
      this.props;
    const districtId = initialValues?.address?.district?._id;
    const { hasAddress } = JSON.parse(localStorage.getItem('userInfo')).user
      .businessAdminInfo;

    if (hasAddress && !districtId && activatePage) {
      this.setState({
        isMapUsed: true
      });
    }

    if (districtId) {
      formRef?.current?.setFieldsValue({
        districtId
      });
    }

    this.handleCountryChange(initialCountryId || userInfo.country._id);
  }

  setIsMapUsed = (value) => {
    this.setState({ isMapUsed: value });
  };

  setUserPosition = (userPosition) => {
    this.setState({ userPosition, userPositionError: false });
  };

  setShowLocationHelpMsg = (showLocationHelpMsg) => {
    this.setState({ showLocationHelpMsg });
  };

  handleCountryChange = (selectedCountryId) => {
    this.setState({ selectedCountryId });
  };

  setUserAddress = (fulluserAddress) => {
    const { formRef } = this.props;

    this.setState({ userAddress: fulluserAddress });
    formRef.current.setFieldsValue({
      firstLine: fulluserAddress?.streetName,
      buildingNumber: fulluserAddress?.buildingNumber
    });
  };

  LocationUsingGoogleMap = (districtId) => {
    const { formRef } = this.props;

    this.setState({ isMapUsed: true, getResultsFromGoogleMap: true });

    formRef?.current?.setFieldsValue({
      districtId
    });
  };

  handleLocationFormCancel = () => {
    const { close, formRef } = this.props;
    formRef.current.resetFields();
    sendSegment('E_PICKUP_NEW_LOCATION_CANCELED');
    close();
  };

  onLocationNameChange = () => {
    const { formRef } = this.props;

    formRef.current
      .validateFields(['locationName'])
      .then(() => {
        this.setShowLocationHelpMsg(true);
      })
      .catch(() => {
        this.setShowLocationHelpMsg(false);
      });
  };

  handleShowUserPositionError = () => {
    this.locationFormContainerRef.current.scrollTo({
      top: 0,
      behavior: 'smooth'
    });

    return this.setState({ userPositionError: true });
  };

  render() {
    const {
      isFormLoading,
      cities,
      intl,
      handleNextToModalLocation,
      formRef,
      setFieldsValueState,
      pickupLocation,
      onFinishFailed = () => {},
      cityID,
      handleChangeCityID = () => {},
      activatePage,
      isLoading,
      initialValues,
      mobileScreenSizes,
      setCityStatus,
      cityStatus,
      availability,
      hideSubmitButtons,
      hideDefaultSetCheckbox = false,
      progressPercent = 0,
      areas,
      setAreas,
      isCreatingInternationalLocation,
      isGeoLocationRequired = isRegisteredViaBetaFunnel()
    } = this.props;
    const {
      locationFields,
      isMapUsed,
      googleMapLimitedDistrict,
      limitedAreas,
      userAddress,
      userPosition,
      googleMapInitialValue,
      showLocationHelpMsg,
      selectedCountryId,
      userPositionError
    } = this.state;

    const { isSmallMobileScreen } = mobileScreenSizes;

    const onCityAreaChange = ({ cityID, cityName, areaName }) => {
      setCityStatus
        ? setCityStatus({
            ...cityStatus,
            cityID: cityID,
            cityName: cityName,
            areaName: areaName
          })
        : handleChangeCityID(cityID);
      this.setState({ isMapUsed: false });
    };

    const renderLocationNameField = () => (
      <div className="br-form-row br-location-form__location-name">
        <Form.Item
          name="locationName"
          label={intl.formatMessage({
            id: 'location_form_labels.location_name'
          })}
          rules={[
            { required: true },
            {
              max: 250,
              message: intl.formatMessage(
                {
                  id: 'form.field_max_length'
                },
                {
                  label: intl.formatMessage({
                    id: 'location_form_labels.location_name'
                  }),
                  count: 250
                }
              )
            }
          ]}
          initialValue={initialValues?.locationName}
          onChange={this.onLocationNameChange}
          help={
            showLocationHelpMsg ? (
              <span className="body">
                {intl.formatMessage({
                  id: 'sign_up.business_information.location_name_help'
                })}
              </span>
            ) : null
          }
        >
          <Input
            placeholder={intl.formatMessage({
              id: 'sign_up.business_information.form_labels.location_name_placeholder'
            })}
            autoComplete={'' + Math.random()}
          />
        </Form.Item>
      </div>
    );
    const onFinishLocationForm = (values) => {
      if (isGeoLocationRequired && !userPosition) {
        return this.handleShowUserPositionError();
      }
      values = {
        ...values,
        ...(userPosition &&
          (isMapUsed || isGeoLocationRequired) && {
            geoLocation: [userPosition?.lat, userPosition?.lng]
          })
      };
      handleNextToModalLocation({
        ...values,
        selectedCountryId
      });
    };

    const handleOnFinishFailed = (values) => {
      if (isGeoLocationRequired && !userPosition) {
        return this.handleShowUserPositionError();
      }

      onFinishFailed(values);
    };

    return (
      <Form
        ref={formRef}
        fields={locationFields}
        onValuesChange={setFieldsValueState}
        className="br-location-contact-form"
        onFinish={onFinishLocationForm}
        onFinishFailed={handleOnFinishFailed}
      >
        <div className="br-location-form" ref={this.locationFormContainerRef}>
          <GoogleMapCard
            setUserPosition={this.setUserPosition}
            setUserAddress={this.setUserAddress}
            setResponse={this.LocationUsingGoogleMap}
            isMapUsed={isMapUsed}
            allAreas={areas}
            isGeoLocationRequired={isGeoLocationRequired}
            userPositionError={userPositionError}
          />
          <div>
            <Form.Item
              label={intl.formatMessage({ id: 'sign_up.form_labels.country' })}
            >
              <Select
                onChange={this.handleCountryChange}
                fieldNames={{
                  value: 'id',
                  label: isDashboardArabic() ? 'arabicName' : 'name'
                }}
                placeholder={intl.formatMessage({
                  id: 'settings.pickup_locations.pickup_location_form.form_placeholders.country_placeholder'
                })}
                options={
                  isCreatingInternationalLocation
                    ? getInternationalShippingDestinationCountries()
                    : COUNTRIES
                }
                value={selectedCountryId}
                disabled
              />
            </Form.Item>

            <BRCityArea
              googleMapInitialValue={googleMapInitialValue}
              name="districtId"
              formRef={formRef}
              setUserPosition={this.setUserPosition}
              onChange={onCityAreaChange}
              availability={AVAILABILITY.PICKUP}
              initialValue={initialValues?.address?.district?._id}
              areas={areas}
              setAreas={setAreas}
              selectedCountryId={selectedCountryId}
              isCreatingInternationalLocation={isCreatingInternationalLocation}
              hasForeignPhone={userInfo?.hasForeignPhone}
            />
            <BRAddressDetails initialValues={initialValues} formRef={formRef} />
            {!hideDefaultSetCheckbox && (
              <Form.Item
                className="br-location-form__set-location-default br-form-without-label"
                name="default"
                valuePropName="checked"
              >
                <Checkbox>
                  {intl.formatMessage({
                    id: 'settings.pickup_locations.pickup_location_form.mark_as_default'
                  })}
                </Checkbox>
              </Form.Item>
            )}
            {renderLocationNameField()}
          </div>
        </div>
        {activatePage ? (
          !hideSubmitButtons && (
            <>
              <Progress percent={progressPercent} showInfo={false} />
              <div
                className={classnames({
                  'br-activate__submit-button-sticky': isSmallMobileScreen
                })}
              >
                <BRButton
                  type={'primary'}
                  loading={isLoading}
                  suffixIcon={
                    <Icon className="ant-icon-md" component={ArrowRight} />
                  }
                  htmlType="submit"
                  label={intl.formatMessage({ id: 'login.actions.continue' })}
                  className="br-activate__submit-button br-registration-form-card__submit-btn button"
                />
              </div>
            </>
          )
        ) : (
          <div className="br-location-contact-form__footer__right-side-buttons">
            <BRButton
              type="basic"
              onClick={this.handleLocationFormCancel}
              label={intl.formatMessage({
                id: 'common.cancel'
              })}
            />
            <BRButton
              type="primary"
              htmlType="submit"
              loading={isFormLoading}
              disabled={isFormLoading}
              label={intl.formatMessage({
                id: pickupLocation
                  ? 'settings.pickup_locations.pickup_location_form.update_button'
                  : 'common.next'
              })}
            />
          </div>
        )}
      </Form>
    );
  }
}
export default injectIntl(mediaHook(LocationForm));
