import { useIntl } from 'react-intl';
import { useContext } from 'react';

import { ContextWrapper } from 'contexts/wrapper.context';
import { useModal } from 'contexts/modalProvider.context';

import BRButton from 'components/BRButton/BRButton';
import SuggestionsModal from 'components/SuggestionsModal/SuggestionsModal';

import { ReactComponent as LightBulb } from 'assets/bosta-icons/lightbulb.svg';

import './SuggestionBoxWrapper.less';

const SuggestionBoxWrapper = ({ children }) => {
  const { isFetchingData } = useContext(ContextWrapper);

  const { openModal } = useModal();
  const intl = useIntl();

  const handleOpenSuggestionModal = () => {
    openModal(SuggestionsModal);
  };

  const renderSuggestionButton = () => {
    if (isFetchingData) {
      return null;
    }

    return (
      <BRButton
        className="br-suggestion-box__button"
        type="primary"
        onClick={handleOpenSuggestionModal}
        prefixIcon={<LightBulb />}
        label={intl.formatMessage({ id: 'suggestion_box.suggest_now' })}
      />
    );
  };
  return (
    <>
      {children}
      {renderSuggestionButton()}
    </>
  );
};

export default SuggestionBoxWrapper;
