import { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import classNames from 'classnames';

import {
  YESTERDAY,
  LAST_SEVEN_DAYS,
  THIS_MONTH,
  THIS_QUARTER,
  THIS_YEAR,
  LAST_MONTH,
  LAST_QUARTER,
  LAST_YEAR,
  DATE_RANGES,
  DATE_FORMAT
} from 'constants/date-picker';
import { LOCALE } from 'constants/intl-wrapper';

import { ReactComponent as DatePickerIcon } from 'assets/imgRevamp/DatePickerIcon.svg';

import './BRDatePicker.less';

const BRDatePicker = ({
  intl,
  isActive,
  filterData,
  setFilterData,
  disabledDate,
  datePickerValue,
  header,
  initialFilterIndex,
  getFilterData,
  withOutRanges,
  fixedLabel,
  label,
  dropdownClassName,
  checkVisbilty,
  dateRangeToDate = false,
  customRanges = false
}) => {
  const [datePresetLabel, setDatePresetLabel] = useState(
    initialFilterIndex
      ? DATE_RANGES(dateRangeToDate)[initialFilterIndex].label
      : intl.formatMessage({ id: 'date_picker.select_date' })
  );
  const [isPanelVisible, setIsPanelVisible] = useState(false);
  const filterElements = document.getElementsByClassName('ant-tag-blue');
  const activeFilterClassName = 'br-date-picker__active-tag';

  useEffect(() => {
    if (!withOutRanges) {
      const element = initialFilterIndex
        ? Array.from(filterElements)?.find(
            ({ outerText }) =>
              outerText ===
              DATE_RANGES(dateRangeToDate)[initialFilterIndex].label
          )
        : '';
      let selectedFilter = false;
      DATE_RANGES(dateRangeToDate).map((value, index) => {
        const activeElement = document.getElementsByClassName(
          activeFilterClassName
        )[index];
        if (activeElement) {
          selectedFilter = true;
          return;
        }
      });
      if (!selectedFilter) {
        element?.classList?.add(activeFilterClassName);
      }
    }
  }, [isPanelVisible]);

  const datePickerHeader = () => {
    return <div className="br-date-picker__date-header">{header}</div>;
  };

  const checkTagColor = (formattedDateValues) => {
    setDatePresetLabel('');
    let startDate = filterData?.pickedUpAtStart;
    let endDate = filterData?.pickedUpAtEnd;
    if (formattedDateValues) {
      startDate = formattedDateValues[0];
      endDate = formattedDateValues[1];
    }
    DATE_RANGES(dateRangeToDate).forEach(({ value, label }) => {
      const element = Array.from(filterElements)?.find(
        ({ outerText }) => outerText === label
      );
      if (element) {
        if (
          value[0].format(DATE_FORMAT) === startDate &&
          value[1].format(DATE_FORMAT) === endDate
        ) {
          element.classList.add(activeFilterClassName);
          setDatePresetLabel(label);
        } else {
          element.classList.remove(activeFilterClassName);
        }
      }
    });
  };

  const onDateChanged = async (dateObjects, dateValues) => {
    const formattedDateValues = dateObjects.map((value) =>
      dayjs(value).format(DATE_FORMAT)
    );
    if (!withOutRanges) {
      checkTagColor(formattedDateValues);
    }
    const date = {
      pickedUpAtStart: formattedDateValues[0],
      pickedUpAtEnd: formattedDateValues[1]
    };
    setIsPanelVisible(false);
    setFilterData && setFilterData({ ...filterData, ...date });
    getFilterData && getFilterData({ ...filterData, ...date });
  };

  return (
    <div className="br-date-picker">
      <DatePicker.RangePicker
        disabled={!isActive}
        inputReadOnly
        className={classNames('br-date-picker__range-picker', {
          'br-date-picker__range-picker-preset':
            datePresetLabel.length || fixedLabel
        })}
        dropdownClassName={classNames(
          'br-date-picker__date-filter',
          dropdownClassName
        )}
        panelRender={(node) => (
          <>
            {<div>{datePickerHeader()}</div>}
            {node}
          </>
        )}
        getPopupContainer={(trigger) =>
          trigger.parentElement.parentElement.parentElement.parentElement
            .parentElement.parentElement.parentElement.parentElement
            .parentElement
        }
        allowClear={false}
        ranges={
          customRanges
            ? customRanges
            : !withOutRanges && intl.locale === LOCALE.AR
            ? {
                أمس: YESTERDAY,
                'آخر 7 أيام': LAST_SEVEN_DAYS,
                'هذا الشهر': THIS_MONTH,
                'هذا الربع': THIS_QUARTER,
                'هذا العام': THIS_YEAR,
                'الشهر الماضي': LAST_MONTH,
                'الربع الأخير': LAST_QUARTER,
                'العام الماضي': LAST_YEAR
              }
            : !withOutRanges && {
                Yesterday: YESTERDAY,
                'Last 7 days': LAST_SEVEN_DAYS,
                'This month': THIS_MONTH,
                'This quarter': THIS_QUARTER,
                'This year': THIS_YEAR,
                'Last month': LAST_MONTH,
                'Last quarter': LAST_QUARTER,
                'Last year': LAST_YEAR
              }
        }
        disabledDate={disabledDate}
        onChange={onDateChanged}
        onOpenChange={(visible) => {
          setIsPanelVisible(visible);
          if (checkVisbilty) {
            checkVisbilty(visible);
          }
        }}
        value={datePickerValue}
        format={!datePresetLabel.length && 'D MMM, YYYY'}
        suffixIcon={
          <div className="br-date-picker__range-picker-preset__suffix-container">
            <DatePickerIcon />
            <span className="br-date-picker__date-filter__text">
              {fixedLabel ? label : datePresetLabel}
            </span>
          </div>
        }
        separator={'-'}
      />
    </div>
  );
};

export default injectIntl(BRDatePicker);
