import React, { useState, useContext } from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import classnames from 'classnames';
import { mediaHook } from 'customHooks';
import dayjs from 'dayjs';

import { TYPE_CLASS_NAMES } from 'constants/header';
import {
  sendVerificationCode,
  setShowBanner,
  getShowBanner,
  updateFeatureConfiguration
} from 'services/auth';
import { useModal } from 'contexts/modalProvider.context';
import { ContextWrapper } from 'contexts/wrapper.context';
import { isUserFullyActivated } from 'utils/features-access';
import { sendSegment } from 'utils/segment';
import { DATE_FORMAT } from 'constants/analytics';

import { notify } from 'components/Notify/Notify';
import BRButton from 'components/BRButton/BRButton';
import ChangeEmailModal from 'components/BRModals/ChangeEmailModal/ChangeEmailModal';

import { ReactComponent as CloseIcon } from 'assets/bosta-icons/Close.svg';
import { ReactComponent as Warning } from 'assets/bosta-icons/alert-circle.svg';
import { ReactComponent as AlertIcon } from 'assets/bosta-icons/alert-triangle.svg';

import './Banner.less';

export const BannerContent = ({
  icon,
  title,
  subText,
  buttonProps,
  secondButtonProps,
  buttonText,
  secondButtonText,
  className,
  containerClassName,
  type = 'warning',
  dismissIcon = false,
  onDismissIconClick,
  changeButtonToText = false,
  extraRender,
  bannerFixed = false,
  isSmallMobileScreen,
  extraImage = false,
  isRestoreAccount = false
}) => {
  const typeClass = TYPE_CLASS_NAMES[type.toLowerCase()];

  const actionSection = (
    <div
      className={classnames(
        'br-banner-content__buttons-container',
        containerClassName,
        {
          'br-banner-content__button-text': changeButtonToText
        }
      )}
    >
      {secondButtonText && (
        <BRButton {...secondButtonProps} label={secondButtonText} />
      )}
      {buttonText && <BRButton {...buttonProps} label={buttonText} />}
    </div>
  );

  return (
    <div className="br-banner-content__parent">
      <div
        className={classnames('br-banner-content', typeClass, className, {
          'br-banner-fixed': bannerFixed
        })}
      >
        <div
          className={classnames(
            'br-banner-content__inner-container display-flex',
            { 'br-banner-content__inner-container-no-subtitle': !subText }
          )}
        >
          <div className="br-banner-content__text-container">
            {icon}
            <div className="br-banner-content__text">
              <div className="display-flex">
                <div
                  className={`br-banner-content__text-title ${
                    isRestoreAccount ? 'body' : 'body-medium'
                  }`}
                >
                  {title}
                </div>
                <div className="br-banner-content__text__dismiss-icon-responsive">
                  {dismissIcon && (
                    <BRButton
                      suffixIcon={<CloseIcon />}
                      onClick={onDismissIconClick}
                      className="br-banner-content__text__dismiss-icon button-md"
                      type="treitary-gray"
                    />
                  )}
                </div>
              </div>
              {subText && (
                <div className="br-banner-content__text-subtitle body">
                  {subText}
                </div>
              )}
            </div>
          </div>
          <div className="display-flex">
            {(buttonText || secondButtonText) &&
              bannerFixed &&
              !isSmallMobileScreen &&
              actionSection}
            {dismissIcon && (
              <BRButton
                suffixIcon={<CloseIcon />}
                onClick={onDismissIconClick}
                className="br-banner-content__dismiss-icon button-md"
                type="treitary-gray"
              />
            )}
          </div>
        </div>
        {(buttonText || secondButtonText) &&
          (!bannerFixed || isSmallMobileScreen) &&
          actionSection}
        {extraRender && extraRender}
      </div>
      {extraImage && (
        <img
          className="br-banner-content__extra-image"
          src={extraImage}
          alt=""
        />
      )}
    </div>
  );
};

const Banner = ({ intl, history, isLoading, mobileScreenSizes }) => {
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const businessId = userInfo?.user?.businessAdminInfo?.businessId;

  const [email, setEmail] = useState(userInfo?.user?.emails[0]?.address);
  const [hideVerifyEmailBanner, setHideVerifyEmailBanner] = useState(
    getShowBanner(businessId, 'showVerifyEmailBanner')
  );
  const [hideMoyasarBanner, setHideMoyasarBanner] = useState(
    localStorage.getItem('hideMoyasarBanner')
  );
  const [changeButtonText, setChangeButtonText] = useState(false);
  const [
    isLoadingSendingVerificationCode,
    setIsLoadingSendingVerificationCode
  ] = useState(false);

  const { businessInfo, setBusinessInfo } = useContext(ContextWrapper);
  const { openModal } = useModal();

  const { isSmallMobileScreen } = mobileScreenSizes;

  const bankInfoBannerWithDismiss =
    dayjs(businessInfo?.fullyActivatedAt).add(2, 'day') <= dayjs() &&
    !businessInfo?.featuresConfigurations?.bankInfoBannerDismissedAt;

  const bankInfoBannerWithoutDismiss =
    dayjs(businessInfo?.featuresConfigurations?.bankInfoBannerDismissedAt).add(
      5,
      'day'
    ) <= dayjs();

  const handleGoToPaymentPage = () => {
    history.push({
      pathname: '/settings/payment-info',
      state: {
        showOtpModal: true,
        source: `Banner ${bankInfoBannerWithDismiss ? '1' : '2'}`
      }
    });
  };

  const handleDismissBankDetails = async () => {
    try {
      const currentDate = dayjs().format(DATE_FORMAT);
      await updateFeatureConfiguration({
        bankInfoBannerDismissedAt: currentDate
      });
      setBusinessInfo({
        ...businessInfo,
        featuresConfigurations: {
          ...businessInfo?.featuresConfigurations,
          bankInfoBannerDismissedAt: currentDate
        }
      });
      sendSegment('Bank_Details_Banner1_Dismiss');
    } catch (error) {
      notify({ msg: error.message, error });
    }
  };

  const handleSendVerificationCodeClick = async () => {
    try {
      await sendVerificationCode({ email: email });
      setChangeButtonText(true);
      setIsLoadingSendingVerificationCode(true);
      setShowBanner(businessId, 'showVerifyEmailBanner');
      notify({
        msg: intl.formatMessage({
          id: 'sign_up.email_verification.activation_sent'
        }),
        type: 'success'
      });
      setTimeout(() => {
        setHideVerifyEmailBanner(true);
      }, 3000);
    } catch (error) {
      notify({ msg: error.message, error });
    }
  };

  const priorityArray = [
    {
      priority: 0,
      component: (
        <BannerContent
          icon={<Warning />}
          type="warning"
          bannerFixed
          title={
            <div>
              {intl.formatMessage(
                {
                  id: 'banner.email_verification.title'
                },
                {
                  email: (
                    <span className="br-banner-email-address">{email}</span>
                  )
                }
              )}
            </div>
          }
          secondButtonText={intl.formatMessage({
            id: 'sign_up.email_verification.change_email'
          })}
          secondButtonProps={{
            onClick: () => {
              openModal(ChangeEmailModal, {
                handleSendVerificationCodeClick,
                onSuccess: (email) => {
                  setEmail(email);
                }
              });
            },
            type: 'treitary-gray',
            className: 'button'
          }}
          buttonText={
            !changeButtonText
              ? intl.formatMessage({
                  id: 'banner.email_verification.button_text'
                })
              : intl.formatMessage({
                  id: 'banner.email_verification.code_sent'
                })
          }
          buttonProps={{
            disabled: isLoadingSendingVerificationCode,
            onClick: handleSendVerificationCodeClick,
            type: 'basic',
            className: 'button'
          }}
          dismissIcon
          onDismissIconClick={() => setHideVerifyEmailBanner(true)}
          changeButtonToText={isLoadingSendingVerificationCode}
          isSmallMobileScreen={isSmallMobileScreen}
        />
      ),
      show:
        !userInfo?.user?.emails[0].verified &&
        !hideVerifyEmailBanner &&
        !isLoading
    },
    isUserFullyActivated && {
      priority: 1,
      component: (
        <BannerContent
          icon={bankInfoBannerWithDismiss ? <Warning /> : <AlertIcon />}
          type={bankInfoBannerWithDismiss ? 'warning' : 'danger'}
          bannerFixed
          containerClassName="br-banner__bank-info-actions"
          title={intl.formatMessage({
            id: `banner.bank_details.title${
              bankInfoBannerWithDismiss ? '' : '_danger'
            }`
          })}
          buttonText={
            bankInfoBannerWithDismiss &&
            intl.formatMessage({
              id: 'banner.bank_details.dismiss_button_text'
            })
          }
          buttonProps={
            bankInfoBannerWithDismiss && {
              onClick: handleDismissBankDetails,
              type: 'link-gray',
              className: 'button'
            }
          }
          secondButtonText={intl.formatMessage({
            id: 'banner.bank_details.button_text'
          })}
          secondButtonProps={{
            onClick: handleGoToPaymentPage,
            type: 'basic',
            className: 'button'
          }}
          isSmallMobileScreen={isSmallMobileScreen}
        />
      ),
      show:
        !businessInfo.bankInfo &&
        (bankInfoBannerWithDismiss || bankInfoBannerWithoutDismiss)
    }
  ];

  const priorityArraySorted = priorityArray.sort((a, b) => {
    const x = a['priority'];
    const y = b['priority'];
    return x < y ? 1 : x > y ? -1 : 0;
  });

  let index = 0;
  let notFound = true;
  while (notFound) {
    if (index >= priorityArraySorted.length) {
      return null;
    }
    if (priorityArraySorted[index].show) {
      notFound = false;
    } else {
      index++;
    }
  }

  return priorityArraySorted[index].component;
};

export default mediaHook(injectIntl(withRouter(Banner)));
