import { Tag } from 'antd';
import classnames from 'classnames';

import './BRTag.less';

// gray, teal, blue, green, red, yellow, black

const BRTag = ({ children, showDot, color = 'gray' }) => {
  return (
    <Tag className={classnames('br-tag', `br-tag-${color}`, 'caption')}>
      {showDot && <span className="br-tag-dot" />}
      {children}
    </Tag>
  );
};

export default BRTag;
