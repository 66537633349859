import { COUNTRIES } from 'constants/country-data';

export const MIN_KEY_CODE = 9;
export const MAX_KEY_CODE = 13;
export const MAX_INPUT_LENGTH = 25;
export const NUMBER_TEXT = 'NUMBER_TEXT';
export const PHONE_NUMBER = 'PHONE_NUMBER';
export const ALPHA_NUMERIC = 'ALPHA_NUMERIC';
export const TEXT = 'text';
export const phoneRegExpEgypt = /^1[0-2|5]{1}[0-9]{8}$/;
export const phoneRegExpSaudi = /^(\+9665|05|5)([013-9][0-9]{7})$/;
export const phoneRegExpSaudiWithoutCountryCode = /^(05|5)([013-9][0-9]{7})$/;
export const phoneRegExpEgyptWithZero = /^01[0-2|5]{1}[0-9]{8}$/;
export const phoneRegExpSaudiLanLineAndPhone =
  /^(\+9665|05|5)([013-9][0-9]{7})|^[0-9]{9}$/;

export const PHONE_NUMBER_MAX_LENGHT =
  JSON.parse(localStorage.getItem('userInfo'))?.user?.country?.code ===
  COUNTRIES[1].codeName
    ? 20
    : 11;

export const FIXED_LINE = 0;

// phoneRegExpSaudiLanLineAndPhone /^(5)[013-9]|(01[1-4|6-7])([0-9]{7})$/;

export const AT_LEAST_EIGHT_LETTERS =
  /^[~`!@#$%^&*()_+=[\]\\{}|;':",.\/<>?a-zA-Z0-9-]{8,}$/;
export const AT_LEAST_ONE_DIGIT = /(?=.*\d)/;
export const AT_LEAST_ONE_UPPERCASE = /[A-Z]/;
export const AT_LEAST_ONE_SPECIAL_CHAR = /(?=.*(\W|_))/;
export const INCLUDES_DIGITS_REGEX = /^\d+$/;

export const STORE_LINK_REGEX = /^[a-zA-Z0-9-]*$/;

export const PRICE_INPUT_REGEX = /^[1-9]+\d*(\.\d{0,2})?$/;
