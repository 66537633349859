import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import { fmt } from 'components/IntlWrapper/IntlWrapper';

dayjs.extend(quarterOfYear);

export const IS_TODAY_START_OF_MONTH =
  dayjs().format('YYYY-MM-DD') ===
  dayjs().startOf('month').format('YYYY-MM-DD');

export const TODAY = [dayjs(), dayjs()];

export const YESTERDAY = [
  dayjs().subtract(1, 'days'),
  dayjs().subtract(1, 'days')
];
export const LAST_SEVEN_DAYS = [
  dayjs().subtract(7, 'days'),
  dayjs().subtract(1, 'days')
];

export const THIS_WEEK = [dayjs().startOf('week'), dayjs().subtract(1, 'days')];
export const THIS_WEEK_TO_DATE = [dayjs().startOf('week'), dayjs()];

export const LAST_WEEK = [
  dayjs().subtract(1, 'week').startOf('week'),
  dayjs().subtract(1, 'week').endOf('week')
];

export const THIS_MONTH = IS_TODAY_START_OF_MONTH
  ? [
      dayjs().startOf('month').subtract(1, 'month'),
      dayjs().subtract(1, 'month').endOf('month'),
      5
    ]
  : [dayjs().startOf('month'), dayjs().subtract(1, 'days'), 5];

export const THIS_MONTH_TO_DATE = IS_TODAY_START_OF_MONTH
  ? [
      dayjs().startOf('month').subtract(1, 'month'),
      dayjs().subtract(1, 'month').endOf('month'),
      5
    ]
  : [dayjs().startOf('month'), dayjs(), 5];

export const THIS_QUARTER = [
  dayjs().startOf('quarter'),
  dayjs().subtract(1, 'days'),
  6
];

export const THIS_QUARTER_TO_DATE = [dayjs().startOf('quarter'), dayjs(), 6];

export const THIS_YEAR = [
  dayjs().startOf('year'),
  dayjs().subtract(1, 'days'),
  7
];

export const THIS_YEAR_TO_DATE = [dayjs().startOf('year'), dayjs(), 7];

export const LAST_MONTH = IS_TODAY_START_OF_MONTH
  ? [
      dayjs().startOf('month').subtract(2, 'months'),
      dayjs().subtract(2, 'month').endOf('month')
    ]
  : [
      dayjs().startOf('month').subtract(1, 'months'),
      dayjs().subtract(1, 'month').endOf('month')
    ];
export const LAST_QUARTER = [
  dayjs().startOf('quarter').subtract(1, 'quarter'),
  dayjs().endOf('quarter').subtract(1, 'quarter')
];
export const LAST_YEAR = [
  dayjs().startOf('year').subtract(1, 'year'),
  dayjs().endOf('year').subtract(1, 'year')
];

export const DATE_FORMAT = 'YYYY-MM-DD';

export const DATE_RANGES = (toDate) => [
  {
    label: fmt({ id: `date_picker.filter_option.today` }),
    value: TODAY
  },
  {
    label: fmt({ id: `date_picker.filter_option.yesterday` }),
    value: YESTERDAY
  },
  {
    label: fmt({ id: `date_picker.filter_option.this_week` }),
    value: toDate ? THIS_WEEK_TO_DATE : THIS_WEEK
  },
  {
    label: fmt({ id: `date_picker.filter_option.last_week` }),
    value: LAST_WEEK
  },
  {
    label: fmt({ id: `date_picker.filter_option.last_seven_days` }),
    value: LAST_SEVEN_DAYS
  },
  {
    label: fmt({ id: `date_picker.filter_option.this_month` }),
    value: toDate ? THIS_MONTH_TO_DATE : THIS_MONTH
  },
  {
    label: fmt({ id: `date_picker.filter_option.this_quarter` }),
    value: toDate ? THIS_QUARTER_TO_DATE : THIS_QUARTER
  },
  {
    label: fmt({ id: `date_picker.filter_option.this_year` }),
    value: toDate ? THIS_YEAR_TO_DATE : THIS_YEAR
  },
  {
    label: fmt({ id: `date_picker.filter_option.last_month` }),
    value: LAST_MONTH
  },
  {
    label: fmt({ id: `date_picker.filter_option.last_quarter` }),
    value: LAST_QUARTER
  },
  {
    label: fmt({ id: `date_picker.filter_option.last_year` }),
    value: LAST_YEAR
  }
];
