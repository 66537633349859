import React from 'react';
import { Modal } from 'antd';
import Icon from '@ant-design/icons';
import { injectIntl } from 'react-intl';

import BRButton from 'components/BRButton/BRButton';

import { ReactComponent as Danger } from 'assets/bosta-icons/alert-triangle.svg';
import { ReactComponent as Close } from 'assets/bosta-icons/Close.svg';

import './CreateOrderModal.less';

const CreateOrderModal = ({ intl, close, ...props }) => {
  const handleCreateOrder = () => {
    window.location.href = '/create-order';
    close();
  };
  return (
    <Modal
      centered
      title={null}
      footer={null}
      width={null}
      onCancel={close}
      wrapClassName="br-create-order-modal"
      {...props}
    >
      <div className="br-create-order-modal__container">
        <Icon
          component={Danger}
          className="br-create-order-modal__icon ant-icon-xl"
        />
        <Icon
          onClick={close}
          component={Close}
          className="br-create-order-modal__dismiss-icon ant-icon-md"
        />
        <div className="br-create-order-modal__title display-sm">
          {intl.formatMessage({ id: 'pickups.create_order_modal.title' })}
        </div>
        <div className="br-create-order-modal__subtitle">
          {intl.formatMessage(
            { id: 'pickups.create_order_modal.subtitle' },
            {
              span: (children) => (
                <span className="body-medium">{children}</span>
              )
            }
          )}
        </div>
        <BRButton
          onClick={handleCreateOrder}
          type="primary"
          label={intl.formatMessage({
            id: 'pickups.create_order_modal.create_order'
          })}
        />
      </div>
    </Modal>
  );
};

export default injectIntl(CreateOrderModal);
