import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/ie11'; // For IE 11 support
import 'polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import enUS from 'antd/lib/locale/en_US';
import arEG from 'antd/lib/locale/ar_EG';
import dayjs from 'dayjs';
import 'dayjs/locale/ar';

import { getLocaleFromLocalStorage } from 'utils/intl-wrapper';
import { LOCALE } from 'constants/intl-wrapper';

import App from 'App';
import { store } from 'store/configureStore';
import IntlWrapper, { intl } from 'components/IntlWrapper/IntlWrapper';
import { WrapperProvider } from 'contexts/wrapper.context';
import { ModalProvider } from 'contexts/modalProvider.context';

import SegmentInit from 'SegmentInit';
import * as serviceWorker from 'serviceWorker';
import UpdateLocale from 'dayjs/plugin/updateLocale';
import utc from 'dayjs/plugin/utc';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import timezone from 'dayjs/plugin/timezone';
import relativeTime from 'dayjs/plugin/relativeTime';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import tz from 'dayjs/plugin/timezone';

const validateMessages = {
  required: intl.formatMessage({ id: 'form.required' }),
  string: {
    max: intl.formatMessage({ id: 'form.maxLen' })
  },
  whitespace: intl.formatMessage({ id: 'form.required' })
};

dayjs.extend(UpdateLocale);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);
dayjs.extend(quarterOfYear);
dayjs.extend(tz);
dayjs.extend(isToday);
dayjs.extend(isYesterday);
dayjs.extend(isTomorrow);

dayjs.updateLocale('ar', {
  meridiem: (hour, minute, isLowercase) => {
    return hour > 12 ? 'م' : 'ص';
  }
});
const locale = getLocaleFromLocalStorage();
const getLocale = () => {
  dayjs.locale(locale);
  if ([LOCALE.AR, LOCALE.AR_SA].includes(locale)) {
    return arEG;
  } else {
    return enUS;
  }
};

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <IntlWrapper>
        <ConfigProvider
          // direction='rtl'
          locale={getLocale()}
          getPopupContainer={(trigger) =>
            trigger?.parentElement || document.body
          }
          form={{
            validateMessages,
            scrollToFirstError: true
          }}
        >
          {SegmentInit()}
          <WrapperProvider>
            <ModalProvider>
              <App />
            </ModalProvider>
          </WrapperProvider>
        </ConfigProvider>
      </IntlWrapper>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
