import messages from 'messages';
import { LOCALE, LOCAL_STORAGE_LOCALE_KEY } from 'constants/intl-wrapper';
import { USER_COUNTRY_CODE } from 'constants/helpers';
import { COUNTRIES } from 'constants/country-data';

export const flattenMessages = (nestedMessages = messages.en, prefix = '') => {
  return Object.keys(nestedMessages).reduce((messages, key) => {
    const value = nestedMessages[key];
    const prefixedKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === 'string') {
      Object.assign(messages, { [prefixedKey]: value });
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey));
    }

    return messages;
  }, {});
};

export function getLocaleFromLocalStorage() {
  let language = localStorage.getItem(LOCAL_STORAGE_LOCALE_KEY);
  if (USER_COUNTRY_CODE === COUNTRIES[1].codeName) {
    if (!language || language === LOCALE.AR) {
      language = LOCALE.AR_SA;
      setLocaleInLocalStorage(LOCALE.AR_SA);
    }
  } else {
    if (language) {
      language =
        language === LOCALE.AR_SA || !Object.values(LOCALE).includes(language)
          ? LOCALE.AR
          : language;
    } else {
      language = LOCALE.AR;
    }
    setLocaleInLocalStorage(language);
  }
  return language;
}

export function setLocaleInLocalStorage(locale) {
  if (USER_COUNTRY_CODE === COUNTRIES[1].codeName && locale === LOCALE.AR) {
    localStorage.setItem(LOCAL_STORAGE_LOCALE_KEY, LOCALE.AR_SA);
  }
  localStorage.setItem(LOCAL_STORAGE_LOCALE_KEY, locale);
}

export function changeDocumentDirection(locale) {
  if ([LOCALE.AR, LOCALE.AR_SA].includes(locale)) {
    document.dir = 'rtl';
  } else {
    document.dir = 'ltr';
  }
}

export function formatNumbersBasedOnLanguage(value, specificLocale = false) {
  const locale = isDashboardArabic() ? 'ar-EG' : LOCALE.EN;
  const formattedNumber = new Intl.NumberFormat(
    specificLocale ? specificLocale : locale
  ).format(value);

  if (locale === 'ar-EG') {
    return formattedNumber.replace('٫', '.');
  }

  return formattedNumber;
}

export const isDashboardArabic = () =>
  [LOCALE.AR, LOCALE.AR_SA].includes(getLocaleFromLocalStorage());
