import React, { useEffect, useRef, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Form, Checkbox } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { sendSegment } from 'utils/segment';
import { emailRule, phoneNumberRule } from 'utils/forms';
import { PHONE_PLACEHOLDER } from 'constants/helpers';
import { PHONE_NUMBER, TEXT } from 'constants/Forms';
import { ALL_COUNTRIES } from 'constants/country-data';

import BRButton from 'components/BRButton/BRButton';
import BRFormInputs from 'components/BRFormInputs/BRFormInputs';
import { notify } from 'components/Notify/Notify';

const ContactForm = (props) => {
  const {
    intl,
    handleSubmit,
    setFieldsValueState,
    contactsFormData,
    fields,
    close,
    onFinishFailed,
    addContact,
    submitLabel = false,
    showCancel = true,
    showSetDefaultCheckbox = true,
    selectedCountryId
  } = props;

  const [addSecPhoneClicked, setAddSecPhoneClicked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const contactsFormRef = useRef();

  const handleContactsFormCancel = () => {
    contactsFormRef.current.resetFields();
    sendSegment('E_PICKUP_NEW_LOCATION_CONTACT_CANCELED');
    close();
  };

  useEffect(() => {
    if (contactsFormData)
      contactsFormRef.current.setFieldsValue({
        firstName: contactsFormData.firstName,
        lastName: contactsFormData.lastName,
        email: contactsFormData.email,
        phone: contactsFormData.phone,
        secPhone: contactsFormData.secPhone
      });
  }, [contactsFormData]);

  const onAddSecPhoneClicked = () => {
    setAddSecPhoneClicked(true);
  };

  const getSelectedCountry = () => {
    if (selectedCountryId) {
      return ALL_COUNTRIES.find((country) => country.id === selectedCountryId);
    }
  };

  const handleOnFinish = async (values) => {
    setIsLoading(true);

    try {
      await handleSubmit(values);
    } catch (error) {
      notify({ msg: error.message, error });
    }

    setIsLoading(false);
  };

  return (
    <Form
      ref={contactsFormRef}
      fields={fields}
      onValuesChange={setFieldsValueState}
      onFinish={handleOnFinish}
      onFinishFailed={onFinishFailed}
    >
      <div className="br-contact-form">
        <div className="br-form-row">
          <BRFormInputs
            type={TEXT}
            formRef={contactsFormRef}
            maxCount={100}
            name="firstName"
            label={intl.formatMessage({ id: 'contact_form_labels.first_name' })}
            rules={[
              {
                required: true,
                max: 100
              }
            ]}
            inputTitle={intl.formatMessage({
              id: 'contact_form_labels.first_name'
            })}
          />
          <BRFormInputs
            type={TEXT}
            formRef={contactsFormRef}
            maxCount={100}
            name="lastName"
            label={intl.formatMessage({ id: 'contact_form_labels.last_name' })}
            rules={[
              {
                required: true,
                max: 100
              }
            ]}
            inputTitle={intl.formatMessage({
              id: 'contact_form_labels.last_name'
            })}
          />
        </div>
        <div className="br-form-row">
          <BRFormInputs
            type={PHONE_NUMBER}
            formRef={contactsFormRef}
            name="phone"
            label={intl.formatMessage({ id: 'contact_form_labels.phone' })}
            rules={[
              { required: true },
              phoneNumberRule({
                message: intl.formatMessage({
                  id: 'form.phone_not_valid'
                }),
                internationlNumbers: true,
                country: getSelectedCountry()
              })
            ]}
            inputTitle={intl.formatMessage({
              id: 'contact_form_labels.phone'
            })}
            placeholder={intl.formatMessage({
              id: PHONE_PLACEHOLDER
            })}
          />
        </div>
        {addSecPhoneClicked ? (
          <div className="br-form-row">
            <BRFormInputs
              type={PHONE_NUMBER}
              formRef={contactsFormRef}
              name="secPhone"
              rules={[
                phoneNumberRule({
                  message: intl.formatMessage({
                    id: 'form.phone_not_valid'
                  }),
                  landlineNumbers: true,
                  country: getSelectedCountry()
                })
              ]}
              optional
              inputTitle={intl.formatMessage({
                id: 'contact_form_labels.secondary_phone'
              })}
              placeholder={intl.formatMessage({
                id: PHONE_PLACEHOLDER
              })}
            />
          </div>
        ) : (
          <>
            <BRButton
              type="link-color"
              prefixIcon={<PlusOutlined />}
              label={intl.formatMessage({
                id: `contact_form_labels.add_number`
              })}
              onClick={onAddSecPhoneClicked}
            />
          </>
        )}
        <div className="br-form-row">
          <BRFormInputs
            type={TEXT}
            formRef={contactsFormRef}
            name="email"
            rules={[
              emailRule(
                intl.formatMessage({
                  id: 'form.email_not_valid'
                })
              )
            ]}
            optional
            inputTitle={intl.formatMessage({
              id: 'contact_form_labels.email'
            })}
            placeholder={intl.formatMessage({
              id: 'settings.pickup_locations.pickup_location_form.form_placeholders.email'
            })}
          />
        </div>
        {showSetDefaultCheckbox && (
          <Form.Item
            className="br-contact-form__set-default"
            name="isDefault"
            valuePropName="checked"
          >
            <Checkbox>
              {intl.formatMessage({
                id: `contact_form_labels.set_default_contact`
              })}
            </Checkbox>
          </Form.Item>
        )}
      </div>
      <div className="br-location-contact-form__footer__right-side-buttons">
        {showCancel && (
          <BRButton
            type="basic"
            onClick={handleContactsFormCancel}
            label={intl.formatMessage({
              id: 'common.cancel'
            })}
          />
        )}
        <BRButton
          type="primary"
          htmlType="submit"
          loading={isLoading}
          label={
            submitLabel ||
            intl.formatMessage({
              id: addContact
                ? 'settings.pickup_locations.pickup_location_form.contact_person_title'
                : 'settings.pickup_locations.actions.create_button'
            })
          }
        />
      </div>
    </Form>
  );
};
export default injectIntl(ContactForm);
