import EmptyCatalogTable from 'assets/imgRevamp/SllrEmptyCatalogTable.png';
import EmptyCatalogTableAR from 'assets/imgRevamp/SllrEmptyCatalogTableAr.png';
import EmptyCatalogTableMobile from 'assets/imgRevamp/EmptyCatalogTableMobile.png';
import EmptyCatalogTableMobileAR from 'assets/imgRevamp/EmptyCatalogTableMobileAR.png';
import EmptyCatalogTableSaudi from 'assets/imgRevamp/SllrEmptyCatalogTableSaudi.png';
import EmptyCatalogTableSaudiAR from 'assets/imgRevamp/SllrEmptyCatalogTableSaudiAR.png';
import EmptyCatalogTableSaudiMobile from 'assets/imgRevamp/EmptyCatalogTableMobileSaudi.png';
import EmptyCatalogTableSaudiMobileAR from 'assets/imgRevamp/EmptyCatalogTableMobileSaudiAR.png';
import AdjacentCard1 from 'assets/imgRevamp/SllrAdjacentCard1.png';
import { ReactComponent as AdjacentCard2 } from 'assets/imgRevamp/SllrAdjacentCard2.svg';
import AdjacentCard1Saudi from 'assets/imgRevamp/SllrAdjacentCard1Saudi.png';
import { ReactComponent as AdjacentCard2Saudi } from 'assets/imgRevamp/SllrAdjacentCard2Saudi.svg';
import EmptyCard1 from 'assets/imgRevamp/SllrEmptyCard1.png';
import EmptyCard1Saudi from 'assets/imgRevamp/SllrEmptyCard1Saudi.png';

export const IMAGES_LIST = {
  EG: {
    catalog_table: EmptyCatalogTable,
    catalog_tableAR: EmptyCatalogTableAR,
    catalog_table_mobile: EmptyCatalogTableMobile,
    catalog_tableAR_mobile: EmptyCatalogTableMobileAR,
    sllr_store_table: AdjacentCard1,
    sllr_store_analytics: <AdjacentCard2 />,
    empty_card1: EmptyCard1
  },
  SA: {
    catalog_table: EmptyCatalogTableSaudi,
    catalog_tableAR: EmptyCatalogTableSaudiAR,
    catalog_table_mobile: EmptyCatalogTableSaudiMobile,
    catalog_tableAR_mobile: EmptyCatalogTableSaudiMobileAR,
    sllr_store_table: AdjacentCard1Saudi,
    sllr_store_analytics: <AdjacentCard2Saudi />,
    empty_card1: EmptyCard1Saudi
  },
  AE: {
    catalog_table: EmptyCatalogTableSaudi,
    catalog_tableAR: EmptyCatalogTableSaudiAR,
    catalog_table_mobile: EmptyCatalogTableSaudiMobile,
    catalog_tableAR_mobile: EmptyCatalogTableSaudiMobileAR,
    sllr_store_table: AdjacentCard1Saudi,
    sllr_store_analytics: <AdjacentCard2Saudi />,
    empty_card1: EmptyCard1Saudi
  }
};
