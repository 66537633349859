import {
  SET_NEW_NOTIFICATION,
  SET_READ_NOTIFICATION
} from 'actions/actionTypes';

const initialState = {
  newNotification: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_NEW_NOTIFICATION:
      return {
        ...state,
        newNotification: true
      };
    case SET_READ_NOTIFICATION:
      return {
        ...state,
        newNotification: false
      };

    default:
      return state;
  }
}
