import { bostaHttp } from 'http.js';

export const fetchTeamsAndMembersList = (businessId, payload) => {
  return bostaHttp.get(`/businesses/${businessId}/users`, payload);
};

export const featchGroupMembers = () => {
  return bostaHttp.get(`/auth/groups`);
};

export const createNewMamber = (businessId, payload) => {
  return bostaHttp.post(`/businesses/${businessId}/invite`, payload);
};

export const acceptInvitation = (payload) => {
  return bostaHttp.post(`/users/accept-invitation`, payload);
};

export const resendCode = (payload) => {
  return bostaHttp.post(`/users/business-admin/phone/verify`, payload);
};

export const verifyCode = (payload) => {
  return bostaHttp.post(`/users/business-admin/phone/confirm`, payload);
};

export const verifyAcceptInvitation = (payload) => {
  return bostaHttp.get(`/users/accept-invitation`, payload);
};

export const deletedMember = (id) => {
  return bostaHttp.delete(`/users/business/admin/${id}`, null);
};

export const reInviteMember = (businessId, userId) => {
  return bostaHttp.get(`/businesses/${businessId}/re-invite/${userId}`, null);
};

export const getUserPermission = (userId) => {
  return bostaHttp.get(`/users/${userId}`, null);
};

export const updatePermission = (businessId, data) => {
  return bostaHttp.put(`/businesses/${businessId}/users`, data);
};
