import { useRef } from 'react';

import SignUpHeader from 'components/NewSignUp/components/SignUpHeader/SignUpHeader';
import SignUp from 'components/NewSignUp/components/SignUp/SignUp';
import SignUpPricing from 'components/NewSignUp/components/SignUpPricing/SignUpPricing';
import SignUpPartners from 'components/NewSignUp/components/SignUpPartners/SignUpPartners';

import './NewSignUp.less';

const NewSignUp = ({ country, setCountry }) => {
  const signUpPricingSectionRef = useRef();

  return (
    <div className="br-new-signup__container">
      <SignUpHeader />
      <SignUp
        signUpPricingSectionRef={signUpPricingSectionRef}
        country={country}
        setCountry={setCountry}
      />
      <SignUpPricing ref={signUpPricingSectionRef} />
      <SignUpPartners />
    </div>
  );
};

export default NewSignUp;
