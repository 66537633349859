import { isRegisteredViaBetaFunnel } from 'utils/helpers';

import SignUpPricingTableHeader from 'components/NewSignUp/components/SignUpPricing/components/SignUpPricingTableHeader/SignUpPricingTableHeader';
import { fmt } from 'components/IntlWrapper/IntlWrapper';

import { ReactComponent as Deliver } from 'assets/bosta-icons/Deliver.svg';
import { ReactComponent as Fulfillment } from 'assets/bosta-icons/fullfilment.svg';
import { ReactComponent as Product } from 'assets/bosta-icons/Product.svg';
import { ReactComponent as International } from 'assets/bosta-icons/international-shipping-colored.svg';
import { ReactComponent as OutForDelivery } from 'assets/bosta-icons/Out for Delivery.svg';
import { ReactComponent as BankNote } from 'assets/bosta-icons/bank-note.svg';
import { ReactComponent as Pickups } from 'assets/bosta-icons/Pickups.svg';
import { ReactComponent as Inventory } from 'assets/bosta-icons/Inventory.svg';
import { ReactComponent as MobileApps } from 'assets/bosta-icons/MobileApps.svg';
import { ReactComponent as Plugins } from 'assets/bosta-icons/Plugins.svg';
import { ReactComponent as Palma } from 'assets/imgRevamp/Palma-logo.svg';
import { ReactComponent as Amazon } from 'assets/imgRevamp/Amazon-logo.svg';
import { ReactComponent as Emma } from 'assets/imgRevamp/Emma-logo.svg';
import { ReactComponent as Fawry } from 'assets/imgRevamp/fawry-logo.svg';
import { ReactComponent as Taager } from 'assets/imgRevamp/Taager-logo.svg';
import { ReactComponent as ValuIcon } from 'assets/bosta-icons/valu_astric_icon.svg';

export const LITE = 'LITE';
export const PRO = 'PRO';

export const MARKETING_CHANNELS = {
  FACEBOOK: 'Facebook',
  GOOGLE: 'Google',
  EVENT: 'Event',
  FRIEND: 'From a friend',
  OLD_CUSTOMER: 'Tried Bosta as a customer before',
  EXPANDCART: 'Expandcart',
  ZVENDO: 'zVendo',
  ZYDA: 'Zyda',
  VOWALA: 'Vowala',
  SHOPIFY: 'Shopify',
  WOOCOMMERCE: 'WooCommerce'
};

export const salesChannelOption = [
  'Facebook Shop',
  'Instagram',
  'Facebook Messenger',
  'Tiktok',
  'Shopify',
  'Woo Commerce',
  'Salla',
  'ZID',
  'Magento',
  'Custom Website'
];

export const industryOption = [
  {
    value: 'Books, Arts and Media',
    label: fmt({
      id: 'sign_up.industry_option.books'
    })
  },
  {
    value: 'Electronics',
    label: fmt({
      id: 'sign_up.industry_option.electronics'
    })
  },
  {
    value: 'Cosmetics and personal care',
    label: fmt({
      id: 'sign_up.industry_option.cosmetics'
    })
  },
  {
    value: 'Fashion',
    label: fmt({
      id: 'sign_up.industry_option.fashion'
    })
  },
  {
    value: 'Furniture and appliances',
    label: fmt({
      id: 'sign_up.industry_option.furniture'
    })
  },
  {
    value: 'Healthcare supplements',
    label: fmt({
      id: 'sign_up.industry_option.healthcare'
    })
  },
  {
    value: 'Home and living',
    label: fmt({
      id: 'sign_up.industry_option.home'
    })
  },
  {
    value: 'Gifts',
    label: fmt({
      id: 'sign_up.industry_option.gifts'
    })
  },
  {
    value: 'Jewelry and accessories ',
    label: fmt({
      id: 'sign_up.industry_option.jewelry'
    })
  },
  {
    value: 'Leather',
    label: fmt({
      id: 'sign_up.industry_option.leather'
    })
  },
  {
    value: 'Mothers and babies',
    label: fmt({
      id: 'sign_up.industry_option.mothers'
    })
  },
  {
    value: 'Medical supplies',
    label: fmt({
      id: 'sign_up.industry_option.medical'
    })
  },
  {
    value: 'Office equipment and supplies',
    label: fmt({
      id: 'sign_up.industry_option.office'
    })
  },
  {
    value: 'Pet supplies',
    label: fmt({
      id: 'sign_up.industry_option.pets'
    })
  },
  {
    value: 'Sports wear and equipment',
    label: fmt({
      id: 'sign_up.industry_option.sports'
    })
  },
  {
    value: 'Toys',
    label: fmt({
      id: 'sign_up.industry_option.toys'
    })
  },
  {
    value: 'E-commerce',
    label: fmt({
      id: 'sign_up.industry_option.e_commerce'
    })
  },
  {
    value: 'Food',
    label: fmt({
      id: 'sign_up.industry_option.food'
    })
  },
  {
    value: 'Shoes',
    label: fmt({
      id: 'sign_up.industry_option.shoes'
    })
  }
];

export const heardAboutUs = [
  { value: 'Facebook', label: 'facebook_option' },
  { value: 'Google', label: 'google_option' },
  { value: 'Event', label: 'event_option' },
  { value: 'From a friend ', label: 'friend_option' },
  {
    value: 'Tried Bosta as a customer before',
    label: 'customer_option'
  },
  { value: 'Expandcart', label: 'expandcart' },
  { value: 'zVendo', label: 'zvendo' },
  { value: 'Zyda', label: 'zyda' },
  { value: 'Vowala', label: 'vowala' },
  { value: 'Shopify', label: 'shopify' },
  { value: 'WooCommerce', label: 'woocommerce' }
];

export const INTERESTED_IN_SERVICE_OPTIONS = [
  {
    label: fmt({
      id: 'sign_up.interested_services_options.product_shipment'
    }),
    value: 'Products Shipments'
  },
  // {
  //   label: fmt({
  //     id: 'sign_up.interested_services_options.bosta_box'
  //   }),
  //   value: 'Bosta Box (Cross boarder)'
  // },

  {
    label: fmt({
      id: 'sign_up.interested_services_options.documents'
    }),
    value: 'Documents & B2B'
  },
  {
    label: fmt({
      id: 'sign_up.interested_services_options.bulky'
    }),
    value: 'Bulky'
  }
];

export const VOLUME_OPTIONS = [
  {
    label: '1 - 10',
    value: 'LITE_1'
  },
  {
    label: '11 - 100',
    value: 'LITE_2'
  },
  {
    label: '101 - 500',
    value: 'LITE_3'
  },
  {
    label: '501 - 1,000',
    value: 'PRO_1'
  },
  {
    label: '1,001 - 2,000',
    value: 'PRO_2'
  },
  {
    label: '2,000+',
    value: 'PRO_3'
  }
];

export const PRO_VOLUME_OPTIONS = ['PRO_1', 'PRO_2', 'PRO_3'];

export const LITE_VOLUME_OPTIONS = ['LITE_1', 'LITE_2', 'LITE_3'];

export const STEPPER = {
  SERVICES_STEP: 1,
  PRICING_STEP: 2,
  LAST_STEP: 3,
  SAUDI_LAST_STEP: 2
};

export const SERVICES = [
  {
    apiValue: 'NORMAL_SHIPMENT',
    icon: Deliver,
    title: fmt({
      id: 'sign_up.services.list.shipping.title'
    }),
    subtitle: fmt({
      id: 'sign_up.services.list.shipping.subtitle'
    }),
    defaultChecked: true
  },
  {
    apiValue: 'WITH_PRODUCT_CATALOG',
    icon: Product,
    title: fmt({
      id: 'sign_up.services.list.products.title'
    }),
    subtitle: fmt({
      id: 'sign_up.services.list.products.subtitle'
    }),
    tagText: fmt({
      id: 'sign_up.services.tags.beta'
    }),
    interestedNotificationMsg: fmt({
      id: 'sign_up.services.list.products.notify_interested'
    }),
    interestedSegmentEventAttr: 'Interested-in-sllr',
    isDisabled: true
  },
  {
    apiValue: 'WITH_FULFILLMENT_SHIPMENT',
    icon: Fulfillment,
    title: fmt({
      id: 'sign_up.services.list.fulfillment.title'
    }),
    subtitle: fmt({
      id: 'sign_up.services.list.fulfillment.subtitle'
    }),
    tagText: fmt({
      id: 'sign_up.services.tags.coming_soon'
    }),
    interestedNotificationMsg: fmt({
      id: 'sign_up.services.list.fulfillment.notify_interested'
    }),
    interestedSegmentEventAttr: 'Interested-in-fulfillment',
    isDisabled: true
  }
];

export const PLANS = {
  BUSINESS: 'yiqKg_aGM1',
  BASIC: 'c__CT4DU9I'
};

export const PLANS_SUMMERY_TEXT = (country) => {
  const isNewActivationFlow = isRegisteredViaBetaFunnel();

  return {
    ['Next Day']: {
      title: `${fmt(
        {
          id: 'sign_up.pricing.cashouts'
        },
        {
          value: fmt({
            id: isNewActivationFlow
              ? 'pickups.table_columns.repeatation_types.daily'
              : 'sign_up.pricing.instant'
          })
        }
      )} ${isNewActivationFlow ? '' : '⚡'}`,
      ...(!isNewActivationFlow && {
        subtitle: fmt({
          id: 'sign_up.pricing.instant_description'
        })
      })
    },
    Weekly: {
      title: fmt(
        {
          id: 'sign_up.pricing.cashouts'
        },
        {
          value: fmt({
            id: 'pickups.table_columns.repeatation_types.weekly'
          })
        }
      ),
      ...(!isNewActivationFlow && {
        subtitle: fmt({
          id: 'sign_up.pricing.weekly_description'
        })
      })
    }
  };
};

export const PAYMENT_FREQUENCY = {
  NEXT_DAY: 'Next Day'
};

export const BUSINESS_NAME_LENGHT = 21;

export const ERROR_CODES = {
  EMAIL_EXISTS: 1012,
  PHONE_EXISTS: [1002, 1053, 1046],
  BUSINESS_NAME_EXIST: 2020
};

export const ACTIVATION_STEPS_KEY = {
  VERIFY_EMAIL: 'VERIFY_EMAIL',
  SHIPPING_PRICES: 'SHIPPING_PRICES',
  BUSINESS_PROFILE: 'BUSINESS_PROFILE',
  PICKUP_LOCATION: 'PICKUP_LOCATION',
  BANK_ACCOUNT: 'BANK_ACCOUNT',
  ACCOUNT_TYPE: 'ACCOUNT_TYPE',
  SUMMARY: 'SUMMARY'
};

export const ACCOUNT_TYPE_RADIO = {
  TAX_ID: 1,
  NATIONAL_ID: 2,
  DEFAULT: 0
};

export const PICKUP = 'pickup';
export const SEND = 'SEND';
export const NORMAL = 'Normal';

export const MOBILE_APP_REG_SRC = 'BUSINESS_APP';
export const DASHBOARD_REG_SRC = 'DASHBOARD';

export const FREELANCE_SA_LINK = 'https://freelance.sa/register';

export const INTERNATIONAL_SERVICES = {
  AE: [
    {
      apiValue: 'INTERNATIONAL_SHIPMENT',
      icon: International,
      title: fmt({
        id: 'sign_up.services.list.international.title'
      }),
      defaultChecked: true
    },
    {
      apiValue: 'NORMAL_SHIPMENT',
      icon: Deliver,
      title: fmt({
        id: 'sign_up.services.list.domestic_uae.title'
      }),
      subtitle: fmt({
        id: 'sign_up.services.list.domestic_uae.subtitle'
      }),
      tagText: fmt({
        id: 'sign_up.services.tags.coming_soon'
      }),
      isDisabled: true
    }
  ]
};

export const PDF_FILE_TYPE = 'application/pdf';

export const RECAPTCHA_SITE_KEY = '6Lcx14ckAAAAAO1O90bjhbH3Ji2LcBy1JZDCRSzW';

export const NEW_SIGNUP_FEATURES = [
  { labelId: 'fast_shipping', icon: OutForDelivery },
  { labelId: 'free_pickups', icon: Pickups },
  { labelId: 'live_tracking', icon: MobileApps },
  { labelId: 'cash_on_delivery', icon: BankNote },
  { labelId: 'inventory_services', icon: Inventory },
  { labelId: 'valu_integration', icon: ValuIcon },
  { labelId: 'easy_plugins', icon: Plugins }
];

export const SIGNUP_PARTNERS_LOGOS = [Palma, Amazon, Emma, Fawry, Taager];

export const SIGNUP_PRICING_COLUMNS = [
  {
    title: (
      <SignUpPricingTableHeader
        title={fmt({
          id: 'new_sign_up.pricing.table_columns.service_title'
        })}
        subtitle={fmt({
          id: 'new_sign_up.pricing.table_columns.service_subtitle'
        })}
      />
    ),
    dataIndex: 'service',
    width: 200
  },
  {
    title: (
      <SignUpPricingTableHeader
        title={fmt({
          id: 'new_sign_up.pricing.table_columns.cities.cairo.title'
        })}
        subtitle={fmt({
          id: 'new_sign_up.pricing.table_columns.cities.cairo.subtitle'
        })}
      />
    ),
    dataIndex: 'cairo',
    align: 'center'
  },
  {
    title: (
      <SignUpPricingTableHeader
        title={fmt({
          id: 'new_sign_up.pricing.table_columns.cities.alexandria.title'
        })}
        subtitle={fmt({
          id: 'new_sign_up.pricing.table_columns.cities.alexandria.subtitle'
        })}
      />
    ),
    dataIndex: 'alexandria',
    align: 'center'
  },
  {
    title: (
      <SignUpPricingTableHeader
        title={fmt({
          id: 'new_sign_up.pricing.table_columns.cities.delta_and_canal.title'
        })}
        subtitle={fmt({
          id: 'new_sign_up.pricing.table_columns.cities.delta_and_canal.subtitle'
        })}
      />
    ),
    dataIndex: 'delta_and_canal',
    align: 'center'
  },
  {
    title: (
      <SignUpPricingTableHeader
        title={fmt({
          id: 'new_sign_up.pricing.table_columns.cities.near_upper.title'
        })}
        subtitle={fmt({
          id: 'new_sign_up.pricing.table_columns.cities.near_upper.subtitle'
        })}
      />
    ),
    dataIndex: 'near_upper',
    align: 'center'
  },
  {
    title: (
      <SignUpPricingTableHeader
        title={fmt({
          id: 'new_sign_up.pricing.table_columns.cities.far_upper.title'
        })}
        subtitle={fmt({
          id: 'new_sign_up.pricing.table_columns.cities.far_upper.subtitle'
        })}
      />
    ),
    dataIndex: 'far_upper',
    align: 'center'
  },
  {
    title: (
      <SignUpPricingTableHeader
        title={fmt({
          id: 'new_sign_up.pricing.table_columns.cities.south_sinai.title'
        })}
        subtitle={fmt({
          id: 'new_sign_up.pricing.table_columns.cities.south_sinai.subtitle'
        })}
      />
    ),
    dataIndex: 'southSinai',
    align: 'center'
  }
];

export const SIGNUP_PRICING_DATA = [
  {
    service: (
      <SignUpPricingTableHeader
        title={fmt({
          id: 'new_sign_up.pricing.table_columns.deliver_service.title'
        })}
        subtitle={fmt({
          id: 'new_sign_up.pricing.table_columns.deliver_service.subtitle'
        })}
        isHeader={false}
      />
    ),
    cairo: 68.4,
    alexandria: 74.1,
    delta_and_canal: 79.8,
    near_upper: 91.2,
    far_upper: 102.6,
    southSinai: 114
  },
  {
    service: (
      <SignUpPricingTableHeader
        title={fmt({
          id: 'new_sign_up.pricing.table_columns.large_size_service.title'
        })}
        subtitle={fmt({
          id: 'new_sign_up.pricing.table_columns.large_size_service.subtitle'
        })}
        isHeader={false}
      />
    ),
    cairo: 182,
    alexandria: 188,
    delta_and_canal: 193,
    near_upper: 205,
    far_upper: 216,
    southSinai: 228
  },
  {
    service: (
      <SignUpPricingTableHeader
        title={fmt({
          id: 'new_sign_up.pricing.table_columns.heavy_weight_service.title'
        })}
        subtitle={fmt({
          id: 'new_sign_up.pricing.table_columns.heavy_weight_service.subtitle'
        })}
        isHeader={false}
      />
    ),
    cairo: 365,
    alexandria: 421
  }
];

export const SIGNUP_PRICING_FEATURES_LABEL_IDS = [
  'daily_cashout',
  'free_pickups',
  'cash_on_delivery',
  'insurance'
];

export const SIGNUP_PAGE_VIDEOS_IDS = ['24tg-cJOOiA', 'nHlOLYwsqRc'];
