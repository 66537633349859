import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng
} from 'use-places-autocomplete';
import { Input } from 'antd';
import { AimOutlined } from '@ant-design/icons';
import classnames from 'classnames';
import Icon from '@ant-design/icons';

import { LOCATION_SERVICES_LINK } from 'constants/marketplace';
import { MAP_MARKER_STATUS } from 'constants/brGoogleMap';
import { getCurrentUserCountryData } from 'constants/countries/countries-mapping';

import { notify } from 'components/Notify/Notify';

import { ReactComponent as Pinpoint } from 'assets/bosta-icons/pinpoint.svg';
import { ReactComponent as ClearCircle } from 'assets/bosta-icons/Circle-clear.svg';

import './PlacesSearchBox.less';

const PlacesSearchBox = ({
  handleNewSearchInput,
  setEnteredPosition,
  setSearchResultAddress,
  intl,
  userMoveMap,
  handleIsOpenedForTheFirstTime,
  handleConfirmLocation,
  markerStatus,
  shouldDetectUserLocationOnMount
}) => {
  const [userSelectLocation, setUserSelectLocation] = useState(false);
  const [isBlurred, setIsBlurred] = useState(false);
  let geoLocatorTimer = null;

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: {
        country: getCurrentUserCountryData().codeName
      }
    },
    cacheKey: getCurrentUserCountryData().codeName,
    debounce: 300
  });

  useEffect(() => {
    if (shouldDetectUserLocationOnMount) {
      handleOnClickGeoLocator();
    }
    return () => {
      clearTimeout(geoLocatorTimer);
    };
  }, []);

  const handleInput = (e) => {
    setUserSelectLocation(false);
    handleNewSearchInput();
    setValue(e.target.value);
  };

  const handleSelect =
    ({ description }) =>
    async () => {
      setUserSelectLocation(true);
      try {
        if (description) {
          setValue(description, false);
          const results = await getGeocode({ address: description });
          const position = await getLatLng(results[0]);
          setEnteredPosition(position);
          setSearchResultAddress(results[0]);
          handleConfirmLocation();
        } else setEnteredPosition(value);
      } catch (error) {
        let customMessage = null;
        if (error === 'ZERO_RESULTS') {
          customMessage = intl.formatMessage({
            id: 'common.maps_no_result_found'
          });
        }
        notify({ msg: customMessage || error?.message || error, error });
      }
    };

  const handleOnClickGeoLocator = () => {
    setValue(null);
    handleNewSearchInput();
    handleIsOpenedForTheFirstTime();
    if (navigator.permissions && navigator.permissions.query) {
      navigator.permissions.query({ name: 'geolocation' }).then(({ state }) => {
        if (state !== 'denied') {
          userMoveMap();
        } else {
          notify({
            msg: (
              <div className="display-flex">
                <span>
                  {intl.formatMessage({
                    id: 'br_google_map.location_access_error'
                  })}
                </span>
                <a
                  className="br-google-map__places-search-box__learn-more"
                  href={LOCATION_SERVICES_LINK}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {intl.formatMessage({
                    id: 'wallet.balance.empty_state.learn_more'
                  })}
                </a>
              </div>
            ),
            type: 'warning'
          });
        }
      });
    } else {
      userMoveMap();
    }
  };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text }
      } = suggestion;

      return (
        <li key={place_id} onClick={handleSelect(suggestion)}>
          <div>
            <Icon component={Pinpoint} className="ant-icon-md" />
            <span className="body">{main_text}</span>
          </div>
          {secondary_text && <span className="caption">{secondary_text}</span>}
        </li>
      );
    });

  return (
    <div className="br-google-map__places-search-box">
      <div className="br-google-map__places-search-box__header">
        <div className="br-google-map__places-search-box__search">
          <Input.Search
            className={classnames('br-google-map__places-search-box__input', {
              'br-google-map__places-search-box__has-input': value
            })}
            allowClear={{ clearIcon: <ClearCircle /> }}
            value={value}
            onChange={handleInput}
            disabled={!ready}
            placeholder={intl.formatMessage({
              id: 'br_google_map.search_placeholder'
            })}
            onBlur={() => {
              setTimeout(() => {
                setIsBlurred(true);
              }, 200);
            }}
            onFocus={() => {
              setIsBlurred(false);
            }}
          />
          <div
            className={classnames('br-google-map__places-search-box__list', {
              'display-none': userSelectLocation
            })}
          >
            {!isBlurred && status === 'OK' && <ul>{renderSuggestions()}</ul>}
          </div>
        </div>
      </div>
      <AimOutlined
        onClick={handleOnClickGeoLocator}
        className={classnames(
          {
            'br-google-map__places-search-box__my-location-icon-error': [
              MAP_MARKER_STATUS.FAILED,
              MAP_MARKER_STATUS.UNDETERMINED
            ].includes(markerStatus)
          },
          'br-google-map__places-search-box__my-location-icon'
        )}
      />
    </div>
  );
};

export default injectIntl(PlacesSearchBox);
