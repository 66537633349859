export const HeardAboutUsKey = 'f1c7b870fd9a9460b52194423cbe7013b1560c91';
export const ProOrLiteKey = '44c417b5d70d03ff0b20bb48a5a89da8df84b482';
export const IsPhoneVerifyKey = '618a0a7106567ca26f7c010ffe1095ad59de87ce';
export const SalesChannelKey = '9dfa4697c5bed6aa928eaea91d0698d959d8cc72';
export const IsPickupAddressAdded = '26217a80af0ea7372cae6103b300ad7ae0f4c3f2';
export const PickupCity = 'c117f48fa18ff62ad2ff9f84057c4bc064ce703d';
export const IndustryTypeKey = '81de0ad129adaeb5bb5558d1348569dc39beb027';
export const EmailVerify = '7fc3fccf4d9d39a7c695280663a2641693dc7ac4';
export const SalesURLKey = '3511004cc695623a17b75c90b5cdcd4728206692';
export const referralSource = '1adbf12ee4084051b9450169a588114448cba12a';
export const campaign = '50396016165538313ca6d6503deebeaa4ef28daa';
export const channel = '8abd588991cefac0a85ab275587f41176a2236f9';
export const promoCode = 'cd446a74541c959b5188bf694bd09a4bbd5755b8';
export const businessIdKey = 'fa46bba763f07037383281f01bb6bbe55b099252';
export const InterestedInServices = '95c537df642be5a161fc63fab53d6c3a4fbf268a';
export const TYPE_LITE = 'LITE';
