import React, { useState, useRef } from 'react';
import { injectIntl } from 'react-intl';
import { Modal, Form, Input } from 'antd';

import { updateVerifyPhone, sendVerificationCode } from 'services/auth';
import { emailRule } from 'utils/forms';

import BRButton from 'components/BRButton/BRButton';
import { notify } from 'components/Notify/Notify';

import './ChangeEmailModal.less';

const ChangeEmailModal = ({
  intl,
  close,
  onSuccess,
  handleSendVerificationCodeClick,
  ...props
}) => {
  const [usedEmail, setUsedEmail] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const formRef = useRef();

  const handleChangeEmail = async (values) => {
    const { email } = values;
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const userID = userInfo?.user?._id;

    try {
      await updateVerifyPhone(userID, { email: email });
      userInfo.user.emails[0].address = email;
      localStorage.setItem('userInfo', JSON.stringify(userInfo));
      onSuccess(email);
      handleSendVerificationCodeClick
        ? handleSendVerificationCodeClick()
        : await sendVerificationCode({ email: email });
      close();
      notify({
        msg: intl.formatMessage({
          id: 'sign_up.email_verification.activation_sent'
        }),
        type: 'success'
      });
    } catch (e) {
      if (e.errorCode === 1054) {
        setUsedEmail(true);
      }
    }
  };

  const emailOnChange = () => {
    formRef?.current
      ?.validateFields(['email'])
      .then(() => setDisabled(false))
      .catch(() => setDisabled(true));
  };

  return (
    <Modal
      {...props}
      title={
        <div className="display-xs">
          {intl.formatMessage({
            id: 'sign_up.email_verification.change_your_email'
          })}
        </div>
      }
      footer={
        <>
          <BRButton
            label={intl.formatMessage({
              id: 'common.cancel'
            })}
            type={'basic'}
            onClick={close}
          />
          <BRButton
            label={intl.formatMessage({
              id: 'sign_up.email_verification.save_and_send'
            })}
            type={'primary'}
            htmlType="submit"
            form="changeEmailForm"
            disabled={disabled}
          />
        </>
      }
      wrapClassName="br-change-email-modal"
    >
      <Form ref={formRef} onFinish={handleChangeEmail} name="changeEmailForm">
        <Form.Item
          name="email"
          label={intl.formatMessage({
            id: 'contact_form_labels.email'
          })}
          rules={[
            { required: true },
            emailRule(
              intl.formatMessage({
                id: 'form.email_not_valid'
              })
            )
          ]}
          hasFeedback
          validateStatus={usedEmail ? 'error' : undefined}
          help={
            usedEmail
              ? intl.formatMessage({
                  id: 'sign_up.email_verification.email_exists'
                })
              : undefined
          }
          onChange={emailOnChange}
        >
          <Input
            placeholder={intl.formatMessage({
              id: 'sign_up.form_placeholders.email'
            })}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default injectIntl(ChangeEmailModal);
