import React, { useEffect, useContext } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import { newNotification, readNotification } from 'actions/index';
import { getCountNotification } from 'services/notifications';
import { getUserPermission } from 'services/teams-and-members';
import { useModal } from 'contexts/modalProvider.context';
import { ContextWrapper } from 'contexts/wrapper.context';
import { APP_NAME } from 'constants/common';

import { notify } from 'components/Notify/Notify';
import ViewPermissionsModal from 'components/Settings/components/TeamsMembers/components/ViewPermissionsModal/ViewPermissionsModal';

const InitialLoad = ({ setNewNotification, setReadNotification }) => {
  const { setDraftOrdersCount } = useContext(ContextWrapper);
  const { openModal } = useModal();

  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));

    async function fetchCountNotification() {
      try {
        if (
          userInfo &&
          userInfo.user.businessAdminInfo &&
          userInfo.user.businessAdminInfo.hasAddress
        ) {
          const response = await getCountNotification({ source: APP_NAME });
          const { totalCount } = response;

          if (totalCount === 0) {
            setReadNotification();
          } else {
            setNewNotification();
          }
        }
      } catch (error) {
        notify({ msg: error.message, error });
      }
    }
    async function fetchPermission() {
      try {
        const response = await getUserPermission(userInfo.user._id);

        if (
          userInfo.user.group &&
          response.group.name !== userInfo.user.group.name
        ) {
          userInfo.user.group = response.group;
          openModal(ViewPermissionsModal);
        } else if (!userInfo.user.group) {
          userInfo.user.group = response.group;
        } else if (!userInfo.user.isOrganizationalAccount) {
          userInfo.user.isOrganizationalAccount =
            response?.organizationalAccount;
        }
        localStorage.setItem('userInfo', JSON.stringify(userInfo));
      } catch (error) {
        notify({ msg: error.message, error });
      }
    }

    fetchCountNotification();
    fetchPermission();
  }, [setNewNotification, setReadNotification]);
  return <></>;
};

const mapDispatchToProps = (dispatch) => ({
  setNewNotification: () => dispatch(newNotification()),
  setReadNotification: () => dispatch(readNotification())
});

export default connect(null, mapDispatchToProps)(withRouter(InitialLoad));
