export const PHONE_NUMBER_MAX_LENGTH = {
  EG: 11,
  SA: 10
};

export const IDENTITY_NUMBER_REGEX = {
  EG: /^.{10}$/,
  SA: /^.{10}$/,
  AE: /^\d{3}-?\d{4}-?\d{7}-?\d{1}$/
};

export const IDENTITY_NUMBER_ERROR = {
  EG: 'sign_up.business_information.store_owner_form.id_number.validation.invalid',
  SA: 'sign_up.business_information.store_owner_form.id_number.validation.invalid',
  AE: 'sign_up.business_information.store_owner_form.id_number.validation.uae_invalid'
};
