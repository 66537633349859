import { useIntl } from 'react-intl';

import { PROHIBITED_ITEMS_MODAL_LIST } from 'constants/home-page-activation';

import './ProhibitedItemsList.less';

const ProhibitedItemsList = () => {
  const intl = useIntl();

  return (
    <div className="br-prohibited-items-list__container">
      {PROHIBITED_ITEMS_MODAL_LIST.map(({ labelId, icon: Icon }) => (
        <div key={labelId} className="br-prohibited-items-list__item">
          <Icon />
          <div className="br-prohibited-items-list__item-content">
            <div className="heading">
              {intl.formatMessage({
                id: `prohibited_items_modal.prohibited_items.list.${labelId}.title`
              })}
            </div>
            <div className="br-prohibited-items-list__item-subtitle">
              {intl.formatMessage({
                id: `prohibited_items_modal.prohibited_items.list.${labelId}.subtitle`
              })}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProhibitedItemsList;
