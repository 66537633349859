import { bostaHttp } from 'http.js';
import { downloadHeader } from 'utils/download';

export const getProductList = (payload) => {
  return bostaHttp.get(`/products/fulfillment/list-products`, payload);
};

export const createFulfillmentMassOrders = (payload) => {
  return bostaHttp.post(`/fulfillment/deliveries/bulky`, payload);
};

export const exportCSVInventory = (payload) => {
  return bostaHttp.post(
    `/fulfillment/products/export`,
    payload,
    downloadHeader
  );
};

export const exportCSVInventoryBosta = (payload) => {
  return bostaHttp.get(
    `products/fulfillment/export-products`,
    payload,
    downloadHeader
  );
};

export const getProductDetails = (id) => {
  return bostaHttp.get(`/fulfillment/products?bostaSku=${id}`);
};

export const getFulfillmentAnalytics = (payload) => {
  return bostaHttp.get(`fulfillment/products/analytics`, payload);
};

export const getFulfillmentMostSelling = (payload) => {
  return bostaHttp.get(`fulfillment/products/mostSelling`, payload);
};

export const getPurchaseList = (payload) => {
  return bostaHttp.post(`/fulfillment/purchase/list`, payload);
};

export const getPurchaseOrdersBosta = (payload) => {
  return bostaHttp.get(`products/fulfillment/purchase-orders`, payload);
};

export const getPurchaseDetails = ({ id }, payload) => {
  return bostaHttp.post(`fulfillment/purchase/order-line/${id}`, payload);
};

export const exportInventoryRequests = (payload) => {
  return bostaHttp.post(`fulfillment/purchase/export`, payload, downloadHeader);
};

export const exportInventoryRequestsBosta = (payload) => {
  return bostaHttp.get(
    `products/fulfillment/export/purchase-orders`,
    payload,
    downloadHeader
  );
};

export const inventoryRequestActions = (id, payload) => {
  return bostaHttp.post(`fulfillment/purchase/action/${id}`, payload);
};

export const checkProductAvailability = (payload) => {
  return bostaHttp.post('/fulfillment/products/check', payload);
};

export const getSerialsList = (payload) => {
  return bostaHttp.get(`/fulfillment-v2/serials/list`, payload);
};

export const exportSerialsList = (payload) => {
  return bostaHttp.get(
    `/fulfillment-v2/serials/export`,
    payload,
    downloadHeader
  );
};
