import { useState } from 'react';
import { Modal } from 'antd';
import { useIntl } from 'react-intl';

import { checkEmailProvider } from 'utils/helpers';
import { EMAILS_PROVIDERS_LINK } from 'constants/links';
import { sendVerificationCode } from 'services/auth';

import BRButton from 'components/BRButton/BRButton';
import { notify } from 'components/Notify/Notify';

import { ReactComponent as WaveIcon } from 'assets/bosta-icons/HandWaveIcon.svg';
import { ReactComponent as Letter } from 'assets/bosta-icons/Letter.svg';

import './VerifyEmailModal.less';

const userInfo = JSON.parse(localStorage.getItem('userInfo'));

const VerifyEmailModal = ({ close, ...props }) => {
  const [isLoading, setIsLoading] = useState(false);

  const intl = useIntl();

  const email = userInfo?.user?.emails[0]?.address;

  const handleOpenMail = () => {
    let url;

    if (checkEmailProvider(email)) {
      url = EMAILS_PROVIDERS_LINK.OUTLOOK;
    } else {
      url = EMAILS_PROVIDERS_LINK.GMAIL;
    }

    close();
    window.open(url, '_blank');
  };

  const handleSendVerificationCodeClick = async () => {
    setIsLoading(true);

    try {
      await sendVerificationCode({ email });
      notify({
        msg: intl.formatMessage({
          id: 'sign_up.email_verification.activation_sent'
        }),
        type: 'success'
      });
    } catch (error) {
      notify({ msg: error.message, error });
    }

    setIsLoading(false);
  };

  return (
    <Modal
      title={null}
      footer={null}
      width={null}
      onCancel={close}
      wrapClassName="br-verify-email__modal"
      {...props}
    >
      <Letter />
      <div className="br-verify-email__title display-sm">
        {intl.formatMessage(
          { id: 'verify_email_modal.title' },
          {
            firstName: userInfo?.user?.profile?.firstName,
            icon: <WaveIcon />,
            span: (children) => <span>{children}</span>
          }
        )}
      </div>
      <div className="br-verify-email__subtitle">
        {intl.formatMessage(
          { id: 'verify_email_modal.subtitle' },
          {
            email
          }
        )}
      </div>
      <div className="br-verify-email__actions">
        <BRButton
          type="primary"
          className="button-md"
          label={intl.formatMessage({ id: 'verify_email_modal.open_email' })}
          onClick={handleOpenMail}
        />
        <BRButton
          className="button-md"
          label={intl.formatMessage({
            id: 'sign_up.email_verification.resend_email'
          })}
          onClick={handleSendVerificationCodeClick}
          loading={isLoading}
        />
        <BRButton
          type="treitary-gray"
          className="button-md"
          label={intl.formatMessage({
            id: 'verify_email_modal.explore_and_verify_later'
          })}
          onClick={close}
        />
      </div>
    </Modal>
  );
};

export default VerifyEmailModal;
