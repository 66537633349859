import { injectIntl } from 'react-intl';

import { BannerContent } from 'components/Banner/Banner';

import { ReactComponent as Warning } from 'assets/bosta-icons/alert-circle.svg';

import './CancelledPickupsBanner.less';

const CancelledPickupsBanner = ({
  intl,
  cancelledPickupsCount = 0,
  handlePrepareOrdersGuide
}) => {
  return (
    <div className="br-pickups-cancelled-pickup__banner">
      <BannerContent
        icon={<Warning />}
        subText={intl.formatMessage(
          {
            id: 'new_order.request_pickup_modal.cancellation_modal.title'
          },
          {
            span: (children) => (
              <span className="br-pickups-cancelled-pickup__banner-count">
                {children}
              </span>
            ),
            count: cancelledPickupsCount
          }
        )}
        buttonText={intl.formatMessage({
          id: 'new_order.request_pickup_modal.cancellation_modal.action'
        })}
        buttonProps={{
          onClick: () => handlePrepareOrdersGuide({ isFromPickupModal: false })
        }}
      />
    </div>
  );
};

export default injectIntl(CancelledPickupsBanner);
