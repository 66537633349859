import { bostaHttp } from 'http.js';

export const getPricingInfo = (pickupSectorId, vatIncluded = false) => {
  return bostaHttp.get(
    `/pricing/calculator?pickupSectorId=${pickupSectorId}&vatIncluded=${vatIncluded}`,
    null
  );
};

export const getPricingTierInfo = () => {
  return bostaHttp.get(`pricing/info`);
};

export const getShippingFeesInfo = (city) => {
  return bostaHttp.get(
    `/pricing/shipment/calculator?pickupCity=Cairo&dropOffCity=${city}&type=SEND&size=Normal`
  );
};

export const calculatePricingFees = (payload) => {
  return bostaHttp.get(`/pricing/shipment/calculator`, payload);
};

export const getInternationalPricing = () => {
  return bostaHttp.get(`/pricing/internationalTier/info`);
};

export const getInternationalExchangeRate = (payload) => {
  return bostaHttp.get(`/pricing/currency/exchange`, payload);
};

export const calculateVatEstimate = (payload) => {
  return bostaHttp.get('/pricing/international/CustomVatEstimate', payload);
};
