import SignUpBanner from 'components/NewSignUp/components/SignUp/components/SignUpBanner/SignUpBanner';
import SignUpForm from 'components/NewSignUp/components/SignUp/components/SignUpForm/SignUpForm';

import './SignUp.less';

const SignUp = ({ signUpPricingSectionRef, country, setCountry }) => {
  return (
    <div className="br-signup__container">
      <SignUpBanner signUpPricingSectionRef={signUpPricingSectionRef} />
      <SignUpForm country={country} setCountry={setCountry} />
    </div>
  );
};

export default SignUp;
