import { Modal } from 'antd';
import { useIntl } from 'react-intl';

import {
  COST_PER_CHARGABLE_PICKUP,
  MIN_CHARGABLE_PICKUPS
} from 'constants/pickups';

import BRButton from 'components/BRButton/BRButton';

import { ReactComponent as Orders } from 'assets/bosta-icons/Orders.svg';

import './PickupFeesModal.less';

const PickupFeesModal = ({ close, onSuccess, ...props }) => {
  const intl = useIntl();

  const handleOnSuccess = () => {
    close();
    onSuccess();
  };

  return (
    <Modal
      {...props}
      title={null}
      footer={null}
      wrapClassName="br-pickup-fees__modal"
    >
      <div className="br-pickup-fees-modal__container">
        <div className="br-pickups-fees-modal__icon">
          <Orders />
        </div>
        <div className="display-sm">
          {intl.formatMessage({ id: 'pickup_fees_modal.title' })}
        </div>
        <div className="br-pickups-fees-modal__subtitle body-medium">
          {intl.formatMessage({ id: 'pickup_fees_modal.subtitle' })}
        </div>
        <div className="br-pickups-fees-modal__pricing body-medium">
          {intl.formatMessage(
            { id: 'pickup_fees_modal.pricing' },
            {
              minShipments: MIN_CHARGABLE_PICKUPS,
              price: COST_PER_CHARGABLE_PICKUP
            }
          )}
        </div>
        <div className="br-pickups-fees-modal__footer">
          <BRButton
            onClick={close}
            label={intl.formatMessage({ id: 'common.back' })}
            className="button-md"
          />
          <BRButton
            type="primary"
            onClick={handleOnSuccess}
            label={intl.formatMessage({ id: 'pickup_fees_modal.action' })}
            className="button-md"
          />
        </div>
      </div>
    </Modal>
  );
};

export default PickupFeesModal;
