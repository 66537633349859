import { LOCALE } from 'constants/intl-wrapper';
import { getLocaleFromLocalStorage } from 'utils/intl-wrapper';

export const getLocalizedValue = ({
    value,
    valueAr,
    lang = getLocaleFromLocalStorage()
  }) => {
    const { EN } = LOCALE;
  
    const nameEn = value || valueAr;
    const nameAr = valueAr || value;
  
    switch (lang) {
      case EN:
        return nameEn;
  
      default:
        return nameAr;
    }
  };
  