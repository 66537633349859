import classnames from 'classnames';

import './SignUpPricingTableHeader.less';

const SignUpPricingTableHeader = ({ title, subtitle, isHeader = true }) => {
  return (
    <div
      className={classnames('br-signup-pricing-table-header__container', {
        'br-signup-pricing-table-header': isHeader
      })}
    >
      <span className="body">{title}</span>
      <span>{subtitle}</span>
    </div>
  );
};

export default SignUpPricingTableHeader;
