import { notify } from 'components/Notify/Notify';
import { fmt } from 'components/IntlWrapper/IntlWrapper';
import {
  isSafari,
  isMobileSafari,
  isMobile,
  isAndroid,
  isChrome,
  isFirefox
} from 'react-device-detect';

const readPDFFileFromByteArray = (data) => {
  const byteCharacters = atob(data);
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  const file = new Blob([byteArray], {
    type: 'application/pdf;base64'
  });
  const fileURL = (window.URL || window['webkitURL']).createObjectURL(file);
  return fileURL;
};

export const downloadAsPdf = (data, fileName, message, notifyFulfillment) => {
  if (
    isMobile &&
    (window.navigator.userAgent.includes('CriOS') ||
      window.navigator.userAgent.includes('FxiOS'))
  ) {
    try {
      const dataUri = `data:application/pdf;base64,${data}`;
      const link = document.createElement('a');
      link.setAttribute('href', dataUri);
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.dispatchEvent(new Event('click'));
      setTimeout(() => document.body.removeChild(link), 500);
      if (notifyFulfillment) {
        notify({
          msg: message
            ? message
            : fmt({ id: 'common.file_downloaded_successfully' }),
          type: 'success'
        });
      }
    } catch (error) {
      notify({ msg: error.message, error });
    }
    return;
  }

  if (
    isMobileSafari ||
    isSafari ||
    (isMobile && isAndroid && (isChrome || isFirefox))
  ) {
    _downloadAsPdf(data, fileName, message, notifyFulfillment);
    return;
  }

  try {
    const fileURL = readPDFFileFromByteArray(data);

    const newWindow = window.open('/_loading');

    newWindow.onload = function () {
      newWindow.location = fileURL;
    };
  } catch (error) {
    if (
      error.message.includes('onload') ||
      error.message.includes('is null') ||
      error.message.includes('of null') ||
      error.message.includes("'onload' of null")
    ) {
      notify({ msg: fmt({ id: 'common.browser_block_download' }), status: -1 });
    } else {
      notify({ msg: error.message, error });
    }
  }
};

const _downloadAsPdf = (data, fileName, message, notifyFulfillment) => {
  try {
    const pom = document.createElement('a');
    const fileURL = readPDFFileFromByteArray(data);
    pom.href = fileURL;
    pom.setAttribute('download', fileName);
    window.location.href = fileURL;
    document.body.appendChild(pom);
    pom.click();
    document.body.removeChild(pom);
    if (notifyFulfillment) {
      notify({
        msg: message
          ? message
          : fmt({ id: 'common.file_downloaded_successfully' }),
        type: 'success'
      });
    }
  } catch (error) {
    notify({ msg: fmt({ id: 'common.file_download_failed' }) + error.message });
  }
};

export const downloadAsXlsx = (text, fileName) => {
  const pom = document.createElement('a');
  const xlsxContent = text; // here we load our csv data
  const blob = new Blob([xlsxContent], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'
  });
  const url = URL.createObjectURL(blob);
  pom.href = url;
  pom.setAttribute('download', fileName);
  pom.click();
};

export const downloadHeader = {
  responseType: 'arraybuffer',
  headers: {
    Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  }
};
