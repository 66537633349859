export const config = {
  apiKey: 'AIzaSyBlnRkQ5zT9gj1__Yxej5Q5erlIhsx2CEw',
  authDomain: 'bosta-business-app.firebaseapp.com',
  databaseURL: 'https://bosta-business-app.firebaseio.com',
  projectId: 'bosta-business-app',
  storageBucket: 'bosta-business-app.appspot.com',
  messagingSenderId: '454518994291',
  appId: '1:454518994291:web:8e94561707cd54c18cc106',
  measurementId: 'G-53GCHW71MZ'
};
