import ProhibitedItemsList from 'components/ProhibitedItemsAndUncoveredZones/components/ProhibitedItemsList/ProhibitedItemsList';
import UncoveredZonesList from 'components/ProhibitedItemsAndUncoveredZones/components/UncoveredZonesList/UncoveredZonesList';
import { fmt } from 'components/IntlWrapper/IntlWrapper';

import { ReactComponent as ProhibitedItems } from 'assets/bosta-icons/prohibited-items.svg';
import { ReactComponent as UncoveredZones } from 'assets/bosta-icons/uncovered-zones.svg';
import { ReactComponent as FoodAndBeverage } from 'assets/bosta-icons/food_beverage.svg';
import { ReactComponent as Fragile } from 'assets/bosta-icons/fragile.svg';
import { ReactComponent as Illegal } from 'assets/bosta-icons/illegal.svg';
import { ReactComponent as Dangerous } from 'assets/bosta-icons/dangerous.svg';
import { ReactComponent as Unlicensed } from 'assets/bosta-icons/unlicensed.svg';
import { ReactComponent as Diamond } from 'assets/bosta-icons/diamond.svg';

export const PROHIBITED_ITEMS_LABELIDS = [
  'food_and_beverage',
  'stolen_items',
  'fragile_items',
  'alcoholic',
  'illegal_electronic',
  'explosives',
  'currency_exchange',
  'people_and_animals',
  'dangerous_items',
  'cheques',
  'exceeding_max_cod',
  'unlicensed_items'
];

export const MATERIALS_VIDEO_URL =
  'https://www.youtube.com/watch?v=0NZ9ehtqdFc';

export const COMMON_QUESTIONS = [
  {
    title: fmt({
      id: 'home_page_activation.common_questions.shipping_prices.title'
    }),
    content: fmt({
      id: 'home_page_activation.common_questions.shipping_prices.content'
    })
  },
  {
    title: fmt({
      id: 'home_page_activation.common_questions.cashout_methods.title'
    }),
    content: fmt({
      id: 'home_page_activation.common_questions.cashout_methods.content'
    })
  },
  {
    title: fmt({
      id: 'home_page_activation.common_questions.packaging_material.title'
    }),
    content: fmt(
      {
        id: 'home_page_activation.common_questions.packaging_material.content'
      },
      {
        a: (children) => (
          <a
            href={MATERIALS_VIDEO_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            {children}
          </a>
        )
      }
    )
  },
  {
    title: fmt({
      id: 'home_page_activation.common_questions.packaging_guideline.title'
    }),
    content: fmt({
      id: 'home_page_activation.common_questions.packaging_guideline.content'
    })
  },
  {
    title: fmt({
      id: 'home_page_activation.common_questions.prohibited_items.title'
    }),
    content: (
      <ol>
        {PROHIBITED_ITEMS_LABELIDS.map((labelId) => (
          <li key={labelId}>
            {fmt({
              id: `home_page_activation.common_questions.prohibited_items.list.${labelId}`
            })}
          </li>
        ))}
      </ol>
    )
  }
];

export const PROHIBITED_ITEMS_MODAL_COLLAPSE_TITLE = [
  {
    title: fmt({ id: 'prohibited_items_modal.prohibited_items.title' }),
    subtitle: fmt({ id: 'prohibited_items_modal.prohibited_items.subtitle' }),
    content: ProhibitedItemsList,
    icon: ProhibitedItems
  },
  {
    title: fmt({ id: 'prohibited_items_modal.uncovered_zones.title' }),
    subtitle: fmt({ id: 'prohibited_items_modal.uncovered_zones.subtitle' }),
    content: UncoveredZonesList,
    icon: UncoveredZones
  }
];

export const PROHIBITED_ITEMS_MODAL_LIST = [
  { labelId: 'food_and_beverage', icon: FoodAndBeverage },
  { labelId: 'fragile', icon: Fragile },
  { labelId: 'illegal_items', icon: Illegal },
  { labelId: 'dangerous_items', icon: Dangerous },
  { labelId: 'unlicensed_items', icon: Unlicensed },
  { labelId: 'high_value', icon: Diamond }
];

export const ACTIVATION_STEPS_MAPPING = {
  CREATE_YOUR_FIRST_ORDER: 1,
  PACK_AND_PREPARE: 2,
  SCHEDULE_PICKUP: 3,
  RECEIVE_REVENUES: 4
};

export const ACTIVATION_STEPS_COUNT = 5;

export const SETUP_GUIDE_EXCLUDED_PATHNAME = ['overview'];
export const SETUP_GUIDE_AUTO_SHOW_PATHNAME = ['order-summary'];
