import {
  DEFAULT_ACCESS,
  FINANCE_ACCESS,
  WALLET_ACCESS,
  TECH_ACCESS,
  TEAM_MANAGMENT_ACCESS,
  FINANCE_WALLET_ACCESS,
  FINANCE_TECH_ACCESS,
  FINANCE_TEAM_MANAGMENT_ACCESS,
  WALLET_TECH_ACCESS,
  WALLET_TEAM_MANAGMENT_ACCESS,
  TECH_TEAM_MANAGMENT_ACCESS,
  FULL_ACCESS,
  FINANCE_WALLET_TECH_ACCESS,
  FINANCE_WALLET_TEAM_MANAGMENT_ACCESS,
  FINANCE_TEAM_MANAGMENT_TECH_ACCESS,
  WALLET_TEAM_MANAGMENT_TECH_ACCESS,
  BUSINESS_SUB_ACCOUNT,
  EDITOR_DEFAULT_ACCESS,
  EDITOR_FINANCE_ACCESS,
  EDITOR_WALLET_ACCESS,
  EDITOR_TECH_ACCESS,
  EDITOR_TEAM_MANAGMENT_ACCESS,
  EDITOR_FINANCE_WALLET_ACCESS,
  EDITOR_FINANCE_TECH_ACCESS,
  EDITOR_FINANCE_TEAM_MANAGMENT_ACCESS,
  EDITOR_WALLET_TECH_ACCESS,
  EDITOR_WALLET_TEAM_MANAGMENT_ACCESS,
  EDITOR_TECH_TEAM_MANAGMENT_ACCESS,
  EDITOR_FULL_ACCESS,
  EDITOR_FINANCE_WALLET_TECH_ACCESS,
  EDITOR_FINANCE_WALLET_TEAM_MANAGMENT_ACCESS,
  EDITOR_FINANCE_TEAM_MANAGMENT_TECH_ACCESS,
  EDITOR_WALLET_TEAM_MANAGMENT_TECH_ACCESS
} from 'constants/teams-and-members';

const aclMatrix = {
  ALL_ACCESS: [
    DEFAULT_ACCESS,
    EDITOR_DEFAULT_ACCESS,
    FINANCE_ACCESS,
    EDITOR_FINANCE_ACCESS,
    WALLET_ACCESS,
    EDITOR_WALLET_ACCESS,
    TECH_ACCESS,
    EDITOR_TECH_ACCESS,
    TEAM_MANAGMENT_ACCESS,
    EDITOR_TEAM_MANAGMENT_ACCESS,
    FINANCE_WALLET_ACCESS,
    EDITOR_FINANCE_WALLET_ACCESS,
    FINANCE_TECH_ACCESS,
    EDITOR_FINANCE_TECH_ACCESS,
    FINANCE_TEAM_MANAGMENT_ACCESS,
    EDITOR_FINANCE_TEAM_MANAGMENT_ACCESS,
    WALLET_TECH_ACCESS,
    EDITOR_WALLET_TECH_ACCESS,
    WALLET_TEAM_MANAGMENT_ACCESS,
    EDITOR_WALLET_TEAM_MANAGMENT_ACCESS,
    TECH_TEAM_MANAGMENT_ACCESS,
    EDITOR_TECH_TEAM_MANAGMENT_ACCESS,
    FULL_ACCESS,
    EDITOR_FULL_ACCESS,
    FINANCE_WALLET_TECH_ACCESS,
    EDITOR_FINANCE_WALLET_TECH_ACCESS,
    FINANCE_WALLET_TEAM_MANAGMENT_ACCESS,
    EDITOR_FINANCE_WALLET_TEAM_MANAGMENT_ACCESS,
    FINANCE_TEAM_MANAGMENT_TECH_ACCESS,
    EDITOR_FINANCE_TEAM_MANAGMENT_TECH_ACCESS,
    WALLET_TEAM_MANAGMENT_TECH_ACCESS,
    EDITOR_WALLET_TEAM_MANAGMENT_TECH_ACCESS,
    BUSINESS_SUB_ACCOUNT
  ],
  FINANCE_ACCESS: [
    FINANCE_WALLET_ACCESS,
    EDITOR_FINANCE_WALLET_ACCESS,
    FINANCE_TECH_ACCESS,
    EDITOR_FINANCE_TECH_ACCESS,
    FINANCE_TEAM_MANAGMENT_ACCESS,
    EDITOR_FINANCE_TEAM_MANAGMENT_ACCESS,
    FINANCE_WALLET_TECH_ACCESS,
    EDITOR_FINANCE_WALLET_TECH_ACCESS,
    FINANCE_ACCESS,
    EDITOR_FINANCE_ACCESS,
    FULL_ACCESS,
    EDITOR_FULL_ACCESS,
    FINANCE_WALLET_TEAM_MANAGMENT_ACCESS,
    EDITOR_FINANCE_WALLET_TEAM_MANAGMENT_ACCESS,
    FINANCE_TEAM_MANAGMENT_TECH_ACCESS,
    EDITOR_FINANCE_TEAM_MANAGMENT_TECH_ACCESS
  ],
  WALLET_ACCESS: [
    FULL_ACCESS,
    EDITOR_FULL_ACCESS,
    WALLET_ACCESS,
    EDITOR_WALLET_ACCESS,
    FINANCE_WALLET_ACCESS,
    EDITOR_FINANCE_WALLET_ACCESS,
    WALLET_TECH_ACCESS,
    EDITOR_WALLET_TECH_ACCESS,
    FINANCE_WALLET_TECH_ACCESS,
    EDITOR_FINANCE_WALLET_TECH_ACCESS,
    WALLET_TEAM_MANAGMENT_TECH_ACCESS,
    EDITOR_WALLET_TEAM_MANAGMENT_TECH_ACCESS,
    FINANCE_WALLET_TEAM_MANAGMENT_ACCESS,
    EDITOR_FINANCE_WALLET_TEAM_MANAGMENT_ACCESS,
    WALLET_TEAM_MANAGMENT_ACCESS,
    EDITOR_WALLET_TEAM_MANAGMENT_ACCESS
  ],
  TECHNOLOGY_ACCESS: [
    FULL_ACCESS,
    EDITOR_FULL_ACCESS,
    WALLET_TECH_ACCESS,
    EDITOR_WALLET_TECH_ACCESS,
    TECH_TEAM_MANAGMENT_ACCESS,
    EDITOR_TECH_TEAM_MANAGMENT_ACCESS,
    FINANCE_WALLET_TECH_ACCESS,
    EDITOR_FINANCE_WALLET_TECH_ACCESS,
    FINANCE_TECH_ACCESS,
    EDITOR_FINANCE_TECH_ACCESS,
    FINANCE_TEAM_MANAGMENT_TECH_ACCESS,
    EDITOR_FINANCE_TEAM_MANAGMENT_TECH_ACCESS,
    WALLET_TEAM_MANAGMENT_TECH_ACCESS,
    EDITOR_WALLET_TEAM_MANAGMENT_TECH_ACCESS,
    TECH_ACCESS,
    EDITOR_TECH_ACCESS
  ],
  FULL_ACCESS: [FULL_ACCESS, EDITOR_FULL_ACCESS],
  MANAGE_ACCESS: [
    TEAM_MANAGMENT_ACCESS,
    EDITOR_TEAM_MANAGMENT_ACCESS,
    FULL_ACCESS,
    EDITOR_FULL_ACCESS,
    WALLET_TEAM_MANAGMENT_ACCESS,
    EDITOR_WALLET_TEAM_MANAGMENT_ACCESS,
    TECH_TEAM_MANAGMENT_ACCESS,
    EDITOR_TECH_TEAM_MANAGMENT_ACCESS,
    FINANCE_TEAM_MANAGMENT_ACCESS,
    EDITOR_FINANCE_TEAM_MANAGMENT_ACCESS,
    FINANCE_WALLET_TEAM_MANAGMENT_ACCESS,
    EDITOR_FINANCE_WALLET_TEAM_MANAGMENT_ACCESS,
    FINANCE_TEAM_MANAGMENT_TECH_ACCESS,
    EDITOR_FINANCE_TEAM_MANAGMENT_TECH_ACCESS,
    WALLET_TEAM_MANAGMENT_TECH_ACCESS,
    EDITOR_WALLET_TEAM_MANAGMENT_TECH_ACCESS
  ]
};

export default aclMatrix;
