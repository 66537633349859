import React, { Component } from 'react';
class Page500 extends Component {
  render() {
    return (
      <div className="app full-height display-flex justify-center ">
        <span className="display-flex align-center justify-center">
          <h1 className="br-page-404__left-text">500</h1>
          <span>
            <h4 className="br-page-404__left-text-warning">
              Houston, we have a problem!
            </h4>
            <p className=" br-page-404__right-text">
              The page you are looking for is temporarily unavailable.
            </p>
          </span>
        </span>
      </div>
    );
  }
}

export default Page500;
