import React from 'react';
import { injectIntl } from 'react-intl';
import { Modal } from 'antd';
import stringSimilarity from 'string-similarity';

import { getGeocode } from 'services/cities';
import { sendSegment } from 'utils/segment';
import { COUNTRY_POINT_MAP } from 'constants/pickups';
import { MAP_MARKER_STATUS, ERROR_STATUS } from 'constants/brGoogleMap';

import BRGoogleMap from 'components/BRGoogleMap/BRGoogleMap';
import BRContentHeader from 'components/BRContentHeader/BRContentHeader';
import BRButton from 'components/BRButton/BRButton';

import './GoogleMapModal.less';

class GoogleMapModal extends React.Component {
  state = {
    userAddress: null,
    userPosition: COUNTRY_POINT_MAP,
    pickupAvailability: true,
    isLoading: false,
    locatedDistrict: false,
    markerStatus: '',
    googleMapResponse: [],
    districtStrings: [],
    selectedDistrictId: null
  };

  componentDidMount() {
    const { isMapUsed, allAreas = [] } = this.props;
    const { SUCCESS, INITIAL } = MAP_MARKER_STATUS;
    this.handleMarkerStatusChange(isMapUsed ? SUCCESS : INITIAL);

    if (allAreas.length) {
      const extractDistrictNames = (districts) =>
        districts
          .map(({ districtName, districtOtherName }) => [
            districtName,
            districtOtherName
          ])
          .flat();

      const districtStrings = allAreas
        .map(({ districts }) => extractDistrictNames(districts))
        .flat();

      this.setState({ districtStrings });
    }
  }

  findPossibleDistrictNames = (googleAddressComponent = []) => {
    const possibleDistrictNames = [];

    googleAddressComponent.forEach((addressComponent) => {
      if (
        ['administrative_area_level_2', 'administrative_area_level_3'].includes(
          addressComponent.types[0]
        )
      ) {
        possibleDistrictNames.push(addressComponent.long_name);
      }
    });

    return possibleDistrictNames.reverse();
  };

  findMatchingDistrict = (possibleDistrictNames = []) => {
    const { districtStrings } = this.state;
    const matches = [];

    if (!possibleDistrictNames.length) {
      return;
    }

    possibleDistrictNames.forEach((districtName) => {
      const cleanedDistrictName = districtName
        ?.replace(/Province|Governorate|محافظة/g, '')
        ?.trim();

      const districtSimilarity = stringSimilarity.findBestMatch(
        cleanedDistrictName,
        districtStrings
      );

      if (districtSimilarity.bestMatch.rating > 0.5) {
        matches.push(districtSimilarity);
      }
    });

    const bestMatchDistrictName = matches.reduce(
      (bestIndex, possibleMatch) =>
        possibleMatch.bestMatchIndex > bestIndex.bestMatchIndex
          ? possibleMatch
          : bestIndex,
      matches[0]
    );

    if (!bestMatchDistrictName) {
      return;
    }

    return bestMatchDistrictName.bestMatch.target;
  };

  getDistrictByName = (matchingDistrictName) => {
    const { allAreas = [] } = this.props;

    const allDistricts = allAreas.map(({ districts }) => districts).flat();

    return allDistricts.find(({ districtName, districtOtherName }) =>
      [districtName, districtOtherName].includes(matchingDistrictName)
    );
  };

  handleConfirmLocation = async () => {
    const { isGeoLocationRequired } = this.props;
    const { userPosition } = this.state;
    const { LOADING, SUCCESS, FAILED, UNDETERMINED } = MAP_MARKER_STATUS;

    if (isGeoLocationRequired) {
      return this.handleMarkerStatusChange(SUCCESS);
    }

    this.handleMarkerStatusChange(LOADING);
    this.setState({ selectedDistrictId: null });

    try {
      const payload = {
        searchType: 'latlng',
        searchString: Object.values(userPosition).join(',')
      };
      const data = await getGeocode(payload);

      if (data?.results?.length) {
        const googleAddressComponent = data.results[0]?.address_components;
        const possibleDistrictNames = this.findPossibleDistrictNames(
          googleAddressComponent
        );
        const matchingDistrict = this.findMatchingDistrict(
          possibleDistrictNames
        );

        if (!matchingDistrict) {
          this.setState({ pickupAvailability: false });
          this.handleMarkerStatusChange(UNDETERMINED);

          sendSegment('PIN_LOCATION_FAILED_TO_MAP', {
            googlePossibleDistrictNames: possibleDistrictNames.join(', ')
          });
        } else {
          const district = this.getDistrictByName(matchingDistrict);

          this.setState({ selectedDistrictId: district.districtId });

          sendSegment('PIN_LOCATION_MAPPED_SUCCESSFULLY', {
            googlePossibleDistrictNames: possibleDistrictNames.join(', '),
            'Matched District Name': matchingDistrict,
            'Matched District ID': district.districtId,
            'Pickup Availability': district.pickupAvailability
          });

          if (district.pickupAvailability) {
            this.handleMarkerStatusChange(SUCCESS);
          } else {
            this.setState({ pickupAvailability: false });
            this.handleMarkerStatusChange(FAILED);
          }
        }
      } else {
        this.setState({ pickupAvailability: false });
        this.handleMarkerStatusChange(UNDETERMINED);
      }
    } catch (error) {
      sendSegment('PIN_LOCATION_COULD_NOT_BE_MAPPED', {
        'Error message': error.message
      });
      this.setState({ pickupAvailability: false });
      this.handleMarkerStatusChange(
        error.status === ERROR_STATUS.UNDETERMINED_LOCATION
          ? UNDETERMINED
          : FAILED
      );
    }
    this.setState({ isLoading: false });
  };

  confirmLocationButtonClicked = async () => {
    const { close, onSuccess, isGeoLocationRequired } = this.props;
    const { userPosition, userAddress, selectedDistrictId } = this.state;

    if (selectedDistrictId) {
      onSuccess({
        userPosition,
        userAddress,
        selectedDistrictId
      });
      close();
    } else {
      if (isGeoLocationRequired) {
        onSuccess({
          userPosition
        });
      }
      close();
    }
  };

  setUserPosition = (userPosition) => {
    this.setState({ userPosition });
  };

  setUserAddress = (fulluserAddress) => {
    this.setState({ userAddress: fulluserAddress });
  };

  handleNewSearchInput = () => {
    this.setState({ pickupAvailability: true, locatedDistrict: false });
  };

  handleMarkerStatusChange = (status) => {
    this.setState({ markerStatus: status });
  };

  render() {
    const { close, intl, position, isMapUsed, isGeoLocationRequired } =
      this.props;
    const { pickupAvailability, isLoading, locatedDistrict, markerStatus } =
      this.state;
    const { SUCCESS } = MAP_MARKER_STATUS;

    return (
      <Modal
        {...this.props}
        footer={null}
        title={null}
        onCancel={close}
        width={null}
        wrapClassName="br-google-map-modal"
      >
        <BRContentHeader
          title={intl.formatMessage({
            id: 'br_google_map.actions.add_location'
          })}
          className="br-google-map-modal"
          titleClassName="br-google-map-modal__title display-xs"
        />
        <BRGoogleMap
          setCurrentPosition={this.setUserPosition}
          setCurrentAddress={this.setUserAddress}
          handleNewSearchInput={this.handleNewSearchInput}
          isMapUsed={isMapUsed}
          userPosition={position}
          className="br-google-map-modal_location"
          withSearch
          close={close}
          handleConfirmLocation={this.handleConfirmLocation}
          markerStatus={markerStatus}
          isGeoLocationRequired={isGeoLocationRequired}
          shouldDetectUserLocationOnMount
        />
        <div className="br-google-map-modal_location__footer">
          <BRButton
            type="basic"
            onClick={close}
            label={intl.formatMessage({
              id: 'common.cancel'
            })}
          />
          {pickupAvailability && !locatedDistrict ? (
            <BRButton
              disabled={markerStatus !== SUCCESS}
              type="primary"
              onClick={this.confirmLocationButtonClicked}
              label={intl.formatMessage({
                id: 'br_google_map.actions.confirm_location'
              })}
            />
          ) : (
            <BRButton
              type="primary"
              onClick={close}
              label={intl.formatMessage({
                id: 'br_google_map.actions.add_manually'
              })}
            />
          )}
        </div>
      </Modal>
    );
  }
}

export default injectIntl(GoogleMapModal);
