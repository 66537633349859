import React, { useState, useEffect } from 'react';
import { Modal } from 'antd';
import { injectIntl } from 'react-intl';

import { sendSegment } from 'utils/segment';
import { isInstantCashoutPlanAvailable } from 'constants/countries/countries-mapping';

import DeliveryTypesSection from '../DeliveryTypesSection/DeliveryTypesSection';
import PricingCalculatorForm from '../PricingCalculatorForm/PricingCalculatorForm';
import PricingFlagsSection from '../PricingFlagsSection/PricingFlagsSection';
import BRButton from 'components/BRButton/BRButton';

import { ReactComponent as LeftArrow } from 'assets/bosta-icons/Arrow-left.svg';

import './PricingCalculatorModal.less';

const PricingCalculatorModal = ({ close, intl, hidePlanSection, ...props }) => {
  const pricingTierIds = JSON.parse(localStorage.getItem('userInfo'))?.user
    ?.country?.pricingSignUpTiers;

  const [selectedPlan, setSelectedPlan] = useState(
    hidePlanSection
      ? null
      : isInstantCashoutPlanAvailable()
      ? pricingTierIds?.businessTierId
      : pricingTierIds?.basicTierId
  );

  const [type, setType] = useState('SEND');
  const [pricingData, setPricingData] = useState(null);
  const [cod, setCod] = useState(0);

  useEffect(() => {
    sendSegment('Open_Price_Calcultaor', {
      Source: hidePlanSection ? 'Pricing_Page' : 'Setup_Page'
    });
  }, []);

  const onPricePlanClick = (value) => {
    setSelectedPlan(value);
    sendSegment('Price_Plan_Changed');
  };

  const PLANS = {
    instant: {
      title: intl.formatMessage({
        id: 'pricing_plans_calculator.modal.plans_section.instant_cashout_title'
      }),
      sub: intl.formatMessage({
        id: 'pricing_plans_calculator.modal.plans_section.instant_cashout_sub'
      }),
      value: pricingTierIds?.businessTierId
    },
    weekly: {
      title: intl.formatMessage({
        id: 'pricing_plans_calculator.modal.plans_section.weekly_cashout_title'
      }),
      sub: intl.formatMessage({
        id: 'pricing_plans_calculator.modal.plans_section.weekly_cashout_sub'
      }),
      value: pricingTierIds?.basicTierId
    }
  };
  return (
    <Modal
      className="br-pricing-calculator-modal"
      {...props}
      onCancel={close}
      title={
        <div>
          <LeftArrow onClick={close} />
          <span className="display-xs">
            {intl.formatMessage({
              id: 'pricing_plans_calculator.modal.header'
            })}
          </span>
        </div>
      }
      footer={
        <div>
          <BRButton
            type="secondry"
            label={intl.formatMessage({ id: 'common.cancel' })}
            onClick={close}
          />
        </div>
      }
      width={null}
    >
      {!hidePlanSection && (
        <div className="br-pricing-calculator__choose-plan__container">
          <span className="display-xs">
            {intl.formatMessage({
              id: 'pricing_plans_calculator.modal.plans_section.title'
            })}
          </span>
          <div className="br-pricing-calculator__plan-content">
            {isInstantCashoutPlanAvailable() && (
              <div
                className={`${
                  selectedPlan === pricingTierIds?.businessTierId
                    ? 'selected-plan-card'
                    : ''
                }`}
                onClick={() => onPricePlanClick(PLANS.instant.value)}
              >
                <span
                  className={`${
                    selectedPlan === pricingTierIds?.businessTierId
                      ? 'body-medium'
                      : 'body'
                  }`}
                >
                  {intl.formatMessage({
                    id: 'pricing_plans_calculator.modal.plans_section.instant_cashout'
                  })}
                </span>
              </div>
            )}
            <div
              className={`${
                selectedPlan !== pricingTierIds?.businessTierId
                  ? 'selected-plan-card'
                  : ''
              }`}
              onClick={() => onPricePlanClick(PLANS.weekly.value)}
            >
              <span
                className={`${
                  selectedPlan !== pricingTierIds?.businessTierId
                    ? 'body-medium'
                    : 'body'
                }`}
              >
                {intl.formatMessage({
                  id: 'pricing_plans_calculator.modal.plans_section.weekly_cashout'
                })}
              </span>
            </div>
          </div>
          <div className="br-pricing-calculator__selected-plan-info">
            <span className="heading">
              {
                PLANS[
                  selectedPlan !== pricingTierIds?.businessTierId
                    ? 'weekly'
                    : 'instant'
                ].title
              }
            </span>
            <span className="caption-lg">
              {
                PLANS[
                  selectedPlan !== pricingTierIds?.businessTierId
                    ? 'weekly'
                    : 'instant'
                ].sub
              }
            </span>
          </div>
        </div>
      )}

      <div className="br-pricing-calculator__main-part__container">
        <div>
          <DeliveryTypesSection setType={setType} />
          <PricingCalculatorForm
            type={type}
            tierId={selectedPlan}
            setPricingData={setPricingData}
            setCod={setCod}
          />
        </div>
        <div>
          <PricingFlagsSection pricingData={pricingData} cod={cod} />
        </div>
      </div>
    </Modal>
  );
};

export default injectIntl(PricingCalculatorModal);
