import { forwardRef } from 'react';
import { useIntl } from 'react-intl';

import {
  SIGNUP_PRICING_COLUMNS,
  SIGNUP_PRICING_DATA,
  SIGNUP_PRICING_FEATURES_LABEL_IDS
} from 'constants/signup';

import BRTable from 'components/BRNewTable/BRTable';

import { ReactComponent as Check } from 'assets/bosta-icons/Check.svg';

import './SignUpPricing.less';

const SignUpPricing = (props, ref) => {
  const intl = useIntl();

  return (
    <div className="br-signup-pricing__container" ref={ref}>
      <div className="br-signup-pricing__title display-sm">
        {intl.formatMessage({ id: 'new_sign_up.pricing.title' })}
      </div>
      <div className="br-signup-pricing__subtitle">
        {intl.formatMessage({ id: 'new_sign_up.pricing.subtitle' })}
      </div>
      <BRTable
        className="br-signup-pricing__table"
        columns={SIGNUP_PRICING_COLUMNS}
        listingData={SIGNUP_PRICING_DATA}
        showFilter={false}
        pagination={false}
      />
      <div className="br-signup-pricing__features">
        {SIGNUP_PRICING_FEATURES_LABEL_IDS.map((labelId) => (
          <div key={labelId}>
            <Check />
            {intl.formatMessage({
              id: `new_sign_up.pricing.features.${labelId}`
            })}
          </div>
        ))}
      </div>
    </div>
  );
};

export default forwardRef(SignUpPricing);
