import { useCallback, useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { Modal, Checkbox, Tag, Tooltip } from 'antd';
import Icon from '@ant-design/icons';
import classnames from 'classnames';
import { mediaHook } from 'customHooks';
import InfiniteScroll from 'react-infinite-scroller';

import { LOCALE } from 'constants/intl-wrapper';
import { formatNumbersBasedOnLanguage } from 'utils/intl-wrapper';
import { listAllDeliveries, printMassAirwayBills } from 'services/shipments';
import { NEW_ORDERS_STATE_CODES } from 'constants/pickups';
import {
  PUDO_PROVIDER,
  MAP_DELIVERIES_CODES,
  MAP_BEFORE_TYPE,
  DELIVERY_TYPES_CODES,
  PACKING_GUIDE_LINK
} from 'constants/shipments';
import {
  getSmartFlyersLocalizationId,
  isSmartStickersAvailable
} from 'constants/countries/countries-mapping';
import { checkSmartDate, dates } from 'utils/helpers';
import { COUNTRY_CURRENCY } from 'constants/helpers';
import { downloadAsPdf } from 'utils/download';
import { sendSegment } from 'utils/segment';
import { getDeviceType } from 'utils';
import { useModal } from 'contexts/modalProvider.context';

import BRButton from 'components/BRButton/BRButton';
import VideoModal from 'components/Wallet/components/VideoModal/VideoModal';
import { notify } from 'components/Notify/Notify';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import BREmptyState from 'components/BREmptyState/BREmptyState';
import SmartStickerGuideModal from 'components/SmartStickerGuideModal/SmartStickerGuideModal';

import { ReactComponent as BoxIcon } from 'assets/bosta-icons/box.svg';
import { ReactComponent as PlayIcon } from 'assets/bosta-icons/play.svg';
import { ReactComponent as PackIcon } from 'assets/bosta-icons/pack.svg';
import { ReactComponent as Printer } from 'assets/bosta-icons/Printer.svg';
import { ReactComponent as Calendar } from 'assets/bosta-icons/Calendar.svg';
import { ReactComponent as CloseIcon } from 'assets/bosta-icons/Close.svg';
import { ReactComponent as ArrowLeft } from 'assets/bosta-icons/Chevron-left.svg';
import { ReactComponent as FXFBagde } from 'assets/bosta-icons/Fxf-Badge.svg';
import { ReactComponent as NegativeCOD } from 'assets/bosta-icons/Negative-COD.svg';
import { ReactComponent as ProfileIcon } from 'assets/bosta-icons/Profile.svg';
import { ReactComponent as Printer3dIcon } from 'assets/bosta-icons/Printer3d.svg';
import EmptyOrdersView from 'assets/bosta-images/PrepareOrderEmpty.svg';

import './PrepareOrdersModal.less';

const PrepareOrdersModal = ({
  close,
  intl,
  mobileScreenSizes: { isSmallMobileScreen },
  history,
  businessInfo,
  setBusinessInfo,
  pickupCutoffTime,
  handleOpenCreateEditPickupModal = () => {},
  onSuccess = () => {},
  source,
  isFromPickupModal,
  ...props
}) => {
  const [printingAwbView, setPrintingAwbView] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [deliveries, setDeliveries] = useState([]);
  const [selectedDeliveriesIds, setSelectedDeliveriesIds] = useState([]);
  const [hasMoreDeliveries, setHasMoreDeliveries] = useState(false);
  const [page, setPage] = useState(1);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [allDeliveriesCount, setAllDeliveriesCount] = useState(0);

  const { openModal } = useModal();

  const onCheckBoxChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedDeliveriesIds([...selectedDeliveriesIds, value]);
    } else {
      setSelectedDeliveriesIds((oldSelectedDeliveries) =>
        oldSelectedDeliveries.filter((id) => id !== value)
      );
    }
  };

  const onSelectAllChange = () => {
    if (selectedDeliveriesIds.length === deliveries.length) {
      setSelectedDeliveriesIds([]);
    } else {
      setSelectedDeliveriesIds(deliveries.map(({ _id }) => _id));
    }
  };

  const getNewOrders = async () => {
    try {
      setPrintingAwbView(true);
      setIsLoading(true);
      const { deliveries, count } = await listAllDeliveries({
        limit: 200,
        page,
        stateCodes: NEW_ORDERS_STATE_CODES
      });
      setDeliveries(deliveries);
      setAllDeliveriesCount(count);
      setHasMoreDeliveries(deliveries.length < count);
      setSelectedDeliveriesIds(deliveries.map(({ _id }) => _id));
    } catch (error) {
      notify({ msg: error.message, error });
    }
    setIsLoading(false);
  };

  const getTrackingNumbers = () => {
    return selectedDeliveriesIds.map(
      (selectedDelivery) =>
        deliveries.find((delivery) => delivery._id === selectedDelivery)
          .trackingNumber
    );
  };

  const handlePrintAirwayBill = async () => {
    try {
      setIsLoading(true);
      const allDeliveriesSelected =
        selectedDeliveriesIds.length === deliveries.length;
      const payload = allDeliveriesSelected
        ? { allPendingOrders: true }
        : { ids: selectedDeliveriesIds.toString() };
      const data = await printMassAirwayBills(payload);
      const trackingNumbers = getTrackingNumbers();
      if (typeof data === 'string') {
        const fileName = `Orders${trackingNumbers.join(', ')}.pdf`;
        downloadAsPdf(data, fileName);
      } else {
        notify({
          msg: intl.formatMessage({ id: 'common.export_success' }),
          type: 'success'
        });
      }
      setPrintingAwbView(false);
    } catch (error) {
      notify({ msg: error.message, error });
    }
    setIsLoading(false);
  };

  const handleClose = () => {
    if (isFromPickupModal) {
      close();
      handleOpenCreateEditPickupModal({
        openModal,
        onSuccess,
        hideSchedulingPickupModal: true
      });
    } else {
      close();
    }
  };

  const fetchNextDeliveries = async () => {
    const pageNumber = page + 1;
    setPage(pageNumber);
    setIsLoadingMore(true);
    try {
      const { deliveries: moreDeliveries } = await listAllDeliveries({
        limit: 200,
        page: pageNumber,
        stateCodes: NEW_ORDERS_STATE_CODES
      });
      const newDeliveries = deliveries.concat(moreDeliveries);
      setDeliveries(newDeliveries);
      setHasMoreDeliveries(newDeliveries.length < allDeliveriesCount);
      setSelectedDeliveriesIds(newDeliveries.map(({ _id }) => _id));
    } catch (error) {
      notify({ msg: error.message, error });
    }
    setIsLoadingMore(false);
  };

  const handleSmartStickersClick = (e) => {
    e.stopPropagation();
    sendSegment('Stickers_Opened_Guide', {
      'Entry Point': 'Web_Prepare your order',
      'Device Type': getDeviceType()
    });
    close();
    openModal(SmartStickerGuideModal, {
      prepareOrdersModalProps: {
        history,
        businessInfo,
        setBusinessInfo,
        source,
        pickupCutoffTime
      }
    });
  };

  const prepareStep = useCallback(
    ({
      icon,
      title,
      desc,
      buttonLabel,
      descNewLine,
      descClassName = '',
      buttonAction,
      transparentIcon,
      banner
    }) => {
      return (
        <div className="br-prepare-orders__step" onClick={buttonAction}>
          <div className="br-prepare-orders__step-info">
            <Icon
              className={classnames(
                'br-prepare-orders__step-icon',
                'ant-icon-md',
                {
                  'br-prepare-orders__transparent-icon': transparentIcon
                }
              )}
              component={icon}
            />
            <span className="body-medium">{title}</span>
            <span
              className={classnames(
                'br-prepare-orders-modal__text-gray',
                'body',
                descClassName
              )}
            >
              {desc}
              {banner && !isSmallMobileScreen && (
                <span className="br-prepare-orders-modal__banner">
                  {banner}
                </span>
              )}
            </span>

            {descNewLine && (
              <span className="br-prepare-orders-modal__text-gray body">
                {descNewLine}
              </span>
            )}
          </div>
          <BRButton type="link-color" className="button" label={buttonLabel} />
          {banner && isSmallMobileScreen && (
            <div className="br-prepare-orders-modal__banner">{banner}</div>
          )}
        </div>
      );
    },
    [isSmallMobileScreen]
  );

  const renderStepsView = (
    <>
      <div className="br-prepare-orders__title-and-steps">
        <div className="br-prepare-orders__title-section">
          <Icon className="br-prepare-orders__icon" component={BoxIcon} />
          <span className="display-sm">
            {intl.formatMessage({
              id: `new_orders.prepare_orders_modal.prepare_title`
            })}
          </span>
          <span className="br-prepare-orders-modal__text-gray">
            {intl.formatMessage({
              id: `new_orders.prepare_orders_modal.prepare_subtitle`
            })}
          </span>
        </div>
        <div className="br-prepare-orders__steps-section">
          <div className="br-prepare-orders__stepper">
            {[1, 2, 3].map((number) => (
              <span
                key={number}
                className="body-medium br-prepare-orders-modal__text-gray"
              >
                {formatNumbersBasedOnLanguage(number)}
              </span>
            ))}
          </div>
          <div className="br-prepare-orders__steps-container">
            {prepareStep({
              icon: PackIcon,
              title: intl.formatMessage({
                id: `new_orders.prepare_orders_modal.steps.pack`
              }),
              desc: isSmallMobileScreen ? (
                <>
                  {intl.formatMessage({
                    id: `new_orders.prepare_orders_modal.steps.pack_desc`
                  })}{' '}
                  {intl.formatMessage({
                    id: `new_orders.prepare_orders_modal.steps.pack_desc_newline`
                  })}
                </>
              ) : (
                intl.formatMessage({
                  id: `new_orders.prepare_orders_modal.steps.pack_desc`
                })
              ),
              ...(!isSmallMobileScreen && {
                descNewLine: intl.formatMessage({
                  id: `new_orders.prepare_orders_modal.steps.pack_desc_newline`
                })
              }),
              buttonLabel: intl.formatMessage({
                id: `new_orders.prepare_orders_modal.steps.request_materials`
              }),
              buttonAction: () => {
                sendSegment('Step1_Request_Materials');
                window.open('/shop', '_blank');
              }
            })}
            {prepareStep({
              icon: Printer,
              title: intl.formatMessage({
                id: 'smart_massupload.upload_success.print_air_waybills'
              }),
              desc: intl.formatMessage({
                id: `new_orders.prepare_orders_modal.steps.print_desc`
              }),
              descClassName: 'br-prepare-orders-modal__small-desc',
              banner: isSmartStickersAvailable() && (
                <span className="br-prepare-orders-modal__smart-stickers body-medium">
                  <Printer3dIcon />
                  {intl.formatMessage(
                    {
                      id: getSmartFlyersLocalizationId(
                        `new_orders.prepare_orders_modal.steps.smart_stickers`
                      )
                    },
                    {
                      span: (children) => (
                        <BRButton
                          type="link-color"
                          label={children}
                          onClick={(e) => handleSmartStickersClick(e)}
                        />
                      )
                    }
                  )}
                </span>
              ),
              buttonLabel: intl.formatMessage({
                id: `new_orders.prepare_orders_modal.steps.print_awb`
              }),
              buttonAction: () => {
                sendSegment('Step2_Print_AWBs');
                getNewOrders();
              }
            })}
            {prepareStep({
              icon: Calendar,
              transparentIcon: true,
              title: intl.formatMessage({
                id: `nav_bar.schedule_pickup`
              }),
              desc: intl.formatMessage({
                id: `new_orders.prepare_orders_modal.steps.schedule_desc`
              }),
              descClassName: 'br-prepare-orders-modal__small-desc',
              buttonLabel: intl.formatMessage({
                id: `nav_bar.schedule_pickup`
              }),
              buttonAction: () => {
                close();
                sendSegment('Step3_Schedule_Pickup');
                handleOpenCreateEditPickupModal({
                  openModal,
                  onSuccess
                });
              }
            })}
          </div>
        </div>
      </div>
      <div className="br-prepare-orders__actions">
        <BRButton
          className="button"
          type="basic"
          label={intl.formatMessage({
            id: `new_orders.prepare_orders_modal.play`
          })}
          prefixIcon={<Icon component={PlayIcon} />}
          onClick={() => {
            sendSegment('Play_Preparation_Guide');
            openModal(VideoModal, {
              src: 'https://www.youtube.com/embed/WSzqmyxaGXE',
              showCloseIcon: true
            });
          }}
        />
        <BRButton
          className="button"
          type="link-gray"
          label={intl.formatMessage({
            id: `new_order.order_details.packing_guidelines`
          })}
          onClick={() => {
            sendSegment('Packing_Guidelines');
            window.open(PACKING_GUIDE_LINK());
          }}
        />
      </div>
      <BRButton
        className="br-prepare-orders-modal__close"
        type="link-gray"
        suffixIcon={<Icon className="ant-icon-md" component={CloseIcon} />}
        onClick={handleClose}
      />
    </>
  );

  const renderAwbView = (
    <LoadingWrapper
      wrapperClassName="br-prepare-orders-modal__delivery-loading"
      loading={isLoading}
    >
      {!deliveries.length ? (
        <BREmptyState
          className="br-table-component__empty-state"
          title={intl.formatMessage({
            id: `new_orders.prepare_orders_modal.empty_state.title`
          })}
          subTitle={intl.formatMessage({
            id: `new_orders.prepare_orders_modal.empty_state.subtitle`
          })}
          actions={
            <BRButton
              type="primary"
              label={intl.formatMessage({
                id: 'overview.button_text_empty_state'
              })}
              onClick={() => {
                close();
                history.push('/create-order');
              }}
            />
          }
          emptyViewImage={EmptyOrdersView}
        />
      ) : (
        <div className="br-prepare-orders-modal__scroll-container">
          <InfiniteScroll
            className="br-prepare-orders-modal__infinte-scroll"
            pageStart={0}
            initialLoad={false}
            loadMore={() => {
              !isLoadingMore && fetchNextDeliveries();
            }}
            hasMore={hasMoreDeliveries}
            useWindow={false}
          >
            {deliveries.map((delivery) => (
              <Checkbox
                key={delivery._id}
                value={delivery._id}
                checked={selectedDeliveriesIds.includes(delivery._id)}
                onChange={onCheckBoxChange}
              >
                <div className="display-flex justify-space-between">
                  <BRButton
                    className="br-prepare-orders-modal__delivery-tracking body-medium"
                    onClick={(e) => {
                      e.stopPropagation();
                      window.open(`/orders/${delivery.trackingNumber}`);
                    }}
                    label={`#${delivery.trackingNumber}`}
                    type="link-gray"
                  />
                  <span>
                    <div className="display-flex align-end">
                      <span>
                        {intl.formatMessage({
                          id: `orders.shipment_details.shipment_details_summary.order_types.${
                            delivery.type.code ===
                              DELIVERY_TYPES_CODES.RETURN_TO_ORIGIN &&
                            delivery?.type_before
                              ? MAP_DELIVERIES_CODES[
                                  MAP_BEFORE_TYPE[delivery?.type_before]
                                ]
                              : MAP_DELIVERIES_CODES[delivery.type?.code]
                          }`
                        })}
                      </span>
                      {delivery?.isFulfillmentOrder && (
                        <Tooltip
                          title={intl.formatMessage({
                            id: 'orders_listing.fxf_badge__tooltip'
                          })}
                        >
                          <Tag className="br-orders__fxf-type-badge subheading">
                            <Icon
                              component={FXFBagde}
                              className="ant-icon-md"
                            />
                            {intl.formatMessage({
                              id: 'orders_listing.fxf_badge'
                            })}
                          </Tag>
                        </Tooltip>
                      )}
                      {delivery?.integrationInfo?.provider ===
                        PUDO_PROVIDER && (
                        <Tag className="br-orders__fxf-type-badge subheading">
                          {intl.formatMessage({ id: 'orders_listing.pudo' })}
                        </Tag>
                      )}
                    </div>
                  </span>
                </div>
                <div className="display-flex justify-space-between br-prepare-orders-modal__delivery-date-cod">
                  <span className="caption br-prepare-orders-modal__text-gray">
                    {checkSmartDate(
                      delivery.updatedAt
                        ? delivery.updatedAt
                        : delivery.createdAt
                    )}
                    {dates(
                      delivery.updatedAt
                        ? delivery.updatedAt
                        : delivery.createdAt,
                      ' hh:mm A'
                    )}
                  </span>
                  <span className="caption-lg display-flex align-center br-prepare-orders-modal__text-gray">
                    {delivery.cod ? (
                      <>
                        {Math.sign(delivery.cod) === -1 && (
                          <Icon
                            component={NegativeCOD}
                            className="ant-icon-md"
                          />
                        )}
                        {intl.formatMessage(
                          {
                            id: COUNTRY_CURRENCY
                          },
                          { cod: formatNumbersBasedOnLanguage(delivery.cod) }
                        )}
                      </>
                    ) : (
                      intl.formatMessage({
                        id: `common.no_cash_on_delivery`
                      })
                    )}
                  </span>
                </div>
                <div className="display-flex br-prepare-orders-modal__delivery-profile">
                  <Icon className="ant-icon-md" component={ProfileIcon} />
                  {delivery.receiver.fullName
                    ? delivery.receiver.fullName
                    : `${delivery.receiver.firstName} ${delivery.receiver.lastName}`}
                </div>
              </Checkbox>
            ))}
            {isLoadingMore && <LoadingWrapper />}
          </InfiniteScroll>
        </div>
      )}
    </LoadingWrapper>
  );

  useEffect(() => {
    sendSegment('Prepare_Your_Order_Modal', {
      Source: source
    });
  }, []);

  return (
    <Modal
      className={classnames('br-prepare-orders-modal', {
        'br-prepare-orders-modal__awb-view':
          deliveries.length && printingAwbView,
        'br-prepare-orders-modal__empty-state':
          !deliveries.length && printingAwbView
      })}
      onCancel={handleClose}
      title={
        printingAwbView ? (
          <>
            <BRButton
              type="link-gray"
              suffixIcon={
                <Icon
                  className="ant-icon-md"
                  component={ArrowLeft}
                  rotate={
                    [LOCALE.AR, LOCALE.AR_SA].includes(intl.locale) && 180
                  }
                />
              }
              onClick={() => {
                setPrintingAwbView(false);
              }}
            />
            <span className="heading">
              {intl.formatMessage({
                id: `new_orders.prepare_orders_modal.steps.print_awb`
              })}
            </span>
            <BRButton
              type="link-gray"
              suffixIcon={
                <Icon className="ant-icon-md" component={CloseIcon} />
              }
              onClick={handleClose}
            />
          </>
        ) : null
      }
      footer={
        printingAwbView && !!deliveries.length ? (
          <>
            <Checkbox
              indeterminate={
                !!selectedDeliveriesIds.length &&
                selectedDeliveriesIds.length !== deliveries.length
              }
              checked={
                selectedDeliveriesIds.length === deliveries.length && !isLoading
              }
              disabled={isLoading}
              onChange={onSelectAllChange}
            >
              {intl.formatMessage({
                id: `common.select_all`
              })}
            </Checkbox>
            <BRButton
              type="primary"
              className="button"
              prefixIcon={<Printer />}
              disabled={isLoading || !selectedDeliveriesIds.length}
              label={intl.formatMessage(
                {
                  id: `new_orders.prepare_orders_modal.print_awb`
                },
                {
                  count:
                    selectedDeliveriesIds.length === deliveries.length
                      ? allDeliveriesCount
                      : selectedDeliveriesIds.length
                }
              )}
              onClick={handlePrintAirwayBill}
            />
          </>
        ) : null
      }
      {...props}
    >
      {printingAwbView ? renderAwbView : renderStepsView}
    </Modal>
  );
};

export default injectIntl(mediaHook(PrepareOrdersModal));
