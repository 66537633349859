import { bostaHttp } from 'http.js';

export const checkIsNewFeaturesShown = () => {
  return bostaHttp.get(`/businesses/feature-view`);
};

export const updateIsNewFeaturesShown = (payload) => {
  return bostaHttp.put(`/businesses/feature-view`, payload);
};

export const getForeignCountries = () => {
  return bostaHttp.get(`/foreign-countries`);
};

export const getCountryBanks = (countryId) => {
  return bostaHttp.get(`/foreign-countries/international-banks/${countryId}`);
};
