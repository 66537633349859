import React, { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';

import { ReactComponent as Check } from 'assets/imgRevamp/CheckIcon.svg';
import RegistrationContainer from 'components/RegistrationContainer/RegistrationContainer';

import './EmailResetPassword.less';

const EmailResetPassword = (props) => {
  const {
    location: { state },
    intl,
    history
  } = props;

  const isPasswordExpired = state?.isPasswordExpired;

  useEffect(() => {
    if (state === undefined) {
      history.push({ pathname: '/Forget-password' });
    }
  }, [history, state]);

  return (
    <RegistrationContainer
      fromSllr={state?.isFromSllr}
      hideTrackOrderInHeader
      className="br-confirm-reset-container"
      content={
        <div className="br-confirm-reset-parent">
          <span className="br-confirm-reset-parent__check-icon">
            <Check />
          </span>
          <span className="subheading br-confirm-reset-parent__title">
            {intl.formatMessage({
              id: isPasswordExpired
                ? 'login.set_new_passowrd.expired_title'
                : 'login.reset_password.title'
            })}
          </span>
          <span className="display-md">
            {intl.formatMessage({ id: 'login.reset_password.description' })}
          </span>
          <span className="display-md br-confirm-reset-parent__user-email">
            {state?.email}
          </span>
        </div>
      }
    />
  );
};
export default injectIntl(withRouter(EmailResetPassword));
