/* eslint-disable max-len */
import React, { lazy } from 'react';
import { retry } from 'utils';
import { COUNTRIES } from 'constants/country-data';
import { isUserAuth } from 'utils/helpers';
import {
  isAnalyticsBlocked,
  isUserFullyActivated
} from 'utils/features-access';

import aclMatrix from './constants/aclMatrix';
import { isDomesticShippingAvailable } from 'constants/countries/countries-mapping';

const userInfo = JSON.parse(localStorage.getItem('userInfo'));
const isSaudi =
  userInfo?.user?.country?.code === COUNTRIES[1].codeName || false;

const Analytics = lazy(
  async () =>
    await retry(async () => await import('components/Analytics/Analytics'))
);
const CreateEditOrder = lazy(
  async () =>
    await retry(
      async () =>
        await import(
          'components/Orders/components/CreateEditOrder/CreateEditOrder'
        )
    )
);
const OrderSummary = lazy(
  async () =>
    await retry(
      async () =>
        await import('components/Orders/components/OrderSummary/OrderSummary')
    )
);
const FlyersShop = lazy(
  async () =>
    await retry(async () => await import('components/FlyersShop/FlyersShop'))
);
const Borrow = lazy(
  async () => await retry(async () => await import('components/Borrow/Borrow'))
);
const InternationalShipping = lazy(() =>
  retry(() => import('components/InternationalShipping/InternationalShipping'))
);
const PaymentRedirect = lazy(
  async () =>
    await retry(
      async () => await import('components/PaymentRedirect/PaymentRedirect')
    )
);
const Orders = lazy(
  async () =>
    await retry(async () => await import('components/Orders/OrdersContainer'))
);
const SupporTickets = lazy(
  async () =>
    await retry(
      async () => await import('components/SupportTickets/SupportTickets')
    )
);
const Pickups = lazy(
  async () =>
    await retry(async () => await import('components/Pickups/Pickups'))
);
const Overview = lazy(
  async () =>
    await retry(async () => await import('components/Overview/Overview'))
);
const Inventory = lazy(
  async () =>
    await retry(
      async () => await import('components/Fulfillment/FulfillmentContainer')
    )
);
const NewSettings = lazy(
  async () =>
    await retry(async () => await import('components/Settings/Settings'))
);

const Wallet = lazy(
  async () =>
    await retry(async () => await import('./components/Wallet/Wallet'))
);

const Products = lazy(
  async () =>
    await retry(
      async () => await import('./components/Products/ProductsContainer')
    )
);

const BostaCapital = lazy(
  async () =>
    await retry(
      async () => await import('components/BostaCapital/BostaCapital')
    )
);

const HomePageActivation = lazy(
  async () =>
    await retry(
      async () =>
        await import('components/HomePageActivation/HomePageActivation')
    )
);

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = (businessInfo) => {
  const isFirstOrderCreated = businessInfo?.isFirstOrderCreated;
  const isFirstPickupCreated = businessInfo?.isFirstPickupCreated;

  return [
    {
      path: '/orders',
      name: 'Orders',
      component: Orders,
      access: [...aclMatrix.ALL_ACCESS]
    },
    {
      path: '/create-order',
      exact: true,
      name: 'NewShipment',
      access: !isDomesticShippingAvailable() ? [] : [...aclMatrix.ALL_ACCESS],
      component: CreateEditOrder
    },
    {
      path: '/create-international-order',
      exact: true,
      name: 'NewInternationalOrder',
      access: businessInfo?.internationalShippingActivated
        ? [...aclMatrix.ALL_ACCESS]
        : [],
      component: CreateEditOrder,
      initialProps: {
        isInternationalOrder: true
      }
    },
    {
      path: '/create-international-order/order-summary',
      exact: true,
      name: 'InternationalOrderSummary',
      access: businessInfo?.internationalShippingActivated
        ? [...aclMatrix.ALL_ACCESS]
        : [],
      component: OrderSummary,
      initialProps: {
        isInternationalOrder: true
      }
    },
    {
      path: '/create-order/order-summary',
      exact: true,
      name: 'OrderSummary',
      component: OrderSummary,
      access: [...aclMatrix.ALL_ACCESS]
    },
    {
      path: '/edit-order/:id',
      exact: true,
      name: 'NewShipment',
      component: CreateEditOrder,
      access: [...aclMatrix.ALL_ACCESS]
    },
    {
      path: '/create-fulfillment-return/:id',
      exact: true,
      name: 'CreateFulfillmentReturn',
      component: CreateEditOrder,
      access: [...aclMatrix.ALL_ACCESS]
    },
    {
      path: '/create-fulfillment-exchange/:id',
      exact: true,
      name: 'CreateFulfillmentExchange',
      component: CreateEditOrder,
      access: [...aclMatrix.ALL_ACCESS]
    },
    {
      path: '/edit-international-order/:id',
      exact: true,
      name: 'NewInternationalOrder',
      component: CreateEditOrder,
      access: businessInfo?.internationalShippingActivated
        ? [...aclMatrix.ALL_ACCESS]
        : [],
      initialProps: {
        isInternationalOrder: true
      }
    },
    {
      path: '/pickups',
      name: 'Pickups',
      component: Pickups,
      access: [...aclMatrix.ALL_ACCESS]
    },
    {
      path: '/products',
      name: 'Products',
      component: Products,
      access: [...aclMatrix.ALL_ACCESS]
    },
    {
      path: '/thank-you',
      exact: true,
      name: 'thank-you',
      component: Overview,
      access: [...aclMatrix.ALL_ACCESS]
    },
    {
      path: '/overview',
      name: 'Overview',
      component:
        businessInfo.isRegisteredViaBetaFunnel && !isUserFullyActivated
          ? HomePageActivation
          : Overview,
      access: [...aclMatrix.ALL_ACCESS]
    },
    {
      path: '/inventory',
      name: 'Inventory',
      component: Inventory,
      access: [...aclMatrix.ALL_ACCESS]
    },
    {
      path: '/box',
      name: 'BostaBox',
      component: InternationalShipping,
      access: isSaudi ? [] : [...aclMatrix.ALL_ACCESS]
    },
    {
      path: '/borrow',
      name: 'Borrow',
      component: Borrow,
      access: []
    },
    {
      path: '/payment',
      exact: true,
      name: 'Payment',
      component: PaymentRedirect,
      access: [...aclMatrix.ALL_ACCESS]
    },
    {
      path: '/shop',
      exact: true,
      name: 'BostaShop',
      component: FlyersShop,
      access: [...aclMatrix.ALL_ACCESS]
    },
    {
      path: '/wallet',
      name: 'Wallet',
      component: Wallet,
      access: [...aclMatrix.WALLET_ACCESS]
    },
    {
      path: '/analytics',
      name: 'Analytics',
      component: Analytics,
      access: isAnalyticsBlocked ? [] : [...aclMatrix.ALL_ACCESS]
    },
    {
      path: '/support-tickets',
      exact: true,
      name: 'support',
      component: SupporTickets,
      access: [...aclMatrix.ALL_ACCESS]
    },
    {
      path: '/settings',
      name: 'Settings',
      component: NewSettings,
      access: isUserAuth() ? [...aclMatrix.ALL_ACCESS] : []
    },
    {
      path: '/capital',
      exact: true,
      name: 'BostaCapital',
      component: BostaCapital,
      access: isSaudi ? [] : [...aclMatrix.ALL_ACCESS]
    }
  ];
};

export default routes;
