import { ReactComponent as WarningIcon } from 'assets/bosta-icons/warning-shadow.svg';
import { ReactComponent as OrdersIcon } from 'assets/bosta-icons/Orders.svg';

export const BUSINESS_TYPES = { B2B: 'B2B' };
export const BUSINESS_TIERS = { BRONZE: 'Bronze' };

export const FIRST_TIER_UPGRADE_TARGET = 100;

export const BUSINESS_DOWNGRADE_STATES = {
  Normal: {
    string: 'Normal',
    icon: <OrdersIcon />,
    text_id: 'tracker_bar.business_upgrade_status.normal'
  },
  Warning: {
    string: 'Warning',
    icon: <WarningIcon />,
    text_id: 'tracker_bar.business_upgrade_status.warning'
  }
};
