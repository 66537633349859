import { useIntl } from 'react-intl';

import { SIGNUP_PARTNERS_LOGOS } from 'constants/signup';

import './SignUpPartners.less';

const SignUpPartners = () => {
  const intl = useIntl();

  return (
    <div className="br-signup-partners__container">
      <div className="display-sm">
        {intl.formatMessage({ id: 'new_sign_up.partners.title' })}
      </div>
      <div className="br-signup-partners__logos">
        {SIGNUP_PARTNERS_LOGOS.map((PartnerLogo, index) => (
          <PartnerLogo key={index} />
        ))}
      </div>
    </div>
  );
};

export default SignUpPartners;
