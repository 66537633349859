import React from 'react';
import Icon from '@ant-design/icons';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';

import { useModal } from 'contexts/modalProvider.context';

import BRButton from 'components/BRButton/BRButton';
import PricingCalculatorModal from './components/PricingCalculatorModal/PricingCalculatorModal';

import { ReactComponent as RightArrow } from 'assets/bosta-icons/Arrow-right.svg';

import './PricingCalculatorBanner.less';

const PricingCalculatorBanner = ({ intl, source = 'signUp' }) => {
  const { openModal } = useModal();

  const handleOnPriceCalculatorClick = () => {
    openModal(PricingCalculatorModal, {
      hidePlanSection: source !== 'signUp'
    });
  };
  return (
    <div
      className={classNames('br-pricing-calculator-banner__wrapper', {
        'br-pricing-calculator-banner__pricing-plan-page': source !== 'signUp'
      })}
    >
      <div className="br-pricing-calculator-banner__container">
        <div className="br-pricing-calculator-banner__text-container">
          <span className="subheading">
            {intl.formatMessage({
              id: 'pricing_plans_calculator.banner.header'
            })}
          </span>
          <span className="display-sm">
            {intl.formatMessage({
              id: 'pricing_plans_calculator.banner.title'
            })}
          </span>
        </div>
        <BRButton
          type="outline"
          label={intl.formatMessage({
            id: 'pricing_plans_calculator.banner.button_text'
          })}
          suffixIcon={<Icon component={RightArrow} />}
          onClick={handleOnPriceCalculatorClick}
        />
      </div>
    </div>
  );
};

export default injectIntl(PricingCalculatorBanner);
