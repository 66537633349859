import { INTERNATIONAL_SERVICES } from 'constants/signup';
import { getCurrentUserCountryData } from 'constants/countries/countries-mapping';

import ServicesContainer from 'components/SignUp/components/ServicesContainer/ServicesContainer';
import InternationalShippingDestination from './components/InternationalShippingDestination/InternationalShippingDestination';

import './InternationalServicesContainer.less';

const InternationalServicesContainer = (props) => {
  return (
    <div className="br-international-service__container">
      <InternationalShippingDestination />
      <ServicesContainer
        {...props}
        services={INTERNATIONAL_SERVICES[getCurrentUserCountryData().codeName]}
        isInternationalServices
      />
    </div>
  );
};

export default InternationalServicesContainer;
