import {
  SmartUploadOrdersTemplate,
  SmartUploadOrdersTemplateAR,
  SmartUploadOrdersTemplateKSA,
  SmartUploadOrdersTemplateKSA_AR
} from 'constants/mass-upload';

const DEFAULT_EXCEL_COLUMNS = {
  FULL_NAME: 0,
  PHONE: 1,
  SECOND_PHONE: 2,
  CITY: 3,
  AREA: 4,
  STREET_NAME: 5,
  SECOND_LINE: 6,
  IS_WORK_ADDRESS: 7,
  DELIVERY_NOTES: 8,
  FULFILLMENT_CASH_AMOUNT: 9,
  CASH_AMOUNT: 10,
  NUMBER_OF_ITEMS: 11,
  PACKAGE_DESCRIPTION: 12,
  ORDER_REFERENCE: 13,
  GOODS_VALUE_AMOUNT: 14,
  ALLOW_OPENEING_PACKAGE: 15,
  RETURN_NUMBER_OF_ITEMS: 17,
  RETURN_PACKAGE_DESCRIPTION: 18,
  PACKAGE_TYPE: 20
};

const KSA_DAULT_EXCEL_COLUMNS = {
  FULL_NAME: 0,
  PHONE: 1,
  SECOND_PHONE: 2,
  CITY: 3,
  AREA: 4,
  STREET_NAME: 5,
  SECOND_LINE: 6,
  IS_WORK_ADDRESS: 7,
  DELIVERY_NOTES: 8,
  FULFILLMENT_CASH_AMOUNT: 9,
  CASH_AMOUNT: 10,
  NUMBER_OF_ITEMS: 11,
  PACKAGE_DESCRIPTION: 12,
  ORDER_REFERENCE: 13,
  MULTI_PACKAGES: 14,
  GOODS_VALUE_AMOUNT: 15,
  ALLOW_OPENEING_PACKAGE: 16,
  RETURN_NUMBER_OF_ITEMS: 18,
  RETURN_PACKAGE_DESCRIPTION: 19,
  PACKAGE_TYPE: 21
};

export const SMART_MASSUPLOAD_COLUMNS = {
  EG: DEFAULT_EXCEL_COLUMNS,
  SA: KSA_DAULT_EXCEL_COLUMNS,
  DEFAULT: DEFAULT_EXCEL_COLUMNS
};

export const SMART_UPLOAD_DEFAULT_TEMPLATE = {
  DEFAULT: {
    EN: SmartUploadOrdersTemplate,
    AR: SmartUploadOrdersTemplateAR
  },
  SA: {
    EN: SmartUploadOrdersTemplateKSA,
    AR: SmartUploadOrdersTemplateKSA_AR
  }
};
