import SuggestionsModal from 'components/SuggestionsModal/SuggestionsModal';
import { useModal } from 'contexts/modalProvider.context';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const Suggestions = () => {
  const history = useHistory();

  const { openModal } = useModal();

  useEffect(() => {
    openModal(SuggestionsModal);

    history.push('/');
  }, [history]);

  return null;
};

export default Suggestions;
