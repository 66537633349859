import React, { useState, useEffect, forwardRef } from 'react';
import { injectIntl } from 'react-intl';
import { Form } from 'antd';
import classnames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import { getAllAreas } from 'services/cities';
import { cleanEmptyString, getAreaName, getCityName } from 'utils/helpers';
import { useModal } from 'contexts/modalProvider.context';
import { COUNTRIES } from 'constants/country-data';
import { getInternationalShippingDestinationCountries } from 'constants/countries/countries-mapping';

import BRButton from 'components/BRButton/BRButton';
import BRCityAreaCollapse from 'components/BRCityAreaCollapse/BRCityAreaCollapse';
import { notify } from 'components/Notify/Notify';

import './BRCityArea.less';

export const AVAILABILITY = {
  DROPOFF: 'dropOffAvailability',
  PICKUP: 'pickupAvailability'
};

export const CONTEXT = {
  dropOffAvailability: 'dropoff',
  pickupAvailability: 'pickup'
};

const BRCityArea = (props) => {
  const {
    formRef,
    intl,
    name,
    areas = [],
    setAreas,
    availability,
    formItemClassName = '',
    onChange,
    disabled,
    dropdownWidth,
    hasFeedback,
    cityID,
    addressInitialValue,
    googleMapInitialValue,
    setCities,
    setUserPosition = () => {},
    shouldSetAreaInitialValue = true,
    selectedCountryId,
    isCreatingInternationalLocation,
    ...restOfProps
  } = props;

  const [selectedCityArea, setSelectedCityArea] = useState({});
  const [isAllAreasLoading, setIsAreasLoading] = useState(false);
  const [allAreas, setAllAreas] = useState(areas);

  const { openModal } = useModal();
  
  const isCreatingInternationalLocationWithNoCountry =
    isCreatingInternationalLocation && !selectedCountryId;

  const setCityAreaInitialValues = (addressInitialValue = {}) => {
    const { city, districtId, district } = addressInitialValue;

    if (city) {
      const { districts, ...cityObj } =
        allAreas.find((area) => [city, city?._id].includes(area?.cityId)) || {};

      const areaObj =
        districts?.find((area) =>
          [districtId, district?._id].includes(area?.districtId)
        ) || {};

      setSelectedCityArea({
        ...cityObj,
        ...areaObj
      });
    } else if (districtId) {
      const { districts, ...cityObj } =
        allAreas.find((area) => {
          return area.districts.find((area) => area.districtId === districtId);
        }) || {};

      const areaObj =
        districts?.find((area) => area?.districtId === districtId) || {};

      setSelectedCityArea({
        ...cityObj,
        ...areaObj
      });
    }
  };

  const handleOpenCityAreaModal = () => {
    openModal(BRCityAreaCollapse, {
      name,
      formRef,
      availability: availability || AVAILABILITY.DROPOFF,
      setCities,
      updateAddress: () => {},
      selectedCityArea,
      setSelectedCityArea,
      setCityArea: () => {},
      setUserPosition: () => {},
      fetchedAreas: allAreas,
      onChange,
      selectedCountryId,
      countriesList: isCreatingInternationalLocation
        ? getInternationalShippingDestinationCountries()
        : COUNTRIES,
      ...restOfProps
    });
  };

  const renderLabel = () => {
    const cityName = getCityName(selectedCityArea);

    const areaName = getAreaName({
      area: selectedCityArea
    });

    return cityName ? (
      <>
        <span className="br-city-area__field">
          <span className="br-city-area__area-name">{areaName}</span>
          <span className="br-city-area__city-name">{cityName}</span>
        </span>
      </>
    ) : (
      <span className="br-city-area__placeholder">
        {intl.formatMessage({
          id: `form.area_select_placeholder`
        })}
      </span>
    );
  };

  useEffect(() => {
    async function fetchData() {
      setIsAreasLoading(true);
      try {
        const payload = {
          context: CONTEXT[availability],
          ...(selectedCountryId && { countryId: selectedCountryId })
        };

        const allAreas = (await getAllAreas(payload)) || [];

        setAllAreas(allAreas);
        setCities?.(allAreas);
        setAreas?.(allAreas);
      } catch (error) {
        notify({ msg: error.message, error });
      }

      setIsAreasLoading(false);
    }
    if (!isCreatingInternationalLocationWithNoCountry) {
      fetchData();

      if (!isEmpty(selectedCityArea)) {
        setSelectedCityArea({});
        formRef.current.setFieldsValue({
          address: { district: null, city: null }
        });
      }
    }
  }, [availability, selectedCountryId]);

  useEffect(() => {
    const address = formRef?.current?.getFieldValue?.('address');
    const districtId = formRef?.current?.getFieldValue?.('districtId');

    if (
      allAreas?.length &&
      (address || districtId) &&
      shouldSetAreaInitialValue
    ) {
      setCityAreaInitialValues(districtId ? { districtId } : address);
    }

    if (address && isEmpty(cleanEmptyString(address)) && !districtId) {
      setSelectedCityArea({});
    }
  }, [
    allAreas,
    formRef?.current?.getFieldValue?.('address'),
    formRef?.current?.getFieldValue?.('districtId')
  ]);

  useEffect(() => {
    if (googleMapInitialValue) {
      setSelectedCityArea(googleMapInitialValue);
    }
  }, [googleMapInitialValue]);

  return (
    <Form.Item
      name={name || 'districtId'}
      rules={[{ required: true }]}
      className={classnames('br-forms-new-input br-form-city-area', {
        'br-form-city-area__dimmed':
          isCreatingInternationalLocationWithNoCountry
      })}
      label={intl.formatMessage({
        id: `location_form_labels.area`
      })}
      hasFeedback
    >
      <BRButton
        className="br-city-area__btn"
        label={renderLabel()}
        loading={isAllAreasLoading}
        onClick={handleOpenCityAreaModal}
      />
    </Form.Item>
  );
};

export default injectIntl(BRCityArea);
