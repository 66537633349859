import StackdriverErrorReporter from 'stackdriver-errors-js';

let errorHandler;

if (
  ['production', 'beta'].includes(window?.env?.ENVIRONMENT) &&
  window?.env?.ERROR_REPORTING_API_KEY
) {
  errorHandler = new StackdriverErrorReporter();
  errorHandler.start({
    key: window?.env?.ERROR_REPORTING_API_KEY,
    projectId: 'bosta-new-infrastructure',
    service: 'Business Dashboard'
  });
} else {
  errorHandler = { report: console.error };
}

export default errorHandler;
