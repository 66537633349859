import { useRef, useState } from 'react';
import { Form, Input } from 'antd';
import { useIntl } from 'react-intl';
import ReCAPTCHA from 'react-google-recaptcha';
import querySearch from 'stringquery';
import { useHistory } from 'react-router-dom';

import { TEXT } from 'constants/Forms';
import { LOCALE } from 'constants/intl-wrapper';
import {
  DASHBOARD_REG_SRC,
  ERROR_CODES,
  RECAPTCHA_SITE_KEY
} from 'constants/signup';
import {
  businessNameRule,
  emailRule,
  fullNameRule,
  requiredRule
} from 'utils/forms';
import { getUserProfileData } from 'utils/signup';
import { getSignInPath } from 'utils/login';
import { useModal } from 'contexts/modalProvider.context';
import { register } from 'services/auth';

import BRInternationalPhone from 'components/BRInternationalPhone/BRInternationalPhone';
import BRFormInputs from 'components/BRFormInputs/BRFormInputs';
import BRPasswordInput from 'components/BRPasswordInput/BRPasswordInput';
import TermsAndConditions from 'components/SignUp/components/TermsAndConditions/TermsAndConditions';
import BRButton from 'components/BRButton/BRButton';
import VerifyPhoneModal from 'components/SignUp/components/VerifyPhoneModal.js/VerifyPhoneModal';
import { notify } from 'components/Notify/Notify';

import { ReactComponent as LocationIcon } from 'assets/bosta-icons/Location.svg';

import './SignUpForm.less';

const SIGN_IN_PATH = getSignInPath();

const SignUpForm = ({ country, setCountry }) => {
  const [isRecaptchaVerified, setIsRecaptchaVerified] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isPhoneNumberAlreadyUsed, setIsPhoneNumberAlreadyUsed] =
    useState(false);

  const formRef = useRef();
  const recaptchaRef = useRef();

  const intl = useIntl();
  const history = useHistory();
  const { openModal } = useModal();

  const handleGoToWebsitePage = () => {
    window.open(
      `https://bosta.co/${
        intl.locale === LOCALE.EN ? 'en' : 'ar'
      }/tracking-shipments`,
      '_blank'
    );
  };

  const onFinish = async (values) => {
    if (!isRecaptchaVerified) {
      return notify({
        msg: intl.formatMessage({ id: 'sign_up.verfiy_human' }),
        status: -1
      });
    }

    setIsLoading(true);

    const { email, password, fullName, phone, businessName } = values;
    const query = querySearch(history?.location?.search);
    const profile = getUserProfileData({ fullName, phone, country });

    try {
      const payload = {
        email,
        password,
        recaptchaValue: isRecaptchaVerified,
        profile,
        countryId: country.id,
        source: query?.utm_source,
        campaign: query?.utm_campaign,
        meduim: query?.utm_medium,
        businessName,
        isRegisteredViaBetaFunnel: true,
        ...(query && {
          userUrlData: {
            ...query
          }
        })
      };
      const regSrc = DASHBOARD_REG_SRC;

      const response = await register(regSrc, payload);
      localStorage.setItem('userInfo', JSON.stringify(response));
      localStorage.setItem('newToken', response.token);

      window.analytics?.identify(email, {
        'User Email': email,
        Permissions: response?.user?.group?.name,
        'Dashboard Language': intl.locale,
        Phone: phone
      });

      openModal(VerifyPhoneModal, {
        phone: payload?.profile?.phone,
        email,
        userID: response?.user?._id,
        history: history,
        initialCountry: country
      });
    } catch (error) {
      recaptchaRef.current?.reset();
      setIsRecaptchaVerified(false);

      if (error.code === ERROR_CODES.EMAIL_EXISTS) {
        const payload = {
          email,
          profile: {
            fullName: fullName,
            phone: phone
          },
          country,
          businessName
        };
        history.push({
          pathname: SIGN_IN_PATH,
          state: {
            email,
            firstName: error.data?.firstName,
            lastName: error.data?.lastName,
            payload,
            pathName: '/'
          }
        });
      } else if (ERROR_CODES.PHONE_EXISTS.includes(error.errorCode)) {
        setIsPhoneNumberAlreadyUsed(true);
      } else if (error.errorCode === ERROR_CODES.BUSINESS_NAME_EXIST) {
        notify({
          msg: intl.formatMessage({
            id: 'sign_up.business_name_already_exist'
          }),
          status: -1
        });
      } else {
        notify({ msg: error.message, error });
      }
    }

    setIsLoading(false);
  };

  const handleOnSignInClick = () => {
    history.push(SIGN_IN_PATH);
  };

  const handleOnRecaptchaChange = (value) => {
    setIsRecaptchaVerified(value);
  };

  return (
    <div className="br-signup-form__container">
      <div className="br-signup-form__inner">
        <div className="heading">
          {intl.formatMessage({ id: 'new_sign_up.main.signup_form.title' })}
        </div>
        <Form ref={formRef} onFinish={onFinish}>
          <div className="br-signup-form__row">
            <BRFormInputs
              type={TEXT}
              inputTitle={intl.formatMessage({
                id: 'new_sign_up.main.signup_form.form_labels.full_name'
              })}
              placeholder={intl.formatMessage({
                id: 'new_sign_up.main.signup_form.form_placeholders.full_name'
              })}
              formRef={formRef}
              name="fullName"
              rules={[
                fullNameRule(
                  intl.formatMessage({
                    id: 'sign_up.full_name_message'
                  })
                ),
                requiredRule(
                  intl.formatMessage(
                    { id: 'form.required_custom' },
                    {
                      label: intl.formatMessage({
                        id: 'contact_form_labels.full_name'
                      })
                    }
                  )
                )
              ]}
              hasFeedback
            />
            <BRFormInputs
              type={TEXT}
              inputTitle={intl.formatMessage({
                id: 'new_sign_up.main.signup_form.form_labels.business_name'
              })}
              placeholder={intl.formatMessage({
                id: 'new_sign_up.main.signup_form.form_placeholders.business_name'
              })}
              formRef={formRef}
              name="businessName"
              rules={[
                requiredRule(
                  intl.formatMessage({ id: 'sign_up.business_name_message' })
                ),
                businessNameRule(
                  intl.formatMessage({
                    id: 'settings.business_account.business_name_validation_message'
                  })
                )
              ]}
              hasFeedback
            />
          </div>
          <BRInternationalPhone
            name="phone"
            label={intl.formatMessage({
              id: 'new_sign_up.main.signup_form.form_labels.mobile'
            })}
            country={country}
            setCountry={setCountry}
            formRef={formRef}
            usedPhoneNumber={isPhoneNumberAlreadyUsed}
            setUsedPhoneNumber={setIsPhoneNumberAlreadyUsed}
          />
          <Form.Item
            name="email"
            label={intl.formatMessage({
              id: 'new_sign_up.main.signup_form.form_labels.email'
            })}
            rules={[
              requiredRule(
                intl.formatMessage(
                  {
                    id: 'form.required_custom'
                  },
                  {
                    label: intl.formatMessage({
                      id: 'contact_form_labels.email'
                    })
                  }
                )
              ),
              emailRule(
                intl.formatMessage({
                  id: 'form.email_not_valid'
                })
              )
            ]}
            hasFeedback
          >
            <Input
              placeholder={intl.formatMessage({
                id: 'sign_up.form_placeholders.email'
              })}
              type="email"
            />
          </Form.Item>
          <BRPasswordInput
            label={intl.formatMessage({
              id: 'new_sign_up.main.signup_form.form_labels.password'
            })}
            formRef={formRef}
          />
          <TermsAndConditions formRef={formRef} country={country} />
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={RECAPTCHA_SITE_KEY}
            onChange={handleOnRecaptchaChange}
            onExpired={handleOnRecaptchaChange}
          />
          <BRButton
            type="brand"
            htmlType="submit"
            className="br-signup-form__submit-btn button-lg"
            label={intl.formatMessage({
              id: 'new_sign_up.main.signup_form.submit_btn'
            })}
            loading={isLoading}
          />
        </Form>
        <div className="br-signup-form__login-btn">
          {intl.formatMessage(
            { id: 'new_sign_up.main.signup_form.already_signed_up' },
            {
              loginButton: (
                <BRButton
                  type="link-color"
                  label={intl.formatMessage({
                    id: 'new_sign_up.main.signup_form.sign_in'
                  })}
                  onClick={handleOnSignInClick}
                />
              )
            }
          )}
        </div>
        <BRButton
          type="link-color"
          label={intl.formatMessage({ id: 'new_sign_up.header.track_order' })}
          className="br-signup__track-order button-md"
          prefixIcon={<LocationIcon />}
          onClick={handleGoToWebsitePage}
        />
      </div>
    </div>
  );
};

export default SignUpForm;
