import { getLocaleFromLocalStorage } from 'utils/intl-wrapper';

import { isDomesticShippingAvailable } from './countries/countries-mapping';

import { fmt } from 'components/IntlWrapper/IntlWrapper';

const locale = getLocaleFromLocalStorage();

const isUserFullyActivated = JSON.parse(localStorage.getItem('userInfo'))?.user
  ?.isUserFullyActivated;

export const BOSTA_GUIDE_URL = `https://bosta.freshdesk.com/a/solutions/articles/36000256730?lang=${
  locale || 'en'
}`;

export const PATH_NAME_CREATE = '/create-order';

export const STEPPER_STEPS = ({ isDocumentsUnderReview }) => [
  { title: fmt({ id: 'onboarding.create_account' }) },
  {
    title: fmt({
      id: `new_header.actions.${
        isDomesticShippingAvailable()
          ? 'activate_account'
          : isDocumentsUnderReview
          ? 'reviewing_details'
          : !isUserFullyActivated
          ? 'complete_details'
          : 'account_activated'
      }`
    }),
    duration: fmt({ id: 'onboarding.duration_mins' }, { duration: 5 })
  },
  {
    title: fmt({ id: 'orders_listing.empty_state.title' }),
    duration: fmt({ id: 'onboarding.duration_mins' }, { duration: 2 })
  },
  {
    title: fmt({
      id: `onboarding.steps.3.${
        isDomesticShippingAvailable() ? 'prepare_your_order' : 'schedule_pickup'
      }`
    }),
    duration: fmt(
      { id: 'onboarding.duration_mins' },
      { duration: isDomesticShippingAvailable() ? 3 : 1 }
    )
  }
];

export const OVERVIEW_ONBOARDING_STEPS = {
  CREATE_ACCOUNT: 0,
  ACTIVATE_ACCOUNT: 1,
  CREATE_ORDER: 2,
  PREPARE_ORDER: 3
};

export const ANT_STEPS_STATES = {
  FINISH: 'finish',
  PROCESS: 'process'
};

export const TOTAl_ONBOARDING_STEPS = 4;
export const ACTIVATE_DELIVERY_SERVICE_STEP = 3;
