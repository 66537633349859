import { useIntl } from 'react-intl';
import { Dropdown, Menu } from 'antd';
import classnames from 'classnames';

import {
  getCurrentUserCountryData,
  getInternationalShippingDestinationCountries
} from 'constants/countries/countries-mapping';
import { COUNTRIES, URL_FLAGS } from 'constants/country-data';
import { LOCALE } from 'constants/intl-wrapper';
import { isDashboardArabic } from 'utils/intl-wrapper';

import './OrderDestinationSelector.less';

const currentUserCountry = getCurrentUserCountryData();

const OrderDestinationSelector = ({
  isOrigin,
  selectedDestinationCountry,
  setSelectedDestinationCountry = () => {}
}) => {
  const intl = useIntl();

  const destinationCountries = isOrigin
    ? []
    : getInternationalShippingDestinationCountries();

  const isSelectorActive = destinationCountries.length > 2;

  const renderLabel = () => {
    const { name, arabicName } = isOrigin
      ? currentUserCountry
      : selectedDestinationCountry || COUNTRIES[1];

    return isDashboardArabic() ? arabicName : name;
  };

  const getCountryFlagUrl = () => {
    const { codeName } = isOrigin
      ? currentUserCountry
      : selectedDestinationCountry || COUNTRIES[1];

    return `${URL_FLAGS}${codeName.toLowerCase()}.svg`;
  };

  const destiantionCountriesMenu = () => {
    return (
      <Menu
        items={getInternationalShippingDestinationCountries().map(
          (country) => ({
            key: country.codeName,
            label:
              intl.locale === LOCALE.EN ? country.name : country.arabicName,
            onClick: () => setSelectedDestinationCountry(country)
          })
        )}
      />
    );
  };

  return (
    <Dropdown
      overlay={destiantionCountriesMenu()}
      disabled={!isSelectorActive}
      trigger={['click']}
    >
      <div
        className={classnames('br-order-destination__selector', {
          'br-order-destination__selector-active': isSelectorActive
        })}
      >
        <img src={getCountryFlagUrl()} alt="country-flag" />
        <div className="br-order-destination__info">
          <span className="br-order-destination__origin subheading">
            {intl.formatMessage({
              id: `international_orders.create_edit_order.${
                isOrigin ? 'from' : 'to'
              }`
            })}
          </span>
          <span className="body-medium">{renderLabel()}</span>
        </div>
      </div>
    </Dropdown>
  );
};

export default OrderDestinationSelector;
