export const FETCH_DELIVERIES = 'FETCH_DELIVERIES';
export const CREATE_DELIVERY = 'CREATE_DELIVERY';
export const EDIT_DELIVERY = 'EDIT_DELIVERY';
export const DELETE_DELIVERY = 'DELETE_DELIVERY';
export const PRINT_AIRWAYBILL = 'PRINT_AIRWAYBILL';
export const PRINT_SELECTED_AIRWAYBILL = 'PRINT_SELECTED_AIRWAYBILL';
export const FETCH_DELIVERY_DETAILS = 'FETCH_DELIVERY_DETAILS';
export const FETCH_DELIVERY_STATE_HISTORY = 'FETCH_DELIVERY_STATE_HISTORY';
export const CREATE_CSV = 'CREATE_CSV';
export const SET_SHIPMENT_TRACKING = 'SET_SHIPMENT_TRACKING';

export const FETCH_AWAITING_FOR_ACTION_DELIVERIES =
  'FETCH_AWAITING_FOR_ACTION_DELIVERIES';
export const ORDER_OVERVIEW = 'ORDER_OVERVIEW';
export const COD_OVERVIEW = 'COD_OVERVIEW';

export const EXPORT_CSV = 'EXPORT_CSV';

export const FETCH_PICKUPS = 'FETCH_PICKUPS';
export const EDIT_PICKUP = 'EDIT_PICKUP';
export const CREATE_PICKUP = 'CREATE_PICKUP';
export const DELETE_PICKUP = 'DELETE_PICKUP';
export const FETCH_PICKUP_DETAILS = 'FETCH_PICKUP_DETAILS';
export const EXPORT_PICKUPS_CSV = 'EXPORT_PICKUPS_CSV';

export const FETCH_PICKUPS_LOCATION = 'FETCH_PICKUPS_LOCATION';
export const EDIT_PICKUPS_LOCATION = 'EDIT_PICKUPS_LOCATION';
export const CREATE_PICKUPS_LOCATION = 'CREATE_PICKUPS_LOCATION';
export const DELETE_PICKUPS_LOCATION = 'DELETE_PICKUPS_LOCATION';
export const DEFAULT_PICKUPS_LOCATION = 'DEFAULT_PICKUPS_LOCATION';

export const FETCH_CITIES = 'FETCH_CITIES';
export const FETCH_CITY = 'FETCH_CITY';
export const UPDATE_CITY = 'UPDATE_CITY';
export const CREATE_CITY = 'CREATE_CITY';

export const FETCH_ZONES = 'FETCH_ZONES';
export const FETCH_ZONE = 'FETCH_ZONE';
export const UPDATE_ZONE = 'UPDATE_ZONE';

export const FETCH_TICKETS = 'FETCH_TICKETS';
export const CREATE_TICKET = 'CREATE_TICKET';
export const CREATE_CONVERSATION = 'CREATE_CONVERSATION';
export const GET_CONVERSATION = 'GET_CONVERSATION';

export const SIGNIN_ACTION = 'SIGNIN_ACTION';
export const SIGNUP_ACTION = 'SIGNUP_ACTION';
export const GET_BUSINESS_INFO = 'GET_BUSINESS_INFO';
export const FORGET_PASSWORD = 'FORGET_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';

export const FETCH_CODS = 'FETCH_CODS';
export const EXPORT_TRANSCATION = 'EXPORT_TRANSCATION';
export const EXPORT_ALL_TRANSCATION = 'EXPORT_ALL_TRANSCATION';
export const COD_DETAILS = 'COD_DETAILS';
export const FETCH_UNPAID_DELIVERIES = 'FETCH_UNPAID_DELIVERIES';

export const FETCH_CASHOUT_DATA = 'FETCH_CASHOUT_DATA';
export const FETCH_CASHOUT_AMOUNT = 'FETCH_CASHOUT_AMOUNT';
export const FETCH_CASHOUT_DETAILS = 'FETCH_CASHOUT_DETAILS';
export const CLEAR_CASHOUT_DETAILS = 'CLEAR_CASHOUT_DETAILS';
export const FETCH_CSV_TRANSACTION = 'FETCH_CSV_TRANSACTION';
export const CLEAR_CASHOUT_DATA = 'CLEAR_CASHOUT_DATA';
export const FETCH_SUMMARY_DATA = 'FETCH_SUMMARY_DATA';

export const UPDATE_PHONE_FAWRY = 'UPDATE_PHONE_FAWRY';
export const VERIFY_PHONE_FAWRY = 'VERIFY_PHONE_FAWRY';
export const SET_MASTER_CODE = 'SET_MASTER_CODE';

export const SET_NEW_NOTIFICATION = 'SET_NEW_NOTIFICATION';
export const SET_READ_NOTIFICATION = 'SET_READ_NOTIFICATION';
