export const IBAN_LENGTH = {
  EG: '24 ~ 29',
  SA: '24 ~ 29',
  AE: '23'
};

export const IBAN_PLACEHOLDER = {
  EG: `settings.payment_method.bank_details_modal.place_holder.iban`,
  SA: `settings.payment_method.bank_details_modal.place_holder_ksa.iban`,
  AE: `settings.payment_method.bank_details_modal.place_holder_ae.iban`
};

export const IBAN_REGEX = {
  EG: /^[a-zA-Z0-9]{24,29}$/,
  SA: /^[a-zA-Z0-9]{15,34}$/,
  AE: /^[a-zA-Z0-9]{23}$/
};
