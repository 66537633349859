import React, { useEffect } from 'react';
import { Button } from 'antd';
import { injectIntl } from 'react-intl';
import Icon from '@ant-design/icons';
import { withRouter } from 'react-router';

import { sendSegment } from 'utils/segment';

import BRContentHeader from 'components/BRContentHeader/BRContentHeader';

import { ReactComponent as PageNotFound } from 'assets/imgRevamp/page_not_found.svg';
import { ReactComponent as bostaLogo } from 'assets/imgRevamp/bosta_logo.svg';
import { ReactComponent as bostaLogoArabic } from 'assets/imgRevamp/bosta_arabic.svg';

import './NotFoundPage.less';

const NotFoundPage = (props) => {
  const { intl } = props;

  useEffect(() => {
    sendSegment('404_SCREEN_VIEWED');
  }, []);

  return (
    <div className="br-not-found-container">
      <div className="br-not-found-container__logo">
        <Icon component={intl.locale === 'en' ? bostaLogo : bostaLogoArabic} />
      </div>
      <div className="br-not-found-container__not-found">
        <Icon component={PageNotFound} />
      </div>
      <div>
        <BRContentHeader
          title={intl.formatMessage({
            id: 'settings.teams_members.not_found.title'
          })}
          subtitle={intl.formatMessage({
            id: 'settings.teams_members.not_found.sub_title'
          })}
          isInternalComponent
        />
      </div>
      <div>
        <a href="http://bosta.co/">
          <Button type="secondary" htmlType="button">
            {intl.formatMessage({
              id: 'settings.teams_members.not_found.button'
            })}
          </Button>
        </a>
      </div>
    </div>
  );
};
export default injectIntl(withRouter(NotFoundPage));
