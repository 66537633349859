import { useEffect } from 'react';
import { Modal } from 'antd';
import { injectIntl } from 'react-intl';

import { useModal } from 'contexts/modalProvider.context';
import { sendSegment } from 'utils/segment';

import BRButton from 'components/BRButton/BRButton';
import SmartStickerGuideModal from 'components/SmartStickerGuideModal/SmartStickerGuideModal';

import { ReactComponent as GooglePlay } from 'assets/imgRevamp/GooglePlay.svg';
import { ReactComponent as AppStore } from 'assets/imgRevamp/AppStore.svg';
import { ReactComponent as MobileAppBarcode } from 'assets/imgRevamp/MobileAppBarcode.svg';

import './ScanModal.less';

const ScanModal = ({ intl, close, ...props }) => {
  const { openModal } = useModal();
  
  useEffect(() => {
    sendSegment('QR_Code_Opened');
  }, []);

  const handleBackClick = () => {
    openModal(SmartStickerGuideModal);
    close();
  };

  return (
    <Modal
      className="br-smart-sticker-scan-modal"
      title={null}
      footer={null}
      width={462}
      onCancel={close}
      {...props}
    >
      <div className="br-smart-sticker-scan-modal__container">
        <div className="br-smart-sticker-scan-modal__header">
          <div className="br-smart-sticker-scan-modal__title heading">
            {intl.formatMessage({
              id: 'smart_stickers_guide.scan_modal.title'
            })}
          </div>
          <div className="br-smart-sticker-scan-modal__apps-icons">
            <AppStore />
            <GooglePlay />
          </div>
        </div>
        <div className="br-smart-sticker-scan-modal__body">
          <span className="heading">
            {intl.formatMessage({ id: 'common.or' })}
          </span>
          <div className="br-smart-sticker-scan-modal__scan-title heading">
            {intl.formatMessage({
              id: 'smart_stickers_guide.scan_modal.scan_title'
            })}
          </div>
          <MobileAppBarcode />
        </div>
        <div className="br-smart-sticker-scan-modal__footer">
          <BRButton
            label={intl.formatMessage({ id: 'common.back' })}
            onClick={handleBackClick}
          />
        </div>
      </div>
    </Modal>
  );
};

export default injectIntl(ScanModal);
