import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import classnames from 'classnames';

import { isSaudi } from 'constants/helpers';
import { CASHOUT_FREQUENCY } from 'constants/wallet';
import { getCashoutConfiguration } from 'services/wallet';
import {
  getCurrency,
  showCashoutFees
} from 'constants/countries/countries-mapping';

import BRButton from 'components/BRButton/BRButton';
import { notify } from 'components/Notify/Notify';

const ServicesContainer = ({
  intl,
  plannedFrequency,
  frequency,
  setPlannedFrequency,
  startingFromValue,
  active,
  title,
  cashout,
  description,
  nextDayfees
}) => {
  const [weeklyCashoutFees, setWeeklyCashoutFees] = useState(0);

  useEffect(() => {
    if (frequency === CASHOUT_FREQUENCY.WEEKLY && showCashoutFees()) {
      handleGetCashoutConfiguration();
    }
  }, []);

  const handleGetCashoutConfiguration = async () => {
    try {
      const { weeklyTransferFeesPerNumberOfDays } =
        await getCashoutConfiguration();

      setWeeklyCashoutFees(weeklyTransferFeesPerNumberOfDays[1]);
    } catch (error) {
      notify({ msg: error.message, error });
    }
  };

  return (
    <div
      className={classnames('br-signup__plan-card', {
        active: active
      })}
    >
      <div className="br-signup__plan-card__description">
        <p className="header subheading">{title}</p>
        <p className="title display-xs">{cashout}</p>
        <p className="description body">{description}</p>
      </div>
      <div className="br-signup__plan-card__price">
        <div className="br-sign-up__plan-card__price-container">
          <span className="header caption-lg">
            {intl.formatMessage({
              id: 'sign_up.pricing.starting_from'
            })}
          </span>
          <div>
            <span className="title display-md">
              {intl.formatMessage(
                {
                  id: `common.${isSaudi() ? 'SAR_modified' : 'EGP_modified'}`
                },
                {
                  cod: startingFromValue
                }
              )}
            </span>
            <span className="br-signup__per-order">
              {intl.formatMessage({
                id: 'sign_up.pricing.per_order'
              })}
            </span>
          </div>
          {!isNaN(nextDayfees) && (
            <p className="br-pricing-plan-card__next-day-fees-text caption-lg">
              {intl.formatMessage(
                {
                  id: 'sign_up.pricing.next_day_transfer_fees_percentage'
                },
                {
                  value: nextDayfees
                }
              )}
            </p>
          )}
          {!!weeklyCashoutFees && (
            <p className="br-pricing-plan-card__next-day-fees-text caption-lg">
              {intl.formatMessage(
                {
                  id: 'change_cashout_modal.fees_applies'
                },
                {
                  amount: getCurrency(weeklyCashoutFees).localized
                }
              )}
            </p>
          )}
          <p className="vat caption">
            {intl.formatMessage({
              id: 'sign_up.pricing.excluding_vat'
            })}
          </p>
        </div>
        <BRButton
          className="button-lg"
          onClick={() => setPlannedFrequency(frequency)}
          type={
            plannedFrequency === frequency
              ? 'link-color'
              : frequency === CASHOUT_FREQUENCY.DAILY
              ? 'outline'
              : 'basic'
          }
          label={
            plannedFrequency === frequency
              ? intl.formatMessage({
                  id: 'sign_up.pricing.current_plan'
                })
              : intl.formatMessage({
                  id: 'sign_up.pricing.switch_plan'
                })
          }
        />
      </div>
    </div>
  );
};
export default injectIntl(ServicesContainer);
