import { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Modal, Progress } from 'antd';
import Icon from '@ant-design/icons';
import classnames from 'classnames';
import dayjs from 'dayjs';

import { printMassAirwayBills } from 'services/shipments';
import { getPickupScheduleTimeForSameDay } from 'constants/countries/countries-mapping';
import { isDashboardArabic } from 'utils/intl-wrapper';
import { sendSegment } from 'utils/segment';
import { downloadAsPdf } from 'utils/download';

import BRButton from 'components/BRButton/BRButton';
import BRInfoBanner from 'components/BRInfoBanner/BRInfoBanner';
import { notify } from 'components/Notify/Notify';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';

import { ReactComponent as LeftArrowIcon } from 'assets/bosta-icons/Left.svg';
import { ReactComponent as RightArrowIcon } from 'assets/bosta-icons/Right.svg';
import { ReactComponent as CloseIcon } from 'assets/bosta-icons/Close.svg';
import { ReactComponent as BoxIcon } from 'assets/bosta-icons/box.svg';
import { ReactComponent as Printer3dIcon } from 'assets/bosta-icons/Printer3d.svg';
import { ReactComponent as PackIcon } from 'assets/bosta-icons/pack.svg';
import { ReactComponent as PrinterIcon } from 'assets/bosta-icons/Printer.svg';
import { ReactComponent as CalendarIcon } from 'assets/bosta-icons/Calendar.svg';
import { ReactComponent as ColoredCalenderIcon } from 'assets/bosta-icons/ColoredCalender.svg';

import './SchedulingPickupModal.less';

const SchedulingPickupModal = ({
  intl,
  close,
  closeParentModal,
  backToParentModal,
  ...props
}) => {
  const [currentStep, setCurrentStep] = useState(-1);
  const [isPrimaryBannerVisible, setIsPrimaryBannerVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleStepForwardClick = () => {
    if (currentStep !== STEPS.length - 1) {
      setCurrentStep((curr) => curr + 1);
    }
  };

  const handleStepBackClick = () => {
    if (currentStep !== -1) {
      setCurrentStep((curr) => curr - 1);
    }
  };

  const handleCloseModal = (shouldCloseParentModal = true) => {
    if (shouldCloseParentModal) {
      closeParentModal?.();
    }
    close();
  };

  const STEPS = [
    {
      introductionTitle: intl.formatMessage({
        id: 'new_pickups.scheduling_pickup_modal.steps.check_order_packed.introduction_title'
      }),
      icon: <BoxIcon />,
      title: intl.formatMessage({
        id: 'new_pickups.scheduling_pickup_modal.steps.check_order_packed.title'
      }),
      subtitle: intl.formatMessage({
        id: 'new_pickups.scheduling_pickup_modal.steps.check_order_packed.subtitle'
      }),
      progressPercent: 30,
      renderBanner: () => (
        <BRInfoBanner
          content={intl.formatMessage({
            id: 'new_pickups.scheduling_pickup_modal.steps.check_order_packed.primary_banner'
          })}
        />
      ),
      footerBtns: [
        ...(!isPrimaryBannerVisible
          ? [
              {
                type: 'primary',
                prefixIcon: <Icon component={PackIcon} />,
                label: intl.formatMessage({
                  id: 'new_pickups.scheduling_pickup_modal.steps.check_order_packed.primary_btn'
                }),
                onClick: () => {
                  setIsPrimaryBannerVisible(true);
                  window.open('/shop', '_blank');
                }
              }
            ]
          : []),
        {
          label: intl.formatMessage({
            id: 'new_pickups.scheduling_pickup_modal.steps.check_order_packed.secondary_btn'
          }),
          onClick: () => {
            setIsPrimaryBannerVisible(false);
            handleStepForwardClick();
          }
        }
      ]
    },
    {
      introductionTitle: intl.formatMessage({
        id: 'new_pickups.scheduling_pickup_modal.steps.print_awb.introduction_title'
      }),
      icon: <Printer3dIcon />,
      title: intl.formatMessage({
        id: 'new_pickups.scheduling_pickup_modal.steps.print_awb.title'
      }),
      subtitle: intl.formatMessage({
        id: 'new_pickups.scheduling_pickup_modal.steps.print_awb.subtitle'
      }),
      progressPercent: 60,
      footerBtns: [
        {
          type: 'primary',
          prefixIcon: <Icon component={PrinterIcon} />,
          label: intl.formatMessage({
            id: 'new_pickups.scheduling_pickup_modal.steps.print_awb.primary_btn'
          }),
          onClick: async () => {
            setIsLoading(true);
            const payload = { allPendingOrders: true };
            try {
              const data = await printMassAirwayBills(payload);
              if (typeof data === 'string') {
                const fileName = `Air-waybills_[${dayjs(new Date()).format(
                  'DD-MM-YYYY'
                )}].pdf`;
                downloadAsPdf(data, fileName);
              } else {
                notify({
                  msg: intl.formatMessage({ id: 'common.export_success' }),
                  type: 'success'
                });
              }
              handleStepForwardClick();
            } catch (error) {
              notify({ msg: error.message, error });
            }
            setIsLoading(false);
          }
        },
        {
          label: intl.formatMessage({
            id: 'new_pickups.scheduling_pickup_modal.steps.print_awb.secondary_btn'
          }),
          onClick: handleStepForwardClick
        },
        {
          type: 'treitary-gray',
          label: intl.formatMessage({
            id: 'new_pickups.scheduling_pickup_modal.steps.print_awb.text_btn'
          }),
          onClick: handleStepForwardClick
        }
      ]
    },
    {
      introductionTitle: intl.formatMessage({
        id: 'new_pickups.scheduling_pickup_modal.steps.schedule.introduction_title'
      }),
      icon: <ColoredCalenderIcon />,
      title: intl.formatMessage({
        id: 'new_pickups.scheduling_pickup_modal.steps.schedule.title'
      }),
      subtitle: intl.formatMessage(
        {
          id: 'new_pickups.scheduling_pickup_modal.steps.schedule.subtitle'
        },
        {
          time: getPickupScheduleTimeForSameDay()
        }
      ),
      progressPercent: 90,
      footerBtns: [
        {
          type: 'primary',
          prefixIcon: (
            <Icon component={CalendarIcon} className="icon-white-color" />
          ),
          label: intl.formatMessage({
            id: 'new_pickups.scheduling_pickup_modal.steps.schedule.primary_btn'
          }),
          onClick: () => {
            handleCloseModal(false);
            backToParentModal?.();
          }
        },
        {
          type: 'treitary-gray',
          label: intl.formatMessage({
            id: 'new_pickups.scheduling_pickup_modal.steps.schedule.text_btn'
          }),
          onClick: handleCloseModal
        }
      ]
    }
  ];

  const renderIntroductionStep = () => {
    return (
      <div className="br-scheduling-pickup-modal__introduction-step">
        <div className="br-scheduling-pickup-modal__header">
          <div className="br-scheduling-pickup-modal__step-progress">
            <BoxIcon />
          </div>
          <CloseIcon className="clickable-icon" onClick={handleCloseModal} />
        </div>
        <div className="br-scheduling-pickup-modal__content">
          <div className="br-scheduling-pickup-modal__title">
            {intl.formatMessage({
              id: 'new_pickups.scheduling_pickup_modal.introduction.title'
            })}
          </div>
          <div className="br-scheduling-pickup-modal__subtitle">
            {intl.formatMessage({
              id: 'new_pickups.scheduling_pickup_modal.introduction.subtitle'
            })}
          </div>
          {STEPS.map(({ introductionTitle, footerBtns = [] }, index) => (
            <div className="br-scheduling-pickup-modal__introduction-step__steps-content">
              {footerBtns[0]?.prefixIcon}
              <span className="body-medium">{`${
                index + 1
              }. ${introductionTitle}`}</span>
            </div>
          ))}
        </div>
        <div className="br-scheduling-pickup-modal__footer">
          <BRButton
            type="primary"
            suffixIcon={
              <Icon
                component={isDashboardArabic() ? LeftArrowIcon : RightArrowIcon}
              />
            }
            label={intl.formatMessage({
              id: 'new_pickups.scheduling_pickup_modal.introduction.primary_btn'
            })}
            onClick={() => {
              handleStepForwardClick();
            }}
          />
        </div>
      </div>
    );
  };

  const renderCurrentStep = () => {
    const {
      icon,
      title,
      subtitle,
      progressPercent,
      footerBtns = [],
      renderBanner
    } = STEPS[currentStep];

    const StepIcon = icon;
    const BackArrow = isDashboardArabic() ? RightArrowIcon : LeftArrowIcon;

    return (
      <>
        <div className="br-scheduling-pickup-modal__header">
          <BackArrow
            onClick={handleStepBackClick}
            className={classnames('clickable-icon', {
              'visibility-hidden': currentStep === -1 || isPrimaryBannerVisible
            })}
          />
          <span>
            {intl.formatMessage({
              id: 'new_pickups.scheduling_pickup_modal.header_title'
            })}
          </span>
          <CloseIcon className="clickable-icon" onClick={handleCloseModal} />
        </div>
        <div className="br-scheduling-pickup-modal__content">
          <Progress
            className="br-scheduling-pickup-modal__progress"
            type="circle"
            percent={progressPercent}
            format={() => (
              <div className="br-scheduling-pickup-modal__step-progress">
                {/* <img src={StepIcon} alt="" /> */}
                {StepIcon}
              </div>
            )}
          />
          <div className="br-scheduling-pickup-modal__step-tracker">
            {intl.formatMessage(
              {
                id: 'new_pickups.scheduling_pickup_modal.step_tracker'
              },
              {
                currentStep: currentStep + 1,
                totalSteps: STEPS.length
              }
            )}
          </div>
          <div className="br-scheduling-pickup-modal__title">{title}</div>
          <div className="br-scheduling-pickup-modal__subtitle">{subtitle}</div>
        </div>
        <div className="br-scheduling-pickup-modal__footer">
          {isPrimaryBannerVisible && renderBanner?.()}
          {footerBtns.map((btnProps) => (
            <BRButton {...btnProps} />
          ))}
        </div>
      </>
    );
  };

  useEffect(() => {
    sendSegment('Schedule_Pickup_Guide_Modal');
  }, []);

  return (
    <Modal
      wrapClassName="br-scheduling-pickup-modal"
      visible
      footer={null}
      {...props}
    >
      <LoadingWrapper loading={isLoading}>
        {currentStep === -1 ? renderIntroductionStep() : renderCurrentStep()}
      </LoadingWrapper>
    </Modal>
  );
};

export default injectIntl(SchedulingPickupModal);
