import React from 'react';
import { Input } from 'antd';
import BRFormInputCounter from 'components/BRFormInputCounter/BRFormInputCounter';

class BRFormInputNumber extends React.Component {
  state = { value: null, regex: /^\d+$/ };

  handleOnChange = (evt) => {
    const { filedName, handleOnChangeForm } = this.props;
    const { regex } = this.state;
    const value = evt.currentTarget.value;

    if (regex.test(value)) {
      handleOnChangeForm(filedName, value);
      this.setState({ value });
      return true;
    } else if (value.length === 0) {
      handleOnChangeForm(filedName, value);
      this.setState({ value });
      return true;
    } else {
      return false;
    }
  };

  render() {
    const {
      maxLength,
      placeholder,
      label,
      isWithCounter,
      optional,
      autoComplete,
      value,
      disabled,
      refName,
      handleOnPaste,
      prefix
    } = this.props;

    return (
      <>
        {isWithCounter ? (
          <BRFormInputCounter
            onChange={this.handleOnChange}
            label={label}
            maxCount={maxLength}
            optional={optional}
            value={value}
          >
            <Input
              type="text"
              data-hj-allow
              maxLength={maxLength}
              placeholder={placeholder}
              autoComplete={autoComplete}
              disabled={disabled}
              prefix={prefix}
            />
          </BRFormInputCounter>
        ) : (
          <Input
            type="number"
            data-hj-allow
            value={value ? value : null}
            onChange={this.handleOnChange}
            maxLength={maxLength}
            placeholder={placeholder}
            autoComplete={autoComplete}
            disabled={disabled}
            onPaste={handleOnPaste}
            ref={refName}
          />
        )}
      </>
    );
  }
}

export default BRFormInputNumber;
