import { useState, useEffect } from 'react';
import { Select } from 'antd';
import Highlighter from 'react-highlight-words';
import classnames from 'classnames';

import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';

import './BRSelect.less';

const BRSelect = ({
  options = [],
  selectLabelKey,
  selectValueKey = '',
  isLoading = false,
  disabled = false,
  showSearch = true,
  formRef,
  fieldName,
  initialValue = null,
  handleChangeValue = () => {},
  handleAfterSelect = () => {},
  placeholder,
  className,
  dropdownTitle,
  dropdownMatchSelectWidth
}) => {
  const [searchValue, setSearchValue] = useState('');

  const handleResetSearchValue = () => {
    setSearchValue('');
  };

  const OnChangeSelectValue = (value, { label }) => {
    handleChangeValue(label, value);
    handleResetSearchValue();
    if (fieldName && formRef) {
      formRef.current.setFieldsValue({ [fieldName]: value });
    }
    handleAfterSelect();
  };

  return (
    <Select
      className={classnames('br-select', className)}
      placeholder={placeholder}
      virtual={false}
      disabled={disabled}
      showSearch={showSearch}
      defaultValue={initialValue}
      dropdownMatchSelectWidth={dropdownMatchSelectWidth}
      optionFilterProp="label"
      filterOption={(input, option) => {
        return option.label.toLowerCase().includes(input.toLowerCase());
      }}
      onChange={OnChangeSelectValue}
      onClear={handleResetSearchValue}
      onSearch={(value) => {
        setSearchValue(value);
      }}
      dropdownRender={(menu) =>
        isLoading ? (
          <LoadingWrapper />
        ) : (
          <>
            {dropdownTitle && (
              <span className="br-select-dropdown__title subheading">
                {dropdownTitle}
              </span>
            )}
            {menu}
          </>
        )
      }
    >
      {options.map((option) => (
        <Select.Option
          value={option[selectValueKey]}
          label={option[selectLabelKey]}
          key={option[selectValueKey]}
        >
          <span className="br-selected-indicator" />
          <Highlighter
            className="br-select-highlighter"
            highlightClassName="br-city__highlighted-text"
            searchWords={[searchValue]}
            autoEscape={true}
            textToHighlight={option[selectLabelKey]}
          />
        </Select.Option>
      ))}
    </Select>
  );
};

export default BRSelect;
