import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';

import { LOCALE } from 'constants/intl-wrapper';
import { getLocaleFromLocalStorage } from 'utils/intl-wrapper';

import { fmt } from 'components/IntlWrapper/IntlWrapper';

dayjs.extend(quarterOfYear);

const { AR, EN } = LOCALE;
const locale = getLocaleFromLocalStorage();

export const ORDER_TYPES = [
  { label: 'forward', value: 10 },
  { label: 'exchange', value: 30 },
  { label: 'customer_return_pickup', value: 25 },
  { label: 'cash_collection', value: 15 },
  { label: 'sign_and_return', value: 35 },
  { label: 'fulfillment', value: 200 }
];

export const MAP_DELIVERIES_CODES = {
  10: 'SEND',
  15: 'CASH_COLLECTION',
  25: 'CUSTOMER_RETURN_PICKUP',
  30: 'EXCHANGE',
  35: 'SIGN_AND_RETURN',
  200: 'FXF_SEND'
};

export const IS_TODAY_START_OF_MONTH =
  dayjs().format('YYYY-MM-DD') ===
  dayjs().startOf('month').format('YYYY-MM-DD');

export const YESTERDAY = [
  dayjs().subtract(1, 'days'),
  dayjs().subtract(1, 'days')
];

export const LAST_SEVEN_DAYS = [
  dayjs().subtract(7, 'days'),
  dayjs().subtract(1, 'days')
];

export const THIS_MONTH = IS_TODAY_START_OF_MONTH
  ? [
      dayjs().startOf('month').subtract(1, 'month'),
      dayjs().subtract(1, 'month').endOf('month')
    ]
  : [dayjs().startOf('month'), dayjs().subtract(1, 'days')];

export const THIS_QUARTER = [
  dayjs().startOf('quarter'),
  dayjs().subtract(1, 'days')
];
export const THIS_YEAR = [dayjs().startOf('year'), dayjs().subtract(1, 'days')];
export const LAST_MONTH = IS_TODAY_START_OF_MONTH
  ? [
      dayjs().startOf('month').subtract(2, 'months'),
      dayjs().subtract(2, 'month').endOf('month')
    ]
  : [
      dayjs().startOf('month').subtract(1, 'months'),
      dayjs().subtract(1, 'month').endOf('month')
    ];
export const LAST_QUARTER = [
  dayjs().startOf('quarter').subtract(1, 'quarter'),
  dayjs().endOf('quarter').subtract(1, 'quarter')
];
export const LAST_YEAR = [
  dayjs().startOf('year').subtract(1, 'year'),
  dayjs().endOf('year').subtract(1, 'year')
];

export const CITIES = 'cities';
export const HUBS = 'hubs';

export const DATE_FORMAT = 'YYYY-MM-DD';

export const DATE_RANGES = [
  {
    label: fmt({ id: `date_picker.filter_option.yesterday` }),
    value: YESTERDAY
  },
  {
    label: fmt({ id: `date_picker.filter_option.last_seven_days` }),
    value: LAST_SEVEN_DAYS
  },
  {
    label: fmt({ id: `date_picker.filter_option.this_month` }),
    value: THIS_MONTH
  },
  {
    label: fmt({ id: `date_picker.filter_option.this_quarter` }),
    value: THIS_QUARTER
  },
  {
    label: fmt({ id: `date_picker.filter_option.this_year` }),
    value: THIS_YEAR
  },
  {
    label: fmt({ id: `date_picker.filter_option.last_month` }),
    value: LAST_MONTH
  },
  {
    label: fmt({ id: `date_picker.filter_option.last_quarter` }),
    value: LAST_QUARTER
  },
  {
    label: fmt({ id: `date_picker.filter_option.last_year` }),
    value: LAST_YEAR
  }
];

export const CASH_OUT_TABLE_LIMIT = 6;

export const SECTIONS_BUTTONS = [
  {
    label: fmt({ id: 'analytics.delivery_performance.title' }),
    elementId: 'deliveryPerformanceSection'
  },
  {
    label: fmt({ id: 'analytics.geographical_analysis.title' }),
    elementId: 'geographicalAnalysisSection'
  },
  {
    label: fmt({ id: 'analytics.financial_summary.title' }),
    elementId: 'financialSummarySection'
  }
];

export const EXCEPTIONS_REASONS = [
  {
    key: null,
    reason: fmt({
      id: 'analytics.delivery_performance.unsuccessful_reasons.exceptions_reasons.unknown_reason'
    })
  },
  {
    key: 1,
    reason: fmt({
      id: 'analytics.delivery_performance.unsuccessful_reasons.exceptions_reasons.customer_is_not_in_address'
    })
  },
  {
    key: 2,
    reason: fmt({
      id: 'analytics.delivery_performance.unsuccessful_reasons.exceptions_reasons.customer_changed_the_address'
    })
  },
  {
    key: 3,
    reason: fmt({
      id: 'analytics.delivery_performance.unsuccessful_reasons.exceptions_reasons.customer_postponed'
    })
  },
  {
    key: 4,
    reason: fmt({
      id: 'analytics.delivery_performance.unsuccessful_reasons.exceptions_reasons.customer_wants_to_open_order'
    })
  },
  {
    key: 5,
    reason: fmt({
      id: 'analytics.delivery_performance.unsuccessful_reasons.exceptions_reasons.wrong_phone_or_address'
    })
  },
  {
    key: 6,
    reason: fmt({
      id: 'analytics.delivery_performance.unsuccessful_reasons.exceptions_reasons.shipper_cancel_order'
    })
  },
  {
    key: 7,
    reason: fmt({
      id: 'analytics.delivery_performance.unsuccessful_reasons.exceptions_reasons.customer_not_answer'
    })
  },
  {
    key: 8,
    reason: fmt({
      id: 'analytics.delivery_performance.unsuccessful_reasons.exceptions_reasons.customer_refused_to_receive'
    })
  },
  {
    key: 9,
    reason: fmt({
      id: 'analytics.delivery_performance.unsuccessful_reasons.exceptions_reasons.customer_refused_to_give_the_order'
    })
  },
  {
    key: 10,
    reason: fmt({
      id: 'analytics.delivery_performance.unsuccessful_reasons.exceptions_reasons.customer_refused_to_pay'
    })
  },
  {
    key: 13,
    reason: fmt({
      id: 'analytics.delivery_performance.unsuccessful_reasons.exceptions_reasons.address_is_not_clear'
    })
  },
  {
    key: 14,
    reason: fmt({
      id: 'analytics.delivery_performance.unsuccessful_reasons.exceptions_reasons.phone_is_wrong'
    })
  },
  {
    key: 100,
    reason: fmt({
      id: 'analytics.delivery_performance.unsuccessful_reasons.exceptions_reasons.bad_weather'
    })
  },
  {
    key: 'lost',
    reason: fmt({
      id: 'analytics.delivery_performance.unsuccessful_reasons.exceptions_reasons.order_lost'
    })
  },
  {
    key: 'damaged',
    reason: fmt({
      id: 'analytics.delivery_performance.unsuccessful_reasons.exceptions_reasons.order_damaged'
    })
  }
];

export const NOT_ACTIVE_CITIES_LIST = [
  {
    name: 'Cairo',
    nameAr: 'القاهرة',
    totalOrders: 0
  },
  { name: 'Alexandria', nameAr: 'الإسكندرية', totalOrders: 0 },
  { name: 'Giza', nameAr: 'الجيزة', totalOrders: 0 },
  { name: 'Assuit', nameAr: 'أسيوط', totalOrders: 0 },
  { name: 'Luxor', nameAr: 'الأقصر', totalOrders: 0 },
  { name: 'Menya', nameAr: 'المنيا', totalOrders: 0 },
  { name: 'Matrouh', nameAr: 'مرسى مطروح', totalOrders: 0 },
  { name: 'Behira', nameAr: 'البحيرة', totalOrders: 0 },
  { name: 'Port Said', nameAr: 'بور سعيد', totalOrders: 0 },
  { name: 'Mansoura', nameAr: 'المنصوره', totalOrders: 0 },
  { name: 'Aswan', nameAr: 'أسوان', totalOrders: 0 }
];

export const NOT_ACTIVE_UNSUCCESSFUL_REASONS = {
  1: 0,
  2: 0,
  3: 0,
  4: 0,
  13: 0,
  14: 0,
  6: 0
};

export const NOT_ACTIVE_WALLET_DATA = [
  { date: '14 Jan', label: 'Bosta fees', value: 500 },
  { date: '14 Jan', label: 'Bosta fees', value: 500 },
  { date: '15 Jan', label: 'Bosta fees', value: 5000 },
  { date: '15 Jan', label: 'Bosta fees', value: 5000 },
  { date: '16 Jan', label: 'Cash collected', value: 10000 },
  { date: '16 Jan', label: 'Cash collected', value: 10000 },
  { date: '17 Jan', label: 'Bosta fees', value: 20000 },
  { date: '17 Jan', label: 'Cash collected', value: 20000 },
  { date: '18 Jan', label: 'Bosta fees', value: 20000 },
  { date: '18 Jan', label: 'Cash collected', value: 20000 },
  { date: '19 Jan', label: 'Bosta fees', value: 20000 },
  { date: '19 Jan', label: 'Cash collected', value: 20000 },
  { date: '20 Jan', label: 'Bosta fees', value: 20000 },
  { date: '20 Jan', label: 'Cash collected', value: 20000 },
  { date: '21 Jan', label: 'Bosta fees', value: 25000 },
  { date: '21 Jan', label: 'Cash collected', value: 25000 }
];

export const OTHERS = 'Others';

export const OTHERS_AR = 'آخرون';

export const TOTAL_DELIVERIES_VIEW_EMPTY_STATE_URLS = `https://bosta.freshdesk.com/${locale}/support/solutions/articles/36000345327`;

export const AVG_INDUSTRY_URLS = {
  AVERAGE_DELIVERY_TIME_URL: {
    [AR]: 'https://bosta.freshdesk.com/ar/support/solutions/articles/36000344863-%D8%A7%D8%B2%D8%A7%D9%8A-%D8%A7%D9%82%D9%84%D9%84-%D9%85%D8%AA%D9%88%D8%B3%D8%B7-%D9%85%D8%AF%D8%A9-%D8%AA%D9%88%D8%B5%D9%8A%D9%84-%D8%A7%D9%84%D8%A3%D9%88%D8%B1%D8%AF%D8%B1',
    [EN]: 'https://bosta.freshdesk.com/en/support/solutions/articles/36000344862-how-to-reduce-your-delivery-s-fail-attempts-'
  }[locale],

  DELIVERY_SUCCESS_RATE_URL: `https://bosta.freshdesk.com/${locale}/support/solutions/articles/36000344861`,

  DELIVERY_ATTEMPT_ANALYSIS_URL: {
    [AR]: 'https://bosta.freshdesk.com/ar/support/solutions/articles/36000344862-%D8%A7%D8%B2%D8%A7%D9%8A-%D8%A7%D9%82%D9%84%D9%84-%D8%B9%D8%AF%D8%AF-%D8%A7%D9%84%D9%85%D8%AD%D8%A7%D9%88%D9%84%D8%A7%D8%AA-%D8%A7%D9%84%D9%84%D8%A7%D8%B2%D9%85%D8%A9-%D9%84%D8%AA%D9%88%D8%B5%D9%8A%D9%84-%D8%A7%D9%84%D8%A3%D9%88%D8%B1%D8%AF%D8%B1',
    [EN]: 'https://bosta.freshdesk.com/en/support/solutions/articles/36000344863-delivery-attempts-analysis'
  }[locale]
};

export const ANALYTICS_VIDEO = 'https://www.youtube.com/embed/8_TiB5pKwo8';
export const ANALYTICS_START_YEAR = 2021;
