import Icon from '@ant-design/icons';
import { Tooltip } from 'antd';

import {
  PICKUP_LOCATION_OPTIONS,
  BULKY,
  LIGHT_BULKY,
  HEAVY_BULKY,
  XXL
} from 'constants/pricing';
import { getCurrency } from 'constants/countries/countries-mapping';
import {
  CASHOUT_FREQUENCY,
  PAYMENT_FREQUENCY_LABEL,
  PAYMENT_SCHEDULE,
  PERCENTAGE
} from 'constants/wallet';
import { isSaudi } from 'constants/helpers';

import { fmt, intl } from 'components/IntlWrapper/IntlWrapper';

import { ReactComponent as Tool } from 'assets/bosta-icons/Tooltip.svg';

export const formatSizeName = (name) => {
  let formattedName = '';
  if (name?.sizeAlias !== name?.sizeName) {
    formattedName = name.sizeAlias + ' ' + name.sizeName;
    formattedName = formattedName.replace('-', '_');
    formattedName = formattedName.replace(/\s/g, '_');
    formattedName = formattedName.toLowerCase();
  } else {
    formattedName = name?.sizeAlias;
    formattedName = formattedName?.replace('-', '_');
    formattedName = formattedName?.replace(/\s/g, '_');
    formattedName = formattedName?.toLowerCase();
  }
  return formattedName;
};

export const getSizeTooltip = (size) => {
  switch (size) {
    case LIGHT_BULKY:
      return fmt({
        id: 'settings.pricing_plan.tooltips.light_bulky'
      });
    case HEAVY_BULKY:
      return fmt({
        id: 'settings.pricing_plan.tooltips.heavy_bulky'
      });
    case XXL:
      return fmt({
        id: 'settings.pricing_plan.tooltips.xxl_white_bag'
      });
    default:
      break;
  }
};

export const insertIcon = (size) => {
  if ([LIGHT_BULKY, HEAVY_BULKY, XXL].includes(size)) {
    return (
      <Tooltip
        title={getSizeTooltip(size)}
        className="pricing-extra-info__size-tooltip"
      >
        <Icon component={Tool} />
      </Tooltip>
    );
  }
};

export const formatData = (sector, index, showExtraInfo = false, useWeight) => {
  const sectorsFormat = [
    {
      key: index++ / 10,
      name: showExtraInfo ? (
        intl.locale === 'en' ? (
          <div className="pricing-extra-info-container">
            <span className="pricing-extra-info__size-title">
              {sector.dropoffSectorName}
            </span>
            <span className="pricing-extra-info__size-description caption-lg">
              {PICKUP_LOCATION_OPTIONS[sector.dropoffSectorId].value}
            </span>
          </div>
        ) : (
          <div>{sector.dropoffSectorNameArabic}</div>
        )
      ) : intl.locale === 'en' ? (
        sector.dropoffSectorName
      ) : (
        sector.dropoffSectorNameArabic
      ),
      value: PICKUP_LOCATION_OPTIONS[sector.dropoffSectorId]?.value,
      dropOffSectorName: sector.dropoffSectorId
    }
  ];

  if (useWeight) {
    sectorsFormat.push({
      key: index++ * 10,
      name: fmt({
        id: 'settings.pricing_plan.basic_fees'
      }),
      deliver: sector.tierServiceTypes[0].tierSizes[0].cost,
      exchange: sector.tierServiceTypes[1].tierSizes[0].cost,
      return: sector.tierServiceTypes[2].tierSizes[0].cost,
      cash_collection: sector.tierServiceTypes[3].tierSizes[0].cost,
      rto: sector.tierServiceTypes[4].tierSizes[0].cost,
      dropOffSectorName: sector.dropoffSectorId
    });
  } else {
    const typesObj = {
      SEND: [],
      CUSTOMER_RETURN_PICKUP: [],
      CASH_COLLECTION: [],
      RTO: []
    };
    sector.tierServiceTypes.forEach((serviceType) => {
      typesObj[serviceType.typeName] = serviceType.tierSizes;
    });
    for (let i = 0; i < sector.tierServiceTypes[0].tierSizes.length; i++) {
      if (sector.tierServiceTypes[0].tierSizes[i].sizeName !== BULKY) {
        sectorsFormat.push({
          key: index++ * 10,
          name: showExtraInfo ? (
            <div className="pricing-extra-info-container">
              <span className="pricing-extra-info__size-title">
                <span>
                  {fmt({
                    id: `settings.pricing_plan.sizes.${formatSizeName(
                      sector.tierServiceTypes[0].tierSizes[i]
                    )}`
                  })}
                </span>
                {insertIcon(sector.tierServiceTypes[0].tierSizes[i].sizeAlias)}
              </span>
              <span className="pricing-extra-info__size-description caption-lg">
                {sector.tierServiceTypes[0].tierSizes[i].sizeWidth
                  ? fmt(
                      { id: 'sign_up.pricing.pricing_more_info' },
                      {
                        width:
                          sector.tierServiceTypes[0].tierSizes[i].sizeWidth,
                        height:
                          sector.tierServiceTypes[0].tierSizes[i].sizeHeight
                      }
                    )
                  : sector.tierServiceTypes[0].tierSizes[i].sizeName ===
                    LIGHT_BULKY
                  ? fmt({ id: `settings.pricing_plan.light_bulky_size` })
                  : fmt({ id: `settings.pricing_plan.heavy_bulky_size` })}
              </span>
            </div>
          ) : (
            fmt({
              id: `settings.pricing_plan.sizes.${formatSizeName(
                sector.tierServiceTypes[0].tierSizes[i]
              )}`
            })
          ),
          otherDay: sector.tierServiceTypes[0].tierSizes[i]?.otherDay,
          deliver: Math.round(typesObj['SEND'][i].cost * 10) / 10,
          exchange: Math.round(typesObj['EXCHANGE'][i]?.cost * 10) / 10,
          return:
            Math.round(typesObj['CUSTOMER_RETURN_PICKUP'][i]?.cost * 10) / 10,
          cash_collection:
            Math.round(typesObj['CASH_COLLECTION'][i].cost * 10) / 10,
          rto: Math.round(typesObj['RTO'][i].cost * 10) / 10,
          dropOffSectorName: sector.dropoffSectorId
        });
      }
    }
  }
  return sectorsFormat;
};

export const formatPricingTableData = (
  pricingData,
  useWeight = false,
  showExtraInfo = false
) => {
  const tableData = [];
  pricingData.forEach((sector, index) => {
    const sectorData = formatData(sector, index * 10, showExtraInfo, useWeight);
    tableData.push(...sectorData);
  });
  return tableData;
};

export const typeObject = {
  CASH_COLLECTION: 3,
  CUSTOMER_RETURN_PICKUP: 2,
  EXCHANGE: 1,
  SEND: 0,
  RTO: 4
};

const currency = getCurrency().localized;

export const feesBreakdown = (bostaFees) => [
  {
    label: fmt({
      id: `bosta_fees_popover.shipping_fees`
    }),
    tooltip: fmt({
      id: `bosta_fees_popover.shipping_fees_tooltip`
    }),
    value: bostaFees?.shippingFee
  },
  {
    label: fmt({
      id: `bosta_fees_popover.insurance_fees`
    }),
    tooltip: fmt(
      {
        id: `bosta_fees_popover.insurance_fees_tooltip`
      },
      {
        fee:
          bostaFees?.insuranceFee &&
          parseInt(bostaFees?.insuranceFee?.percentage * 100)
      }
    ),
    value:
      bostaFees?.insuranceFee?.amount &&
      bostaFees?.insuranceFee?.amount.toFixed(1),
    isVisible: () => bostaFees?.insuranceFee
  },
  {
    label: fmt({
      id: `bosta_fees_popover.collection_fees`
    }),
    tooltip: fmt(
      {
        id: `bosta_fees_popover.collection_fees_tooltip`
      },
      {
        amount: bostaFees?.tier?.extraCodFee?.codAmount,
        fee:
          bostaFees?.extraCodFee &&
          parseInt(bostaFees?.extraCodFee?.percentage * 100)
      }
    ),
    value:
      bostaFees?.extraCodFee?.amount &&
      bostaFees?.extraCodFee?.amount?.toFixed(1),
    isVisible: () => bostaFees?.extraCodFee
  },
  {
    label: fmt({
      id: 'bosta_fees_popover.cod_fees'
    }),
    tooltip: fmt(
      {
        id: `bosta_fees_popover.cod_fees_tooltip`
      },
      {
        amount: bostaFees?.tier?.codFee?.amount,
        currency
      }
    ),
    value: bostaFees?.codFee?.amount && bostaFees?.codFee?.amount?.toFixed(1),
    isVisible: () => bostaFees?.codFee
  },
  {
    label: fmt({
      id: `bosta_fees_popover.next_day_transfer_fees`
    }),
    tooltip: fmt(
      {
        id: `bosta_fees_popover.extra_fees_tooltip`
      },
      {
        fee:
          bostaFees?.expediteFee &&
          parseInt(bostaFees?.expediteFee?.percentage * 100)
      }
    ),
    value:
      bostaFees?.expediteFee?.amount &&
      bostaFees?.expediteFee?.amount?.toFixed(1),
    isVisible: () => bostaFees?.expediteFee
  },
  {
    label: fmt({
      id: `bosta_fees_popover.discount`
    }),
    tooltip: fmt(
      {
        id: `bosta_fees_popover.discount_tooltip`
      },
      {
        amount: bostaFees?.tier?.zeroCodDiscount?.amount
      }
    ),
    value:
      bostaFees?.zeroCodDiscount?.amount &&
      (bostaFees?.zeroCodDiscount?.amount * -1).toFixed(1),
    isVisible: () => bostaFees?.zeroCodDiscount
  },
  {
    label: fmt({
      id: `bosta_fees_popover.promotion`
    }),
    tooltip: fmt(
      {
        id: `bosta_fees_popover.promotion_tooltip_${isPercentageLabel(
          bostaFees?.promotion?.valueType
        )}`
      },
      {
        value: isPercentageValue(
          bostaFees?.promotion?.valueType,
          bostaFees?.promotion?.value
        )
      }
    ),
    value:
      bostaFees?.promotion?.amount &&
      (bostaFees?.promotion?.amount * -1).toFixed(1),
    isVisible: () => bostaFees?.promotion
  },
  {
    label: fmt(
      {
        id: `bosta_fees_popover.vat`
      },
      {
        vatValue: parseInt(bostaFees?.vat * 100) || 14
      }
    ),
    tooltip: fmt(
      {
        id: 'bosta_fees_popover.vat_tooltip'
      },
      {
        vatValue: parseInt(bostaFees?.vat * 100) || 14
      }
    ),
    value: (bostaFees?.priceAfterVat - bostaFees?.priceBeforeVat).toFixed(1)
  },
  {
    label: fmt({
      id: `bosta_fees_popover.open_package_fees`
    }),
    tooltip: fmt({
      id: 'bosta_fees_popover.open_package_fees_tooltip'
    }),
    value: bostaFees?.openingPackageFee?.amount,
    isVisible: () => !!bostaFees?.openingPackageFee?.amount
  }
];

export const isPercentageLabel = (type) => {
  return type === PERCENTAGE ? 'percentage' : isSaudi() ? 'sar' : 'egp';
};

export const isPercentageValue = (type, value) => {
  return parseInt(type === PERCENTAGE ? value * 100 : value);
};

export const getPaymentScheduleLabel = (paymentData = {}) => {
  const { paymentFrequency, paymentSchedule = [] } = paymentData;

  const { WEEKLY, DAILY, NEXT_DAY } = CASHOUT_FREQUENCY;

  if (paymentFrequency === DAILY) {
    return PAYMENT_FREQUENCY_LABEL.DAILY;
  }

  if (paymentFrequency === NEXT_DAY) {
    return PAYMENT_FREQUENCY_LABEL.NEXT_DAY;
  }

  if (paymentFrequency === WEEKLY && paymentSchedule.length === 1) {
    return PAYMENT_FREQUENCY_LABEL.WEEKLY;
  }

  const paymentScheduleKey = Object.keys(PAYMENT_SCHEDULE).find(
    (key) =>
      PAYMENT_SCHEDULE[key].length === paymentSchedule.length &&
      PAYMENT_SCHEDULE[key].every((day) => paymentSchedule.includes(day))
  );

  return PAYMENT_FREQUENCY_LABEL[paymentScheduleKey];
};
