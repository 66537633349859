import React, { useRef, useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { Form, Input } from 'antd';

import { mediaHook } from 'customHooks';
import { sendSegment } from 'utils/segment';
import { emailRule } from 'utils/forms';
import { getSignInPath } from 'utils/login';
import { getSignUpPath } from 'utils/signup';
import { ERROR_CODES } from 'constants/login';
import { sendEmailForgetPassword } from 'services/auth';

import { notify } from 'components/Notify/Notify';
import BRButton from 'components/BRButton/BRButton';
import RegistrationContainer from 'components/RegistrationContainer/RegistrationContainer';

import './ForgetPassword.less';

const ForgetPassword = (props) => {
  const {
    intl,
    history,
    location,
    mobileScreenSizes: { isSmallMobileScreen }
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [notFoundUserError, setNotFoundUserError] = useState(false);

  const formRef = useRef();
  const isFromSllr = location?.state?.isFromSllr;

  const checkFormValidation = async () => {
    setNotFoundUserError(false);
    try {
      await formRef.current.validateFields();
      setIsValidEmail(true);
    } catch (errorInfo) {
      if (errorInfo.errorFields.length) {
        setIsValidEmail(false);
      } else {
        setIsValidEmail(true);
      }
    }
  };

  useEffect(() => {
    const { location } = history;
    if (location?.state?.email) {
      formRef.current?.setFieldsValue({ email: location.state.email });
      checkFormValidation();
    }
  }, [history]);

  const handleSubmit = async (values) => {
    try {
      setIsLoading(true);
      await sendEmailForgetPassword(values.email);
      sendSegment('E_FORGOT_PASSWORD', { email: values.email });
      history.push({
        pathname: '/confirm-reset-password',
        state: {
          email: values.email
        }
      });
    } catch (error) {
      setIsLoading(false);
      const { errorCode } = error;
      if (errorCode === ERROR_CODES.USER_NOT_FOUND) {
        setNotFoundUserError(true);
      } else {
        notify({ msg: error.message, error });
      }
    }
  };

  const handleGoToSignIn = () => {
    history.push(getSignInPath());
  };

  const handleGoToSignUp = () => {
    history.push(getSignUpPath());
  };

  return (
    <RegistrationContainer
      hideTrackOrderInHeader
      className="br-forget-password-container"
      content={
        <>
          <span className="display-md">
            {intl.formatMessage({ id: 'login.forget_passowrd.title' })}
          </span>
          <span className="br-forget-form-card__subtitle">
            {intl.formatMessage({ id: 'login.forget_passowrd.subtitle' })}
          </span>
          <Form
            ref={formRef}
            onFinish={handleSubmit}
            onValuesChange={checkFormValidation}
          >
            <Form.Item
              label={intl.formatMessage({
                id: 'contact_form_labels.email'
              })}
              className="br-forget-password-form__email"
              name="email"
              hasFeedback
              rules={[
                { required: true },
                emailRule(
                  intl.formatMessage({
                    id: 'form.email_not_valid'
                  })
                )
              ]}
              help={
                notFoundUserError ? (
                  <span className="br-forget-password__not-found-user-error">
                    {intl.formatMessage({
                      id: 'login.forget_passowrd.not_found_user'
                    })}
                  </span>
                ) : undefined
              }
            >
              <Input type="email" />
            </Form.Item>
            {!isSmallMobileScreen && (
              <span className="br-forget-form-card__new_to_bosta br-login-form-card__subtitle">
                <span>{intl.formatMessage({ id: 'login.new_to_bosta' })}</span>
                <BRButton
                  className="button"
                  type="link-color"
                  label={intl.formatMessage({
                    id: 'login.actions.get_started'
                  })}
                  onClick={handleGoToSignUp}
                />
              </span>
            )}
            <BRButton
              type="primary"
              className="button-lg br-registration-form-card__submit-btn"
              htmlType="submit"
              disabled={!isValidEmail}
              loading={isLoading}
              label={intl.formatMessage({ id: 'login.actions.reset_password' })}
            />
          </Form>
          <BRButton
            type="link-color"
            label={intl.formatMessage({
              id: 'login.actions.return_to_signin'
            })}
            className="br-forget-password__return-to-signin-btn"
            onClick={handleGoToSignIn}
          />
          {isSmallMobileScreen && (
            <span className="br-forget-form-card__new_to_bosta">
              {intl.formatMessage({ id: 'login.new_to_bosta' })}
              <BRButton
                type="link-color"
                label={intl.formatMessage({
                  id: 'login.actions.get_started'
                })}
                onClick={handleGoToSignUp}
              />
            </span>
          )}
        </>
      }
    />
  );
};

export default injectIntl(withRouter(mediaHook(ForgetPassword)));
