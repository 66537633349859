import { fmt } from 'components/IntlWrapper/IntlWrapper';

import { ReactComponent as AndroidIcon } from 'assets/bosta-icons/Android.svg';
import { getSmartFlyersLocalizationId } from './countries/countries-mapping';

export const SMART_STICKERS_GUIDE_STEPS = [
  {
    title: fmt({
      id: getSmartFlyersLocalizationId(
        'smart_stickers_guide.steps.step_1.title'
      )
    }),
    subtitle: fmt(
      {
        id: getSmartFlyersLocalizationId(
          'smart_stickers_guide.steps.step_1.subtitle'
        )
      },
      {
        span: (children) => (
          <span className="br-smart-stickers-guide__teal-text body-medium">
            {children}
          </span>
        )
      }
    )
  },
  {
    title: fmt({
      id: getSmartFlyersLocalizationId(
        'smart_stickers_guide.steps.step_2.title'
      )
    }),
    subtitle: fmt({
      id: getSmartFlyersLocalizationId(
        'smart_stickers_guide.steps.step_2.subtitle'
      )
    })
  },
  {
    title: fmt({ id: 'smart_stickers_guide.steps.step_3.title' }),
    subtitle: fmt(
      {
        id: getSmartFlyersLocalizationId(
          'smart_stickers_guide.steps.step_3.subtitle'
        )
      },
      {
        span: (children) => <span className="body-medium">{children}</span>
      }
    )
  },
  {
    title: fmt({ id: 'smart_stickers_guide.steps.step_4.title' }),
    subtitle: fmt({
      id: getSmartFlyersLocalizationId(
        'smart_stickers_guide.steps.step_4.subtitle'
      )
    })
  }
];

export const SMART_STICKERS_GUIDE_VIDEO_URL =
  'https://www.youtube.com/embed/yWdGoQ9Se6k';

export const SMART_MATERIALS_GUIDE_VIDEO_URL =
  'https://www.youtube.com/embed/ExKFFi0sk2Y?si=ruCYAEp6y0JA0wDr';
