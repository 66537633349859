import { useCallback, useRef, useState } from 'react';
import { injectIntl } from 'react-intl';
import { List, Checkbox } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import classnames from 'classnames';
import { useDrag, useDrop } from 'react-dnd';
import { DnDHook } from 'customHooks';

import { formatNumbersBasedOnLanguage } from 'utils/intl-wrapper';

import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import BRButton from 'components/BRButton/BRButton';

import './MobileCards.less';

const MobileCards = ({
  dataSource,
  columns,
  pagination,
  isLoading,
  allowSelection,
  isScrolling,
  handleOnClickCard,
  handlePrintSelectedRows,
  handleDeleteSelectedRows,
  mobileCardActions,
  handleSelectedCards,
  mobileScreenSizes,
  filterLength = 0,
  handleClearSearch = () => {},
  scrollToFilters = () => {},
  selectedCards = [],
  setSelectedCards = () => {},
  isSelectButtonClicked = false,
  setIsSelectButtonClicked = () => false,
  mobileCardsAction = false,
  intl,
  moveRow,
  isDraggableRows = false
}) => {
  const onSelectOrderCard = ({ checked, key }) => {
    const selectedItems = checked
      ? [...selectedCards, key]
      : selectedCards.filter((cardKey) => cardKey !== key);
    setSelectedCards(selectedItems);
    handleSelectedCards(selectedItems);
  };

  const handleCheckSelection = useCallback(() => {
    if (!selectedCards.length && !isSelectButtonClicked) {
      setIsSelectButtonClicked(true);
    } else if (!selectedCards.length && isSelectButtonClicked) {
      const selectedItems = dataSource.map((item) => item.key);
      setSelectedCards(selectedItems);
      handleSelectedCards(selectedItems);
    } else {
      setSelectedCards([]);
      handleSelectedCards([]);
      setIsSelectButtonClicked(false);
    }
  }, [selectedCards, isSelectButtonClicked]);

  const listItem = ({ row, index, ref = null, className = '' }) => {
    return (
      <div
        className={classnames(
          'br-table-mobile-card',
          {
            'br-table-mobile-selected-card': selectedCards.includes(row?.key)
          },
          className
        )}
        ref={ref}
      >
        {isSelectButtonClicked && (
          <Checkbox
            checked={selectedCards?.includes(row?.key)}
            onChange={({ target: { checked } }) =>
              onSelectOrderCard({ checked, key: row.key })
            }
          />
        )}
        <div
          onClick={() => {
            !isSelectButtonClicked && handleOnClickCard(dataSource[index]);
          }}
        >
          {columns.map((item) =>
            item.render
              ? item.render(row[item.dataIndex], row)
              : row[item.dataIndex]
          )}
        </div>
      </div>
    );
  };

  const DraggableListItem = ({ row, index }) => {
    const ref = useRef(null);

    const [{ isOver, dropClassName }, drop] = useDrop({
      accept: 'DraggableRow',
      collect: (monitor) => {
        const { index: dragIndex } = monitor.getItem() || {};
        if (dragIndex === index) {
          return {};
        }
        return {
          isOver: monitor.isOver(),
          dropClassName:
            dragIndex < index
              ? ' mobile-card-drop-over-downward'
              : ' mobile-card-drop-over-upward'
        };
      },
      drop: (item) => {
        moveRow(item.index, index);
      }
    });
    const [, drag] = useDrag({
      type: 'DraggableRow',
      item: {
        index
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging()
      })
    });
    drop(drag(ref));

    return listItem({
      row,
      index,
      ref,
      className: `${isOver ? dropClassName : ''}`
    });
  };

  return (
    <div className="br-mobile-cards">
      {isLoading ? (
        <LoadingWrapper
          loading={isLoading}
          className="br-mobile-cards__loading"
          indicator={
            <div>
              <LoadingOutlined spin />
              <div className="br-table__loading body">
                {intl.formatMessage({ id: 'common.loading' })}
              </div>
            </div>
          }
        />
      ) : (
        <div
          className={classnames({
            'br-table-mobile-cards-with-selection': allowSelection
          })}
        >
          {allowSelection && (
            <div
              className={classnames('br-table-mobile__fixed-header', {
                'br-table-mobile__fixed-header__with-shadow': isScrolling
              })}
            >
              <span className="br-table-mobile__fixed-header__left-section">
                <>
                  <BRButton
                    label={
                      !selectedCards.length && isSelectButtonClicked
                        ? intl.formatMessage({ id: 'common.select_all' })
                        : !!selectedCards?.length
                        ? intl.formatMessage(
                            { id: 'products.products_table.selected' },
                            {
                              count: selectedCards?.length
                            }
                          )
                        : intl.formatMessage({ id: 'form.select_placeholder' })
                    }
                    className={classnames('button-md', {
                      'br-table-mobile__select-button-active':
                        isSelectButtonClicked && handlePrintSelectedRows
                    })}
                    prefixIcon={
                      <Checkbox
                        className={classnames({
                          'br-table-mobile__select-checkbox':
                            !isSelectButtonClicked
                        })}
                        indeterminate={
                          selectedCards.length &&
                          selectedCards.length !== dataSource?.length
                        }
                        checked={
                          !isSelectButtonClicked ||
                          selectedCards.length === dataSource?.length
                        }
                      />
                    }
                    onClick={handleCheckSelection}
                    type="basic"
                  />
                  {handlePrintSelectedRows &&
                    isSelectButtonClicked &&
                    (mobileCardActions ? (
                      mobileCardActions
                    ) : (
                      <>
                        <BRButton
                          label={intl.formatMessage({
                            id: 'orders_listing.actions.print_awb'
                          })}
                          className="button-md"
                          type="basic"
                          onClick={handlePrintSelectedRows}
                        />
                        <BRButton
                          label={intl.formatMessage({ id: 'common.delete' })}
                          type="destructive-treitary"
                          className={classnames(
                            'br-orders-table__title__delete-btn'
                          )}
                          onClick={handleDeleteSelectedRows}
                        />
                      </>
                    ))}
                </>
              </span>
              {isSelectButtonClicked &&
                mobileCardsAction &&
                mobileCardsAction()}
            </div>
          )}
          <List
            className="br-table-mobile-list"
            pagination={pagination}
            dataSource={dataSource}
            renderItem={(row, index) =>
              isDraggableRows ? (
                <DraggableListItem row={row} index={index} />
              ) : (
                listItem({ row, index })
              )
            }
          />
        </div>
      )}
      {mobileScreenSizes.isTinylMobileScreen &&
        isScrolling &&
        filterLength !== 0 && (
          <div className="br-mobile-cards__footer">
            <span className="br-mobile-cards__footer__label">
              {intl.formatMessage(
                { id: 'common.filters_applied' },
                { count: formatNumbersBasedOnLanguage(filterLength) }
              )}
            </span>
            <span className="br-mobile-cards__footer__actions">
              <BRButton
                type="basic"
                className="button-md"
                label={intl.formatMessage({ id: 'common.adjust_filters' })}
                onClick={scrollToFilters}
              />
              <BRButton
                type="treitary-color"
                className="button-md"
                label={intl.formatMessage({ id: 'common.clear' })}
                onClick={handleClearSearch}
              />
            </span>
          </div>
        )}
    </div>
  );
};

export default injectIntl(DnDHook(MobileCards));
