import firebase from 'firebase/app';
import { config } from 'Firebase/config';
import 'firebase/messaging';
import { updateGetStartBusiness } from 'services/auth';

export const initializeFirebase = () => {
  if (firebase.messaging.isSupported()) {
    if (!firebase.apps.length) {
      firebase.initializeApp(config);
      const messaging = firebase.messaging();
      messaging.usePublicVapidKey(
        'BNqJbEx3pcUeJORTD9qQT7eyWQ2Ayl1A0J0vBZMIaJGNsX5dKKlst5zX-7zdMV_g7SsTqFKl_-d8H7kqml0s7B0'
      );
    } else {
      firebase.app(); // if already initialized, use that one
    }
  }
};

export const askForPermissioToReceiveNotifications = async () => {
  try {
    if (firebase.messaging.isSupported()) {
      const messaging = firebase.messaging();
      const permission = await messaging.requestPermission();
      if (permission === 'granted') {
        const registration = await navigator.serviceWorker.register(
          'firebase-messaging-sw.js'
        );
        await messaging.useServiceWorker(registration);
      }
      const tokenFirebase = localStorage.getItem('firebaseToken');
      const token = await messaging.getToken().then(async (Response) => {
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        if (
          userInfo.user.businessAdminInfo?.businessId &&
          tokenFirebase !== Response
        ) {
          localStorage.setItem('firebaseToken', Response);
          await updateGetStartBusiness(
            {
              firebaseToken: Response
            },
            userInfo.user.businessAdminInfo.businessId
          );
        }
      });
      return token;
    }
  } catch (error) {
    console.error(error);
  }
};

// export const deleteToken = async () => {
//   try {
//     const messaging = firebase.messaging();
//     // await messaging.requestPermission();
//     const token = await messaging.deleteToken();
//     console.log('token:', token);
//     return token;
//   } catch (error) {
//     console.error(error);
//   }
// };

// export const onMessageListener = () =>
//   new Promise((resolve) => {
//     const messaging = firebase.messaging();
//     messaging.onMessage((payload) => {
//       console.log(payload);
//       resolve(payload);
//     });
//   });
