import { useRef, useState } from 'react';
import { injectIntl } from 'react-intl';
import Icon from '@ant-design/icons';
import { Modal, Form, Divider, Input, InputNumber } from 'antd';
import classnames from 'classnames';

import { requiredRule } from 'utils/forms';
import { COD_PLACEHOLDER } from 'constants/shipments';
import { getCurrency } from 'constants/countries/countries-mapping';
import { createMultipleProducts } from 'services/products';
import { MAX_PRODUCT_NAME_COUNT } from 'constants/products';

import BRContentHeader from 'components/BRContentHeader/BRContentHeader';
import BRButton from 'components/BRButton/BRButton';
import { notify } from 'components/Notify/Notify';

import { ReactComponent as PlusIcon } from 'assets/bosta-icons/Plus.svg';
import { ReactComponent as Tooltip } from 'assets/bosta-icons/Tooltip.svg';
import { ReactComponent as CloseIcon } from 'assets/bosta-icons/Close.svg';

import './CreateProductModal.less';

const CreateProductModal = ({
  intl,
  close,
  initialProductName = '',
  onSuccess = () => {},
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [productNameCount, setProductNameCount] = useState({
    0: initialProductName.length
  });

  const formRef = useRef();

  const onFinish = async (values) => {
    setIsLoading(true);
    try {
      const payload = {
        products: values.products.map((product) => {
          return {
            name: product.productName,
            defaultPrice: product?.productPrice
          };
        })
      };
      const { createdProducts } = await createMultipleProducts(payload);
      onSuccess(createdProducts);
      close();
    } catch (error) {
      notify({ msg: error.message, error });
      setIsLoading(false);
    }
  };

  const onInputChange = (event, name) => {
    const newProductNameCount = { ...productNameCount };
    newProductNameCount[name] = event.target.value?.length;
    setProductNameCount(newProductNameCount);
  };

  return (
    <Modal wrapClassName="br-product-modal" footer={null} {...props}>
      <BRContentHeader
        title={intl.formatMessage({
          id: 'new_header.orders.create_product_modal.title'
        })}
        isInternalComponent
      />
      <Form
        ref={formRef}
        name="dynamic_form_nest_item"
        className="br-product-modal__form"
        onFinish={onFinish}
      >
        <Form.List
          name="products"
          initialValue={[{ productName: initialProductName }]}
        >
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <div key={key}>
                  <div
                    key={key}
                    className="br-product-modal__form-item-container"
                  >
                    <div className="br-product-modal__form-item">
                      <Form.Item
                        {...restField}
                        name={[name, 'productName']}
                        className="br-product-modal__form-name"
                        rules={[
                          requiredRule(
                            intl.formatMessage(
                              {
                                id: 'form.required_custom'
                              },
                              {
                                label: intl.formatMessage({
                                  id: 'new_header.orders.create_product_modal.product_name'
                                })
                              }
                            )
                          ),
                          {
                            max: MAX_PRODUCT_NAME_COUNT,
                            message: intl.formatMessage(
                              {
                                id: 'form.field_max_length'
                              },
                              {
                                label: intl.formatMessage({
                                  id: 'new_header.orders.create_product_modal.product_name'
                                }),
                                count: MAX_PRODUCT_NAME_COUNT
                              }
                            )
                          }
                        ]}
                        label={
                          <div className="br-form-item-label">
                            <div className="br-form-item-label__title">
                              <span className="body-medium">
                                {intl.formatMessage({
                                  id: 'new_header.orders.create_product_modal.product_name'
                                })}
                              </span>
                              <div
                                className={classnames(
                                  'br-form-count__count-label body',
                                  {
                                    'br-form-count__count-label-exceed':
                                      productNameCount[name] >
                                      MAX_PRODUCT_NAME_COUNT
                                  }
                                )}
                              >
                                <div>
                                  {<span>{productNameCount[name] || 0}</span>}/
                                  {MAX_PRODUCT_NAME_COUNT}
                                </div>
                              </div>
                            </div>
                          </div>
                        }
                        hasFeedback
                      >
                        <Input
                          onChange={(event) => {
                            onInputChange(event, name);
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, 'productPrice']}
                        className="br-product-modal__form-price"
                        label={
                          <div className="br-form-item-label">
                            <div className="br-form-item-label__title">
                              <span className="body-medium">
                                {intl.formatMessage({
                                  id: `new_order.order_details.order_types.select_from_inventory.inventory_table.price`
                                })}
                              </span>
                              <span className="br-form-optional-label">
                                {intl.formatMessage({
                                  id: 'form.optional_label'
                                })}
                              </span>
                            </div>
                          </div>
                        }
                      >
                        <InputNumber
                          min="1"
                          step="0.01"
                          precision="2"
                          controls={false}
                          prefix={getCurrency().localized}
                          placeholder={COD_PLACEHOLDER}
                        />
                      </Form.Item>
                    </div>
                    {fields.length > 1 && (
                      <Icon
                        component={CloseIcon}
                        className="ant-icon-sm"
                        onClick={() => remove(name)}
                      />
                    )}
                  </div>
                  {fields.length > 1 && <Divider />}
                </div>
              ))}
              <BRButton
                type="link-color"
                onClick={() => add()}
                prefixIcon={<Icon component={PlusIcon} />}
                label={intl.formatMessage({
                  id: `new_order.order_details.order_types.select_from_inventory.add_another_product`
                })}
                className="br-product-modal__form-add-product"
              />
            </>
          )}
        </Form.List>
        <div className="br-product-modal__more-info">
          <Icon component={Tooltip} className="ant-icon-md" />
          <span>
            {intl.formatMessage({
              id: 'new_header.orders.create_product_modal.more_info'
            })}
          </span>
        </div>
      </Form>
      <div className="br-product-modal__footer">
        <BRButton
          type="basic"
          onClick={close}
          label={intl.formatMessage({
            id: 'common.cancel'
          })}
        />
        <BRButton
          form="dynamic_form_nest_item"
          className="br-registration-form-card__submit-btn"
          loading={isLoading}
          type="primary"
          htmlType="submit"
          label={intl.formatMessage({
            id: `common.confirm`
          })}
        />
      </div>
    </Modal>
  );
};

export default injectIntl(CreateProductModal);
