import { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { Checkbox, Space, Tag } from 'antd';
import Icon from '@ant-design/icons';
import classNames from 'classnames';

import { STEPPER, SERVICES } from 'constants/signup';
import { sendSegment } from 'utils/segment';

const ServicesContainer = ({
  intl,
  onNotifyChange,
  notifyMe,
  plannedShipmentTypes,
  onChangeServiceCheckbox,
  stepperCurrentStep,
  services = SERVICES,
  isInternationalServices
}) => {
  useEffect(() => {
    sendSegment('Step2_Choose-Service-Landing');
  }, []);

  const ServiceItem = ({
    apiValue,
    icon,
    title,
    subtitle,
    tagText,
    interestedNotificationMsg,
    isDisabled = false
  }) => (
    <div
      key={apiValue}
      className={classNames('ant-space-item-dev', {
        'ant-space-item-disabled': isDisabled,
        'ant-space-item-checked': plannedShipmentTypes?.includes(apiValue)
      })}
    >
      <Checkbox value={apiValue} className="service" disabled={isDisabled}>
        <Icon className="ant-icon-md" component={icon} />
        <div className="service-description">
          <div>
            <span className="body-medium">{title}</span>
            {tagText && <Tag className="subheading">{tagText}</Tag>}
          </div>
          <span className="body">{subtitle}</span>
        </div>
      </Checkbox>
      {interestedNotificationMsg && (
        <div className="interested-in-service-checkbox">
          <Checkbox.Group
            onChange={(list) =>
              onNotifyChange({
                value: apiValue,
                checked: !!list?.[0],
                interestedNotificationMsg
              })
            }
            value={notifyMe?.filter((el) => el === apiValue)}
          >
            {/* This checkbox is not a group, 
                but the group is used to differentiate its value from the checkbox group parent */}
            <Checkbox value={apiValue} className="body">
              {intl.formatMessage({
                id: 'sign_up.services.interested'
              })}
            </Checkbox>
          </Checkbox.Group>
        </div>
      )}
    </div>
  );

  return (
    <div className="br-signup__services-container">
      {!isInternationalServices && (
        <>
          <p className="br-signup__services-steps subheading">
            {intl.formatMessage(
              {
                id: 'sign_up.services.steps'
              },
              {
                currentStep: `${stepperCurrentStep + 1}`,
                lastStep: STEPPER.LAST_STEP
              }
            )}
          </p>
          <span className="display-md">
            {intl.formatMessage({ id: 'sign_up.services.title' })}
          </span>
        </>
      )}

      <Checkbox.Group
        className="br-services-checkbox-group"
        onChange={onChangeServiceCheckbox}
        value={plannedShipmentTypes}
      >
        <Space direction="vertical">
          {services.map((service) => (
            <ServiceItem {...service} key={service.apiValue} />
          ))}
        </Space>
      </Checkbox.Group>
    </div>
  );
};
export default injectIntl(withRouter(ServicesContainer));
