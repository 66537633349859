import { Modal, Timeline } from 'antd';
import { injectIntl } from 'react-intl';
import { isMobile } from 'react-device-detect';

import {
  SMART_MATERIALS_GUIDE_VIDEO_URL,
  SMART_STICKERS_GUIDE_STEPS,
  SMART_STICKERS_GUIDE_VIDEO_URL
} from 'constants/smart-stickers';
import { useModal } from 'contexts/modalProvider.context';
import { sendSegment } from 'utils/segment';
import { getDeviceType } from 'utils';
import { getSmartFlyersLocalizationId } from 'constants/countries/countries-mapping';
import { isSaudi } from 'constants/helpers';

import { BannerContent } from 'components/Banner/Banner';
import BRButton from 'components/BRButton/BRButton';
import ScanModal from 'components/SmartStickerGuideModal/components/ScanModal/ScanModal';
import PrepareOrdersModal from 'components/Orders/components/PrepareOrdersModal/PrepareOrdersModal';

import { ReactComponent as Check } from 'assets/bosta-icons/Check.svg';
import { ReactComponent as TooltipIcon } from 'assets/bosta-icons/Tooltip.svg';
import { ReactComponent as Close } from 'assets/bosta-icons/Close.svg';

import './SmartStickerGuideModal.less';

const MOBILE_APP_URL = 'https://bostaapp.page.link/oMdp';

const SmartStickerGuideModal = ({
  intl,
  close,
  prepareOrdersModalProps,
  ...props
}) => {
  const { openModal } = useModal();

  const handleOpenMobileApp = () => {
    sendSegment('Continue_in_App', {
      'Device Type': getDeviceType()
    });

    isMobile ? (window.location.href = MOBILE_APP_URL) : openModal(ScanModal);
    close();
  };

  const handleClose = () => {
    prepareOrdersModalProps &&
      openModal(PrepareOrdersModal, {
        ...prepareOrdersModalProps
      });
    close();
  };

  return (
    <Modal
      width={780}
      footer={null}
      {...props}
      className="br-smart-sticker-guide__modal"
    >
      <div className="br-smart-sticker-guide-modal__container">
        <div className="br-smart-sticker-guide-modal__header display-md ">
          <div className="br-smart-sticker-guide-modal__header-title">
            {intl.formatMessage(
              {
                id: getSmartFlyersLocalizationId('smart_stickers_guide.title')
              },
              {
                span: (children) => <span>{children}</span>
              }
            )}
          </div>
          <Close onClick={handleClose} />
        </div>
        <div className="br-smart-sticker-guide-modal__body">
          <div className="br-smart-sticker-guide__steps">
            <Timeline>
              {SMART_STICKERS_GUIDE_STEPS.map((step, index) => (
                <Timeline.Item
                  dot={
                    index + 1 === SMART_STICKERS_GUIDE_STEPS.length ? (
                      <Check />
                    ) : (
                      index + 1
                    )
                  }
                >
                  <div className="br-smart-sticker-guide__step-title heading">
                    {step.title}
                  </div>
                  <div className="br-smart-sticker-guide__step-subtitle">
                    {step.subtitle}
                  </div>
                  {step.bannerContent && (
                    <div className="br-smart-sticker-guide__step-banner">
                      <BannerContent
                        subText={step.bannerContent}
                        type="info"
                        icon={<TooltipIcon />}
                      />
                    </div>
                  )}
                </Timeline.Item>
              ))}
            </Timeline>
          </div>
          <div className="br-smart-sticker-guide__video-container">
            <iframe
              src={
                isSaudi()
                  ? SMART_MATERIALS_GUIDE_VIDEO_URL
                  : SMART_STICKERS_GUIDE_VIDEO_URL
              }
              title="YouTube video player"
              height="100%"
              frameborder="0"
              allow="accelerometer; fullscreen; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />
          </div>
        </div>
        <div className="br-smart-sticker-guide__footer">
          <BRButton
            type="primary"
            className="button-lg"
            label={intl.formatMessage({
              id: 'smart_stickers_guide.open_mobile_app'
            })}
            onClick={handleOpenMobileApp}
          />
        </div>
      </div>
    </Modal>
  );
};

export default injectIntl(SmartStickerGuideModal);
