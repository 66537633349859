import React from 'react';

import { isDashboardArabic } from 'utils/intl-wrapper';

import LiteKsaTerms from 'assets/imgRevamp/ksa-lite-terms.png';
import ProKsaTerms from 'assets/imgRevamp/ksa-pro-terms.png';

export const SaudiTerms = ({ isLite = false, isArabic = false }) => {
  const modalBodyClassName = 'br-signup__terms-modal';
  return (
    <div className={modalBodyClassName} name={modalBodyClassName}>
      {isDashboardArabic() ? (
        <p>
          <span>عقد إتفاقية تقدیم خدمات شحن</span>
          <br />
          <br />
          عميلنا العزيز،
          <br />
          يسرنا تقديم خطة الأسعار والشروط والأحكام فيما بيننا على النحو الآتي:
          <br />
          <br />
          <ul>
            <li>
              الشركة أو العميل المقدم لفتح حساب هنا على منصة بوسطة: ويشار إليه
              في هذا العقد ب"الطرف الأول " أو "الشركة"
            </li>
            <br />و<br />
            <li>
              بوسطة، وهي شركة تأسست وقائمة بموجب قوانين المملكة العربية السعودية
              (سجل التجاري 1010761104) وعنوان مكتبها الرئيسي الرياض المملكة
              العربية السعودية يمثلها السيد عماد فارس، بصفته المدير العام يسمى
              فيما بعد "بوسطة و/أو الطرف الثاني". البريد الإلكتروني:
              Imad.Fares@bosta.co
            </li>
            <br />
          </ul>
          <br />
          التمهيد: حيث أن الطرف الأول في حاجة إلى خدمات التوصيل للمستخدم الأخير
          و خدمة تجهيز الطلبات لتقديمها لزبائنهم داخل المملكة، وحيث أن الطرف
          الثاني شركة رائدة في تقديم الخدمات اللوجستية المتكاملة في الشرق الأوسط
          وقـد قبلت ووافقت على تقديم خدمات التوصيل المطلوبة. بناءً على تطابق
          العرض والقبول، اتفق الطرفان على البنود والشروط التالية:
          <br />
          <br />
          <span>المادة 1: التمهيد والشروط والملاحق:</span>
          <br />
          يعتبر التمهيد المذكور أعلاه والبنود والمرفقات (أ) و (ب) و (ج) جزءًا لا
          يتجزأ من هذه الاتفاقية، تُقرأ معها وتُفسّر وفق أحكامها.
          <br />
          <br />
          <span>المادة 2: مدة الاتفاقية:</span>
          <br />
          تكون مدة هذا العقد سنة واحدة. اعتبارا من تاريخ التوقيع عليه بواسطة
          الطرفين، ويؤخذ في الاعتبار التوقيع اللاحق عند اختلاف تواريخ الطرفين،
          ويجوز تجديده لمدة أو مدد مماثلة بناء على اتفاق كتابي متبادل يوقعه
          الطرفان.
          <br />
          <br />
          <span>المادة 3 - نطاق العمل</span>
          <br />
          يوافق الطرف الثاني على تنفيذ خدمات التوصيل للطرف الأول. يباشر الطرف
          الثاني أداء الخدمة وفق الملحق "أ" المرفق.
          <br />
          <br />
          <span>المادة 4- سعر وشروط الدفع:</span>
          <br />
          الأسعار: ستكون الأسعار حسب الملحق (ب) المرفق.
          <br />
          <ul>
            <li>
              بموجب اتفاق الدفع نقدًا عند التسليم يقوم الطرف الثاني بتحصيل النقد
              وفقًا لقيمة الفاتورة من عميل الطرف الأول عند التسليم.
            </li>
            <li>
              يقوم الطرف بتحويل القيمة المتحصلة والمجمعة من العملاء إلى الطرف
              الأول كل …….بعد خصم قيمة رسوم الخدمة المتراكمة او أسعار الخدمات
              وسيتم تقديم فاتورة بذلك.
            </li>
            <li>
              في حالة وجود أي رسوم خدمات متبقية في نهاية الشهر، فسيتم إصدار
              فاتورة و دفعها من قبل الطرف الأول في غضون 30 يومًا من تاريخ تقديم
              الطرف الثاني الفاتورة بحد ائتماني قدره ........ ريال سعودي /
              شهريًا وفقًا لسياسة ائتمان الطرف الثاني.
            </li>
            <li>
              في حالة عدم دفع الطرف الأول للطرف الثاني حسب شروط الدفع المتفق
              عليها أعلاه بعد استلام الفاتورة، يحق للطرف الثاني خصم مبلغ
              الفاتورة المستحقة من المبلغ الذي يتم تحصيله - نيابة عن الطرف الأول
              - بموجب الدفع عند التسليم (COD)
            </li>
            <li>
              لا تشمل أسعار الخدمة ضريبة القيمة المضافة (VAT)، والتي يتم تحصيلها
              من الطرف الأول (عند الاقتضاء) بناءً على اللوائح الحكومية.
            </li>
            <li>
              رسوم التحويل المصرفية: سوف تطبق على الطرف الأول حسب نظام سما.
            </li>
            <li>
              الدفع عن طريق نقاط البيع او عبر الانترنت: سيتم تطبيق رسوم بنكية
              لتكون الرسوم لبطاقات مدى …… % بطاقات فيزا وماستر كارد …… % ولكل
              عملية.
            </li>
            <li>
              في حالة فشل الطرف الأول في الالتزام بشروط الدفع المتفق عليها،
              يحتفظ الطرف الثاني بالحق في فرض الحجز على البضائع والعمليات أيضاً
              مقابل المبالغ المستحقة الدفع الخاصة بالشحن و/ أو التخزين السابق،
              حتى تتم تسوية مبالغ الفواتير المستحقة.
            </li>
            <li>
              إذا حدثت أية تغييرات أخرى في الضرائب و/ أو الأنظمة الحكومية قد
              تؤثر على تكلفة الوقود وأسفرت عن زيادة التكاليف الأخرى، ستعتمد من
              الطرفين وتدفع من قبل الطرف الأول.
            </li>
            <li>
              يحق للطرف الثاني التقديم لمراجعة الأسعار بعد إكمال ثلاثة (3) أشهر
              من تاريخ الاتفاقية أو في حالة عدم التزام العميل بعدد وحجم الطلبات
              شهريا.
            </li>
            <li>
              تستند جميع الأسعار إلى نطاق العمل المطلوب من الطرف الأول. أي تغيير
              في الطريقة و / أو تغيير النطاق الذي تم إدخاله خلال المدة، يمنح
              الطرف الثاني الحق في مراجعة أسعاره.
            </li>
          </ul>
          <br />
          <br />
          <span>المادة 5. القوة القاهرة</span>
          <br />
          لا تتحمل الأطراف المسؤولية في حالة القوة القاهرة التي تجعل من المستحيل
          على الأطراف أداء كل أو جزء من التزاماتها المنصوص عليها في هذا العقد
          على سبيل المثال لا الحصر الزلازل والأعاصير وحصار حركة المرور والحوادث
          والحروب وأعمال الشغب والاضطرابات المدنية والأعمال الحكومية والأمراض
          الوبائية والتدابير الوقائية المتخذة وفقاً لها والأحداث الخارجة عن
          سيطرة أي من الطرفين.
          <br />
          <br />
          <span>المادة 6 – الإنهاء:</span>
          <br />
          <ul>
            <li>
              يحق لكل طرف إنهاء هذا العقد من خلال إعطاء الطرف الآخر إشعارًا
              كتابيًا بالإنهاء قبل (1) شهر واحد من تاريخ الإنهاء. تتم جميع
              التسويات النقدية خلال فترة الإشعار.
            </li>
            <li>
              يجوز للطرف الثاني إنهاء هذا العقـد إذا فشل الطرف الأول في الامتثال
              والالتزام بشروط الدفع المتفق عليها
            </li>
            <li>
              يلغى هذا العقد إذا أصبح أي من الطرفين المتعاقدين معسرًا أو تقدم
              طالباً إعلان الإفلاس أو وضعه تحت سيطرة المصفي أو الحارس القضائي
              وكيل التفليسة.
            </li>
            <li>
              يمكن أن يتم الإنهاء بسبب حدوث حالة قاهرة وفقًا للمادة 5 من القوة
              القاهرة بعد إشعار خطي من الطرف المتضرر.
            </li>
            <li>
              عند إنهاء العقد على الطرف الأول أن يدفع للطرف الثاني نسبة سعر
              الخدمات وفق ما تم تنفيذه من العقد والمستحقة الدفع فيما يتعلق
              بالخدمات المنفذة وما يتعلق بأي استثمار (بالاتفاق المتبادل) يقوم به
              الطرف الثاني حتى تاريخ الإنهاء. ويجب أن يكون أي دفع من هذا القبيل
              يرضي تمامًا حقوق الطرف الثاني في الدفعات أو التعويض أو الأضرار
              فيما يتعلق بالإنهاء أو خرق الاتفاق الذي ينتج عنه حق الإنهاء.
            </li>
          </ul>
          <br />
          <br />
          <span>المادة 7. التأمين </span>
          <br />
          يلتزم الطرف الثاني بشراء والإبقاء على التأمين لتغطية مركباتهم / شركات
          الشحن وتأمين مسؤولية الموظفين وفقًا للقانون والمتطلبات التنظيمية طوال
          مدة هذا العقد وتجديداته وتمديداته.
          <br />
          <br />
          <span>المادة 8 - التنازل والتعاقد من الباطن</span>
          <br />
          يكون للطرف الثاني الحق في التعاقد من الباطن لتنفيذ أي جزء من هذا العقد
          بدون إشعار مسبق للطرف الأول فيما يتعلق بالتعاقد من الباطن وأحكام الشرط
          في تلك المسألة. يكون الطرف الثاني مسؤولاً أمام الطرف الأول عن الخدمات
          التي يقوم بها المقاول من الباطن وبالقدر الذي تكون فيه الخدمة التي يقوم
          بها كما لو تمّ تقديمها من الطرف الثاني و / أو موظفيه.
          <br />
          <br />
          <span>المادة 9. الأضرار والمطالبات</span>
          <br />
          لن يكون الطرف الثاني مسؤولاً عن أي تأخير في التوصيل إلا عند إثبات أن
          التأخير كان فقط بسبب الإهمال المتعمد للطرف الثاني أو مقاوليه من
          الباطن. على أي حال، ستكون مسؤولية الطرف الثاني محدودة بحد أقصى تكلفة
          توصيل المنتج المتأخر.
          <br />
          لن يكون الطرف الثاني مسؤولاً عن أي تلف أو أي تدهور او أي فقدان او أي
          نقصان البضائع المحملة ينشأ هناك إلا عند إثبات أن الضرر أو التدهور او
          النقص او الفقدان كان فقط بسبب الإهمال المتعمد للطرف الثاني أو مقاوليه
          من الباطن. على أي حال، ستكون مسؤولية الطرف الثاني محدودة وبحد أقصى
          بقيمة 375 ريال سعودي لا يتحمل الطرف الثاني بأي حال من الأحوال
          المسؤولية عن: -
          <br />
          <ul>
            <li>
              {' '}
              الأضرار غير المباشرة أو التبعية؛ أو أي خسائر مباشرة أو غير مباشرة،
              والتي قد تنشأ عن التأخير في تسليم أي وحدة / وحدات
            </li>
            <li>
              الخسارة أو الضرر الناشئ عن:
              <br />
              <ul>
                <li>التعبئة أو العنونة غير الكافية أو غير الصحيحة،</li>
                <li>أو الطبيعة القابلة للتلف أو الخطرة أو الهشة</li>
                <li>أو الترتيبات الميكانيكية للبضائع؛</li>
                <li>
                  أو الفشل من جانب المرسل إليه لتسلمها في خلال فترة زمنية
                  معقولة.
                </li>
                <li>معيار المنتج حسب وصف الطرف الأول لعملائه.</li>
              </ul>
            </li>
          </ul>
          <br />
          يجب على الطرف الأول أن يبلغ عن المطالبة في خلال 48 ساعة للسماح للطرف
          الثاني بالتحقيق على الفور ولن يقبل الطرف الثاني أي مطالبات بعد ذلك.
          ويجب على الطرف الأول إبلاغ الطرف الثاني من خلال تقرير الحادث الذي يجب
          الاتفاق عليه بشكل متبادل قبل استحقاق أي مطالبة.
          <br />
          أي أضرار تبعية بسبب البيئة نتيجة لإهمال يتم إثباته على الطرف الثاني
          سوف تكون على مسئولية الطرف الثاني. ومع ذلك، إذا كان الانسكاب أو التلف
          ناتج عن عيب في التصنيع أو التغليف، فلن يتحمل الطرف الثاني المسؤولية.
          <br />
          <br />
          <span>المادة 10. القانون الحاكم للعقـد</span>
          <br />
          سيتم تفسير هذا العقـد و يحكم بقوانين المملكة العربية السعودية.
          <br />
          <br />
          <span>المادة 11 - تسوية المنازعات</span>
          <br />
          تتم تسوية أي مطالبة أو خلاف أو نزاع ينشأ فيما يتعلق بهذا العقد بطريقة
          ودية وسريعة. في حالة فشل ذلك يتم إحالة النزاع إلى المحكمة السعودية
          المختصة بالرياض - المملكة العربية السعودية وفقاً للقوانين السعودية
          المعمول بها.
          <br />
          <br />
          <span>المادة 12. تفاصيل الحسابات المصرفية</span>
          <br />
          <span>
            <ul>
              <li>تفاصيل حساب مصرف الطرف الأول:</li>
            </ul>
          </span>
          حسب خطاب البنك المرفق في الملحق ج
          <br />
          <span>
            <ul>
              <li>تفاصيل حساب بنك الطرف الثاني:</li>
            </ul>
          </span>
          اسم البنك: البنك السعودي البريطاني (ساب)
          <br />
          سويفت كود:
          <br />
          عنوان البنك:
          <br />
          العملة: ريال سعودي
          <br />
          رقم الحساب:
          <br />
          رقم الحساب الدولي:
          <br />
          نوع الحساب: حالي
          <br />
          المستفيد:
          <br />
          <br />
          <span>المادة 13- الإشعارات</span>
          <br />
          يجب أن تتم كتابـة جميع الإشعارات المطلوبة بموجب أحكام هذا العقد وترسل
          إلى الممثل المعين للطرف الآخر على العنوان (العناوين) المنصوص عليها في
          العقد، ويمكن تسليمها و / أو يمكن أن ترسل عن طريق البريد المسجل /
          البريد الدولي. يجب أن تكون جميع الإشعارات أو الاتصالات التي يقدمها
          الطرفان بموجب هذا العقد أو المتعلقة بها مكتوبة باللغة الإنجليزية
          وموجهة إلى:
          <br />
          <span>
            <ul>
              <li>إذا كان للطرف الأول</li>
            </ul>
          </span>
          <br />
          العنوان : ………………..
          <br />
          الهاتف : ……………………………
          <br />
          عناية : ……………………..
          <br />
          البريد الإلكتروني :  
          <br />
          <br />
          <span>
            <ul>
              <li>إذا كان الطرف الثاني:</li>
            </ul>
          </span>
          <br />
          شركة بوسطة للوجستيات المحدودة
          <br />
          العنوان:
          <br />
          هاتف:
          <br />
          عناية: .عماد فارس
          <br />
          البريد الالكتروني: Imad.Fares@bosta.co
          <br />
          <br />
          <span>المادة 14: التنازل:</span>
          لا يحق لأي من الطرفين التنازل عن هذا العقد ما لم يتم الحصول على
          الموافقة الكتابية المسبقة من الطرف الآخر
          <br />
          <br />
          <span>المادة 15: السرية:</span>
          يقر الطرفان بأن جميع المعلومات المتعلقة بكل طرف و / أو عملياته تعتبر
          سرية وتنتمي إلى الطرف المعني. يحتفظ الطرفان بسرية جميع هذه المعلومات،
          يجوز لهما نسخ أو استخدام أو الكشف عن أي معلومات من هذا القبيل دون
          موافقة خطية مسبقة من الطرف الآخر.
          <br />
          <br />
          <span>المادة 16: لغـة العقـد :</span>
          تم تحرير هذا العقد باللغتين العربية والإنجليزية، وفي حال التعارض مع
          عقد آخر، أو التعارض بين لغتيه تسود نصوص هذا العقد باللغة العربية وتكون
          ملزمة للطرفين.
          <br />
          وإثباتا لذلك، فقد تم توقيع هذا العقد بواسطة الطرفين من نسختين من قبل
          ممثليهم المفوضين حسب الأصول اعتبارًا من التاريخ المذكور أعلاه، كل طرف
          منهما يحتفظ بنسخة واحدة
          <br />
          نيابة عن
          <br />
          الطرف الثاني
          <br />
          شركة بوسطة للوجستيات المحدودة
          <br />
          عماد فارس
          <br />
          المدير العام
          <br />
          <br />
          <span>
            الملحق أ
            <br />
            مجال العمل
          </span>
          <br />
          <br />
          <span>نطاق الطرف الثاني:</span>
          التوصيل للمستخدم الأخير داخل المملكة العربية السعودية
          <br />
          <ul>
            <li>
              الشحنات المرتجعة ستعود إلى نقطة التحميل بعد 2 محاولات للتسليم.
            </li>
            <li>الطلبات الأدنى المتوقعة ……/ شهر.</li>
            <li>
              يقوم الطرف الأول بإخطار الطرف الثاني قبل 15 يوما في حال رغب في
              زيادة حجم الطلبات لأكثر من …….. طلب / يوم لكل مدينة.
            </li>
          </ul>
          <br />
          <br />
          <span>الشروط العامة لخدمات التوصيل</span>
          <ul>
            <li>
              يقع التحميل والتفريغ الآمنين هي مسؤولية الطرف الأول في حال كانت في
              مرافق الطرف الأول أو عميل الطرف الأول.
            </li>
            <li>
              وفقًا للوائح وزارة النقل والشروط والأحكام العامة للطرف الثاني،
              يُحظر نقل البضائع / العناصر التالية. (المواد المخالفة للقانون
              السعودي، الأدوات المالية (مثل النقد / العملة، .....)، البضائع
              الخطرة / الخطرة والمواد القابلة للاشتعال / الكيماويات.
            </li>
            <li>
              يحضر مندوب الطرف الثاني تجميع الشحنات من مقر الطرف الأول مرة واحدة
              يوميا في وقت محدد متفق عليه من قبل الطرفين بين ….صباحا حتى 4 مساءا
            </li>
            <li>
              إذا تم تجميع أي طلب بعد ال…. مساءا سيتم إضافة 24 ساعة الى مدة
              التسليم
            </li>
            <li>
              يتم النظر في الوزن الفعلي والحجمي لكل طلب، وأيهما أعلى سيكون الوزن
              الخاضع للرسوم على العميل حسب المعادلة (طول X عرض X ارتفاع) 5000X
            </li>
            <li>
              يتم استلام الشحنات في مستودعات الطرف الثاني وتوزيعها على أساس
              الواجهة النهائية لكل شحنة
            </li>
            <li>
              يتم استلام الشحنات في مراكز التوصيل وتوزيعها حسب مناطق التسليم
              الخاصة بالطرف الثاني إذا كانت تفاصيل المستلم وعنوانه كاملة
              ومستوفية جميع البيانات
            </li>
            <li>
              يقوم المندوب بأخذ توقيع العميل عند تسليم الطلب مع تحصيل مبلغ الدفع
              عند الاستلام ان وجد
            </li>
            <li>
              ….. % نسبة التسليم الناجحة للشحنات مسبقة الدفع ….%
              <br /> للشحنات الدفع عند الاستلام
            </li>
            <li>
              نسبة نجاح التسليم لن تشمل أسباب فشل التسليم التالي ذكرها:
              <ul>
                <li>إذا كان عنوان التسليم خطأ</li>
                <li>إذا كان رقم جوال المستلم خطأ</li>
                <li>إذا كان المستلم غير متواجد</li>
                <li>إذا كان المستلم غير متجاوب</li>
                <li>إذا كان مبلغ الدفع عند الاستلام غير جاهز/كامل</li>
                <li>إذا طلب العميل إلغاء الطلب</li>
              </ul>
            </li>
            <li>
              سيتم احتساب 50% من مبلغ التسليم الأساسي المتفق عليه على كل طلب
              محاولة تسليم إضافية
            </li>
            <li>
              يكون التسليم العادي في المدن الرئيسية في غضون 1 إلى 3 أيام عمل من
              مدينة الاستلام الى مدينة التسليم
            </li>
            <li>
              إذا كان هناك طلب للتسليم في نفس اليوم (فقط داخل المدن الرئيسية)
              يجب تسليم الشحنات للطرف الثاني في موعد أقصاه 4 مساء
            </li>
            <li>
              الحد الأقصى للفترة التي يمكن الاحتفاظ بشحنة فشل تسليمها أو المستلم
              طلب إلغائها هي …أيام فقط
            </li>
            <li>يتم إرجاع الشحنات إلى نقطة الاستلام بعد مرور ….. أيام</li>
            <li>
              لا يتحمل الطرف الثاني أي ضرر على الشحنة إذا كان الضرر بسبب سوء
              التغليف من قبل العميل
            </li>
          </ul>
          <br />
          <br />
          <span>الشروط العامة لخدمات تجهيز وتحضير الطلبات</span>
          <ul>
            <li>
              العميل مسؤول عن إبلاغ الطرف الثاني كل تفاصيل البضاعة وكميتها مسبقا
              بوقت كاف قبل إرسال البضاعة إلى مستودعات الطرف الثاني
            </li>
            <li>توفير مساحة مناسبة المخزون في مستودعات الطرف الثاني</li>
            <li>
              نقوم بتوفير 3 مقاسات مختلفة من الأكياس و الغلاف البلاستيكي لعملية
              التغليف ويكون العميل مسؤول عن توفير أي مواد تغليف أخرى إذا تطلب
              الأمر ذلك
            </li>
            <li>
              يقوم الطرف الثاني بأداء أي خدمات إضافية، بما في ذلك المشاريع
              الخاصة، التي يرغب البائع أن يؤديها على النحو المتفق عليه بين
              الطرفين من حيث الخدمات والأسعار
            </li>
            <li>
              يلتزم البائع بإبلاغ الطرف الثاني بأي تحذيرات خاصة يجب أن يتم عرضها
              على العبوة على سبيل المثال العلامات الخطرة والعلامات الهشة
              واتجاهات المواضع وما إلى ذلك. ويمكن التفاوض على أسعار هذه
              الترتيبات بين الطرفين أو يجب توفيرها بواسطة العميل في وقت مبكر.
            </li>
            <li>أخر موعد لاستلام الطلبات هو 1:00 مساء</li>
            <li>يتم إضافة 24 ساعة لأي طلب بعد 1 مساء</li>
            <li>
              يجب على العميل إبلاغ الطرف الثاني في حالة الرغبة في زيادة الطلبات
              لأكثر من …… طلب يوميا
            </li>
            <li>
              يلتزم العميل بإبلاغ الطرف الثاني عن أي مرتجعات من المقرر استلامها،
              ويلتزم الطرف الثاني بإجراء ضمان الجودة للمرتجعات
            </li>
            <li>
              يمكن للعميل طلب تقرير عن المخزون بصفة دورية، ويلتزم الطرف الثاني
              بإرسال التقرير في خلال 48 ساعة من الطلب
            </li>
            <li>
              يلتزم العميل بإبلاغ الطرف الثاني إذا كانت البضاعة تحتاج إلى
              إجراءات تخزين أو معالجة خاصة تختلف عن الطرق المعتادة
            </li>
          </ul>
          <br />
          <br />
          <span>الملحق – ب: الأسعار</span>
          <br />
          <img src={isLite ? LiteKsaTerms : ProKsaTerms} alt="terms" />
          <br />
          <span>
            <ul>
              <li>ملاحظة: الأسعار أعلاه لا تشمل قيمة الضريبة المضافة</li>
            </ul>
          </span>
          <br />
          <br />
          <span>الملحق – ج: خطاب حساب بنكي للطرف الأول</span>
        </p>
      ) : (
        <p>
          <span>
            Contracting an agreement for the provision of freight services
          </span>
          <br />
          Our dear customer, <br />
          We are pleased to offer the price plan and terms and conditions
          between us as follows: <br />
          <br />
          <ul>
            <li>
              The company or client submitted to open an account here on the
              Bosta platform: This is referred to in this contract as the “first
              party” or “the company.”
            </li>
            <br />
            AND
            <br />
            <li>
              BOSTA , a Company incorporated and existing under the laws Saudi
              Arabia (C.R. 1010761104) having its Head Office at Riyadh Saudi
              Arabia represented by Mr. Imad Fares , in his capacity as General
              Manager, hereinafter called the " Bosta - Second Party).
              <br /> Email: Imad.Fares@bosta.co
            </li>
            <br />
          </ul>
          <br />
          Preamble: Whereas, First Party ……………………………… in need for Last mile
          Delivery & fulfillment service to their customers within the Kingdom,
          and whereas the Second Party is a leading integrated logistics service
          provider in Middle East accepted & agreed to provide the required
          Delivery services. Based on coincidence of offer with acceptance both
          parties mutually agreed on the following terms and conditions
          <br />
          <br />
          <span>Article 1: Preamble, Terms & Annexures:</span>
          <br />
          The above preamble, Terms and attached Annexures A, B & C are
          considered as integral part of this Agreement It is read with it and
          interpreted according to its provisions
          <br />
          <br />
          <span>Article 2: Duration of the agreement:</span>
          <br />
          The period of this agreement shall be for one year effective from the
          date of signature, and will be considered the latest Signature when
          the parties’ signing dates are different and may be extended for
          similar period or more upon mutual written agreement to be signed by
          the parties.
          <br />
          <br />
          <span>Article: 3 Scope of work</span>
          <br />
          The Second Party agreed to execute the Delivery Services for the First
          Party. The Second Party shall proceed to perform the service as per
          attached Annexure A.
          <br />
          <br />
          <span>Article 4. Rate & Payment Terms:</span>
          <br />
          Rates: The rates are as per the attached Annexure B: <br />
          <ul>
            <li>
              For Cash on delivery (COD) payments, the Second Party will collect
              the Cash as per the Airway bill value from First Party's customer
              upon delivery.
            </li>
            <li>
              The Second Party will collect the amount and transfer it to the
              First Party every week after deducting the service charges
              accumulated amount or services rates and will submit the invoice.
            </li>
            <li>
              If still any service charges remaining at the end of the month,
              will be invoiced and paid by the First Party within 30 days from
              the date of Second Party’s invoice submission with credit limit as
              per the credit policy of the Second Party.
            </li>
            <li>
              In case of nonpayment of First Party to the Second Party as per
              the agreed payment term stated above ,after receiving the invoice,
              the Second Party will have the right to deduct the due invoice
              amount from the collected Cash on Delivery (COD)on behalf First
              Party
            </li>
            <li>
              The Service Prices are excluded from VAT (Value Added Tax), to be
              charged to the First party (where applicable) based on the
              government regulations.
            </li>
            <li>
              Bank Charges: Will be applicable to First Party as per SAMA.
            </li>
            <li>
              POS & online payments: charges will be applied as per Saudi CITC
              instructions, for Mada cards 0.60 % and for Visa & Mastercard 2.40
              % per transaction.
            </li>
            <li>
              Should the First Party fail to comply with the agreed payment
              terms, the Second Party will reserve the right to impose a lien on
              cargo/operations also for outstanding receivables for previous
              shipment/storage handled, until the outstanding invoice amounts
              are settled.
            </li>
            <li>
              If any further changes in government taxes and / or regulations
              affecting the fuel cost & resulted in increase of other costs, the
              Parties will consider & be paid by the First party.
            </li>
            <li>
              The Second Party will have the right to submit price review after
              completion of Three (3) months from the date of agreement or upon
              noncompliance to from customer from committed agreed volume/
              number of orders per month.
            </li>
            <li>
              All Prices are based on the First Party’s requested Scope of work.
              Any change in method and/or change in scope inserted during the
              term, will give a right to the Second party to submit for price
              review.
            </li>
          </ul>
          <br />
          <br />
          <span>Article 5. Force Majeure </span>
          <br />
          Parties shall not be held liable in case of Force Majeure which
          renders it impossible for the parties to perform the whole or part of
          their obligations stipulated in this agreement such as but not limited
          to earthquake, typhoon, and blockade of the traffic, accident, war,
          riots, civil commotions, government acts, pandemic diseases & measures
          taken accordingly and events beyond the control of either party.
          <br />
          <br />
          <span>Article 6. Termination:</span>
          <br />
          <ul>
            <li>
              Each party has the right to terminate this contract by giving the
              other party a One (1) months advance termination written notice
              prior to the date of termination. All monetary settlements shall
              be done during the notice period.
            </li>
            <li>
              The second party may terminate this contract if the First Party
              fails to comply with the agreed payment terms
            </li>
            <li>
              This contract shall be terminated if any of the contracting
              Parties becomes insolvent, subject to a petition under bankruptcy,
              or is placed under the control of a liquidator or receiver.
            </li>
            <li>
              Termination can be made due to the occurrence of an event of Force
              Majeure in accordance with the Article 5 Force Majeure after due
              written notice from the affected party.
            </li>
            <li>
              On termination the First Party shall pay to the Second party the
              proportion of the contract Price payable in respect of the
              services performed and/or any investment (on mutual agreement)
              done by the Second party up to the date of termination. Any such
              payment shall be in full satisfaction of the Second party’s rights
              to payment, compensation, or damages in respect of the termination
              or the breach of agreement giving rise to the right of
              termination.
            </li>
          </ul>
          <br />
          <br />
          <span>Article 7. Insurance</span>
          <br />
          The Second Party shall purchase and maintain insurance to cover their
          vehicles/carriers and employee liability as per the law and regulatory
          requirements throughout the term of this CONTRACT and its renewals and
          extensions.
          <br />
          <br />
          <span>Article 8. Assignment & Sub-contracting</span>
          <br />
          The Second Party shall have the right to subcontract any part of this
          agreement without the prior notice and approval of the First Party as
          to subcontracting and the terms of condition therein. The Second Party
          shall be responsible to the First Party for Services performed by its
          subcontractors and to the same extent as it is a Service performed by
          the Second Party and /or its employees.
          <br />
          <br />
          <span>Article 9. Damages and Claims </span>
          <br />
          The Second Party shall not be liable for any delays for deliveries
          except upon proof that the delay was solely due to the willful
          negligence of Second Party or their subcontractors. In any case, the
          liability of Second Party will be limited maximum up to the delivery
          charge of the delayed case.
          <br />
          The Second Party shall not be liable for any loaded goods damage,
          loss, shortage or deterioration arising therefrom except upon proof
          that the damage, loss, shortage or deterioration was solely due to the
          willful negligence of Second Party or their subcontractors. In any
          case, the liability of the Second Party will be limited up to 375 SAR.
          The second party will not be responsible for compensation as per the
          below:
          <br />
          <ul>
            <li>
              Indirect or consequential damages; or Any direct or In-Direct
              losses, which may arise from delay in delivery of any unit/s
            </li>
            <li>
              (b) Loss or damage arising from:
              <br />
              <ul>
                <li>Insufficient or improper packing or addressing,</li>
                <li>or the perishable, hazardous, fragile or brittle nature</li>
                <li>Or the mechanical arrangements of the goods;</li>
                <li>
                  {' '}
                  Or Failure by the consignee to take delivery within reasonable
                  time.
                </li>
                <li>
                  Standard of the Product as per description of First Party to
                  their customers.
                </li>
              </ul>
            </li>
          </ul>
          <br />
          The First Party has to inform the Second Party about the claim within
          48 hours to allow the Second Party to investigate immediately, any
          claims after that will not be accepted by the Second Party. First
          Party has to inform the Second Party through an incident report, and
          it has to be mutually agreed upon before to mature any claim.
          <br />
          Any consequential damage to the environment resulting due to proved
          Second Party’s Sole negligence will be taken care by Second Party.
          However, if the spillage damage is due to manufacturing or packaging
          defects, Second Party will not bear responsibility.
          <br />
          <br />
          <span>Article 10. Governing Law of the Contract:</span>
          <br />
          This Contract shall be construed under and governed by the Laws of the
          Kingdom of Saudi Arabia.
          <br />
          <br />
          <span>Article 11. SETTLEMENT OF DISPUTES </span>
          <br />
          Any claim, disagreement, or dispute arising in connection with this
          Contract shall be settled amicably & expeditiously. In the event of
          failure of such, the dispute shall be transferred to the competent
          Saudi law court in Riyadh – Saudi Arabia in accordance with the Saudi
          applicable laws.
          <br />
          <br />
          <span>Article 12. Bank Accounts Details </span>
          <br />
          <span>
            <ul>
              <li>First Party Bank Account Details:</li>
            </ul>
          </span>
          As per attached bank letter in appendix C
          <br />
          <span>
            <ul>
              <li>Second Party Bank Account Details:</li>
            </ul>
          </span>
          Bank Name:
          <br />
          SWIFT CODE:
          <br />
          Bank Address: <br />
          Currency: SAR
          <br />
          Account No:
          <br />
          IBAN <br />
          Account Type: Current
          <br />
          Beneficiary: <br />
          <br />
          <span>Article 13. Notices</span>
          <br />
          All notices required to be given under the provisions of the Contract
          shall be made in writing, shall be sent to the designated
          representative of the other PARTY at the address (es) set forth in the
          contract, and can be hand delivered and / or can be sent by registered
          mail / international courier. All notices or communication to be given
          by the Parties under or pertaining to this Contract shall be in
          writing in English and addressed to:
          <br />
          <span>
            <ul>
              <li>If to the FIRST PARTY: </li>
            </ul>
          </span>
          <br />
          Address : ……………….
          <br />
          Tel : ……………….
          <br />
          Attn : ……………………..
          <br />
          E-mail :
          <br />
          <br />
          <span>
            <ul>
              <li>If to the SECOND PARTY:</li>
            </ul>
          </span>
          <br />
          Bosta Logistics Company
          <br />
          Address:
          <br />
          Tel :
          <br />
          Attn : Imad Fares
          <br />
          E-mail : Imad.Fares@bosta.co
          <br />
          <br />
          <span>Article 14. Assignment :</span>
          No Party has the right to assign this Contract without the getting the
          prior written approval of the other party
          <br />
          <br />
          <span>Article 15: Confidentiality</span>The parties acknowledge that
          all information relating to each party and / or its operations is
          considered confidential and belongs to the party concerned. The
          parties maintain the confidentiality of all this information, and they
          are not permitted to copy, use or disclose any such information
          without the prior written consent of the other party.
          <br />
          <br />
          <span>Article 16: Contract Language:</span>
          This contract is issued bilingual (Arabic & English), and in case of
          contravention with another contract, or between its two languages, the
          provisions of this contract prevails as binding on both parties
          <br />
          IN WITNESS THEREOF, the Parties hereto have caused this Contract to be
          signed in duplicate by their duly authorized representatives as of the
          date above first written, each party retaining one (1) copy each.
          <br />
          On behalf of
          <br />
          Second Party
          <br />
          Bosta Logistics Company
          <br />
          Imad Faris
          <br />
          General Manager
          <br />
          <br />
          <span>
            ANNEXURE A
            <br />
            SCOPE OF WORK
          </span>
          <br />
          <br />
          <span>Second Party’s Scope:</span>Last mile Delivery within Kingdom of
          Saudi Arabia
          <br />
          <ul>
            <li>
              Return Shipments will go back to Pickup point after 4 attempts of
              delivery.
            </li>
            <li>Minimum Expected orders will be ../ Month.</li>
            <li>
              First Party to notify Second party 15 days in case it wants to
              increase the volume of orders for more than …. orders/day per
              city.
            </li>
          </ul>
          <br />
          <br />
          <span>GENERAL CONDITIONS FOR Delivery</span>
          <ul>
            <li>
              Safe Loading and Unloading is the responsibility of First Party at
              First party or first Party’s client facilities.
            </li>
            <li>
              In accordance with the Ministry of Transport regulations and
              Second Party general terms and conditions, the following
              goods/items are restricted from carriage. (materials against Saudi
              law, Financial instruments (such as Cash/Currency, precious
              metals), Dangerous/ Hazardous Goods and Flammable materials/
              Chemicals.
            </li>
            <li>
              The representative of the second party will attend to collect the
              shipments from the headquarters of the first party once a day at a
              specific time agreed upon by the two parties between … to 4 pm
            </li>
            <li>
              If any order collected after 4 pm, 24 hours will be added to the
              delivery period
            </li>
            <li>
              The actual weight and volumetric of each order is considered, and
              whichever is higher will be chargeable to the customer As per the
              below equation: ( Length X Width X Height ) X 5000
            </li>
            <li>
              The shipments will be received at the warehouse of the second
              party and distributed on the basis of the final destination for
              each shipment
            </li>
            <li>
              Shipments are received at the delivery centers and distributed
              according to the delivery areas of the second party if the
              recipient's details and address are complete and with a full data
              set.
            </li>
            <li>
              The representative collect the client's signature upon delivery of
              the order and collects the amount of payment upon receipt, if any
            </li>
            <li>
              95% successful delivery rate for prepaid shipments and 80% for
              cash on delivery shipments
            </li>
            <li>
              The success rate of delivery will not include the following
              reasons for failure of delivery:
              <ul>
                <li>If the delivery address is wrong</li>
                <li>If the recipient's mobile number is wrong</li>
                <li>If the recipient is not available</li>
                <li>If the recipient is not responding</li>
                <li>If the cash on delivery amount is not ready / complete</li>
                <li>If the customer requests to cancel the order</li>
              </ul>
            </li>
            <li>
              There will be 50 % for each additional delivery attempt request
            </li>
            <li>
              Regular delivery in major cities takes place within 1 to 3 working
              days from the city of receipt to the city of delivery
            </li>
            <li>
              If there is a request for same day delivery (only within the main
              cities), shipments must be received to the Second Party no later
              than 4 PM.
            </li>
            <li>
              The maximum period for which a shipment that has failed to be
              delivered or which the recipient has requested to cancel can be
              kept is only …
            </li>
            <li>Shipments are returned to the Pickup point after ….days</li>
            <li>
              The second party is not responsible for any damage to the shipment
              if the damage is due to poor packaging by the customer
            </li>
          </ul>
          <br />
          <br />
          <span>General conditions for order Fulfillment Services</span>
          <ul>
            <li>
              The customer is responsible for informing the second party of all
              details of the goods and their quantities in sufficient time
              before sending the goods to the warehouses of the second party.
            </li>
            <li>Provide adequate space for stock in second-party warehouses</li>
            <li>
              We provide 3 different sizes of bags and plastic wrap for the
              packaging process, and the customer is responsible for providing
              any other packaging materials if required.
            </li>
            <li>
              The second party performs any additional services, including
              special projects, that the seller wishes to perform as agreed upon
              between the two parties in terms of services and prices.
            </li>
            <li>
              The seller is obligated to inform the second party of any special
              warnings that must be displayed on the package, for example,
              dangerous marks, fragile marks, positioning directions, etc. The
              rates for these arrangements can be negotiated between the parties
              or must be provided by the customer in advance.
            </li>
            <li>The cut-off time is 1 PM to receive the orders.</li>
            <li>24 hours are added to any order after 1 PM</li>
            <li>
              The customer must inform the second party in the event of
              increasing the number of orders to more than …. order per day
            </li>
            <li>
              The customer is obligated to inform the second party of any
              returns to be received, and the second party is obligated to
              perform a quality assurance of the returns.
            </li>
            <li>
              The customer can request a stock report periodically, and the
              second party is obligated to send the report within 48 hours of
              the request
            </li>
            <li>
              The customer is obligated to inform the second party if the goods
              need special storage or handling procedures that different from
              the usual methods
            </li>
          </ul>
          <br />
          <br />
          <span>ANNEXURE-B: Service Prices</span>
          <br />
          <img src={isLite ? LiteKsaTerms : ProKsaTerms} alt="terms" />
          <br />
          <span>
            <ul>
              <li>Note: Above Prices are exclusive of VAT</li>
            </ul>
          </span>
          <br />
          <br />
          <span>ANNEXURE-C: First Party Bank account letter</span>
        </p>
      )}
    </div>
  );
};

export default SaudiTerms;
