import React, { useState, useRef } from 'react';
import { Tooltip } from 'antd';
import { mediaHook } from 'customHooks';
import { injectIntl } from 'react-intl';

import {
  getCurrency,
  getUserCurrency
} from 'constants/countries/countries-mapping';
import { COUNTRIES } from 'constants/country-data';
import { formatNumbersBasedOnLanguage } from 'utils/intl-wrapper';

import PricingFlagsMobile from '../PricingFlagsMobile/PricingFlagsMobile';

import { ReactComponent as TooltipIcon } from 'assets/bosta-icons/Info-Circle.svg';

import './PricingFlagsSection.less';
import { PERCENTAGE } from 'constants/wallet';

const PricingFlagsSection = ({
  mobileScreenSizes,
  intl,
  pricingData,
  cod,
  ref
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const headerRef = useRef();

  const { isLargeMobileScreen } = mobileScreenSizes;

  const currency = getCurrency().localized;

  const FLAGS = () => [
    {
      label: intl.formatMessage({
        id: 'bosta_fees_popover.shipping_fees'
      }),
      tooltip: intl.formatMessage({
        id: 'bosta_fees_popover.shipping_fees_tooltip'
      }),
      value: pricingData?.shippingFee,
      isVisible: () => true
    },
    {
      label: intl.formatMessage({
        id: 'bosta_fees_popover.insurance_fees'
      }),
      tooltip: intl.formatMessage(
        {
          id: 'bosta_fees_popover.insurance_fees_tooltip'
        },
        {
          fee: formatNumbersBasedOnLanguage(
            pricingData?.tier?.insuranceFee?.percentage * 100
          )
        }
      ),
      value:
        pricingData?.tier?.insuranceFee?.percentage *
          pricingData?.priceBeforeVat >
        pricingData?.tier?.insuranceFee?.minimumFeeAmount
          ? pricingData?.tier?.insuranceFee?.percentage *
            pricingData?.priceBeforeVat
          : pricingData?.tier?.insuranceFee?.minimumFeeAmount,
      isVisible: () => pricingData?.tier?.configurations?.insuranceFee
    },
    {
      label: intl.formatMessage({
        id: 'bosta_fees_popover.collection_fees'
      }),
      tooltip: intl.formatMessage(
        {
          id: 'bosta_fees_popover.collection_fees_tooltip'
        },
        {
          amount: formatNumbersBasedOnLanguage(
            pricingData?.tier?.extraCodFee?.codAmount
          ),
          fee: formatNumbersBasedOnLanguage(
            pricingData?.tier?.extraCodFee?.percentage * 100
          )
        }
      ),
      value:
        cod <= pricingData?.tier?.extraCodFee?.codAmount
          ? null
          : (cod - pricingData?.tier?.extraCodFee) *
              pricingData?.tier?.extraCodFee?.percentage >
            pricingData?.tier?.extraCodFee?.minimumFeeAmount
          ? (cod - pricingData?.tier?.extraCodFee) *
            pricingData?.tier?.extraCodFee?.percentage
          : pricingData?.tier?.extraCodFee?.minimumFeeAmount,
      isVisible: () => pricingData?.tier?.configurations?.extraCodFee
    },
    {
      label: intl.formatMessage({
        id: 'bosta_fees_popover.cod_fees'
      }),
      tooltip: intl.formatMessage(
        {
          id: `bosta_fees_popover.cod_fees_tooltip`
        },
        {
          amount: formatNumbersBasedOnLanguage(
            pricingData?.tier?.codFee?.amount
          ),
          currency
        }
      ),
      value: cod ? pricingData?.tier?.codFee?.amount : null,
      isVisible: () => pricingData?.tier?.configurations?.codFee
    },
    {
      label: intl.formatMessage({
        id: 'bosta_fees_popover.next_day_transfer_fees'
      }),
      tooltip: intl.formatMessage(
        {
          id: 'bosta_fees_popover.extra_fees_tooltip'
        },
        {
          fee: formatNumbersBasedOnLanguage(
            pricingData?.tier?.expediteFee?.percentage * 100
          )
        }
      ),
      value:
        pricingData?.tier?.expediteFee?.percentage *
          pricingData?.priceBeforeVat >
        pricingData?.tier?.expediteFee?.minimumFeeAmount
          ? pricingData?.tier?.expediteFee?.percentage *
            pricingData?.priceBeforeVat
          : pricingData?.tier?.expediteFee?.minimumFeeAmount,
      isVisible: () => pricingData?.tier?.configurations?.expediteFee
    },
    // {
    //   label: intl.formatMessage({
    //     id: 'bosta_fees_popover.pos_fees'
    //   }),
    //   tooltip: intl.formatMessage(
    //     {
    //       id: 'settings.pricing_plan.vat.pos_fees_subtitle'
    //     },
    //     {
    //       fee: pricingData?.tier?.posFee?.percentage * 100
    //     }
    //   ),
    //   value:
    //     pricingData?.tier?.posFee?.percentage * pricingData?.priceBeforeVat,

    //   isVisible: () => pricingData?.tier?.configurations?.posFee
    // },
    {
      label: intl.formatMessage({
        id: 'bosta_fees_popover.discount'
      }),
      tooltip: intl.formatMessage(
        {
          id: 'bosta_fees_popover.discount_tooltip'
        },
        {
          amount: formatNumbersBasedOnLanguage(
            pricingData?.tier?.zeroCodDiscount?.amount
          )
        }
      ),
      value: !cod ? pricingData?.tier?.zeroCodDiscount?.amount * -1 : null,
      isVisible: () => pricingData?.tier?.configurations?.zeroCodDiscount
    },
    {
      label: intl.formatMessage({
        id: `bosta_fees_popover.promotion`
      }),
      tooltip: intl.formatMessage(
        {
          id: `bosta_fees_popover.promotion_tooltip_${
            pricingData?.promotion?.valueType === PERCENTAGE
              ? 'percentage'
              : 'value'
          }`
        },
        {
          currency,
          value: pricingData?.promotion
            ? pricingData?.promotion?.valueType === PERCENTAGE
              ? parseInt(pricingData?.promotion?.value * 100)
              : pricingData?.promotion?.value
            : pricingData?.promotion?.amount
        }
      ),
      value: pricingData?.promotion?.amount * -1,
      isVisible: () => pricingData?.promotion
    },
    {
      label: intl.formatMessage(
        {
          id: 'bosta_fees_popover.vat'
        },
        {
          vatValue: formatNumbersBasedOnLanguage(
            parseInt(pricingData?.tier?.country?.vat * 100) || 14
          )
        }
      ),
      tooltip: intl.formatMessage(
        {
          id: 'bosta_fees_popover.vat_tooltip'
        },
        {
          vatValue: formatNumbersBasedOnLanguage(
            parseInt(pricingData?.tier?.country?.vat * 100) || 14
          )
        }
      ),
      value:
        (pricingData?.tier?.country?.vat || 0.14) * pricingData?.priceBeforeVat,
      isVisible: () => true
    }
  ];

  const handleOnViewPriceBreakDownClick = () => {
    setIsDrawerOpen(true);
  };

  isLargeMobileScreen &&
    headerRef?.current?.scrollIntoView({
      behavior: 'auto',
      block: 'center',
      inline: 'center'
    });

  return (
    <div className="br-pricing-flags-section__container" ref={headerRef}>
      <div className="br-pricing-flags-section__header-container">
        <span className="subheading">
          {intl.formatMessage({
            id: 'pricing_plans_calculator.modal.pricing_flags.header'
          })}
        </span>
        <span className="display-md">
          {
            getUserCurrency(
              formatNumbersBasedOnLanguage(
                FLAGS().reduce((current, next) => {
                  if (next.isVisible() && next.value) {
                    return current + next.value;
                  }
                  return current;
                }, 0) || 0
              )
            ).localized
          }
        </span>
        <span className="caption">
          {intl.formatMessage({
            id: 'pricing_plans_calculator.modal.pricing_flags.sub_title'
          })}
        </span>
        {isLargeMobileScreen && (
          <span className="button" onClick={handleOnViewPriceBreakDownClick}>
            {intl.formatMessage({
              id: 'pricing_plans_calculator.modal.pricing_flags.sub_title_mobile'
            })}
          </span>
        )}
      </div>
      <div className="br-pricing-flags-flags-container">
        <div className="br-pricing-flags-flags-list">
          {FLAGS().map(
            (flag, id) =>
              flag.isVisible() && (
                <div key={id}>
                  <div>
                    <span className="body">{flag.label}</span>
                    <Tooltip title={flag.tooltip}>
                      <TooltipIcon />
                    </Tooltip>
                  </div>
                  <span className="body">
                    {flag.value
                      ? getUserCurrency(
                          formatNumbersBasedOnLanguage(flag.value)
                        ).localized
                      : '-'}
                  </span>
                </div>
              )
          )}
        </div>
      </div>
      <div className="br-pricing-flags-section__info-text-container">
        <span className="caption">
          {intl.formatMessage({
            id: 'pricing_plans_calculator.modal.pricing_flags.footer'
          })}
        </span>
      </div>
      <PricingFlagsMobile
        setIsDrawerOpen={setIsDrawerOpen}
        isDrawerOpen={isDrawerOpen}
        FLAGS={FLAGS}
      />
    </div>
  );
};

export default injectIntl(mediaHook(PricingFlagsSection));
