import { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Steps } from 'antd';
import classnames from 'classnames';

import { useModal } from 'contexts/modalProvider.context';
import { getActiveStep } from 'utils/home-page-activation';
import { ContextWrapper } from 'contexts/wrapper.context';
import { ACTIVATION_STEPS_MAPPING } from 'constants/home-page-activation';
import { PACKING_GUIDE_LINK } from 'constants/shipments';
import { LOCALE } from 'constants/intl-wrapper';

import ActivationStepProgressDot from 'components/HomePageActivation/components/HomePageActivationSteps/components/ActivationStepProgressDot/ActivationStepProgressDot';
import ProhibitedItemsAndUncoveredZones from 'components/ProhibitedItemsAndUncoveredZones/ProhibitedItemsAndUncoveredZones';
import BRButton from 'components/BRButton/BRButton';
import VerifyEmailModal from 'components/VerifyEmailModal/VerifyEmailModal';

import { ReactComponent as ChevronRight } from 'assets/bosta-icons/Chevron-right.svg';

import './ActivationSteps.less';

const ActivationSteps = ({ isSetupGuide, onAction = () => {} }) => {
  const [activeStep, setActiveStep] = useState(
    ACTIVATION_STEPS_MAPPING.CREATE_YOUR_FIRST_ORDER
  );

  const { businessInfo, handleOpenCreateEditPickupModal } =
    useContext(ContextWrapper);
  const { openModal } = useModal();

  const history = useHistory();
  const intl = useIntl();

  const userInfo = JSON.parse(localStorage.getItem('userInfo'))?.user;

  useEffect(() => {
    setActiveStep(getActiveStep(businessInfo));
  }, [businessInfo]);

  const handleOnCreateFirstOrderClick = () => {
    openModal(ProhibitedItemsAndUncoveredZones);
    history.push('/create-order');
    onAction();
  };

  const handleOnShippingPricesClick = () => {
    history.push('/settings/pricing-plan');
    onAction();
  };

  const handleRequestPackagingMaterialsClick = () => {
    history.push('/shop');
    onAction();
  };

  const handlePackingGuidelinesClick = () => {
    window.open(PACKING_GUIDE_LINK(), '_blank');
    onAction();
  };

  const handleOnSchedulePickupClick = () => {
    handleOpenCreateEditPickupModal({ openModal });
    onAction();
  };

  const handleTrackMaterialOrdersClick = () => {
    history.push({
      pathname: '/shop',
      state: {
        trackTab: true
      }
    });
    onAction();
  };

  const handleTrackOrdersClick = () => {
    window.open(
      `https://bosta.co/${
        intl.locale === LOCALE.EN ? 'en' : 'ar'
      }/tracking-shipments`,
      '_blank'
    );
    onAction();
  };

  const handleCompleteAccountDetailsClick = () => {
    if (userInfo?.emails[0]?.verified) {
      history.push('/business-information');
    } else {
      openModal(VerifyEmailModal);
    }

    onAction();
  };

  const ACTIVATION_STEPS = [
    {
      title: intl.formatMessage({
        id: 'home_page_activation.steps.create_account'
      })
    },
    {
      title: intl.formatMessage({
        id: 'home_page_activation.steps.create_first_order.title'
      }),
      subtitle: intl.formatMessage({
        id: 'home_page_activation.steps.create_first_order.subtitle'
      }),
      primaryButton: (
        <BRButton
          className="button-md"
          type="primary"
          label={intl.formatMessage({
            id: 'home_page_activation.steps.create_first_order.title'
          })}
          onClick={handleOnCreateFirstOrderClick}
        />
      ),
      secondaryButton: (
        <BRButton
          className="button-md"
          type="link-color"
          label={intl.formatMessage({
            id: 'home_page_activation.steps.create_first_order.shipping_prices'
          })}
          onClick={handleOnShippingPricesClick}
        />
      )
    },
    {
      title: intl.formatMessage({
        id: 'home_page_activation.steps.pack_and_prepare.title'
      }),
      subtitle: intl.formatMessage({
        id: 'home_page_activation.steps.pack_and_prepare.subtitle'
      }),
      primaryButton: (
        <BRButton
          type="primary"
          className="button-md"
          label={intl.formatMessage({
            id: 'home_page_activation.steps.pack_and_prepare.request_packaging_materials'
          })}
          onClick={handleRequestPackagingMaterialsClick}
        />
      ),
      secondaryButton: (
        <BRButton
          type="link-color"
          className="button-md"
          label={intl.formatMessage({
            id: 'home_page_activation.steps.pack_and_prepare.packing_guidelines'
          })}
          onClick={handlePackingGuidelinesClick}
        />
      )
    },
    {
      title: intl.formatMessage({
        id: 'home_page_activation.steps.schedule_pickup.title'
      }),
      subtitle: intl.formatMessage({
        id: 'home_page_activation.steps.schedule_pickup.subtitle'
      }),
      primaryButton: (
        <BRButton
          type="primary"
          className="button-md"
          label={intl.formatMessage({
            id: 'home_page_activation.steps.schedule_pickup.title'
          })}
          onClick={handleOnSchedulePickupClick}
        />
      ),
      secondaryButton: (
        <BRButton
          type="link-color"
          className="button-md"
          label={intl.formatMessage({
            id: 'home_page_activation.steps.schedule_pickup.track_material_order'
          })}
          onClick={handleTrackMaterialOrdersClick}
        />
      )
    },
    {
      title: intl.formatMessage({
        id: 'home_page_activation.steps.receive_revenues.title'
      }),
      subtitle: intl.formatMessage({
        id: 'home_page_activation.steps.receive_revenues.subtitle'
      }),
      primaryButton: (
        <BRButton
          type="primary"
          className="button-md"
          label={intl.formatMessage({
            id: 'home_page_activation.steps.receive_revenues.complete_details'
          })}
          onClick={handleCompleteAccountDetailsClick}
        />
      ),
      secondaryButton: (
        <BRButton
          type="link-color"
          className="button-md"
          label={intl.formatMessage({
            id: 'home_page_activation.steps.receive_revenues.track_orders'
          })}
          onClick={handleTrackOrdersClick}
        />
      )
    }
  ];

  return (
    <Steps
      direction="vertical"
      current={activeStep}
      progressDot={ActivationStepProgressDot}
      className={classnames('br-activation-steps', {
        'br-activation-steps__setup-guide': isSetupGuide
      })}
    >
      {ACTIVATION_STEPS.map(
        ({ title, subtitle, primaryButton, secondaryButton }, index) => (
          <Steps.Step
            title={
              <div className="br-activation-steps-content__container">
                <div>
                  <div className="br-activation-step__title body-medium">
                    {title}
                  </div>
                  {activeStep === index && (
                    <>
                      <div className="br-activation-step__subtitle body">
                        {subtitle}
                      </div>
                      <div className="br-activation-step__actions">
                        {primaryButton}
                        {secondaryButton}
                      </div>
                    </>
                  )}
                </div>
                {activeStep === index && !isSetupGuide && <ChevronRight />}
              </div>
            }
          />
        )
      )}
    </Steps>
  );
};

export default ActivationSteps;
