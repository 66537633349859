import React, { useEffect, useState, useCallback } from 'react';
import { Modal } from 'antd';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';

import { cleanEmptyString } from 'utils/helpers';
import { sendSegment } from 'utils/segment';
import { createContacts } from 'services/pickups';

import { notify } from 'components/Notify/Notify';
import BRContentHeader from 'components/BRContentHeader/BRContentHeader';
import ContactForm from 'components/Pickups/components/CreatePickupLocationFormModal/components/ContactForm/ContactForm';

const CreateContactPersonFormModal = (props) => {
  const { intl, close, getPickupLocation, pickupLocation, businessInfo } =
    props;
  const [pickupLocationPayload, setPickupLocationPayload] = useState(null);

  useEffect(() => {
    setPickupLocationPayload(pickupLocation);
  }, [pickupLocation]);

  const handleOnOk = useCallback(
    async (values) => {
      if (!values.isDefault) {
        values.isDefault = false;
      }
      if (pickupLocation.length !== 0) {
        try {
          const contacts = pickupLocationPayload.contacts.map((item) => {
            if (
              pickupLocationPayload?.contactPerson?._id === item._id &&
              !values.isDefault
            ) {
              item.isDefault = true;
            }
            return cleanEmptyString(item);
          });
          contacts.push(cleanEmptyString(values));

          delete pickupLocationPayload?.address?.district?.fmCode;

          await createContacts({
            id: pickupLocationPayload._id,
            payload: {
              contacts,
              locationName: pickupLocationPayload.locationName,
              address: pickupLocationPayload.address
            }
          });
          notify({
            msg: intl.formatMessage({
              id: 'settings.pickup_locations.pickup_location_form.add_contact_success'
            }),
            type: 'success'
          });
          getPickupLocation(pickupLocationPayload._id);
          sendSegment('E_PICKUP_NEW_CONTACT_CREATED');
          close();
        } catch (error) {
          notify({ msg: error.message, error });
        }
      } else {
        notify({
          msg: intl.formatMessage({
            id: 'pickups.pickup_settings.contacts.error_pickup_location'
          })
        });
        close();
      }
    },
    [
      close,
      getPickupLocation,
      intl,
      pickupLocation.length,
      pickupLocationPayload
    ]
  );

  const handleOnCancel = () => {
    sendSegment('E_PICKUP_NEW_CONTACT_CANCELED');
    close();
  };

  return (
    <Modal
      {...props}
      className="br-location-contact-modal"
      title={null}
      onCancel={close}
      footer={null}
      width={null}
    >
      <BRContentHeader
        title={intl.formatMessage({
          id: 'settings.pickup_locations.pickup_location_form.contact_person_title'
        })}
        subtitle={intl.formatMessage({
          id: 'settings.pickup_locations.pickup_location_form.contact_person_sub_title'
        })}
        titleClassName="br-location-contact-form-header__title display-xs"
        subTitleClassName="br-location-contact-form-header__subtitle"
        className={classNames('br-location-contact-form-header')}
      />
      <ContactForm
        addContact
        handleSubmit={handleOnOk}
        close={handleOnCancel}
        selectedCountryId={
          businessInfo?.hasForeignPhone && pickupLocation?.address?.country?._id
        }
      />
    </Modal>
  );
};
export default injectIntl(CreateContactPersonFormModal);
