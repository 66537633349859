import { COUNTRIES } from 'constants/country-data';

export const END_DATES_KEYS = ['confirmedAtEnd', 'dateRangeEnd'];
export const isSaudi = () =>
  JSON.parse(localStorage.getItem('userInfo'))?.user?.country?.code ===
    COUNTRIES[1].codeName || false;

export const USER_COUNTRY_CODE = JSON.parse(localStorage.getItem('userInfo'))
  ?.user?.country?.code;
/*
 * Cairo Timezone -> Africa/Cairo
 * Riyadh Timezone -> Asia/Riyadh
 */

export const COUNTRIES_TIME_ZONE = {
  egypt: 'Africa/Cairo',
  ksa: 'Asia/Riyadh'
};

export const COUNTRY_CURRENCY = isSaudi()
  ? 'common.saudi_amount'
  : 'common.egp_amount';

export const COUNTRY_CURRENCY_MODIFIED = isSaudi()
  ? 'common.SAR_modified'
  : 'common.EGP_modified';

export const PHONE_PLACEHOLDER = isSaudi()
  ? 'form.saudi_arabia_phone_placeholder'
  : 'form.egypt_phone_placeholder';

export const SECONDRY_PHONE_PLACEHOLDER = isSaudi()
  ? 'form.saudi_arabia_secondry_phone_placeholder'
  : 'form.egypt_secondry_phone_placeholder';

// export const STREET_PLACEHOLDER = isSaudi()
//   ? 'form.saudi_arabia_street_placeholder'
//   : 'form.egypt_street_placeholder';

const COUNTRY_STREET_PLACEHOLDER = {
  SA: 'form.saudi_arabia_street_placeholder',
  EG: 'form.egypt_street_placeholder',
  AE: 'form.emirati_street_placeholder',
  QA: 'form.qatari_street_placeholder',
  OM: 'form.oman_street_placeholder',
  KW: 'form.kuwait_street_placeholder'
};

export const STREET_PLACEHOLDER = (countryCode) =>
  COUNTRY_STREET_PLACEHOLDER[countryCode || USER_COUNTRY_CODE];

export const compareHelper = (textOne, textTwo) => {
  const one = textOne.title || textOne.name;
  const two = textTwo.title || textTwo.name;
  if (one.toLowerCase() < two.toLowerCase()) {
    return -1;
  } else if (one.toLowerCase() > two.toLowerCase()) {
    return 1;
  }
  return 0;
};

export const TABLE_PAGE_SIZE_OPTIONS = [50, 100, 200];

export const DAYS_OF_WEEK = {
  SUNDAY: 0,
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6
};
