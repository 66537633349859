import React from 'react';
import { Spin } from 'antd';

const LoadingWrapper = ({
  loading,
  children,
  indicator,
  className,
  wrapperClassName
}) => {
  return (
    <Spin
      size="middle"
      spinning={loading}
      indicator={indicator}
      className={className}
      wrapperClassName={wrapperClassName}
    >
      {children}
    </Spin>
  );
};

export default LoadingWrapper;
